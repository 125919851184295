<template>
  <v-container
    class="content-list pa-0 d-flex flex-column flex-nowrap align-stretch"
    fluid
  >
    <v-progress-circular
      v-if="loading"
      class="mx-auto my-6"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <template v-else-if="error" >
      <slot name="error" :error="error">
        <error-card
          :error="error"
          class="mt-16"
        ></error-card>
      </slot>
    </template>

    <template v-else-if="items.length === 0">
      <slot name="empty">
        <content-list-empty-card></content-list-empty-card>
      </slot>
    </template>

    <template v-else>
      <slot
        v-for="item in items"
        :item="item"
        name="item"
      >
        {{ item }}
      </slot>
    </template>
  </v-container>
</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'
import ContentListEmptyCard from '@/components/common/content/list/ContentListEmptyCard'


export default {
  components: {
    ContentListEmptyCard,
    ErrorCard,
  },

  props: {
    error: String,
    items: Array,
    loading: Boolean,
  }
}
</script>
