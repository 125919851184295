<template>
  <v-list-item
    v-ripple="false"
    :to="movementDetailView"
    class="movement-control-list-item white"
  >
    <v-list-item-avatar>
      <v-icon
        color="grey"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="px-3 text-no-wrap flex-column align-stretch">
      <v-row class="ma-0">
        <v-col class="pa-0 text-truncate overflow-hidden">
          {{ item.object_string }}&nbsp;
        </v-col>

        <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
           - {{ item.sum|measurement(item.currency_string) }}
         </v-col>
      </v-row>

      <v-row class="ma-0 flex-nowrap grey--text text--darken-2 overflow-hidden">
        <v-col class="pa-0 caption text-truncate">{{ item.type_string }}</v-col>
        <v-col class="pa-0 caption text-truncate text-right">{{ account && account.name }}</v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object,
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    account() {
      return this.getAccountById(this.item.account_id)
    },

    avatarIcon() {
      switch (this.item.status) {
        case 'На утверждении':
          return 'mdi-timer-sand'
        default:
          return 'mdi-cart-arrow-down'
      }
    },

    movementDetailView() {
      return {
        name: 'accounts.controls.details.requests.purchases.detail',
        params: {
          accountId: this.item.account_id,
          instanceId: this.item.id,
        }
      }
    }
  },
}
</script>

<style lang="scss">
.movement-control-list-item {
  overflow: hidden;
}
</style>