<template>
  <v-container class="request-detail-card-main pa-0">
    <v-row
      v-for="row in visibleRows"
      :key="row.key"
      class="ma-0"
    >
      <v-col
        class="pa-0 mr-2 flex-grow-0 grey--text text-no-wrap"
        v-text="row.label + ':'"
      ></v-col>

      <v-col
        class="pa-0"
        width="50%"
      >
        <slot :name="row.key">{{ row.value }}</slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    rows: Array
  },

  computed: {
    visibleRows() {
      return this.rows.filter(row => !row.hide)
    }
  },
}
</script>
