export const ERROR_DECIMAL = 'Некорректное число'
export const ERROR_FILES_MAX_TOTAL_SIZE = 'Суммарный размер файлов превышает максимальный'
export const ERROR_MAX_LENGTH_ARRAY = 'Слишком много элементов'
export const ERROR_MAX_LENGTH_STRING = 'Текст слишком длинный'
export const ERROR_MAX_VALUE = 'Значение выше допустимого'
export const ERROR_MIN_LENGTH_ARRAY = 'Недостаточно элементов'
export const ERROR_MIN_LENGTH_STRING = 'Текст слишком короткий'
export const ERROR_MIN_VALUE = 'Значение меньше допустимого'
export const ERROR_NUMERIC = 'Разрешен ввод только цифр'
export const ERROR_REQUIRED = 'Обязательное поле'


export const ERRORS_DESCRIPTIONS = {
    decimal: ERROR_DECIMAL,
    filesMaxTotalSize: ERROR_FILES_MAX_TOTAL_SIZE,
    maxLength: ERROR_MAX_LENGTH_STRING,
    maxValue: ERROR_MAX_VALUE,
    minLength: ERROR_MIN_LENGTH_STRING,
    minValue: ERROR_MIN_VALUE,
    numeric: ERROR_NUMERIC,
    required: ERROR_REQUIRED,
    requiredIf: ERROR_REQUIRED,
}


export function filesMaxTotalSize(maxTotalSize) {
    return (files) => files.reduce((acc, file) => acc + file.size, 0) < maxTotalSize
}


export function getErrors(field, errorsDescriptions = {}) {
    if (!field.$error) {
        return []
    }

    const $errorDescription = {...ERRORS_DESCRIPTIONS, ...errorsDescriptions}
    return Object.entries(field)
        .map(([key, value]) => {
            if (value) {
                return null
            }

            const description = $errorDescription[key]
            if (typeof(description) === 'function') {
                null
            } else {
                return description
            }
        })
        .filter(Boolean)
}