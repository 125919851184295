import {
    STREAM_CLIENT_SET,
    STREAM_ERROR_SET,
    STREAM_MESSAGE,
    STREAM_LAST_EVENT_SET,
} from '@/store/_prototypes/loadable/stream/mutations_types'
import {httpStatusGetError} from '@/assets/js/http'

export default () => ({
    [STREAM_CLIENT_SET]: (state, client) => {
        state.stream = client
    },

    [STREAM_MESSAGE]: (state, data) => {
        console.log(data)
    },

    [STREAM_ERROR_SET]: (state, error) => {
        state.streamError = error ? httpStatusGetError(error) : null
    },

    [STREAM_LAST_EVENT_SET]: (state, eventId) => {
        const lastEventId = Number(eventId)
        state.streamLastEventId = isNaN(lastEventId) ? undefined : lastEventId
    }
})