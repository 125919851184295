import {ACCOUNT_BALANCE_CHANGED} from '@/store/money/accounts/mutation_types'

function getJsonData(message) {
    return message.data ? JSON.parse(message.data) : null
}

export default {
    streamCustomEvents({ commit }, eventSource) {
        eventSource.addEventListener('account_balance_changed', (message) => {
            const data = getJsonData(message)
            const payload = data && {
                accountId: data.account_id,
                balance: data.balance
            }

            commit(`money/accounts/${ACCOUNT_BALANCE_CHANGED}`, payload, {root: true})
        })
    }
}