<template>
  <v-row class="ma-0 pa-0 pa-md-4 align-content-start align-content-md-center justify-md-center fill-height flex-column grey lighten-3">
    <user-subscription></user-subscription>

    <users-impersonate
      v-if="canImpersonate"
      class="mt-4"
    ></users-impersonate>

    <users-settings-application-info class="mt-4"></users-settings-application-info>

    <users-settings-exit class="mt-4"></users-settings-exit>
  </v-row>
</template>

<script>
import UserSubscription from '@/components/users/settings/UserSettingsSubscription.vue'
import UsersImpersonate from '@/components/users/settings/UsersSettingsImpersonate.vue'
import {PERMISSION_ADMIN_IMPERSONATE} from '@/assets/js/api/users'
import User from '@/mixins/User'
import UsersSettingsApplicationInfo from '@/components/users/settings/UsersSettingsApplicationInfo'
import UsersSettingsExit from '@/components/users/settings/UsersSettingsLogout.vue'

export default {
  mixins: [User],

  components: {
    UsersSettingsExit,
    UsersSettingsApplicationInfo,
    UsersImpersonate,
    UserSubscription
  },

  computed: {
    canImpersonate() {
      return this.userHasPermissions(PERMISSION_ADMIN_IMPERSONATE)
    },
  },
}
</script>
