<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    content-class="applications-list-modal-purchases overflow-hidden fill-height"
    max-width="50%"
    v-on:input="$emit('input', $event)"
  >
    <v-card class="d-flex flex-column fill-height overflow-hidden">
      <v-card-title class="grey--text text--darken-1 d-flex flex-nowrap py-0 pr-0">
        <p class="ma-0 text-body-1 flex-fill">
          Прикрепленные покупки к заявке
          <span class="text-no-wrap">{{ application.number }}</span>
          от {{ application.created_at | date }}
        </p>

        <v-btn
          class="flex-shrink-0"
          color="grey"
          fab
          text
          tile
          v-on:click="$emit('input', false)"
        >
          <v-icon size="32">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-list class="overflow-y-auto">
        <purchases-list-item
          v-for="purchase in application.purchases"
          :key="purchase.id"
          :purchase="purchase"
        ></purchases-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import PurchasesListItem from '@/components/requests/private/requests/purchases/list/PurchasesListItem'
export default {
  components: {
    PurchasesListItem
  },
  props: {
    application: Object,
    value: Boolean,
  }
}
</script>

<style lang="scss">
.applications-list-modal-purchases {
  &.v-dialog:not(.v-dialog--fullscreen) {
    height: auto;
    max-height: 100%;
  }
}
</style>