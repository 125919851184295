import { DETAIL_ERROR_SET, DETAIL_LOADING_SET, DETAIL_SET } from '@/store/_prototypes/loadable/detail/mutations_types'
import { ApiData } from '@/assets/js/api/_helpers'


export default (retrieveMethod) => {
  const retriever = new ApiData(retrieveMethod, null)

  return {
    retrieve({ commit }, params) {
        commit(DETAIL_LOADING_SET, true)
        commit(DETAIL_ERROR_SET, null)

        return retriever.call(params)
            .then(() => commit(DETAIL_SET, retriever.data))
            .catch(error => {
                commit(DETAIL_ERROR_SET, retriever.error)
                throw error
            })
            .finally(() => commit(DETAIL_LOADING_SET, retriever.loading))
    },
  }
}
