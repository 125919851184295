<template>
  <v-list-item
    :class="[itemColor]"
    :disabled="deleting"
    v-ripple="false"
    :to="movementDetailView"
    class="movement-list-item"
    v-on:contextmenu.native.prevent="menu && menu.component ? $root.$emit('movements.menu', [$event, movement]) : null"
  >
    <component
      v-if="menu"
      :is="menu.component"
      v-bind="menu.bind"
      ref="contextMenu"
      v-on="menu.on"
    ></component>

    <v-list-item-avatar>
      <v-progress-circular
        v-if="deleting"
        color="primary"
        indeterminate
      ></v-progress-circular>

      <v-icon
        v-else
        :color="avatarColor"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="px-3 text-no-wrap flex-column align-start overflow-hidden">
      <v-list-item-title class="text-truncate align-self-start">
         {{ movement.sum|measurement(currencyCode) }}
       </v-list-item-title>

      <v-list-item-subtitle class="text-truncate d-flex align-center caption">
        <span>{{ accountFrom && accountFrom.name }}</span>
        <v-icon size="16">mdi-chevron-right</v-icon>
        <span>{{ accountTo && accountTo.name }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/movements'
import {mapGetters} from 'vuex'
import MovementsListItemMenu from '@/components/requests/base/movements/list/MovementsListItemMenu'

export default {
  components: {MovementsListItemMenu},
  props: {
    movement: Object,
    deleting: Boolean,
  },

  data() {
    return {
      deleter: undefined,
      menu: {
        bind: {
          item: this.movement
        },
        component: MovementsListItemMenu,
        on: {
          copy: this.movementCopy,
          delete: this.movementDelete,
        },
        show: false,
      },
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
      'getAccountBelongIcon',
      'getAccountTypeIcon',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode',
    ]),

    accountFrom() {
      return this.getAccountById(this.movement.account_from_id)
    },

    accountTo() {
      return this.getAccountById(this.movement.account_to_id)
    },

    avatarIcon() {
      switch (this.movement.status) {
        case STATUS_ACCEPTED:
        case STATUS_SENT:
          return 'mdi-check'

        case STATUS_DECLINED:
          return 'mdi-alert-box'

        case STATUS_DRAFT:
          return 'mdi-pencil'

        case STATUS_CANCELED:
          return 'mdi-close-circle-outline'

        default:
          return 'mdi-cart-arrow-down'
      }
    },

    avatarColor() {
      return this.movement.status === STATUS_DECLINED ? 'red' : 'grey'
    },

    currencyCode() {
      return this.getCurrencyPostfixByNumericCode(this.movement.currency_to_id)
    },

    itemColor() {
      switch (this.movement.status) {
        case STATUS_ACCEPTED:
        case STATUS_CANCELED:
          return 'white'
        case STATUS_DECLINED:
          return 'red lighten-5'
        default:
          return 'white'
      }
    },

    menuEnabled() {
      return this.menu && this.menu
    },

    movementDetailView() {
      console.warn('Please define `movementDetailView` computed')
      return undefined
    }
  },

  methods: {
    movementCopy() {
      console.warn('Please define `movementCopy` method')
    },

    movementDelete() {
      this.deleter.call({id: this.movement.id}).then(() => this.$emit('deleted'))
    },
  }
}
</script>

<style lang="scss">
.movement-list-item {
  overflow: hidden;
}
</style>