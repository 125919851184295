<template v-else>
  <v-col class="flex-fill d-flex flex-column justify-space-between flex-sm-row">
    <v-btn
      key="cancel"
      :block="$vuetify.breakpoint.xsOnly"
      :disabled="isDelegating || isGivingOut"
      class="my-2 my-sm-0 white--text"
      color="red"
      large
      outlined
      v-on:click="gotoBack"
    >
      <v-icon left>mdi-close</v-icon>
      Отмена
    </v-btn>
    <v-spacer></v-spacer>

    <v-btn
      key="give_out"
      :block="$vuetify.breakpoint.xsOnly"
      :disabled="isDelegating"
      :loading="isGivingOut"
      class="my-4 my-sm-0 white--text"
      color="deep-orange"
      width="196px"
      large
      v-on:click="$emit('submit')"
    >
      <v-icon
        color="white"
        left
      >mdi-account-cash</v-icon>
      Возместить
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    instance: Object,
    isDelegating: Boolean,
    isGivingOut: Boolean,
  },

  methods: {
    gotoBack() {
      let backref;
      if (typeof this.$route.meta.backref === 'function') {
        backref = this.$route.meta.backref(this.$route)
      } else {
        backref = this.$route.meta.backref
      }

      this.$router.push(backref)
    },
  }
}
</script>