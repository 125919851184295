<template>
  <v-card class="d-flex flex-column">
    <v-card-title>
      <p class="ma-0">
        Создать заявку на заказ денежных средств в размере&nbsp;
        <span class="text-no-wrap">{{ overcharge|measurement(postfix) }}?</span>
      </p>
    </v-card-title>

    <v-card-text class="text-body-2">
      Невозможно создать заявку на перещение денежных средств,
      т.к. сумма перевода приведет к перерасходу средств.
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions class="my-4 d-flex justify-space-around">
      <v-progress-circular
          v-if="isLoading"
          color="primary"
          indeterminate
      ></v-progress-circular>

      <template v-else>
        <v-btn
          color="green"
          width="128px"
          dark
          v-on:click="submit"
        >
          <v-icon left>mdi-check</v-icon>
          Да
        </v-btn>

        <v-btn
          color="red"
          width="128px"
          dark
          v-on:click="$emit('close')"
        >
          <v-icon left>mdi-close</v-icon>
          Нет
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import ModalDialogConfirm from '@/components/common/modals/ModalDialogConfirm'

export default {
  extends: ModalDialogConfirm,

  props: {
    callback: Function,
    currency: Object,
    overcharge: Number,
    value: Boolean,
  },

  computed: {
    ...mapGetters('money/currencies', [
        'getCurrencyPostfixByNumericCode'
    ]),

    postfix() {
      return this.getCurrencyPostfixByNumericCode(this.currency && this.currency.numeric_code)
    },
  },
}
</script>