<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    class="dialog-requests-private-report-table-settings"
    max-width="50%"
    v-on:input="$emit('input', $event)"
  >
    <v-card class="d-flex flex-column">
      <v-card-title>Настройка фильтров</v-card-title>

      <v-divider></v-divider>

      <v-card-subtitle class="mt-4 pb-0">Дата создания</v-card-subtitle>

      <v-card-text class="d-flex flex-row flex-wrap align-center">
        <v-date-field
          v-model="filtersTemp.created_at__gte"
          class-field="the-accounts-private-report-view__filters-date-field mb-2"
          label="дата с"
          clearable
          dense
          hide-details
        ></v-date-field>

        <span class="px-2">—</span>

        <v-date-field
          v-model="filtersTemp.created_at__lte"
          class-field="the-accounts-private-report-view__filters-date-field mb-2"
          label="дата по"
          clearable
          dense
          hide-details
        ></v-date-field>
      </v-card-text>

      <v-divider></v-divider>
      <v-spacer></v-spacer>

      <v-card-actions class="d-flex justify-space-between py-6">
        <v-btn
          color="red"
          width="150px"
          outlined
          v-on:click="close"
        >
          <v-icon left>mdi-close</v-icon>
          Отмена
        </v-btn>

        <v-btn
          color="green"
          width="150px"
          dark
          v-on:click="apply"
        >
          <v-icon left>mdi-check</v-icon>
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VDateField from '@/components/inputs/VDateField'
export default {
  components: {VDateField},
  props: {
    filters: Object,
    value: Boolean
  },

  data() {
    return {
      filtersTemp: [],
    }
  },

  watch: {
    value() {
      if (this.value) {
        this.filtersTemp = {...this.filters}
      }
    }
  },

  methods: {
    apply() {
      Object.keys(this.filters).forEach(key => this.filters[key] = this.filtersTemp[key])
      this.close()
    },

    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
.dialog-requests-private-report-table-settings {
  &__checkbox {
    max-width: 200px;
    min-width: 200px;
    width: 200px;
  }
}
</style>
