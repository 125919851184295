import movements from './movements'
import receipts_cash from './receipts_cash'


export default {
    namespaced: true,

    modules: {
        movements,
        receipts_cash,
    }
}