<template>
  <money-give-outs-approved-list-infinity-scroll
    :filters="{exclude__status: 'canceled', is_completed: false}"
  ></money-give-outs-approved-list-infinity-scroll>
</template>

<script>
import MoneyGiveOutsApprovedListInfinityScroll
  from '@/components/requests/matching/money/give_outs_approved/list/MoneyGiveOutsApprovedListInfinityScroll'

export default {
  components: {
    MoneyGiveOutsApprovedListInfinityScroll
  },
}
</script>
