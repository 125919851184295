<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper'

export default {
  extends: ContentDetailWrapper,

  data() {
    return {
      pk: 'number'
    }
  },

  computed: {
    ...mapGetters('requests/matching/money/give_outs', [
      'getRequestByNumber'
    ]),
    ...mapState('requests/matching/money/give_outs', {
      'error': 'listError',
      'loading': 'listLoading',
    }),

    instance() {
      return this.getRequestByNumber(this.instanceId)
    },
  },

  methods: {
    ...mapActions('requests/matching/money/give_outs', {
      retrieve: 'streamStart',
    }),
  }
}
</script>