<template>
  <div>
    {{ getRowStatus(item.status) }}
    <v-tooltip
      color="grey lighten-2"
      content-class="black--text"
      left
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-if="item.document === 'application' && item.closed_at"
          color="green"
          size="20"
          v-on="on"
        >
          mdi-check-circle
        </v-icon>
      </template>

      Закрыт {{ item.closed_at }}
    </v-tooltip>
  </div>
</template>

<script>
import {getHumanReadableStatus as getPurchaseHumanReadableStatus} from '@/assets/js/api/requests/private/purchases'
import {getHumanReadableStatus as getApplicationHumanReadableStatus} from '@/assets/js/api/requests/private/applications'

export default {
  props: {
    item: Object,
  },

  methods: {
    getRowStatus(row) {
      switch (row.document) {
        case 'purchase':
          return getPurchaseHumanReadableStatus(row.status)
        case 'application':
          return getApplicationHumanReadableStatus(row.status)
        default:
          return 'Неизвестный документ'
      }
    },
  }
}
</script>
