import TheMatchingMoneyGiveOutsDetailWrapper from '@/views/requests/matching/money_give_outs/TheMatchingMoneyGiveOutsDetailWrapper'
import TheMatchingMoneyGiveOutsFormView from '@/views/requests/matching/money_give_outs/TheMatchingMoneyGiveOutsFormView'
import { PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW } from '@/assets/js/api/users'


export default [
    {
        path: '/requests/matching/money/give_outs/applications/:instanceId',
        component: TheMatchingMoneyGiveOutsDetailWrapper,
        props: true,
        meta: {
            auth: {
                roles: [PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW],
                forbiddenRedirect: {
                    name: 'requests.matching.archives_and_money_give_outs',
                },
                redirect: {
                    name: 'accounts.private'
                }
            },
        },

        children: [
            {
                path: '',
                name: 'requests.matching.money.give_outs.form',
                component: TheMatchingMoneyGiveOutsFormView,
                props: true,
                meta: {
                    backref: {
                        name: 'requests.matching.archives_and_money_give_outs',
                    },
                    title: 'Заявка ДС'
                },
            },
        ]
    },
]
