<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="py-4 py-md-16"
    >
      <template slot="body">
        <v-text-field
          v-model="formData.applicantName"
          :error="$v.formData.applicantName && $v.formData.applicantName.$error"
          :error-messages="errors.applicantName"
          class="mx-3"
          label="Заявитель"
          outlined
          readonly
        ></v-text-field>

        <v-text-field
          v-model="formData.employeeName"
          :error="$v.formData.employeeName && $v.formData.employeeName.$error"
          :error-messages="errors.employeeName"
          class="mx-3"
          label="Получатель"
          outlined
          readonly
        ></v-text-field>

        <v-row class="mx-0">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6">
            <v-text-field
              v-model="formData.sum"
              :error="$v.formData.sum && $v.formData.sum.$error"
              :error-messages="errors.sum"
              :suffix="getCurrencyPostfixByNumericCode(formData.currency)"
              label="Сумма"
              outlined
              readonly
              v-on:input="formData.sum = asNumber($event)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-text-field
              v-model="formData.object"
              :error="$v.formData.object && $v.formData.object.$error"
              :error-messages="errors.object"
              label="Объект"
              outlined
              readonly
            ></v-text-field>
          </v-col>

          <v-col class="py-0 flex-fill">
            <v-text-field
              v-model="formData.type"
              :error="$v.formData.type && $v.formData.type.$error"
              :error-messages="errors.type"
              label="Номенклатура"
              outlined
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <template v-if="!$auth.impersonating()">
          <v-divider></v-divider>

          <v-select
            v-model="formData.accountFrom"
            :error="$v.formData.accountFrom.$error"
            :error-messages="errors.accountFrom"
            :items="accountsItems"
            :readonly="$_readOnly"
            item-text="name"
            item-value="id"
            class="mx-3 mt-8"
            label="Счет списания"
            outlined
          ></v-select>

          <v-divider></v-divider>
        </template>
      </template>

      <template v-if="!$auth.impersonating()" slot="controls">
        <money-compensations-form-controls
          :instance="instance"
          :is-giving-out="creator.loading"
          v-on:submit="submit"
        ></money-compensations-form-controls>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import {mapActions, mapGetters, mapState} from 'vuex'
import MoneyCompensationsFormControls from '@/components/requests/matching/money/compensations/form/MoneyCompensationsFormControls'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    MoneyCompensationsFormControls,
    ContentForm
  },

  mixins: [Form],

  data() {
    return {
      balance: new ApiData(api.money.accounts.balance),
      creator: new ApiData(api.requests.matching.money.compensations.movement),

      formData: {
        accountFrom: null,
        applicantName: null,
        canDelegate: false,
        currency: null,
        employeeName: '',
        issuedAt: null,
        limit: 0,
        object: '',
        purpose: '',
        sum: 0,
        type: '',
      }
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    ...mapState('money/accounts', {
      accounts: 'list',
    }),

    accountsItems() {
      return this.accounts.filter(account => account.is_private && !account.is_account)
    },

    formDataErrors() {
      return {
        accountFrom: {
          balance: 'Не удалось проверить баланс счета списания. Попробуйте позже',
          maxValue: 'На счете недостаточно средств'
        }
      }
    },
  },

  mounted() {
    this.accountsItemsAutoSet()
  },

  watch: {
    'accountsItems': {
      deep: true,
      handler: () => this.accountsItemsAutoSet()
    }
  },

  methods: {
    ...mapActions('requests', {
      fetchRequestsSummary: 'fetchSummary'
    }),

    accountsItemsAutoSet() {
      if (this.accountsItems.length === 1) {
        this.formData.accountFrom = this.accountsItems[0].id
      }
    },

    apiFormData(formData) {
        return {
          'account_id': formData.accountFrom
        }
    },

    beforeSubmit() {
      return this.formData.accountFrom && this.balance.call({id: this.formData.accountFrom})
    },

    formDataCopy(instance) {
      this.formData = {
        ...this.formData,
        accountFrom: instance.accountFrom,
        applicantName: instance.applicant_name,
        canDelegate: instance.can_delegate,
        currency: instance.currency_id,
        employeeName: instance.employee_name,
        issuedAt: instance.issued_at,
        limit: instance.limit,
        object: instance.object_string,
        purpose: instance.purpose,
        sum: instance.sum,
        type: instance.type_string,
      }
    },

    messageOnSaved() {
      this.$notifications.show('Документ успешно отправлен')
    },

    onSaved() {
      this.fetchRequestsSummary()
      this.$router.push(this.$route.meta.backref)
    }
  },

  validations: {
    formData: {
      accountFrom: {
        maxValue(value) {
          return value && this.balance.data
            ? this.formData.sum < this.balance.data.amount
            : true
        },
        required,
      }
    }
  }
}
</script>