<script>
import {
  applicationCanBaAttachedToPurchase,
  isDeletable,
} from '@/assets/js/api/requests/private/applications'
import ContentContextMenu from '@/components/common/content/ContentContextMenu'

export default {
  extends: ContentContextMenu,

  data() {
    return {
      event: 'applications.menu'
    }
  },

  computed: {
    actions() {
      return [
        {
          event: 'copy',
          icon: 'mdi-content-copy',
          show: !this.$auth.impersonating(),
          text: 'Копировать',
        },
        {
          event: 'purchase',
          icon: 'mdi-cart',
          show: !this.$auth.impersonating() && applicationCanBaAttachedToPurchase(this.item),
          text: 'Покупка',
        },
        {
          event: 'delete',
          icon: 'mdi-delete',
          show: !this.$auth.impersonating() && this.item && isDeletable(this.item),
          text: 'Удалить',
        },
      ]
    }
  },
}
</script>
