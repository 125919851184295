export default {
    getAccountById: (state) => id => {
        return state.list.find(account => account.id === id)
    },

    getAccountBelongIcon: () => account => {
      if (account.is_organisation) {
        return 'mdi-briefcase'
      } else {
        return 'mdi-account'
      }
    },

    getAccountTypeIcon: () => account => {
        return account.is_account ? 'mdi-credit-card' : 'mdi-cash-multiple'
    }
}