<template>
  <v-container class="d-flex flex-column pa-0" fluid>
    <portal to="the-app-bar__btn-prepend">
      <v-btn
        :loading="isLoading"
        id="matching.money.give_outs.refresh"
        height="100%"
        dark
        depressed
        exact
        text
        tile
        v-on:click="fetch"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </portal>

    <v-tabs
      v-model="tab"
      :show-arrows="$vuetify.breakpoint.smAndDown"
      class="flex-grow-0"
      color="primary"
      centered
      grow
      v-on:change="saveTab($event)"
    >
      <v-tab>Запросы</v-tab>
      <v-tab>История</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="align-self-stretch"
      touchless
    >
      <v-tab-item
        v-for="($_items, index) in [itemsActive, itemsProcessed]"
        :key="index"
      >
        <content-list
          :error="error"
          :items="$_items"
          :loading="isLoading"
          v-on:load="fetch"
        >
          <template v-slot:item="{ item }">
            <component
              :is="listItemComponent(item)"
              :item="item"
            ></component>
          </template>
        </content-list>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import ContentList from '@/components/common/content/list/ContentList'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import MatchingArchivesListItem from '@/components/requests/matching/archives/requests/list/MatchingArchivesListItem'
import {CONTENT_TAB_SET_ROUTE} from '@/store/content_tabs/mutations_types'
import MatchingMoneyGiveOutsListItem from '@/components/requests/matching/money/give_outs/list/MatchingMoneyGiveOutsListItem'
import {STATUS_ACTIVE} from '@/assets/js/api/requests/matching/archives/requests'
import { comparatorBy } from '@/assets/js/api/common'

export default {
  components: {
    ContentList
  },

  data() {
    return {
      tab: 0,
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/requests', {
        matchingArchivesHasNewComments: 'hasNewComments',
        matchingArchivesIsNew: 'isNew',
        matchingArchivesIsLoading: 'streamIsLoading',
    }),

    ...mapGetters('requests/matching/money/give_outs', {
        matchingMoneyGiveOutsHasNewComments: 'hasNewComments',
        matchingMoneyGiveOutsIsNew: 'isNew',
        matchingMoneyGiveOutsIsLoading: 'streamIsLoading',
    }),

    ...mapState('content_tabs', [
      'previousRoute'
    ]),

    ...mapState('requests/matching/archives/requests', {
      matchingArchivesStreamError: 'streamError',
      matchingArchivesStreamLastEventId: 'streamLastEventId',
      matchingArchivesList: 'list',
    }),

    ...mapState('requests/matching/money/give_outs', {
      matchingMoneyGiveOutsStreamError: 'streamError',
      matchingMoneyGiveOutsStreamLastEventId: 'streamLastEventId',
      matchingMoneyGiveOutsList: 'list',
    }),

    error() {
      if (this.matchingArchivesStreamError && this.matchingArchivesStreamLastEventId === undefined) {
        return this.matchingArchivesStreamError
      } else if (this.matchingMoneyGiveOutsStreamError && this.matchingMoneyGiveOutsStreamLastEventId === undefined) {
        return this.matchingMoneyGiveOutsStreamError
      } else {
        return ''
      }
    },

    isLoading() {
      return this.matchingArchivesIsLoading || this.matchingMoneyGiveOutsIsLoading
    },

    items() {
      const hasNewMessages = [
        ...this.matchingArchivesList.filter(item => this.matchingArchivesHasNewComments(item)),
        ...this.matchingMoneyGiveOutsList.filter(item => this.matchingMoneyGiveOutsHasNewComments(item)),
      ]

      const newItems = [
        ...this.matchingArchivesList.filter(item => this.matchingArchivesIsNew(item)),
        ...this.matchingMoneyGiveOutsList.filter(item => this.matchingMoneyGiveOutsIsNew(item)),
      ]

      const other = [
        ...this.matchingArchivesList.filter(item => ![...newItems, ...hasNewMessages].includes(item)),
        ...this.matchingMoneyGiveOutsList.filter(item => ![...newItems, ...hasNewMessages].includes(item)),
      ]

      return [
        ...hasNewMessages,
        ...newItems,
        ...other,
      ]
    },

    itemsActive() {
      return this.items.filter(item => !item.is_processed && item.status === STATUS_ACTIVE).sort(comparatorBy('created_at')).reverse()
    },

    itemsProcessed() {
      return this.items.filter(item => !this.itemsActive.includes(item)).sort(comparatorBy('created_at')).reverse()
    }
  },

  created() {
    if (this.previousRoute && this.previousRoute.name === this.$route.name) {
      this.tab = this.previousRoute.tab
    } else {
      this.contentTabsSave(null)
    }
  },

  mounted() {
    this.streamsStart()
  },

  methods: {
    ...mapActions('requests/matching/archives/requests', {
      matchingArchivesStreamStart: 'streamStart',
      matchingArchivesStreamStop: 'streamStop',
    }),

    ...mapActions('requests/matching/money/give_outs', {
      matchingMoneyGiveOutsStreamStart: 'streamStart',
      matchingMoneyGiveOutsStreamStop: 'streamStop',
    }),

    ...mapMutations('content_tabs', {
      'contentTabsSave': CONTENT_TAB_SET_ROUTE,
    }),

    fetch() {
      this.streamsStop()
      this.streamsStart()
    },

    listItemComponent(item) {
      if (this.matchingArchivesList.includes(item)) {
        return MatchingArchivesListItem
      } else if (this.matchingMoneyGiveOutsList.includes(item)) {
        return MatchingMoneyGiveOutsListItem
      } else {
        throw new Error(`Unknown instance ${item}`)
      }
    },

    saveTab(index) {
      this.contentTabsSave({
        name: this.$route.name,
        tab: index,
      })
    },

    streamsStop() {
      this.matchingArchivesStreamStop()
      this.matchingMoneyGiveOutsStreamStop()
    },

    streamsStart() {
      this.matchingArchivesStreamStart()
      this.matchingMoneyGiveOutsStreamStart()
    }
  }
}
</script>