<script>
import {mapActions, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper.vue'

export default {
  extends: ContentDetailWrapper,

  props: {
    carId: {
      type: Number,
    },
    rentId: {
      type: [Number, undefined],
      default: null
    },
  },

  computed: {
    ...mapState('cars', {
      'carError': 'detailError',
      'carIsLoading': 'detailLoading',
      'carInstance': 'detail'
    }),
    ...mapState('cars/rents', {
      'rentError': 'detailError',
      'rentIsLoading': 'detailLoading',
      'instance': 'detail'
    }),

    error() {
      return this.carError || (this.rentId && this.rentError)
    },

    loading() {
      return this.carIsLoading || (this.rentId && this.rentIsLoading)
    },

    props() {
      return {
        car: this.carInstance,
        instance: this.instance,
      }
    }
  },

  methods: {
    ...mapActions('cars', {
      'carRetrieve': 'retrieve',
    }),
    ...mapActions('cars/rents', {
      'rentRetrieve': 'retrieve',
    }),

    retrieve() {
      return Promise.all([
        this.carRetrieve({ carId: this.carId }),
        this.rentId && this.rentRetrieve({ rentId: this.rentId }),
      ])
    },
  }
}
</script>
