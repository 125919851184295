<template v-slot:activator>
  <v-list-item
    active-class="primary"
    class="px-4"
    :to="detail"
  >
    <v-list-item-avatar tile>
      <v-container>
          <v-icon
            v-for="icon in [getAccountBelongIcon(account), getAccountTypeIcon(account)]"
            :key="icon"
            :color="isActive ? 'white lighten-1' : 'grey'"
            size="20"
            v-html="icon"
          ></v-icon>
      </v-container>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title
        :class="isActive ? 'text--lighten-4' : 'text--darken-1'"
        class="text-h5 grey--text"
      >
        {{ account.balance | measurement(getCurrencyPostfixByNumericCode(account.currency_id)) }}
      </v-list-item-title>

      <v-list-item-subtitle
        :class="isActive ? 'text--lighten-2' : 'text--darken-1'"
        class="grey--text"
      >{{ account.name }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    account: Object,
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
      'getAccountBelongIcon',
      'getAccountTypeIcon',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    detail() {
      return {
        name: 'accounts.controls.details.requests.movements',
        params: {
          accountId: this.account.id
        }
      }
    },

    isActive() {
      return this.$route.name.startsWith(this.detail.name) && Number(this.$route.params.accountId) === this.account.id
    },
  }
}
</script>