<script>
import MoneyAccountsBaseGroupedList from '@/components/money/accounts/base/MoneyAccountsBaseGroupedList'
import MoneyAccountsPrivateListItem from '@/components/money/accounts/private/MoneyAccountsPrivateListItem'
import {CURRENCY_ID__RUBBLES} from '@/assets/js/api/money'
import {measurement} from '@/filters/numbers'
import MoneyAccountsPrivateListVirtualStoreItem
  from '@/components/money/accounts/private/MoneyAccountsPrivateListVirtualStoreItem'

export default {
  extends: MoneyAccountsBaseGroupedList,

  computed: {
    groups() {
      const
        itemsNonCash = this.items.filter(account => account.is_private && account.is_account && account.currency_id === CURRENCY_ID__RUBBLES),
        itemsCash = this.items.filter(account => account.is_private && !account.is_account && account.currency_id === CURRENCY_ID__RUBBLES),
        itemsCashVirtual = this.items.filter(account => account.is_virtual_store && !account.is_control_only),
        itemsNotRubbles = this.items.filter(account => account.is_private && account.currency_id !== CURRENCY_ID__RUBBLES)

      return [
        {
          items: itemsNonCash.map(account => ({
            account,
            component: MoneyAccountsPrivateListItem,
          })),
          expanded: true,
          icon: 'mdi-credit-card',
          key: 'non-cash',
          subtitle: this.moneyRemain(itemsNonCash),
          title: 'Безналичные счета',
          visible: true
        },
        {
          items: [
            ...itemsCashVirtual.map(account => ({
              account,
              component: MoneyAccountsPrivateListVirtualStoreItem,
            })),
            ...itemsCash.map(account => ({
              account,
              component: MoneyAccountsPrivateListItem,
            }))
          ],
          expanded: true,
          icon: 'mdi-cash-multiple',
          key: 'cash',
          subtitle: this.moneyRemain([...itemsCashVirtual, ...itemsCash]),
          title: 'Наличные счета',
          visible: true
        },
        {
          items: itemsNotRubbles.map(account => ({
            account,
            component: MoneyAccountsPrivateListItem,
          })),
          expanded: true,
          icon: 'mdi-currency-usd',
          key: 'non-rubbles',
          subtitle: this.moneyRemain(itemsNotRubbles),
          title: 'Валютные счета',
          visible: true
        },
      ]
    }
  },

  methods: {
    moneyRemain(items) {
      const byCurrency = {}
      items.forEach(account => {
        let amount = byCurrency[account.currency_id]
        if (amount === undefined) {
          byCurrency[account.currency_id] = account.balance
        } else {
          byCurrency[account.currency_id] = amount + account.balance
        }
      })

      return Object.entries(byCurrency)
        .map(([currency_id, amount]) => measurement(amount, this.getCurrencyPostfixByNumericCode(Number(currency_id))))
        .join(', ')
    }
  }
}
</script>