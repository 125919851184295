import TheUserSettingsView from '@/views/users/TheUserSettingsView'

export default [
    {
        path: '/users/me/settings',
        name: 'users.me.settings',
        component: TheUserSettingsView,
        meta: {
            auth: true,
            title: 'Настройки',
        },
    }
]
