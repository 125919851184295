<script>
import ContentInfinityScrollView from '@/components/common/content/ContentInfinityScrollView'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import ReceiptsCashListItem from '@/components/requests/stores/receipts_cash/list/ReceiptsCashListItem'
import { PERMISSION_REQUESTS_RECEIPTS_CASH_CREATE } from '@/assets/js/api/users'
import User from '@/mixins/User'

export default {
  extends: ContentInfinityScrollView,
  mixins: [User],

  props: {
    accountId: Number,
  },

  data() {
    return {
      apiData: new ApiPaginatedAccumulatedData(api.requests.stores.receipts_cash.list),
      listItem: {
        component: ReceiptsCashListItem,
      }
    }
  },

  computed: {
    canCreate() {
      return this.userHasPermissions(PERMISSION_REQUESTS_RECEIPTS_CASH_CREATE)
    },

    groups() {
      return this.getGroupsByDate('created_at')
    }
  },

  watch: {
    'accountId': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },
}
</script>