<template>
  <v-list-item :to="to">
    <v-list-item-avatar class="rounded-0">
      <v-badge
        :color="badge && badge.background"
        avatar
        overlap
        :value="!!badge"
      >
        <template v-slot:badge>
          <v-icon
            :color="badge && badge.color"
            :size="badge && badge.size"
            v-html="badge && badge.icon"
          ></v-icon>
        </template>

        <slot name="avatar">
          <v-icon>mdi-close</v-icon>
        </slot>
      </v-badge>
    </v-list-item-avatar>

    <v-list-item-content class="flex-column flex-nowrap align-stretch">
      <slot></slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {
  props: {
    canceled: Boolean,
    completed: Boolean,
    isNew: Boolean,
    hasNewMessage: Boolean,
    to: [String, Object]
  },

  data() {
    return {
      $_badge: {
        completed: {
          background: 'green lighten-4',
          color: 'green',
          icon: 'mdi-check',
          size: 16,
        },

        isNew: {
          background: 'green lighten-4',
          color: 'green',
          icon: 'mdi-alert-circle-outline',
          size: 24,
        },

        hasNewMessage: {
          background: 'deep-orange',
          color: 'deep-orange lighten-4',
          icon: 'mdi-email',
          size: 16,
        },

        rejected: {
          background: 'red lighten-4',
          color: 'red',
          icon: 'mdi-close',
          size: 16,
        },
      }
    }
  },

  computed: {
    badge() {
      if (this.hasNewMessage) {
        return this.$data.$_badge.hasNewMessage
      } else if (this.canceled) {
        return this.$data.$_badge.rejected
      } else if (this.completed) {
        return this.$data.$_badge.completed
      } else if (this.isNew) {
        return this.$data.$_badge.isNew
      } else {
        return null
      }
    }
  }
}
</script>