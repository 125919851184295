import {
  PERMISSION_CARS_RENTS_VIEW_ALL,
} from '@/assets/js/api/users'
import TheCarsRentsConflictsView from '@/views/cars/rents/conflicts/TheCarsRentsConflictsView.vue'
import TheCarsRentsConflictsDetaileldWrapperView
    from '@/views/cars/rents/conflicts/TheCarsRentsConflictsDetaileldWrapperView.vue'
import TheCarsRentsConflictsDetailedView from '@/views/cars/rents/conflicts/TheCarsRentsConflictsDetailedView.vue'


export default [
    {
        path: '/cars/rents/conflicts',
        name: 'cars.rents.conflicts.list',
        component: TheCarsRentsConflictsView,
        meta: {
            auth: {
                roles: PERMISSION_CARS_RENTS_VIEW_ALL,
                forbiddenRedirect: {
                    name: 'cars.list'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            title: 'Автомобили'
        },
    },
    {
        path: '/cars/:carId/rents/conflicts',
        component: TheCarsRentsConflictsDetaileldWrapperView,
        props: route => ({
            carId: Number(route.params.carId)
        }),
        children: [
            {
                path: '',
                name: 'cars.rents.conflicts.detailed',
                component: TheCarsRentsConflictsDetailedView,
                meta: {
                    auth: {
                        roles: PERMISSION_CARS_RENTS_VIEW_ALL,
                        forbiddenRedirect: {
                            name: 'cars.list'
                        },
                        redirect: {
                            name: 'auth.login'
                        }
                    },
                    backref: {
                        name: 'cars.rents.conflicts.list'
                    },
                    title: 'Автомобили'
                },
            }
        ]
    },
]
