<template>
  <v-container class="d-flex justify-end">
    <request-matching-result-button
      class="ml-0 ml-sm-4"
      color="green"
      icon="mdi-check"
      label="Утвердить"
      v-on:click="$emit('accept')"
    ></request-matching-result-button>

    <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>

    <request-matching-result-button
      class="ml-0 ml-sm-4"
      color="red"
      icon="mdi-close"
      label="Отклонить"
      v-on:click="$emit('reject')"
    ></request-matching-result-button>
  </v-container>
</template>

<script>
import RequestMatchingResultButton from '@/components/requests/base/matching/result/RequestMatchingResultButton'
export default {
  components: {
    RequestMatchingResultButton
  }
}
</script>
