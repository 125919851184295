<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import Validations from '@/mixins/Validations'
import ModalDialog from '@/components/common/modals/ModalDialog'

export default {
  extends: ModalDialog,
  mixins: [Validations],

  components: {
    ModalDialog,
    ContentForm
  },

  data() {
    return {
      $_formDataInitial: {},
      formData: {},
    }
  },

  created() {
    this.$data.$_formDataInitial = {...this.formData}
  },

  watch: {
    '$modal.visible'(value) {
      if (value) {
        this.$v.$reset()
        this.formData = Object.assign(this.formData, this.$data.$_formDataInitial)
      }
    }
  },

  methods: {
    async submit() {
      this.$modal.locked = true
      this.validating = true

      await this.beforeSubmit()
      this.$v.$touch()
      this.validating = false

      if (!this.$v.$error) {
        Promise.resolve(this.submitEvent())
          .finally(() => this.$modal.locked = false)
      } else {
        this.$modal.locked = false
      }
    },

    submitEvent() {
      this.$emit('input', this.formData)
    }
  },
}
</script>