<template>
  <v-list-item
    v-ripple="false"
    :to="detailView"
    class="movement-control-list-item white"
  >
    <v-list-item-avatar>
      <v-icon
        color="grey"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="px-3 text-no-wrap flex-column align-stretch overflow-hidden">
      <v-row class="ma-0">
        <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">{{ item.sum|measurement(accountPostfix) }}</v-col>
        <v-col class="pa-0 caption text-truncate grey--text text--darken-2 text-right">{{ item.created_at|date }}</v-col>
      </v-row>

      <v-row class="ma-0 flex-nowrap overflow-hidden">
        <v-col class="pa-0 caption text-truncate grey--text text--darken-2">{{ item.comment }}</v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'
import { STATUS_DECLINED, STATUS_DRAFT } from '@/assets/js/api/requests/stores/receipts_cash'

export default {
  props: {
    item: Object,
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    account() {
      return this.getAccountById(this.item.account_id)
    },

    accountPostfix() {
      return this.account
        ? this.getCurrencyPostfixByNumericCode(this.account.currency_id)
        : ""
    },

    avatarIcon() {
      if (this.item.status === STATUS_DRAFT) {
        return 'mdi-pencil'
      } else {
        return 'mdi-check'
      }
    },

    detailView() {
      if ([STATUS_DECLINED, STATUS_DRAFT].includes(this.item.status)) {
        return {
          name: 'accounts.stores.details.requests.receipts_cash.edit',
          params: {
            accountId: this.item.account_id,
            instanceId: this.item.id,
          }
        }
      } else {
        return {
          name: 'accounts.stores.details.requests.receipts_cash.detail',
          params: {
            accountId: this.item.account_id,
            instanceId: this.item.id,
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.movement-control-list-item {
  min-height: 62px;
  overflow: hidden;
}
</style>