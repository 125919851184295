import ControlsMovementsListInfinityScroll from '@/components/requests/controls/movements/list/ControlsMovementsListInfinityScroll'
import TheMoneyControlsMovementsDetailWrapper from '@/views/requests/controls/movements/TheMoneyControlsMovementsDetailWrapper'
import TheMoneyControlsMovementsFormView from '@/views/requests/controls/movements/TheMoneyControlsMovementsFormView'

export default [
    {
        path: 'requests/movements',
        name: 'accounts.controls.details.requests.movements',
        component: ControlsMovementsListInfinityScroll,
        meta: {
            backref: {name: 'accounts.controls'},
            title: 'Перемещения ДС'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
            filters: {account_from_id: Number(route.params.accountId)}
        }),
    },
    {
        path: 'requests/movements/:instanceId',
        component: TheMoneyControlsMovementsDetailWrapper,
        props: route => ({
            accountId: Number(route.params.accountId),
            instanceId: Number(route.params.instanceId),
        }),

        children: [
            {
                path: '',
                name: 'accounts.controls.details.requests.movements.detail',
                component: TheMoneyControlsMovementsFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: {
                        name: 'accounts.controls.details.requests.movements',
                    },
                    title: 'Просмотр перемещения'
                },
            },
        ]
    },
]