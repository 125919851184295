<script>
import {mapActions} from 'vuex'


export default {
  computed: {
    userEmail() {
      const user = this.$auth.user()
      return user ? user.email : null
    }
  },

  methods: {
    ...mapActions('money/accounts', {
      fetchAccounts: 'fetch'
    }),

    ...mapActions('money/currencies', {
      fetchCurrencies: 'fetch'
    }),

    ...mapActions('requests', {
      fetchRequestsSummary: 'fetchSummary'
    }),

    userHasPermissions(...permissions) {
      return !permissions.find(permission => !this.$auth.user().permissions_active.includes(permission))
    },

    async loadUserData(activateLoaders=true) {
      if (this.$auth.check()) {
        return Promise.all([
          this.fetchAccounts(activateLoaders),
          this.fetchCurrencies(),
          this.fetchRequestsSummary()
        ]).catch(error => {
          if (error.response && error.response.status === 401) {
            this.logout()
            this.$router.push({name: 'auth.login'})
          }
        })
      }
    },

    logout() {
      return this.$auth.logout({})
    }
  },
}
</script>
