<template>
  <v-sheet
    v-if="loading"
    class="fill-height align-center justify-center"
    :class="[{'d-flex': error || loading}]"
  >
    <v-progress-circular
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-sheet>

  <error-card
    v-else-if="error"
    :error="error"
  ></error-card>

  <v-container
    v-else
    :class="containerClass"
    class="align-start fill-height pa-0"
    fluid
  >
    <slot></slot>
  </v-container>

</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'

export default {
  components: {
    ErrorCard
  },

  props: {
    containerClass: [String, Array],
    error: String,
    loading: Boolean,
  }
}
</script>