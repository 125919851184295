import ThePrivateControlsMovementsDetailWrapper
    from '@/views/requests/private/controls/movements/ThePrivateControlsMovementsDetailWrapper'
import ThePrivateControlsMovementsFormView
    from '@/views/requests/private/controls/movements/ThePrivateControlsMovementsFormView'
import ControlsMovementsListInfinityScroll
    from '@/components/requests/private/controls/movements/list/ControlsMovementsListInfinityScroll'
import ControlsMovementsListItem from '@/components/requests/private/controls/movements/list/ControlsMovementsListItem'
import {STATUS_DRAFT} from '@/assets/js/api/requests/private/movements'


function detailsBackref (route) {
    return {
        name: 'accounts.private.details.controls.movements',
        params: {
            accountId: route.params.accountId,
        }
    }
}


export default [
    //
    // Applications
    //
    {
        path: 'controls/movements',
        name: 'accounts.private.details.controls.movements',
        component: ControlsMovementsListInfinityScroll,
        meta: {
            backref: {name: 'accounts.private'},
            title: 'Перемещения ДС'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
            filters: {account_from_id: Number(route.params.accountId), status__exclude: [STATUS_DRAFT]},
            listItemComponent: ControlsMovementsListItem,
        }),
    },
    {
        path: 'controls/movements/:instanceId',
        component: ThePrivateControlsMovementsDetailWrapper,
        props: true,

        children: [
            {
                path: '',
                name: 'accounts.private.details.controls.movements.detail',
                component: ThePrivateControlsMovementsFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Просмотр перемещения'
                },
            },
        ]
    }
]