<template>
  <v-form
    :disabled="readonly"
    class="comments-form d-flex flex-row flex-nowrap"
    ref="form"
    lazy-validation
  >
    <v-textarea
      v-model="formData.text"
      :disabled="readonly || isLoading"
      :error="$v.formData.text.$error"
      :error-messages="errors.text"
      auto-grow
      class="pt-0 mt-0"
      placeholder="Комментарий"
      ref="text"
      rows="1"
    ></v-textarea>

    <v-btn
      :disabled="$v.formData.$error || !formData.text || isLoading"
      :loading="isLoading"
      class="ml-auto"
      fab
      icon
      tile
      small
      v-on:click="submit"
    >
      <v-icon>mdi-send</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import Validations from '@/mixins/Validations'
import {maxLength, required} from 'vuelidate/lib/validators'

export default {
  name: 'comment-form',

  mixins: [Validations],

  props: {
    isLoading: Boolean,
  },

  data() {
    return {
      formData: {
        text: '',
      }
    }
  },

  watch: {
    'formData.text'(value) {
      if (!value) {
        this.$v.$reset()
      }
    }
  },

  methods: {

  },

  validations: {
    formData: {
      text: {
        maxLength: maxLength(255),
        required,
      }
    }
  }
}
</script>

<style lang="scss">
.v-form.comments-form {
  width: 100%;
}
</style>