<template>
  <v-row
    :class="{ 'red--border': error }"
    class="ma-0 v-image-field rounded"
  >
    <v-file-input
      v-show="false"
      accept="image/*,application/pdf"
      ref="VFileInputImages"
      multiple
      v-on:change="append($event)"
    ></v-file-input>

    <v-col
      v-for="file in value"
      :key="file.id"
      :cols="cols"
      class="pa-0 v-image-field__preview-wrapper"
    >
      <image-preview
        v-if="isImage(file)"
        :image="file"
        class="v-image-field__preview image pa-2"
      ></image-preview>

      <v-hover
        v-else
        v-slot="{ hover }"
      >
        <v-responsive
          aspect-ratio="1"
          class="v-image-field__preview file ma-2"
          style="cursor: pointer; position: relative;"
        >
          <a
            :download="file.filename"
            :href="file.url"
            class="d-block fill-height d-flex align-center justify-center pa-0 overflow-hidden"
            target="_blank"
          >
            <v-icon
              :size="$vuetify.breakpoint.mdAndUp ? 72 : 48"
            >mdi-file</v-icon>

            <v-icon
              v-if="hover"
              class="image-preview__preview-tooltip"
              color="white"
              size="32"
            >
              mdi-download
            </v-icon>

            <file-name
              :filename="file.filename"
              class="white--text caption text-md-body-2"
              style="position: absolute; bottom: 0; background: rgba(0,0,0,0.6); width: 100%;"
            ></file-name>
          </a>
        </v-responsive>
      </v-hover>

      <v-overlay
        :value="Boolean(file.loading)"
        color="white"
        opacity="0.5"
        absolute
        z-index="0"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>

      <v-btn
        v-if="!readonly"
        class="v-image-field__btn-remove"
        color="red"
        fab
        small
        absolute
        v-on:click="remove(file)"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-col>

    <v-col
      v-if="!readonly && uploadable"
      :cols="cols"
    >
      <v-responsive aspect-ratio="1">
        <v-btn
          :class="{'red--border': error}"
          class="v-image-field__btn-select"
          height="100%"
          width="100%"
          tile
          v-on:click="$refs.VFileInputImages.$refs.input.click()"
        >
          <v-icon
            color="grey"
            size="64"
          >
            mdi-image-plus
          </v-icon>
        </v-btn>
      </v-responsive>
    </v-col>

    <v-col class="py-0 flex-fill">
      <p
        v-if="errorMessage"
        class="ma-0 caption red--text"
      >{{ errorMessage }}</p>
    </v-col>
  </v-row>
</template>

<script>
import ImagePreview from '@/components/common/ImagePreview'
import FileName from '@/components/common/text/FileName'
export default {
  components: {FileName, ImagePreview},
  props: {
    error: [Boolean, String],
    errorMessages: Array,
    readonly: Boolean,
    uploadable: {
      type: Boolean,
      default: true,
    },
    value: Array,
  },

  computed: {
    errorMessage() {
      if (!this.error) {
        return
      }

      if (typeof(this.error) === 'string') {
        return this.error
      }

      if (this.errorMessages && this.errorMessages.length) {
        return this.errorMessages[0]
      }

      return null
    },

    cols() {
      return this.$vuetify.breakpoint.mdAndUp ? 3 : 4
    }
  },

  methods: {
    append(files) {
      const filesObjects = Array.from(files).map(file => ({
        $file: file,
        id: null,
        erp_file: null,
        filename: file.name,
        loading: false,
        size: file.size,
        url: URL.createObjectURL(file)
      }))
      this.$emit('input', [...this.value, ...filesObjects])
    },

    download(dataUrl, filename) {
      let link = document.createElement("a");
      link.download = filename;
      link.target = "_blank";
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    isImage(file) {
      const partials = file.filename.split('.')
      const format = partials[partials.length - 1]
      return ['BMP', 'SVG', 'JPG', 'JPEG', 'PNG', 'GIF'].includes(format.toUpperCase())
    },

    remove(file) {
      this.$emit('input', this.value.filter($_file => !Object.is($_file, file)))
    },
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_colors';

.v-image-field {
  &.red--border {
    border: 2px solid red !important;
  }

  &__btn-select {
    border: 2px dashed map-deep-get($grey, 'darken-1');
  }

  &__btn-remove {
    right: 16px;
    top: 16px;
  }

  &__preview {
    &.file {
      a {
        text-decoration: none;
      }

      &::before {
        border: 2px dashed map-deep-get($grey, 'darken-1');
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__preview-wrapper {
      position: relative;
  }
}

</style>
