import { measurement } from '@/filters/numbers'


export function getFilenameSplitted(filename) {
  const parts = filename.split('.')
  const fileText = (1 < parts.length ? parts.slice(0, parts.length - 1) : parts).join('.')
  const fileExt = 1 < parts.length ? '.' + parts[parts.length - 1] : ''

  return [fileText, fileExt]
}

export function fileExtension(filename) {
  return getFilenameSplitted(filename)[1]
}

export function fileNameText(filename) {
  return getFilenameSplitted(filename)[0]
}


export function fileSizeBytes(size) {
    return `${size} байт`
}


export function fileSizeKb(size) {
    return measurement(size  / 1024, 'Кб')
}


export function fileSizeMb(size) {
    return measurement(size / (1024 * 1024), 'Мб')
}


export function fileSizeGb(size) {
    return measurement(size / (1024 * 1024 * 1024), 'Гб')
}


export function fileSize(size) {
    switch (true) {
        case size < 1024:
            return fileSizeBytes(size)
        case size < 1024 * 1024:
            return fileSizeKb(size)
        case size < 1024 * 1024 * 1024:
            return fileSizeMb(size)
        default:
            return fileSizeMb(size)
    }
}


export default {
    fileExtension,
    fileNameText,
    fileSize,
    fileSizeBytes,
    fileSizeKb,
    fileSizeMb,
    fileSizeGb
}