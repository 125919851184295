import actionsDetail from '@/store/_prototypes/loadable/detail/actions'
import mutationsDetail from '@/store/_prototypes/loadable/detail/mutations'
import stateDetail from '@/store/_prototypes/loadable/detail/state'

import api from '@/assets/js/api'
import conflicts from './conflicts'

export default {
    namespaced: true,

    actions: {
        ...actionsDetail(api.cars.rents.retrieve),
    },
    mutations: {
        ...mutationsDetail(),
    },
    state: {
        ...stateDetail(),
    },
    modules: {
        conflicts,
    }
}
