<script>
import StatusAlertWidget from '@/components/requests/base/widgets/StatusAlertWidget.vue'

export default {
  name: 'ApplicationStatusAlertWidget',
  extends: StatusAlertWidget,

  computed: {
    icon() {
      if (!this.instance) {
        return undefined
      }

      if (this.instance.is_issued) {
        return 'mdi-check-all'
      }

      switch (this.instance[this.statusKey]) {
        case this.acceptedOn:
          return this.acceptedIcon
        case this.canceledOn:
          return this.canceledIcon
        case this.declinedOn:
          return this.declinedIcon
        case this.draftOn:
          return this.draftIcon
        default:
          return this.waitingIcon
      }
    },

    statusText() {
      if (!this.instance) {
        return undefined
      }

      if (this.instance.is_issued) {
        return 'Утверждено. Выдано'
      }

      switch (this.instance[this.statusKey]) {
        case this.acceptedOn:
          return 'Утверждена'
        case this.canceledOn:
          return 'Отклонена'
        case this.declinedOn:
          return 'Требуется корректировка'
        case this.draftOn:
          return 'Черновик'
        default:
          return 'Отправлена'
      }
    },
  }
}
</script>
