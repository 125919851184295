import auth from './auth'
import money from './money'
import objects from './objects'
import requests from './requests'
import users from './users'
import cars from './cars'


export default {
    auth,
    cars,
    money,
    objects,
    requests,
    users,
}
