import Vue from 'vue'
import Vuex from 'vuex'

import content_tabs from './content_tabs'
import money from './money'
import requests from './requests'
import sse from './sse'
import cars from '@/store/cars'


Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    cars,
    content_tabs,
    money,
    requests,
    sse,
  }
})
