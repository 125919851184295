<template>
  <v-navigation-drawer
    v-model="show"
    :width="$vuetify.breakpoint.mobile ? '75%' : '340px'"
    app
    fixed
    right
    temporary
  >
    <menu-mobile-list
      v-for="menuItem in menu"
      :key="menuItem.id"
      :item="menuItem"
    ></menu-mobile-list>

    <v-spacer></v-spacer>
    <p class="text-center caption grey--text text--darken-1">Последняя синхронизация {{ $auth.user().synced_at|datetime }}</p>
  </v-navigation-drawer>
</template>

<script>
import MenuMobileList from '@/components/menu/aside/mobile/MenuMobileList.vue'
import Menu from '@/components/navigation/Menu.vue'
export default {
  extends: Menu,

  components: {
    MenuMobileList
  },

  data() {
    return {
      show: false,
    }
  },

  mounted() {
    this.$root.$on('menu', (showMenu) => this.show = showMenu)
  },
}
</script>
