<template>
  <content-infinity-scroll
    :data="applications"
    :data-filters="filters"
    ref="contentLoader"
  >
    <applications-list-empty
        v-if="!applications.loading && !applications.data.length"
    ></applications-list-empty>

    <template v-else>
      <template v-for="group in applicationsGrouped">
        <p
          v-if="!noCaptions"
          :key="`title__${group.name.replace(' ', '_')}`"
          :class="group.name === $moment().format('DD MMMM') ? 'font-weight-bold primary--text' : 'grey--text text--darken-2'"
          class="px-4 pb-2 mb-1 mt-4 font-weight-regular caption"
        >
          {{ group.name }}
          {{ group.name === $moment().format('DD MMMM') ? '(Сегодня)' : '' }}
          <span class="grey--text">[{{ group.items.length }}]</span>
        </p>

        <v-divider :key="`divider_${group.name.replace(' ', '_')}`"></v-divider>

        <v-list
          :key="group.name.replace(' ', '_')"
          class="pa-0"
        >
          <application-list-item
            v-for="item in group.items"
            :key="item.uid"
            :application="item"
            ref="purchases"
            purchases-list-view
            v-on:deleted="applicationRemove(item)"
          ></application-list-item>
        </v-list>
      </template>
    </template>
  </content-infinity-scroll>
</template>

<script>
import api from '@/assets/js/api'
import ContentInfinityScroll from '@/components/common/content/ContentInfinityScroll'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import ApplicationsListEmpty from '@/components/requests/private/requests/applications/list/ApplicationsListEmpty'
import {STATUS_ACCEPTED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import {STATUS_CANCELED} from '@/assets/js/api/requests/private/applications'
import ApplicationListItem from '@/components/requests/private/requests/applications/list/ApplicationListItem'


export default {
  components: {
    ApplicationListItem,
    ApplicationsListEmpty,
    ContentInfinityScroll
  },

  props: {
    filters: Object,
    noCaptions: Boolean,
  },

  data() {
    return {
      applications: new ApiPaginatedAccumulatedData(api.requests.private.applications.list)
    }
  },

  computed: {
    applicationsConfirmedByDate() {
      let purchasesGrouped = []
      this.applications.data.filter(item => item.status === STATUS_ACCEPTED).forEach(purchase => {
        const date = this.$moment(purchase.issued_at).format('DD MMMM')
        let group = purchasesGrouped.find(group => group.name === date)
        if (!group) {
          group = {name: date, items: []}
          purchasesGrouped.push(group)
        }
        group.items.push(purchase)
      })
      return purchasesGrouped
    },

    applicationsCanceled() {
      return this.applications.data.filter(item => item.status === STATUS_CANCELED)
    },

    applicationsDeclined() {
      return this.applications.data.filter(item => item.status === STATUS_DECLINED)
    },

    applicationsDraft() {
      return this.applications.data.filter(item => item.status === STATUS_DRAFT)
    },

    applicationsSent() {
      return this.applications.data.filter(item => item.status === STATUS_SENT)
    },

    applicationsGrouped() {
      const data = [
        {
          name: 'Требуется исправление',
          items: this.applicationsDeclined,
        },
        {
          name: 'Черновики',
          items: this.applicationsDraft,
        },
        {
          name: 'Ожидают подтверждения',
          items: this.applicationsSent,
        },
        ...this.applicationsConfirmedByDate,
        {
          name: 'Отклонены',
          items: this.applicationsCanceled,
        }
      ]

      return data.filter(group => group.items.length)
    },
  },

  watch: {
    'filters': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },

  methods: {
    applicationRemove(item) {
      this.applications.data = this.applications.data.filter(application => application.id !== item.id)
    },

    reload() {
      this.$refs.contentLoader.load()
    }
  },
}
</script>
