<script>
import { getErrors } from '@/assets/js/vuelidate'

export default {
  props: {
    readonly: Boolean,
  },

  data() {
    return {
      formData: {},
      validating: false,
    }
  },

  computed: {
    errors() {
      const errors = {}
      if (!this.$v.formData.$dirty) {
        return {}
      }

      Object.keys(this.formData).forEach(field => {
        errors[field] = this.$v.formData[field] && getErrors(this.$v.formData[field], this.formDataErrors[field])
      })

      return errors
    },

    formDataErrors() {
      return {}
    },
  },

  methods: {
    async beforeSubmit() {},

    async submit() {
      this.validating = true
      await this.beforeSubmit()
      this.$v.$touch()
      this.validating = false
      if (!this.$v.$error) {
        this.submitEvent()
      }
    },

    submitEvent() {
      this.$emit('input', this.formData)
    },

    asNumber(value) {
      if (typeof value === 'string') {
        const asNumber = Number(value.replaceAll(' ', '').replaceAll(',', '.'))
        if (!isNaN(asNumber)) {
          return asNumber
        }
      }

      return value
    },
  },

  validations: {
    formData: {}
  }
}
</script>