import { request } from '@/assets/js/api/_helpers'


export const STATUS_ACCEPTED = 'accepted'
export const STATUS_CANCELED = 'canceled'
export const STATUS_DECLINED = 'declined'
export const STATUS_DRAFT = 'draft'
export const STATUS_SENT = 'sent'

export const STATUSES_ALLOW_CREATE_PURCHASE = [STATUS_ACCEPTED, STATUS_CANCELED, STATUS_SENT]

const ENDPOINT_APPLICATIONS = '/api/requests/private/applications'


export function applicationCanBaAttachedToPurchase(instance) {
    return STATUSES_ALLOW_CREATE_PURCHASE.includes(instance.status) && !instance.closed_at
}


export function getHumanReadableStatus(status) {
    switch (status) {
        case STATUS_ACCEPTED:
          return 'Утверждена'
        case STATUS_CANCELED:
          return 'Отклонена'
        case STATUS_DECLINED:
          return 'Требуется корректировка'
        case STATUS_DRAFT:
          return 'Черновик'
        default:
          return 'Отправлена'
    }
}


export function isDeletable(application) {
    return application.status === STATUS_DRAFT
}


export function getApplicationFormData(data) {
    const formData = new FormData()

    formData.append('comment', data.comment)
    formData.append('currency', data.currency)
    formData.append('issued_at', data.issued_at)
    formData.append('object', data.object || '')
    formData.append('office', data.office || '')
    formData.append('prepaid', data.prepaid ? '1' : '0')
    formData.append('purpose', data.purpose)
    formData.append('status', data.status)
    formData.append('sum', data.sum)

    data.files.map(file => file.id).filter(Boolean).forEach(fileId => formData.append('files', fileId))
    data.files.filter(file => !file.id).forEach(file => formData.append('files_upload', file.$file))

    return formData
}


export default {
    create: (config) => request('POST', ENDPOINT_APPLICATIONS, config),
    delete: ({ id, ...config }) => request('DELETE', `${ENDPOINT_APPLICATIONS}/${id}`, config),
    list: (config) => request( 'GET', ENDPOINT_APPLICATIONS, config),
    retrieve: ({ id, ...config }) => request( 'GET', `${ENDPOINT_APPLICATIONS}/${id}`, config),
    patch: ({ id, ...config }) => request('PATCH', `${ENDPOINT_APPLICATIONS}/${id}`, config),
}
