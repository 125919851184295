<script>
import ContentTabbed from '@/components/common/content/ContentTabbed'
import MovementsPrivateListItem from '@/components/requests/private/requests/movements/list/MovementsPrivateListItem'
import CarsRentsListInfinityScroll from '@/components/cars/rents/CarsRentsListInfinityScroll.vue'
import User from '@/mixins/User.vue'
import { PERMISSION_CARS_RENTS_VIEW_ALL } from '@/assets/js/api/users'

export default {
  extends: ContentTabbed,

  mixins: [User],

  computed: {
    canCreate() {
      return false
    },

    tabs() {
      return [
        {
          key: 'mine',
          bind: {
            filters: {user: this.$auth.user().id},
          },
          component: CarsRentsListInfinityScroll,
          title: 'Мои',
          visible: true,
        },
        {
          key: 'all',
          bind: {
            listItemComponent: MovementsPrivateListItem,
            showUser: true,
          },
          component: CarsRentsListInfinityScroll,
          title: 'Все',
          visible: this.userHasPermissions(PERMISSION_CARS_RENTS_VIEW_ALL)
        },
      ].filter(tab => tab.visible)
    },
  },
}
</script>
