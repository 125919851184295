import {DETAIL_ERROR_SET, DETAIL_LOADING_SET, DETAIL_SET} from './mutations_types'


export default () => ({
    [DETAIL_ERROR_SET]: (state, error) => {
        state.detailError = error
    },

    [DETAIL_LOADING_SET]: (state, isLoading) => {
        state.detailLoading = isLoading
    },

    [DETAIL_SET]: (state, data) => {
        state.detail = data
    }
})