<template>
  <v-container class="the-money-accounts-view pa-0" fluid>
    <v-navigation-drawer
      :width="$vuetify.breakpoint.mobile ? '100%' : '340px'"
      absolute
      clipped
      permanent
      stateless
    >
      <component :is="accountsListComponent"></component>
    </v-navigation-drawer>

    <v-container
      class="the-money-accounts-view__help fill-height align-center justify-center"
      v-if="!$vuetify.breakpoint.mobile"
      fluid
    >
      <v-sheet class="text-center transparent">
        <v-icon
          color="grey lighten-2"
          size="256"
        >mdi-view-carousel-outline</v-icon>

        <p class="grey--text text--lighten-2 text-h4 text-center">Выберите счет и тип заявки</p>
      </v-sheet>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: {
    accountsListComponent: Object,
  },
}
</script>

<style lang="scss">
.the-money-accounts-view {
  &__help {
      padding-left: 340px !important;
  }
}
</style>
