<template>
  <content-infinity-scroll
    :data="movements"
    :data-filters="filters"
    ref="contentLoader"
  >
    <movements-list-empty
        v-if="!movements.loading && !movements.data.length"
    ></movements-list-empty>

    <template v-else>
      <template v-for="group in movementsGrouped">
        <p
          v-if="!noCaptions"
          :key="`title__${group.name.replace(' ', '_')}`"
          class="px-4 pb-2 mb-1 mt-4 font-weight-regular grey--text text--darken-2 caption"
        >
          {{ group.name }}
          <span class="grey--text">[{{ group.items.length }}]</span>
        </p>

        <v-divider :key="`divider_${group.name.replace(' ', '_')}`"></v-divider>

        <v-list
          :key="group.name.replace(' ', '_')"
          class="pa-0"
        >
          <component
            v-for="item in group.items"
            :key="item.uid"
            :is="listItemComponent"
            :movement="item"
            ref="purchases"
            v-on:deleted="movementRemove(item)"
          ></component>
        </v-list>
      </template>
    </template>
  </content-infinity-scroll>
</template>

<script>
import ContentInfinityScroll from '@/components/common/content/ContentInfinityScroll'
import MovementsListEmpty from '@/components/requests/base/movements/list/MovementsListEmpty'
import MovementsListItem from '@/components/requests/base/movements/list/MovementsListItem'
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/movements'


export default {
  components: {
    MovementsListEmpty,
    ContentInfinityScroll
  },

  props: {
    filters: Object,
    noCaptions: Boolean,
    listItemComponent: {
      type: Object,
      default: MovementsListItem
    }
  },

  data() {
    return {
      movements: undefined,
    }
  },

  computed: {
    movementsConfirmedByDate() {
      let groups = []
      this.movements.data
        .filter(item => [STATUS_ACCEPTED, STATUS_SENT].includes(item.status))
        .forEach(movement => {
          const date = this.$moment(movement.created_at).format('DD MMMM')
          let group = groups.find(group => group.name === date)
          if (!group) {
            group = {name: date, items: []}
            groups.push(group)
          }
          group.items.push(movement)
        })
      return groups
    },

    movementsCanceled() {
      return this.movements.data.filter(item => item.status === STATUS_CANCELED)
    },

    movementsDeclined() {
      return this.movements.data.filter(item => item.status === STATUS_DECLINED)
    },

    movementsDraft() {
      return this.movements.data.filter(item => item.status === STATUS_DRAFT)
    },

    movementsGrouped() {
      const data = [
        {
          name: 'Требуется исправление',
          items: this.movementsDeclined,
        },
        {
          name: 'Черновики',
          items: this.movementsDraft,
        },
        ...this.movementsConfirmedByDate,
        {
          name: 'Отклонены',
          items: this.movementsCanceled,
        }
      ]

      return data.filter(group => group.items.length)
    },
  },

  watch: {
    'filters': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },

  methods: {
    movementRemove(item) {
      this.movements.data = this.movements.data.filter(movement => movement.id !== item.id)
    },

    reload() {
      this.$refs.contentLoader.load()
    }
  },
}
</script>