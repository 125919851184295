<template>
  <v-list-item
    :class="[itemColor]"
    v-ripple="false"
    class="money-requests-give-out-approved-list-item"
    :to="routeDetail"
  >
    <v-list-item-avatar>
      <v-icon
        :color="avatarColor"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="px-3 text-no-wrap flex-column align-stretch">
      <v-row class="ma-0 flex-nowrap">
        <v-col class="pa-0 text-truncate overflow-hidden">
          {{ item.employee_name }}&nbsp;
        </v-col>

        <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
           {{ item.sum|measurement(currencyCode) }}
         </v-col>
      </v-row>

      <v-row class="ma-0 flex-nowrap grey--text text--darken-2 overflow-hidden">
        <v-col class="pa-0 caption text-truncate">{{ item.purpose }}</v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object,
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),

    avatarIcon() {
      return 'mdi-cash-refund'
    },

    avatarColor() {
      return 'grey'
    },

    currency() {
      return this.getCurrencyByNumericCode(this.item.currency_id)
    },

    currencyCode() {
      return this.currency && (this.currency.symbol || this.currency.alphabetic_code)
    },

    routeDetail() {
      return {
        name: 'requests.matching.money.compensations.form',
        params: {
          instanceId: this.item.id
        }
      }
    },

    itemColor() {
      return 'white'
    },
  },
}
</script>
