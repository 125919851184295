<script>
import ContentInfinityScrollView from '@/components/common/content/ContentInfinityScrollView'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import ControlsMovementsListItem from '@/components/requests/controls/movements/list/ControlsMovementsListItem'

export default {
  extends: ContentInfinityScrollView,

  props: {
    accountId: Number,
  },

  data() {
    return {
      apiData: new ApiPaginatedAccumulatedData(api.requests.controls.movements.list),
      listItem: {
        component: ControlsMovementsListItem,
      }
    }
  },

  computed: {
    groups() {
      return this.getGroupsByDate('created_at')
    }
  },

  watch: {
    'accountId': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },
}
</script>