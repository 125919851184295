<script>
import {ApiData, ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import MixinList from '@/mixins/MixinList'

export default {
  mixins: [MixinList],

  props: {
    source: ApiPaginatedAccumulatedData,
    apiMethodDelete: Function,
    apiMethodUpdate: Function,
  },

  computed: {
    items() {
      const
        items = [],
        sourceDataCopy = [...this.source.data];

      sourceDataCopy.reverse().forEach(item => {
        if (!items.find($_item => this.getItemKey(item) === this.getItemKey($_item))) {
          if (this.apiMethodDelete && !item.$_deleter) {
            this.$set(item, '$_deleter', new ApiData(this.apiMethodDelete))
          }

          if (this.apiMethodUpdate && !item.$_updater) {
            this.$set(item, '$_updater', new ApiData(this.apiMethodUpdate))
          }

          items.push(item)
        }
      })

      return items.reverse()
    },
  },

  mounted() {
    this.load()
  },

  methods: {
    extend() {
      this.source.extend()
    },

    load() {
      this.source.call()
    },
  },
}
</script>