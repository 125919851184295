<template>
  <v-container class="d-flex flex-column pa-0" fluid>
    <v-tabs
      v-model="tab"
      :show-arrows="$vuetify.breakpoint.smAndDown"
      class="flex-grow-0"
      color="primary"
      centered
      grow
      v-on:change="saveTab($event)"
    >
      <v-tab>Запросы</v-tab>
      <v-tab>История</v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="align-self-stretch"
      touchless
    >
      <v-tab-item
        v-for="($_items, index) in [itemsActive, itemsProcessed]"
        :key="index"
      >
        <content-list
          :error="error"
          :items="$_items"
          :loading="isLoading"
        >
          <template v-slot:item="{ item }">
            <matching-archives-issues-list-item :item="item"></matching-archives-issues-list-item>
          </template>
        </content-list>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ContentList from '@/components/common/content/list/ContentList'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import MatchingArchivesIssuesListItem
  from '@/components/requests/matching/archives/issues/list/MatchingArchivesIssuesListItem'
import {CONTENT_TAB_SET_ROUTE} from '@/store/content_tabs/mutations_types'
import {comparatorBy} from '@/assets/js/api/common'

export default {
  components: {
    MatchingArchivesIssuesListItem,
    ContentList
  },

  data() {
    return {
      tab: 0,
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/issues', {
        isLoading: 'streamIsLoading',
    }),

    ...mapState('content_tabs', [
      'previousRoute'
    ]),

    ...mapState('requests/matching/archives/issues', {
      streamError: 'streamError',
      streamLastEventId: 'streamLastEventId',
      items: 'list',
    }),

    error() {
      if (this.streamError && this.streamLastEventId === undefined) {
        return this.streamError
      } else {
        return ''
      }
    },

    itemsActive() {
      return this.items.filter(item => !item.is_waiting && !item.is_processed).sort(comparatorBy('issued_at')).reverse()
    },

    itemsProcessed() {
      const
        waiting = this.items.filter(item => item.is_waiting),
        processed = this.items.filter(item => item.is_processed)

      return [...waiting, ...processed].sort(comparatorBy('issued_at')).reverse()
    }
  },

  created() {
    if (this.previousRoute && this.previousRoute.name === this.$route.name) {
      this.tab = this.previousRoute.tab
    } else {
      this.contentTabsSave(null)
    }
  },

  mounted() {
    this.streamStart()
  },

  methods: {
    ...mapActions('requests/matching/archives/issues', [
      'streamStart',
      'streamStop',
    ]),

    ...mapMutations('content_tabs', {
      'contentTabsSave': CONTENT_TAB_SET_ROUTE,
    }),

    fetch() {
      this.streamStop()
      this.streamStart()
    },

    saveTab(index) {
      this.contentTabsSave({
        name: this.$route.name,
        tab: index,
      })
    },
  }
}
</script>