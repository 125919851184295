import controls from './controls'
import matching from './matching'
import privateRequests from './private'
import stores from './stores'

import {request} from '@/assets/js/api/_helpers'


const ENDPOINT_REQUESTS_SUMMARY = '/api/requests/summary'


export default {
    controls,
    matching,
    stores,
    private: privateRequests,
    summary: (config) => request('GET', ENDPOINT_REQUESTS_SUMMARY, config),
}