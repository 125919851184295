<template>
  <portal
    :disabled="!opened"
    to="app"
  >
    <v-overlay
      :class="{desktop: !$vuetify.breakpoint.mobile}"
      :opacity="$vuetify.breakpoint.mobile ? 0.25 : 0"
      :value="opened"
      class="content-context-menu"
    >
      <v-menu
        v-model="opened"
        :position-x="position.x"
        :position-y="position.y"
        close-on-click
        offset-y
        v-on:input="$emit('input', $event)"
      >
        <v-list>
          <v-list-item
            v-for="action in $_actionsActive"
            :key="action.event"
            v-on:click="action.method ? action.method() : $emit(action.event)"
          >
            <v-list-item-avatar>
              <v-icon v-html="action.icon"></v-icon>
            </v-list-item-avatar>

            <v-list-item-title>{{ action.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-overlay>
  </portal>
</template>

<script>

export default {
  props: {
    item: Object,
  },

  data() {
    return {
      event: null,
      opened: false,
      position: {
        x: 0,
        y: 0,
      }
    }
  },

  computed: {
    $_actionsActive() {
      return this.actions.filter(action => action.show)
    },

    actions() {
      return []
    },
  },

  created() {
    this.$root.$on(this.event, this.$_open)
  },

  methods: {
    $_open([event, item]) {
      if (item.id === this.item.id) {
        [this.position.x, this.position.y] = [event.x, event.y]
        this.opened = this.$_actionsActive.length !== 0
      } else if (this.opened) {
        this.opened = false
      }
    },
  }
}
</script>

<style lang="scss">
.content-context-menu {
  &.desktop {
    pointer-events: none;
  }
}
</style>