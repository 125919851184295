<template>
  <content-infinity-scroll
    :data="purchases"
    :data-filters="filters"
    ref="contentLoader"
  >
    <purchases-list-empty
        v-if="!purchases.loading && !purchases.data.length"
    ></purchases-list-empty>

    <template v-else>
      <template v-for="group in purchasesGrouped">
        <p
          v-if="!noCaptions"
          :key="`title__${group.name.replace(' ', '_')}`"
          :class="group.name === $moment().format('DD MMMM') ? 'font-weight-bold primary--text' : 'grey--text text--darken-2'"
          class="px-4 pb-2 mb-1 mt-4 font-weight-regular caption"
        >
          {{ group.name }}
          {{ group.name === $moment().format('DD MMMM') ? '(Сегодня)' : '' }}
          <span class="grey--text">[{{ group.items.length }}]</span>
        </p>

        <v-divider :key="`divider_${group.name.replace(' ', '_')}`"></v-divider>

        <v-list
          :key="group.name.replace(' ', '_')"
          class="pa-0"
        >
          <purchases-list-item
            v-for="item in group.items"
            :key="item.uid"
            :purchase="item"
            ref="purchases"
            v-on:deleted="purchaseRemove(item)"
          ></purchases-list-item>
        </v-list>
      </template>
    </template>
  </content-infinity-scroll>
</template>

<script>
import api from '@/assets/js/api'
import ContentInfinityScroll from '@/components/common/content/ContentInfinityScroll'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import {STATUS_ACCEPTED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import {STATUS_CANCELED} from '@/assets/js/api/requests/private/applications'
import PurchasesListEmpty from '@/components/requests/private/requests/purchases/list/PurchasesListEmpty'
import PurchasesListItem from '@/components/requests/private/requests/purchases/list/PurchasesListItem'


export default {
  components: {
    PurchasesListItem,
    PurchasesListEmpty,
    ContentInfinityScroll
  },

  props: {
    filters: Object,
    noCaptions: Boolean,
  },

  data() {
    return {
      purchases: new ApiPaginatedAccumulatedData(api.requests.private.purchases.list)
    }
  },

  computed: {
    purchasesConfirmedByDate() {
      let purchasesGrouped = []
      this.purchases.data.filter(item => item.status === STATUS_ACCEPTED).forEach(purchase => {
        const date = this.$moment(purchase.purchased_at).format('DD MMMM')
        let group = purchasesGrouped.find(group => group.name === date)
        if (!group) {
          group = {name: date, items: []}
          purchasesGrouped.push(group)
        }
        group.items.push(purchase)
      })
      return purchasesGrouped
    },

    purchasesCanceled() {
      return this.purchases.data.filter(item => item.status === STATUS_CANCELED)
    },

    purchasesDeclined() {
      return this.purchases.data.filter(item => item.status === STATUS_DECLINED)
    },

    purchasesDraft() {
      return this.purchases.data.filter(item => item.status === STATUS_DRAFT)
    },

    purchasesSent() {
      return this.purchases.data.filter(item => item.status === STATUS_SENT)
    },

    purchasesGrouped() {
      const data = [
        {
          name: 'Требуется исправление',
          items: this.purchasesDeclined,
        },
        {
          name: 'Черновики',
          items: this.purchasesDraft,
        },
        {
          name: 'Ожидают подтверждения',
          items: this.purchasesSent,
        },
        ...this.purchasesConfirmedByDate,
        {
          name: 'Отклонены',
          items: this.purchasesCanceled,
        }
      ]

      return data.filter(group => group.items.length)
    },
  },

  watch: {
    'filters': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },

  methods: {
    purchaseRemove(item) {
      this.purchases.data = this.purchases.data.filter(purchase => purchase.id !== item.id)
    },

    reload() {
      this.$refs.contentLoader.load()
    }
  },
}
</script>