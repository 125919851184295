export function measurement(value, postfix= '') {
    const [valueAsNumber, error] = validateNumber(value)
    if (error) {
        return error
    }

    const price = valueAsNumber.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    }).replace(/,/g, ".")

    return postfix ? `${price} ${postfix}` : price
}


export function validateNumber(value) {
    const valueAsNumber = Number(value)
    if (isNaN(valueAsNumber)) {
        return [0, 'Invalid Number']
    } else {
        return [valueAsNumber, null]
    }
}


export default {
    measurement,

    numberPlaces(value, min, filler='0') {
        const [valueAsNumber, error] = validateNumber(value)
        if (error) {
            return error
        }

        let valueAsString = valueAsNumber.toString()
        while (valueAsString.length < min) {
            valueAsString = filler + valueAsString
        }

        return valueAsString
    },
}