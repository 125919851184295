<script>
import MoneyAccountsBaseListItem from '@/components/money/accounts/base/MoneyAccountsBaseListItem'

export default {
  extends: MoneyAccountsBaseListItem,

  computed: {
    submenuItems() {
      return [
        {
          key: 'movements',
          icon: 'mdi-subdirectory-arrow-right',
          title: 'Перемещения',
          to: {name: 'accounts.private.details.controls.movements', params: {accountId: this.account.id}},
          visible: true,
        },
      ]
    }
  }
}
</script>