import { request } from '@/assets/js/api/_helpers'


export const STATUS_ACCEPTED = 'accepted'
export const STATUS_CANCELED = 'canceled'
export const STATUS_DECLINED = 'declined'
export const STATUS_DRAFT = 'draft'
export const STATUS_SENT = 'sent'


const ENDPOINT_MOVEMENTS = '/api/requests/stores/movements'


export function isDeletable(purchase) {
    return purchase.status === STATUS_DRAFT
}


export function getMovementFormData(data) {
    const formData = new FormData()

    formData.append('account_from', data.account_from)
    formData.append('account_to', data.account_to)
    formData.append('comment', data.comment)
    formData.append('currency_ratio', data.currency_ratio)
    formData.append('status', data.status)
    formData.append('sum', data.sum)

    return formData
}


export default {
    create: (config) => request('POST', ENDPOINT_MOVEMENTS, config),
    delete: ({ id, ...config }) => request('DELETE', `${ENDPOINT_MOVEMENTS}/${id}`, config),
    list: (config) => request( 'GET', ENDPOINT_MOVEMENTS, config),
    retrieve: ({ id, ...config }) => request( 'GET', `${ENDPOINT_MOVEMENTS}/${id}`, config),
    patch: ({ id, ...config }) => request('PATCH', `${ENDPOINT_MOVEMENTS}/${id}`, config),
}