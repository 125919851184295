<template>
  <v-hover>
    <template v-slot="{ hover }">
      <v-btn
        :class="{'text-h6': $vuetify.breakpoint.smAndUp, 'text-caption': $vuetify.breakpoint.width < 400}"
        :color="flat ? null : color"
        :elevation="(hover && !$vuetify.breakpoint.mdAndUp) ? 8 : 0"
        :outlined="!flat"
        :text="flat"
        class="font-weight-regular"
        tile
        v-bind="bind"
        v-ripple
        v-on:click="$emit('click')"
      >
        <v-icon
          v-if="icon"
          :color="color"
          left
          v-html="icon">
        </v-icon>
        <span v-if="label">
          {{ label }}
        </span>
      </v-btn>
    </template>
  </v-hover>
</template>

<script>

export default {
  props: {
    bind: Object,
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    flat: Boolean,
    label: String,

  },
}
</script>
