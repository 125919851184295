<template>
  <v-container>
    <v-row class="ma-0 caption nowrap text-no-wrap">
      <p class="ma-0 text-truncate">{{ comment.author }}</p>
      <v-spacer></v-spacer>
      {{ comment.created_at|datetime }}
    </v-row>

    <v-divider></v-divider>

    {{ comment.text }}
  </v-container>
</template>

<script>
export default {
  props: {
    comment: Object
  }
}
</script>