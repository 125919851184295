import TheRequestsStoresMovementsTabbedView from '@/views/requests/stores/movements/TheRequestsStoresMovementsTabbedView'
import TheRequestsStoresMovementsFormView from '@/views/requests/stores/movements/TheRequestsStoresMovementsFormView'
import TheRequestsStoresMovementsDetailWrapper from '@/views/requests/stores/movements/TheRequestsStoresMovementsDetailWrapper'


function detailsBackref (route) {
    return {
        name: 'accounts.stores.details.requests.movements',
        params: {
            accountId: route.params.accountId,
        }
    }
}


export default [
    {
        path: 'requests/movements',
        name: 'accounts.stores.details.requests.movements',
        component: TheRequestsStoresMovementsTabbedView,
        meta: {
            backref: {
                name: 'accounts.stores'
            },
            title: 'Перемещения ДС'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
    },
    {
        path: 'requests/movements/create',
        name: 'accounts.stores.details.requests.movements.create',
        component: TheRequestsStoresMovementsFormView,
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
        meta: {
            backref: detailsBackref,
            title: 'Переместить ДС'
        },
    },
    {
        path: 'requests/movements/:instanceId',
        component: TheRequestsStoresMovementsDetailWrapper,
        props: true,

        children: [
            {
                path: '',
                name: 'accounts.stores.details.requests.movements.detail',
                component: TheRequestsStoresMovementsFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Просмотр перемещения'
                },
            },
            {
                path: 'edit',
                component: TheRequestsStoresMovementsFormView,
                name: 'accounts.stores.details.requests.movements.edit',
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: false
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Редактирование перемещения'
                },
            },
        ]
    },
]