<script>
import {mapActions, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper'

export default {
  extends: ContentDetailWrapper,

  computed: {
    ...mapState('requests/stores/movements', {
      'error': 'detailError',
      'loading': 'detailLoading',
      'instance': 'detail'
    })
  },

  methods: {
    ...mapActions('requests/stores/movements', [
      'retrieve'
    ]),
  }
}
</script>
