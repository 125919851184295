import TheJournalsView from '@/views/journals/TheJournalsView'
import {
    PERMISSION_ACCOUNTS_PRIVATE_VIEW,
} from '@/assets/js/api/users'

import applications from './applications'
import purchases from './purchases'
import reports from './reports'

export default [
    {
        path: '/journals',
        component: TheJournalsView,
        name: 'journals',
        meta: {
            auth: {
                roles: [ PERMISSION_ACCOUNTS_PRIVATE_VIEW ],
                redirect: {
                    name: 'accounts.private'
                }
            }
        },
        children: [
            ...applications,
            ...purchases,
            ...reports,
        ]
    }
]