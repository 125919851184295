function httpResponseNotFound(error) {
    if (!error.response) {
        console.error(error)
        return 'В ходе выполнения операции возникла програмная ошибка. Обратитесь к администратору'
    }

    if (error.code === 'ECONNABORTED') {
        return 'Превышен интервал ожидания для запроса'
    }
}


function httpStatusGetErrorLogin400(error) {
    switch (error.response.data.detail) {
        case 'Client certificate expired':
            return 'Учетная запись заблокирована по сроку действия сертификата. Для получения доступа обратитесь к администратору'
        case 'Code is invalid':
            return 'Введен неверный код'
        case 'Wrong username or password':
            return 'Введен неверный логин или пароль'
        case "Wrong user status! To generate a certificate for a user the user must have status New, Failed or In Process":
            return 'Вход по указанным данным невозможен. Обратитесь к администратору за новым логином и паролем'
        default:
            return 'Неизвестная ошибка, попробуйте повторить позже или обратитесь к администратору'
    }
}


export function httpStatusGetErrorLogin(error) {
    const errorText = httpResponseNotFound(error)
    if (errorText) {
        return errorText
    }

    switch (error.response.status) {
        case 401:
        case 403:
            return (error.response.data && error.response.data.detail) || 'Отказано в доступе'
        case 422:
            return httpStatusGetErrorLogin400(error)
        default:
          return 'Неизвестная ошибка, попробуйте повторить позже или обратитесь к администратору'
    }
}

export function httpStatusGetError (error) {
    let errorText = httpResponseNotFound(error)
    if (errorText) {
        return errorText
    }

    switch (error.response.status) {
        case 401:
        case 403:
            errorText = error.response.data && error.response.data.detail
            switch (errorText) {
                case 'Cannot impersonate as boss':
                    return 'Просмотр от лица управляющего запрещен'
                case 'Permission denied':
                    return 'Отказано в доступе'
                case 'User is inactive for web client':
                    return 'Пользователь не привязан ни к одной учетной записи.'
                default:
                    return errorText
            }
        case 408:
            return 'Превышена максимальная частота запросов за промежуток времени. Попробуйте позже.'
        case (error.response.status < 500):
            return error.response.data && error.response.data.detail
        default:
            return 'Сервер временно недоступен. Попробуйте позже.'
    }
}
