<script>
import MoneyAccountsBaseListItem from '@/components/money/accounts/base/MoneyAccountsBaseListItem'
import {
  PERMISSION_ACCOUNTS_STORES_REFILL_CREATE, PERMISSION_REQUESTS_RECEIPTS_CASH_VIEW
} from '@/assets/js/api/users'

export default {
  extends: MoneyAccountsBaseListItem,

  computed: {
    submenuItems() {
      return [
        {
          key: 'movements',
          alarms: this.getPrivateRequestsForAccountDeclinedCount(this.account.id, 'movements'),
          icon: 'mdi-cash-plus',
          title: 'Выдать ДС',
          to: {name: 'accounts.stores.details.requests.movements', params: {accountId: this.account.id}},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_STORES_REFILL_CREATE),
        },
        {
          key: 'purchases',
          alarms: this.getPrivateRequestsForAccountDeclinedCount(this.account.id, 'purchases'),
          icon: 'mdi-cash-refund',
          title: 'Пополнить счет',
          to: {name: 'accounts.stores.details.requests.receipts_cash', params: {accountId: this.account.id}},
          visible: this.userHasPermissions(PERMISSION_REQUESTS_RECEIPTS_CASH_VIEW),
        },
      ]
    }
  }
}
</script>