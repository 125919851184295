import applications from './applications'
import movements from './movements'
import purchases from './purchases'


export default {
    namespaced: true,

    modules: {
        applications,
        movements,
        purchases
    }
}