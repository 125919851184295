import compensations from './compensations'
import give_outs_approved from './give_outs_approved'


export default {
    namespaced: true,

    modules: {
        compensations,
        give_outs_approved,
    }
}
