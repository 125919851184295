import {LIST_ERROR_SET, LIST_LOADING_SET, LIST_SET} from './mutations_types'


export default () => ({
    [LIST_ERROR_SET]: (state, error) => {
        state.listError = error
    },

    [LIST_LOADING_SET]: (state, isLoading) => {
        state.listLoading = isLoading
    },

    [LIST_SET]: (state, items) => {
        state.list = items
    }
})