import TheRequestsStoresReceiptsCashDetailWrapper from '@/views/requests/stores/receipts_cash/TheRequestsStoresReceiptsCashDetailWrapper'
import TheRequestsStoresReceiptsCashFormView from '@/views/requests/stores/receipts_cash/TheRequestsStoresReceiptsCashFormView'
import TheRequestsStoresReceiptsCashTabbedView
    from '@/views/requests/stores/receipts_cash/TheRequestsStoresReceiptsCashTabbedView'


function detailsBackref (route) {
    return {
        name: 'accounts.stores.details.requests.receipts_cash',
        params: {
            accountId: route.params.accountId,
        }
    }
}


export default [
    {
        path: 'requests/receipts/cash',
        name: 'accounts.stores.details.requests.receipts_cash',
        component: TheRequestsStoresReceiptsCashTabbedView,
        meta: {
            backref: {
                name: 'accounts.stores'
            },
            title: 'Перемещения ДС'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
    },
    {
        path: 'requests/receipts/cash/create',
        name: 'accounts.stores.details.requests.receipts_cash.create',
        component: TheRequestsStoresReceiptsCashFormView,
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
        meta: {
            backref: detailsBackref,
            title: 'Поступление ДС'
        },
    },
    {
        path: 'requests/receipts/cash/:instanceId',
        component: TheRequestsStoresReceiptsCashDetailWrapper,
        props: true,

        children: [
            {
                path: '',
                name: 'accounts.stores.details.requests.receipts_cash.detail',
                component: TheRequestsStoresReceiptsCashFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Создать поступление'
                },
            },
            {
                path: 'edit',
                component: TheRequestsStoresReceiptsCashFormView,
                name: 'accounts.stores.details.requests.receipts_cash.edit',
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: false
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Редактирование перемещения'
                },
            },
        ]
    },
]