<template>
  <error-card
    v-if="data.error"
    :error="data.error"
    class="mt-16"
  ></error-card>

  <v-container
    v-else
    class="content-list pa-0 d-flex flex-column flex-nowrap align-stretch"
    fluid
  >
    <slot></slot>

    <template v-if="data.pageNext">
      <v-lazy
        v-if="!data.loading"
        v-model="loadMore"
      ></v-lazy>
    </template>

    <v-progress-circular
      v-if="data.pageNext || data.loading"
      class="mx-auto my-6"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import User from '@/mixins/User'

export default {
  mixins: [User],

  components: {
    ErrorCard,
  },

  props: {
    data: ApiPaginatedAccumulatedData,
    dataFilters: Object,
  },

  data() {
    return {
      loadMore: false,
    }
  },

  mounted() {
    this.load()
  },

  watch: {
    'dataFilters': {
      deep: true,
      handler() {
        this.load()
      }
    },

    'filters.account'() {
      this.load()
    },

    'loadMore'(value) {
      if (value && !this.data.loading) {
        this.extend()
      }
    }
  },

  methods: {
    extend() {
      this.loadMore = false
      this.data.extend({params: {page: this.data.pageNext, ...this.dataFilters, }})
    },

    async load() {
      this.loadMore = false
      return this.data.call({params: {page: 1, ...this.dataFilters, }})
    },
  }
}
</script>
