<script>
import MoneyAccountsBaseGroupedList from '@/components/money/accounts/base/MoneyAccountsBaseGroupedList'
import MoneyAccountsControlsListItemOperation from '@/components/money/accounts/controls/MoneyAccountsControlsListItemOperation'
import MoneyAccountsControlsListItemOther from '@/components/money/accounts/controls/MoneyAccountsControlsListItemOther'

export default {
  extends: MoneyAccountsBaseGroupedList,

  computed: {
    groups() {
      return [
        {
          items: this.items.filter(account => account.is_virtual_store).map(account => ({
            account,
            component: MoneyAccountsControlsListItemOperation,
          })),
          expanded: true,
          icon: 'mdi-cog',
          key: 'operations',
          title: 'Оперативные счета',
          visible: true
        },
        {
          items: this.items.filter(account => account.is_control_only).map(account => ({
            account,
            component: MoneyAccountsControlsListItemOther,
          })),
          expanded: true,
          icon: 'mdi-account',
          key: 'other',
          title: 'Другие счета',
          visible: true
        },
      ]
    }
  },
}
</script>