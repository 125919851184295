<template>
  <v-container class="the-cars-list-view pa-0 d-flex flex-column align-start" fluid>
    <v-toolbar
      class="flex-grow-0"
      width="100%"
      dense
    >
      <v-text-field
        v-model="params.search"
        class="mx-2 rounded-pill"
        background-color="grey lighten-2"
        append-icon="mdi-magnify"
        label="Поиск"
        clearable
        dense
        hide-details
        flat
        solo
      ></v-text-field>

      <v-divider vertical></v-divider>

      <v-btn
        color="transparent"
        height="48px"
        width="48px"
        depressed
        fab
        tile
        v-on:click="load"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <content-list
      :error="error"
      :items="items"
      :loading="isLoading"
    >
      <template v-slot:item="{ item }">
        <v-list-item
          two-line
          v-on:click="click(item)"
        >
          <v-list-item-avatar>
            <v-progress-circular
              v-if="rents.loading && carToView === item.id"
              color="primary"
              indeterminate
            ></v-progress-circular>

            <v-icon v-else>mdi-car</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle
              v-if="item.driver_id"
              class="red--text">
              {{ item.driver_id === $auth.user().id ? 'В моей эксплуатации' : 'Занят' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </content-list>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContentList from '@/components/common/content/list/ContentList.vue'
import { ApiData } from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'

export default {
  components: {
    ContentList,
  },

  data() {
    return {
      carToView: null,
      rents: new ApiData(api.cars.rents.list, []),
      params: {
        search: '',
      }
    }
  },

  computed: {
    ...mapState('cars', {
      'carsError': 'listError',
      'isLoading': 'listLoading',
      'items': 'list',
    }),

    error() {
      return this.carsError || this.rents.error
    }
  },

  mounted() {
    this.load()
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.load()
      },
    }
  },

  methods: {
    ...mapActions('cars', {
      'carsFetch': 'fetch'
    }),

    ...mapActions('cars/rents', {
      'rentsFetch': 'fetch'
    }),

    click(car) {
      if (car.driver_id === this.$auth.user().id) {
        this.carToView = car.id
        this.rents.call({
          params: {
            is_finished: false,
            car: car.id,
            user: this.$auth.user().id
          }
        }).then(() => {
          const rent = this.rents.data.find(Boolean)
          if (rent) {
            this.$router.push({
              name: 'cars.rents.edit',
              params: {
                carId: car.id,
                rentId: rent.id
              }
            })
          } else {
            this.load()
          }
        })
      } else {
        this.$router.push({
          name: 'cars.rents.create',
          params: {
            carId: car.id
          }
        })
      }
    },

    load() {
      return this.carsFetch({params: this.params})
    }
  },
}
</script>

<style lang="scss">
.the-cars-list-view {
  .v-toolbar {
    .v-toolbar__content {
      padding: 0 !important;
    }
  }
}
</style>
