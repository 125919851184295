import accounts from './accounts'
import currencies from './currencies'


export default {
    namespaced: true,

    modules: {
        accounts,
        currencies,
    }
}