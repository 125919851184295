<script>
import MovementsFormBase from '@/components/requests/base/movements/form/MovementsFormBase'
import {
  getMovementFormData,
  STATUS_ACCEPTED,
  STATUS_DRAFT,
  STATUS_SENT
} from '@/assets/js/api/requests/stores/movements'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import {mapMutations} from 'vuex'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'

export default {
  extends: MovementsFormBase,

  data() {
    return {
      copier: new ApiData(api.requests.stores.movements.retrieve),
      creator: new ApiData(this.instance ? api.requests.stores.movements.patch : api.requests.stores.movements.create),

      formData: {
        account_from: this.accountId || null,
        account_to: null,
        comment: '',
        currency_ratio: 1,
        number: '',
        status: STATUS_DRAFT,
        sum: '',
      },
    }
  },

  computed: {
    accountsFrom() {
      return this.accounts.filter(account => account.is_store)
    },

    accountsTo() {
      return this.accounts.filter(account => account.is_virtual_store)
    },
  },

  mounted() {
    if (this.$auth.impersonating() && this.$route.name === 'accounts.stores.details.requests.movements.edit') {
      this.$router.push({
        name: 'accounts.stores.details.requests.movements.detail',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id,
        }
      })
    }
  },

  methods: {
    ...mapMutations('requests/stores/movements', {
      'setStoreInstance': DETAIL_SET
    }),

    apiFormData: getMovementFormData,

    onSaved(data) {
      if ([STATUS_ACCEPTED, STATUS_SENT].includes(data.status)) {
        this.$router.push({
          name: 'accounts.stores.details.requests.movements',
          params: {
            accountId: data.account_from_id,
          }
        })
      } else {
        if (this.$route.name === 'accounts.stores.details.requests.movements.create') {
          this.$router.push({
            name: 'accounts.stores.details.requests.movements.edit',
            params: {
              accountId: this.creator.data.account_id,
              instanceId: this.creator.data.id,
            }
          })
        }
      }
    },
  }
}
</script>