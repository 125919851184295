<template>
  <v-bottom-navigation
    height="auto"
    :max-height="maxHeight"
    background-color="primary"
    class="the-menu-mobile pa-0 d-flex flex-column overflow-hidden"
    app
    dark
    v-touch="touchEvents"
  >
    <v-sheet
      class="white flex-shrink-0 my-2 mx-auto rounded-xl elevation-2"
      height="2px"
      width="64px"
    ></v-sheet>

    <v-row
      v-if="menuAlwaysVisible.length > 2"
      class="the-menu-mobile__buttons-main ma-0 flex-shrink-0"
      v-on:wheel.prevent.stop
      v-on:touchmove.prevent.stop
      v-on:scroll.prevent.stop
    >
      <v-col
        v-for="(button, index) in menuAlwaysVisible"
        :key="index"
        :cols="12 / menuAlwaysVisible.length"
        class="pa-0"
      >
        <v-badge
          :color="button.badge && button.badge.color"
          :content="button.badge && button.badge.content"
          :value="!!(button.badge && button.badge.content)"
          class="d-block"
          offset-x="35"
          offset-y="15"
        >
          <v-btn
            class="pa-0"
            height="52px"
            width="100%"
            :exact="button.exact"
            :to="button.to"
            v-on:click="expanded = false"
          >
            <span v-text="button.label"></span>
            <v-icon v-text="button.icon"></v-icon>
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>

    <v-container
      v-on:touchstart.stop
      v-on:touchmove.stop
      v-on:touchend.stop
      class="the-menu-mobile__navigation pa-0 flex-shrink-1 overflow-auto white"
      fluid
    >
      <v-row
        v-for="(row, index) in menuRows"
        :key="index"
        class="ma-0 flex-nowrap"
      >
        <the-menu-mobile-nav-link
          v-for="(menuItem, menuItemIndex) in row"
          :key="menuItem.key"
          :badge-color="menuItem.badgeColor"
          :badge-content="menuItem.badge"
          :callback="menuItemCallback(menuItem)"
          :class="{'border-bottom': index < menuRows.length - 1, 'border-right': menuItemIndex < colsNavigation - 1}"
          :icon="menuItem.icon"
          :label="menuItem.text"
          :width="`${100 / colsNavigation}%`"
          class="the-menu-mobile__navigation-rectangle flex-grow-0"
        ></the-menu-mobile-nav-link>
      </v-row>

      <v-divider></v-divider>

      <v-row class="ma-0 flex-nowrap overflow-hidden justify-end">
        <v-col
          class="pa-0 d-flex align-center flex-fill"
          :cols="$vuetify.breakpoint.smAndUp ? null : 12"
        >
          <v-list-item class="the-menu-mobile__user-info pa-0 pl-3 overflow-hidden">
            <v-list-item-avatar>
              <v-icon
                color="grey darken-1"
                size="32px"
              >mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="pr-3">
              <p class="ma-0 grey--text text--darken-3 text-truncate">{{ $auth.user().full_name }}</p>

              <p class="ma-0 grey--text text--darken-3 caption text-truncate">{{ $auth.user().email }}</p>

              <p class="ma-0 grey--text caption">
                <template v-if="$vuetify.breakpoint.smAndUp">Последняя синхронизация</template>
                {{ $auth.user().synced_at|datetime }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <the-menu-mobile-nav-link
          key="account.settings"
          :callback="menuItemCallback(buttonAccountSettings)"
          :icon="buttonAccountSettings.icon"
          :label="buttonAccountSettings.text"
          :width="`${100 / colsNavigation}%`"
          class="the-menu-mobile__navigation-rectangle border-left align-self-center flex-grow-0"
        ></the-menu-mobile-nav-link>

        <the-menu-mobile-nav-link
          key="logout"
          :callback="menuItemCallback(buttonAccountLogout)"
          :icon="buttonAccountLogout.icon"
          :label="buttonAccountLogout.text"
          :width="`${100 / colsNavigation}%`"
          class="the-menu-mobile__navigation-rectangle border-left align-self-center flex-grow-0"
        ></the-menu-mobile-nav-link>
      </v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
import TheMenuMobileNavLink from '@/components/navigation/mobile/main/TheMenuMobileNavLink.vue'
import Menu from '@/components/navigation/Menu.vue'
import {MenuItem} from '@/assets/js/menu'
import {
  getUserFullName, PERMISSION_ACCOUNTS_OPERATIONS_VIEW,
  PERMISSION_ACCOUNTS_PRIVATE_VIEW,
  PERMISSION_ACCOUNTS_STORES_VIEW
} from '@/assets/js/api/users'

export default {
  components: {TheMenuMobileNavLink},
  mixins: [Menu],

  data() {
    return {
      menuActive: null,
      expanded: false,
    }
  },

  computed: {
    buttonAccountLogout() {
      return this.getMenuItem('account.logout')
    },

    buttonAccountSettings() {
      return this.getMenuItem('account.settings')
    },

    colsNavigation() {
      switch (true) {
        case this.$vuetify.breakpoint.width < 340:
          return 3;
        case this.$vuetify.breakpoint.width < 440:
          return 4;
        case this.$vuetify.breakpoint.width < 540:
          return 5;
        case this.$vuetify.breakpoint.width < 600:
          return 6;
        case this.$vuetify.breakpoint.width < 700:
          return 7;
        case this.$vuetify.breakpoint.width < 800:
          return 8;
        case this.$vuetify.breakpoint.width < 1000:
          return 10;
        default:
          return 12;
      }
    },

    menuAlwaysVisible() {
      const buttonAccountsPrivate = this.getMenuItem('accounts.private');

      return [
        {
          badge: {
            color: buttonAccountsPrivate ? buttonAccountsPrivate.badgeColor : 'red',
            content: buttonAccountsPrivate ? buttonAccountsPrivate.badge : '',
          },
          exact: true,
          label: 'Личные счета',
          icon: 'mdi-account-cash',
          to: {name: 'accounts.private'},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_STORES_VIEW) || this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW) || this.userHasPermissions(PERMISSION_ACCOUNTS_OPERATIONS_VIEW),
        },
        {
          exact: false,
          label: 'Заказ ДС',
          icon: 'mdi-cash-plus',
          to: {name: 'requests.applications.create'},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
        },
        {
          exact: false,
          label: 'Покупка',
          icon: 'mdi-cart-plus',
          to: {name: 'accounts.private.purchases.create'},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
        },
        {
          exact: false,
          label: 'Отчет',
          icon: 'mdi-clipboard-text',
          to: {name: 'journals.reports'},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
        },
      ].filter(el => el.visible)
    },

    menuRows() {
      if (!this.menuActive) {
        return []
      }

      let menu = this.menuActive.children
      if (this.menuActive.key !== this.menuMain.key) {
        menu = [
          new MenuItem({
            icon: 'mdi-chevron-left',
            callback: () => this.menuActive = this.menuParent,
            text: 'Назад'
          }),
          ...menu,
        ]
      }

      return [].concat.apply(
        [],
        menu.filter(el => el.visible).map((elem, i) => i % this.colsNavigation ? [] : [menu.slice(i, i + this.colsNavigation)])
      );
    },

    menuMain() {
      return new MenuItem({
        text: 'mainMenu',
        children: [
          this.menuAccounts,
          this.menuRequests,
          this.menuCars,
          this.menuJournals,
        ]
      })
    },

    maxHeight() {
      if (this.expanded) {
        return '75%'
      } else {
        return this.menuAlwaysVisible.length > 2 ? '64px' : '16px'
      }
    },

    menuParent() {
      if (!this.menuActive) {
        return null
      }

      const findParentMenuForMenuId = (menu) => {
        if (menu.children.find(submenu => submenu.key === this.menuActive.key)) {
          return menu
        }

        return menu.children.find($menu => findParentMenuForMenuId($menu))
      }

      return findParentMenuForMenuId(this.menuMain)
    },

    touchEvents() {
      return {
        down: () => {
          this.expanded = false
        },
        up: () => {
          this.menuActive = this.expanded ? this.menuActive : this.menuMain
          this.expanded = true
        }
      }
    },
  },

  methods: {
    getUserFullName,

    menuItemCallback(menuItem) {
      return () => {
        if (menuItem.children.length) {
          this.menuActive = menuItem
        } else if (menuItem.to) {
          this.expanded = false
          this.$router.push(menuItem.to)
        } else if (menuItem.callback) {
          menuItem.callback()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_colors';

.the-menu-mobile {
  bottom: 0;
  position: fixed;
  touch-action: none;
  transition: max-height 0.5s;
  z-index: 5;

  &__buttons-main {
    & > *:first-child {
      padding-left: 4px !important;
    }

    & > *:last-child {
      padding-right: 4px !important;
    }
  }

  &__main {
    z-index: 5;
  }

  &__navigation {
    min-height: 0;
  }

  &__navigation-rectangle {
    $shift: 4px;

    position: relative;

    &.border-bottom:before,
    &.border-left:after,
    &.border-right:after{
      background-color: map-deep-get($grey, 'lighten-2');
      content: "";
      position: absolute;
    }

    &.border-bottom:before {
      bottom: 0;
      left: $shift;
      right: $shift;
      height: 1px;
    }

    &.border-left:after {
      bottom: $shift;
      left: 0;
      top: $shift;
      width: 1px;
    }

    &.border-right:after {
      bottom: $shift;
      right: 0;
      top: $shift;
      width: 1px;
    }
  }

  &__user-info {
    width: 0;
  }

  .v-divider {
    border-color: map-deep-get($grey, 'lighten-2') !important;
  }

  * {
    touch-action: none;
  }
}
</style>
