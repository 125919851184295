<template>
  <v-sheet class="fill-height d-flex flex-row">
    <v-navigation-drawer
      :absolute="$vuetify.breakpoint.mobile"
      :permanent="permanent"
      :touchless="permanent"
      :value="permanent"
      :width="$vuetify.breakpoint.mobile ? '100%' : '360px'"
      class="flex-shrink-0"
      clipped
      height="100%"
    >
      <slot name="menu"></slot>
    </v-navigation-drawer>

    <v-sheet class="flex-fill overflow-x-hidden overflow-y-auto">
      <slot>
        <router-view :class="$vuetify.breakpoint.mobile ? 'flex-grow-1' : 'align-self-center ma-auto'"></router-view>
      </slot>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'side-menu-router-view',

  props: {
    permanent: Boolean,
  }
}
</script>