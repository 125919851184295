<template>
  <v-overlay
    v-if="drag"
    class="the-application-form__drag-drop-overlay"
    opacity="0.85"
  >
    <v-container
      class="the-application-form__drag-drop d-flex align-center justify-center flex-column"
      fluid
    >
      <v-icon
        color="white"
        size="64"
      >mdi-image-area-close</v-icon>

      <p class="white--text text-center text-h5 mt-8">Отпустите файл для загрузки</p>
    </v-container>
  </v-overlay>
</template>


<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      drag: false
    }
  },

  mounted() {
    if (!this.disabled) {
      this.dragdropEnable()
    }
  },

  beforeDestroy() {
    this.dragdropDisable()
  },

  watch: {
    'disabled'(value) {
      if (value) {
        this.dragdropDisable()
      } else {
        this.dragdropEnable()
      }
    }
  },

  methods: {
    dragdrop(event) {
      event.preventDefault()
      this.$emit('input', event.dataTransfer.files)
      this.drag = false
    },

    dragdropEnable() {
      window.addEventListener('dragover',this.dragover,false)
      window.addEventListener('dragleave',this.dragleave,false)
      window.addEventListener('drop', this.dragdrop,false)
    },

    dragdropDisable() {
      window.removeEventListener('dragover', this.dragover,false)
      window.removeEventListener('dragleave', this.dragleave,false)
      window.removeEventListener('drop', this.dragdrop, false)
    },

    dragover(event) {
      event.preventDefault();
      this.drag = !this.$_readOnly && event.dataTransfer.types.includes('Files')
    },

    dragleave(event) {
      event.preventDefault();
      this.drag = false
    },
  }
}
</script>