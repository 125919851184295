<template>
  <v-snackbar
    :value="$notifications.visible"
    class="notification-base"
    content-class="pa-0 ma-0 transparent"
    v-on:input="!$event && $notifications.close()"
    app
    fixed
    top
  >
    <v-alert
      :type="type"
      class="ma-0"
      v-on:click="$notifications.close()"
    >
      {{ text }}
    </v-alert>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    text: String,
    type: {
      type: String,
      default: 'success',
    }
  }
}
</script>

<style lang="scss">
.notification-base.v-snack {
  cursor: pointer;

  .v-snack__action {
    display: none;
  }
}

</style>