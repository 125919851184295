<script>
import {mapActions, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper.vue'

export default {
  extends: ContentDetailWrapper,

  props: {
    carId: {
      type: Number,
    },
  },

  computed: {
    ...mapState('cars', {
      'error': 'detailError',
      'loading': 'detailLoading',
      'instance': 'detail'
    }),

    props() {
      return {
        car: this.instance,
      }
    },
  },

  methods: {
    ...mapActions('cars', {
      'carRetrieve': 'retrieve',
    }),

    retrieve() {
      return this.carRetrieve({carId: this.carId})
    }
  }
}
</script>
