<template>
  <v-card flat>
      <v-card-title class="pa-0 ma-0 d-flex flex-row flex-nowrap text-no-wrap text-h6">
          <v-icon
            :color="hasNewComments(instance) ? 'deep-orange' : 'grey lighten-1'"
            class="flex-shrink-0"
            size="16"
            left
          >
            {{ hasNewComments(instance) ? 'mdi-email' : 'mdi-email-open' }}
          </v-icon>

        <span class="font-weight-regular text-truncate">История переписки рецензентов&nbsp;</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-0">
        <comment-form
          v-if="canCreateComment"
          :is-loading="commentCreator.loading"
          class="mt-4"
          v-on:input="$emit('comment', $event)"
        ></comment-form>

        <template v-if="instance.comments.length">
          <comments-item
            v-for="(comment, index) in commentsReversed"
            :key="index"
            :comment="comment"
            class="px-0"
          ></comments-item>
        </template>

        <p
          v-else
          class="grey--text"
        >Сообщений нет</p>

      </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import {ApiData} from '@/assets/js/api/_helpers'
import CommentsItem from '@/components/common/comments/CommentsItem'
import CommentForm from '@/components/common/comments/CommentsForm'

export default {
  components: {
    CommentForm,
    CommentsItem,
  },

  props: {
    commentCreator: ApiData,
    instance: Object,
  },

  computed: {
    ...mapGetters('requests/matching/archives/requests', [
      'hasNewComments'
    ]),

    canCreateComment() {
      const canCreateComment = this.instance.can_create_main_resolution || this.hasNewComments(this.instance)
      return !this.$auth.impersonating() && !this.instance.is_processed && canCreateComment
    },

    commentsReversed() {
      return [...this.instance.comments].reverse()
    }
  },
}
</script>
