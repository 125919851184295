<template>
  <v-card flat>
      <v-card-title class="pa-0 ma-0 d-flex flex-row flex-nowrap text-no-wrap text-h6">
        <v-icon size="16" left>mdi-history</v-icon>
        <span class="font-weight-regular text-truncate">История работы с заявкой</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-0">
        <loadable-content
          :error="error"
          :loading="isLoading"
        >
          <template v-if="history.length === 0">
            <span class="grey--text">Нет истории действий по заявке</span>
          </template>

          <template v-else>
            <v-row
              v-for="(item, index) in history"
              :key="index"
              class="ma-0"
            >
              <v-col class="pa-0 align-start flex-grow-0 flex-shrink-0 text-right text-no-wrap mr-4 grey--text text--darken-3">
                  <span>{{ item.created_at|datetime }}</span>
              </v-col>
              <v-col class="pa-0 flex-grow-1 grey--text text--darken-3">
                <span class="grey--text">{{ item.user_full_name }}</span> {{ getResultDescription(item.result) }}
              </v-col>
            </v-row>
          </template>

        </loadable-content>
      </v-card-text>
  </v-card>
</template>

<script>
import LoadableContent from '@/components/common/LoadableContent'
export default {
  components: {LoadableContent},
  props: {
    error: String,
    history: Array,
    isLoading: Boolean,
  },

  methods: {
    getResultDescription(result) {
      switch(result) {
        case 'accepted':
          return 'Заявка согласована'
        case 'details_gave_out':
          return 'Ответ на замечание'
        case 'details_required':
          return 'Оставлено замечание'
        case 'rejected':
          return 'Заявка отклонена'
      }
    }
  }
}
</script>
