"use strict";

import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

function captureException(error) {
    if (!axios.isCancel(error)) {
      Sentry.withScope(scope => {
        if (error.response) {
          if (error.response.config.data) {
            let data = {};
            if (error.response.config.data instanceof FormData) {
              for (let key of error.response.config.data.keys()) {
                data[key] = error.response.config.data.get(key)
              }
            } else {
              data = {...error.response.config.data}
            }

            scope.setExtra("Request.data", JSON.stringify(data))
          }

          scope.setExtra("Response.status", error.response.status)
          scope.setExtra("Response.statusText", error.response.statusText)
          if (error.response.data) {
             scope.setExtra("Response.data", JSON.stringify(error.response.data))
          }
        }

        const exception = error.error || error.message || error.originalError || error;
        Sentry.captureException(exception);
      })
  }
}

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    captureException(error)
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {  // Plugin.install = function(Vue, options)
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
export {
  _axios as api
};
