<template>
  <v-list-group
    v-model="expanded"
    active-class="grey--text text--darken-1"
    append-icon=""
    class="pa-0"
  >
    <template v-slot:activator>
      <v-list-item
        class="pa-0"
      >
        <v-icon v-html="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-icon>

        <v-list-item-avatar tile>
          <v-container>
              <v-icon
                v-for="icon in [getAccountBelongIcon(account), getAccountTypeIcon(account)]"
                :key="icon"
                color="grey"
                size="20"
                v-html="icon"
              ></v-icon>
          </v-container>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            :class="submenuItemActive ? 'text--darken-3' : 'text--darken-1'"
            class="text-h5 grey--text"
          >
            <animated-number
              :value="account.balance"
              :formatValue="(value) => measurement(value, getCurrencyPostfixByNumericCode(account.currency_id))"
              :duration="animatedNumberDuration"
            />
          </v-list-item-title>

          <v-list-item-subtitle
            :class="submenuItemActive ? 'text--darken-3' : 'text--darken-1'"
            class="grey--text"
          >{{ account.name }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-chip
          v-if="accountAlarmCounter && !expanded"
          class="white--text"
          color="red"
          v-html="accountAlarmCounter"
          small
        ></v-chip>
      </v-list-item>
    </template>

    <v-list-item
      v-for="submenuItem in $_submenuVisible"
      :key="submenuItem.key"
      :class="Object.is(submenuItem, submenuItemActive) ? 'primary' : 'transparent'"
      :to="submenuItem.to"
    >
      <v-list-item-avatar>
        <v-icon
          :color="Object.is(submenuItem, submenuItemActive) ? 'white' : 'grey'"
          v-html="submenuItem.icon"
        ></v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          :class="Object.is(submenuItem, submenuItemActive) ? 'white--text' : 'grey--text text--darken-1'"
          v-html="submenuItem.title"
        ></v-list-item-title>
      </v-list-item-content>

      <v-chip
        v-if="submenuItem.alarms"
        class="white--text"
        color="red"
        v-html="submenuItem.alarms"
        small
      ></v-chip>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import AnimatedNumber from "animated-number-vue";
import User from '@/mixins/User'
import {measurement} from '@/filters/numbers'

export default {
  mixins: [User],

  components: {
    AnimatedNumber,
  },

  props: {
    account: Object,
  },

  data() {
    return {
      animatedNumberDuration: 0,
      expanded: false
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountBelongIcon',
      'getAccountTypeIcon',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    ...mapGetters('requests', [
      'getPrivateRequestsForAccountDeclinedCount',
      'getSummaryForAccount',
    ]),

    $_submenuVisible() {
      return this.submenuItems.filter(submenuItem => submenuItem.visible)
    },

    submenuItemActive() {
      return this.submenuItems.find(submenuItem =>
        this.$route.name.startsWith(submenuItem.to.name) &&
        !Object.keys(submenuItem.to.params).find(key => submenuItem.to.params[key] != this.$route.params[key])
      )
    },

    accountAlarmCounter() {
      return this.submenuItems.map(submenuItem => submenuItem.alarms || 0).reduce((a, v) => a + v, 0)
    },

    submenuItems() {
      // List[SubmenuItem]
      // SubmenuItem:
      //   key: str
      //   alarms: int  # counter
      //   title: str
      //   to: Vue router object ({name: 'path.example', params: {...}})
      //   visible: bool
      return []
    },
  },

  created() {
    this.animatedNumberDuration = 0
  },

  mounted() {
    this.expanded = Boolean(this.submenuItemActive)
    this.$nextTick(() => {
      this.animatedNumberDuration = 300
    })
  },

  methods: {
    measurement,
  }
}
</script>
