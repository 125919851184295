import { request } from '@/assets/js/api/_helpers'


const ENDPOINT_USERS = '/api/users'
const ENDPOINT_USERS_LIMITS = '/api/users/me/limits'
const ENDPOINT_USERS_STATE = '/api/users/me/state'
const ENDPOINT_USERS_SUBSCRIPTIONS_WEBPUSH = '/api/users/me/subscriptions/webpush'

export const PERMISSION_ACCOUNTS_VIEW = "accounts_view"
export const PERMISSION_ACCOUNTS_OPERATIONS_VIEW = "money_accounts_operations_view"
export const PERMISSION_ACCOUNTS_PRIVATE_REFILL_CREATE = "money_accounts_private_refill_create"
export const PERMISSION_ACCOUNTS_PRIVATE_VIEW = "money_accounts_private_view"
export const PERMISSION_ACCOUNTS_STORES_REFILL_CREATE = "money_accounts_stores_refill_create"
export const PERMISSION_ACCOUNTS_STORES_VIEW = "money_accounts_stores_view"
export const PERMISSION_ADMIN_IMPERSONATE = "admin_impersonate"

export const PERMISSION_CARS_VIEW = "cars_view"
export const PERMISSION_CARS_RENTS_CREATE = "cars_rents_create"
export const PERMISSION_CARS_RENTS_UPDATE = "cars_rents_update"
export const PERMISSION_CARS_RENTS_VIEW = "cars_rents_view"
export const PERMISSION_CARS_RENTS_UPDATE_ALL = "cars_rents_update_all"
export const PERMISSION_CARS_RENTS_VIEW_ALL = "cars_rents_view_all"
export const PERMISSION_REQUESTS_MONEY_GIVE_OUT_APPROVED_CREATE_MOVEMENT = "requests_money_give_out_approved_create_movement"
export const PERMISSION_REQUESTS_MONEY_GIVE_OUT_APPROVED_VIEW = "requests_money_give_out_approved_view"
export const PERMISSION_REQUESTS_RECEIPTS_CASH_CREATE = "requests_receipts_cash_create"
export const PERMISSION_REQUESTS_RECEIPTS_CASH_VIEW = "requests_receipts_cash_view"
export const PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW = "requests_matching_archives_issues_view"
export const PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW = "GetArchiveApp"
export const PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW = "GetMoneyApp"


export function getUserFullName(user) {
    return user
      ? [user.last_name, user.first_name, user.surname].filter(Boolean).join(' ')
      : ''
}


export default {
    list: (config) => request('GET', ENDPOINT_USERS, config),
    retrieve: ({ id, ...config }) => request('GET', `ENDPOINT_USERS/${id}`, config),
    limits: {
        get: (config) => request('GET', ENDPOINT_USERS_LIMITS, config),
    },
    state: {
        get: (config) => request('GET', ENDPOINT_USERS_STATE, config),
    },
    subscriptions: {
        webpush: {
            create: (config) => request('POST', ENDPOINT_USERS_SUBSCRIPTIONS_WEBPUSH, config),
            delete: ({ id, ...config }) => request('POST', `${ENDPOINT_USERS_SUBSCRIPTIONS_WEBPUSH}/${id}`, config),
            list: (config) => request('GET', ENDPOINT_USERS_SUBSCRIPTIONS_WEBPUSH, config),
        }
    }
}
