<template>
  <div class="elevation-2">
    <v-list-item
      two-line
      v-on:click="menuButtonLogout.callback()"
    >
      <v-avatar>
        <v-icon v-html="menuButtonLogout.icon"></v-icon>
      </v-avatar>

      <v-list-item-content>
        Выход
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Menu from '@/components/navigation/Menu.vue'

export default {
  mixins: [Menu],

  computed: {
    menuButtonLogout() {
      return this.getMenuItem('account.logout')
    }
  }
}
</script>
