<script>
import TheRequestsPrivateMovementsFormView from '@/views/requests/private/requests/movements/TheRequestsPrivateMovementsFormView'

export default {
  extends: TheRequestsPrivateMovementsFormView,

  computed: {
    canEdit() {
      return false
    },
  },
}
</script>