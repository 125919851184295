<script>
import {mapActions, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper.vue'

export default {
  extends: ContentDetailWrapper,

  props: {
    carId: {
      type: Number,
    },
  },

  computed: {
    ...mapState('cars/rents/conflicts', {
      'error': 'detailError',
      'loading': 'detailLoading',
      'instance': 'detail'
    }),
  },

  methods: {
    ...mapActions('cars/rents/conflicts', {
      'carRentsConflictsRetrieve': 'retrieve',
    }),

    retrieve() {
      return this.carRentsConflictsRetrieve({carId: this.carId})
    }
  }
}
</script>
