<template>
  <v-row class="ma-0 overflow-hidden flex-nowrap">
    <p class="ma-0 text-truncate">{{ fileNameStart }}</p>
    <p v-if="fileNameEnd" class="ma-0">{{ fileNameEnd }}</p>
    <p class="ma-0">{{ filename|fileExtension }}</p>
  </v-row>
</template>

<script>
import {fileNameText} from '@/filters/files'

export default {
  props: {
    filename: String,
  },

  computed: {
    fileNameParts() {
      const text = fileNameText(this.filename)
      const center = 12 <= text.length ? text.length - 6 : text.length
      return [text.slice(0, center), text.slice(center, text.length)]
    },

    fileNameStart() {
      return this.fileNameParts[0]
    },

    fileNameEnd() {
      return this.fileNameParts[1]
    },
  },
}
</script>
