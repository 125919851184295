<template>
  <v-list-item :to="{ name: 'requests.matching.archives.issues.form', params: {instanceId: item.uid} }">
    <v-list-item-avatar class="rounded-0">
      <v-badge
        :color="badge && badge.background"
        avatar
        overlap
        :value="!!badge"
      >
        <template v-slot:badge>
          <v-icon
            :color="badge && badge.color"
            :size="badge && badge.size"
            v-html="badge && badge.icon"
          ></v-icon>
        </template>

        <v-icon>mdi-archive-arrow-up</v-icon>
      </v-badge>
    </v-list-item-avatar>

    <v-list-item-content class="flex-column flex-nowrap align-stretch">
      <v-row class="ma-0 black--text">
        <v-col class="pa-0 overflow-hidden">
          <p
            v-for="document in documentsToShow"
            :key="document"
            :class="$vuetify.breakpoint.mobile ? 'caption' : 'text-body-2 font-weight-light'"
            class="ma-0 text-truncate"
            v-text="document"
          ></p>
        </v-col>

        <v-col class="pa-0 d-flex flex-column flex-shrink-0 flex-grow-0 justify-space-around text-right text-no-wrap">
          <p class="ma-0">{{ item.created_by || 'Сотрудник отсутсвует' }}</p>

          <p class="ma-0 grey--text caption grey--text text--darken-1">{{ item.issued_at | moment('DD.MM.YYYY') }}</p>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object,
  },

  data() {
    return {
      maxLinesToShow: 3,
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/issues', [
      'isNew',
    ]),

    badge() {
      if (this.item.is_waiting) {
        return {
          background: 'deep-orange lighten-4',
          color: 'deep-orange lighten-2',
          icon: 'mdi-timer-sand-full',
          size: 16,
        }
      } else if (this.item.is_processed) {
        if (this.item.is_declined) {
          return {
            background: 'red lighten-4',
            color: 'red',
            icon: 'mdi-close',
            size: 16,
          }
        } else {
          return {
            background: 'green lighten-4',
            color: 'green',
            icon: 'mdi-check',
            size: 16,
          }
        }
      } else {
        return null
      }
    },

    documents() {
      return Array.from(new Set(this.item.documents.map(document => document.name)))
    },

    documentsToShow() {
      if (this.documentsTooMuch) {
        return this.documents.slice(0, Math.max(0, this.maxLinesToShow - 1))
      } else {
        return this.documents
      }
    },

    documentsTooMuch() {
      return this.documents.length > this.maxLinesToShow
    }
  }
}
</script>
