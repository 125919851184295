<template>
  <v-container
    class="image-preview pa-0"
    v-on:click="active = true"
  >
    <v-overlay
      v-model="active"
      opacity="0.85"
      z-index="25"
    >
      <v-btn
        class="image-preview__full-size-btn-exit"
        color="white"
        fixed
        fab
        top
        right
        v-on:click.stop="active = false"
        v-on:keydown.esc="active = false"
      >
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>

      <v-container class="image-preview__full-size overflow-hidden d-flex align-center justify-center">
        <slot>
          <v-img
            :alt="image.filename"
            :src="image.url"
            max-height="90vh"
            max-width="90vw"
            contain
          ></v-img>
        </slot>
      </v-container>
    </v-overlay>

    <v-responsive
      aspect-ratio="1"
      style="position: relative;"
    >
      <v-hover v-slot="{ hover }">
        <v-container class="image-preview__preview-wrapper pa-0">
          <slot name="preview">
            <v-img
              :alt="image.filename"
              :src="image.url"
              aspect-ratio="1"
              class="v-image-field__preview"
            ></v-img>
          </slot>

          <v-icon
            v-if="hover"
            class="image-preview__preview-tooltip"
            color="white"
            size="64"
          >
            mdi-magnify-plus
          </v-icon>

          <file-name
            :filename="image.filename"
            class="white--text caption text-md-body-2"
            style="position: absolute; bottom: 0; background: rgba(0,0,0,0.3); width: 100%;"
          ></file-name>
        </v-container>
      </v-hover>
    </v-responsive>
  </v-container>
</template>

<script>
import FileName from '@/components/common/text/FileName'
export default {
  components: {FileName},

  props: {
    image: Object,
  },

  data() {
    return {
      active: false,
    }
  }
}
</script>

<style lang="scss">
.image-preview {

  &__full-size {
    height: 90vh;
    width: 90vw;
  }

  &__preview-tooltip {
    background: rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    position: absolute !important;
    right: 0;
    top: 0;
  }

  &__preview-wrapper {
    cursor: pointer;
    position: relative;
  }
}
</style>
