<template>
  <v-container class="the-cars-list-view pa-0 d-flex flex-column align-start" fluid>
    <v-toolbar
      class="flex-grow-0"
      width="100%"
      dense
    >
      <v-text-field
        v-model="params.search"
        class="mx-2 rounded-pill"
        background-color="grey lighten-2"
        append-icon="mdi-magnify"
        label="Поиск"
        clearable
        dense
        hide-details
        flat
        solo
      ></v-text-field>

      <v-divider vertical></v-divider>

      <v-btn
        color="transparent"
        height="48px"
        width="48px"
        depressed
        fab
        tile
        v-on:click="load"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <content-list
      :error="error"
      :items="items"
      :loading="isLoading"
    >
      <template v-slot:item="{ item }">
        <v-list-item
          two-line
          :to="{name: 'cars.rents.conflicts.detailed', params: {carId: item.id}}"
        >
          <v-list-item-avatar>
            <v-icon>mdi-car</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>

            <v-list-item-subtitle class="deep-orange--text">
              {{ item.rents.length }}
              {{ plural(item.rents.length, 'водитель', 'водителя', 'водителя') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </content-list>
  </v-container>
</template>

<script>
import ContentList from '@/components/common/content/list/ContentList.vue'
import { ApiData } from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import { plural } from '../../../../filters/strings'

export default {
  components: {
    ContentList,
  },

  data() {
    return {
      cars: new ApiData(api.cars.rents.conflicts.list, []),
      params: {
        search: '',
      }
    }
  },

  computed: {
    error() {
      return this.cars.error || ''
    },

    isLoading() {
      return this.cars.loading
    },

    items() {
      return this.cars.data
    }
  },

  mounted() {
    this.load()
  },

  watch: {
    params: {
      deep: true,
      handler() {
        this.load()
      },
    }
  },

  methods: {
    plural,
    load() {
      return this.cars.call({params: this.params})
    }
  },
}
</script>

