<template>
  <v-app-bar
    class="the-app-bar"
    color="primary"
    elevation="8"
    app
    absolute
  >
    <portal-target
      name="the-app-bar__btn-prepend"
      slim
    >
      <v-btn
        :disabled="!browserHistory.hasBackref"
        height="100%"
        dark
        depressed
        exact
        text
        tile
        v-on:click="browserHistory.back()"
      >
        <v-icon v-if="browserHistory.hasBackref">mdi-chevron-left</v-icon>
      </v-btn>
    </portal-target>

    <p
      v-if="$route.meta.title"
      class="the-app-bar__page-title white--text text-h6 my-0 mx-auto text-center text-truncate flex-fill"
    >
      <portal-target
        name="the-app-bar__title"
        slim
      >
        {{ $route.meta.title }}
      </portal-target>
    </p>

    <v-spacer v-else></v-spacer>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <menu-desktop-item
        v-for="item in menu.children"
        :key="item.id"
        :item="item"
      ></menu-desktop-item>
    </template>

    <portal-target
      name="the-app-bar__btn-append"
      slim
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        height="100%"
        dark
        depressed
        disabled
        exact
        text
        tile
        v-on:click="$root.$emit('menu', true)"
      >
        <v-icon v-if="false">mdi-menu</v-icon>
      </v-btn>
    </portal-target>
  </v-app-bar>
</template>

<script>
import MenuDesktopItem from '@/components/navigation/desktop/MenuDesktopItem.vue'
import Menu from '@/components/navigation/Menu.vue'
import { browserHistory } from '@/router'

export default {
  mixins: [Menu],
  name: 'the-app-bar',
  components: {MenuDesktopItem},
  data() {
    return {
      browserHistory
    }
  }
}
</script>

<style lang="scss">
.the-app-bar {
  & > .v-toolbar__content {
    padding: 0;
  }
}
</style>
