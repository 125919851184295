<script>
import { STATUS_DECLINED, STATUS_DRAFT } from '@/assets/js/api/requests/private/purchases'
import MovementsListItem from '@/components/requests/base/movements/list/MovementsListItem'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'

export default {
  extends: MovementsListItem,

  data() {
    return {
      deleter: new ApiData(api.requests.private.movements.delete),
    }
  },

  computed: {
    movementDetailView() {
      if ([STATUS_DRAFT, STATUS_DECLINED].includes(this.movement.status)) {
        return {
          name: 'accounts.private.details.requests.movements.edit',
          params: {
            accountId: this.movement.account_to_id, instanceId: this.movement.id
          }
        }
      } else {
        return {
          name: 'accounts.private.details.requests.movements.detail',
          params: {
            accountId: this.movement.account_to_id, instanceId: this.movement.id
          }
        }
      }
    }
  },

  methods: {
    movementCopy() {
      this.$router.push({
          name: 'accounts.private.details.requests.movements.create',
          params: {
            accountId: this.movement.account_to_id
          },
          query: {
            copy: this.movement.id
          }
        })
    },
  }
}
</script>
