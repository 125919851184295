import {STREAM_MESSAGE} from '@/store/_prototypes/loadable/stream/mutations_types'
import {
    REQUEST_SET_DECLINED,
    REQUEST_SET_IS_WAITING,
    REQUEST_SET_PROCESSED
} from '@/store/requests/matching/archives/issues/mutations_types'

export default {
    [REQUEST_SET_DECLINED]: (state, { request, is_declined, decline_reason }) => {
        const stateRequest = state.list.find($_request => $_request.uid === request.uid)
        if (stateRequest) {
            stateRequest.is_declined = is_declined
            stateRequest.decline_reason = decline_reason
        }
    },

    [REQUEST_SET_IS_WAITING]: (state, request) => {
        const stateRequest = state.list.find($_request => $_request.uid === request.uid)
        if (stateRequest) {
            stateRequest.is_waiting = true
        }
    },

    [REQUEST_SET_PROCESSED]: (state, request) => {
        const stateRequest = state.list.find($_request => $_request.uid === request.uid)
        if (stateRequest) {
            stateRequest.is_processed = true
        }
    },

    [STREAM_MESSAGE]: (state, data) => {
        state.list = data
    }
}