<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form class="py-0 py-md-16">
      <template slot="body">
        <request-matching-archives-issues-header
          :instance="instance"
        ></request-matching-archives-issues-header>

        <request-matching-data-primary
          :rows="dataPrimary"
          class="my-4"
        ></request-matching-data-primary>

        <v-divider class="mb-4"></v-divider>

        <request-matching-archives-documents :instance="instance"></request-matching-archives-documents>
      </template>

      <template v-if="!$auth.impersonating()" v-slot:controls>
        <request-matching-archives-issues-controls
          v-if="!instance.is_waiting && !instance.is_processed"
          :instance="instance"
          class="mt-4"
          v-on:confirm="dialogConfirmIssue"
          v-on:decline="dialogDeclineIssue"
        ></request-matching-archives-issues-controls>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'
import datetime from '@/filters/datetime'
import RequestMatchingArchivesIssuesHeader from '@/components/requests/matching/archives/issues/form/RequestMatchingArchivesIssuesHeader'
import ContentForm from '@/components/common/content/form/ContentForm'
import RequestMatchingDataPrimary from '@/components/requests/base/matching/RequestMatchingDataPrimary'
import RequestMatchingArchivesDocuments from '@/components/requests/matching/archives/requests/form/RequestMatchingArchivesDocuments'
import {
  REQUEST_SET_DECLINED,
  REQUEST_SET_IS_WAITING,
  REQUEST_SET_PROCESSED
} from '@/store/requests/matching/archives/issues/mutations_types'
import RequestMatchingArchivesIssuesControls from '@/components/requests/matching/archives/issues/form/RequestMatchingArchivesIssuesControls'
import RequestMatchingArchivesDeclineForm from '@/components/requests/matching/archives/issues/form/modals/decline/RequestMatchingArchivesDeclineForm'

export default {
  components: {
    RequestMatchingArchivesIssuesControls,
    RequestMatchingArchivesDocuments,
    RequestMatchingDataPrimary,
    ContentForm,
    RequestMatchingArchivesIssuesHeader
  },

  props: {
    instance: Object,
  },

  data() {
    return {
      apiResult: new ApiData(api.requests.matching.archives.issues.result),
    }
  },

  computed: {
    actionTypeGenetivus() {
      return this.instance.is_direct ? 'выдачу' : 'отправку'
    },

    dataPrimary() {
      return [
        { key: 'status', label: 'Статус', value: this.status },
        { key: 'decline_reason', label: 'Причина отмены', value: this.instance.decline_reason, hide: !this.instance.is_declined },
        { key: 'office', label: 'Офис выдачи', value: this.instance.office },
        { key: 'description', label: 'Назначение', value: this.instance.description },
        { key: 'issued_at', label: 'Дата выдачи', value: datetime.date(this.instance.issued_at) },
        { key: 'is_direct', label: 'Прямая выдача', value: this.instance.is_direct ? 'Да' : 'Нет' },
      ]
    },

    status() {
      if (this.instance.is_waiting) {
        return 'В пути'
      } else if (this.instance.is_processed) {
        return this.instance.is_declined ? 'Отменена' : 'Выдано'
      } else {
        return 'На рассмотрении'
      }
    }
  },

  methods: {
    ...mapMutations('requests/matching/archives/issues', [
      REQUEST_SET_DECLINED,
      REQUEST_SET_PROCESSED,
      REQUEST_SET_IS_WAITING
    ]),

    dialogConfirmIssue() {
      this.$modal.confirm(`Подтвердить ${this.actionTypeGenetivus} документа`, this.createResult.bind(null, true))
    },

    dialogDeclineIssue() {
      this.$modal.open({
        bind: {
          instance: this.instance,
          title: `Отменить ${this.actionTypeGenetivus} документа`,
        },
        component: RequestMatchingArchivesDeclineForm,
        on: {
          close: () => this.$modal.close(),
          input: (result) => this.onResultCreated(result),
        },
      })
    },

    createResult(result = false, comment = null) {
      return this.apiResult.call({id: this.instance.uid, data: {comment, result}})
        .then(() => {
          this.onResultCreated({ result, comment })
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
    },

    onResultCreated({ result, comment = null }) {
      if (result && !this.instance.is_direct) {
        this[REQUEST_SET_IS_WAITING](this.instance)
      } else {
        this[REQUEST_SET_PROCESSED](this.instance)

        if (!result) {
          this[REQUEST_SET_DECLINED]({
            request: this.instance,
            is_declined: !result,
            decline_reason: comment,
          })
        }
      }

      const resultDescription = result ? 'оформлена' : 'отменена'
      this.$notifications.show(`${this.instance.is_direct ? 'Выдача' : 'Отправка'} по заявке ${resultDescription}`)
      this.$router.push(this.$route.meta.backref)
    }
  }
}
</script>