import ControlsPurchasesListInfinityScroll from '@/components/requests/controls/purchases/list/ControlsPurchasesListInfinityScroll'
import TheMoneyControlsPurchasesDetailWrapper
    from '@/views/requests/controls/purchases/TheMoneyControlsPurchasesDetailWrapper'
import TheMoneyControlsPurchasesFormView from '@/views/requests/controls/purchases/TheMoneyControlsPurchasesFormView'

export default [
    {
        path: 'requests/purchases',
        name: 'accounts.controls.details.requests.purchases',
        component: ControlsPurchasesListInfinityScroll,
        meta: {
            backref: {name: 'accounts.controls'},
            title: 'Покупки'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
            filters: {account_id: Number(route.params.accountId)}
        }),
    },
    {
        path: 'requests/purchases/:instanceId',
        component: TheMoneyControlsPurchasesDetailWrapper,
        props: route => ({
            accountId: Number(route.params.accountId),
            instanceId: Number(route.params.instanceId),
        }),

        children: [
            {
                path: '',
                name: 'accounts.controls.details.requests.purchases.detail',
                component: TheMoneyControlsPurchasesFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: {
                        name: 'accounts.controls.details.requests.purchases',
                    },
                    title: 'Просмотр покупки'
                },
            },
        ]
    },
]