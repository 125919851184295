<template>
  <v-container class="pa-0 d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :class="{
        'elevation-4 rounded-lg': !$vuetify.breakpoint.mobile,
        'fill-height': $vuetify.breakpoint.mobile
      }"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="pt-8 d-flex flex-column flex-nowrap"
    >
      <template slot="body">
        <v-text-field
          :value="car.name"
          class="mx-3 flex-grow-0"
          label="Автомобиль"
          prepend-inner-icon="mdi-car"
          outlined
          readonly
        ></v-text-field>

        <v-row class="mx-0 mb-n1 flex-grow-0 flex-column flex-sm-row">
          <v-col class="pb-0 pb-sm-3 mb-n1 mb-sm-0">
            <v-datetime-field
              v-model="formData.datetime_begin"
              :error="$v.formData.datetime_begin.$error"
              :error-messages="errors.datetime_begin"
              :readonly="$_readOnly"
              label="Начало"
              outlined
            ></v-datetime-field>
          </v-col>

          <v-col>
            <v-datetime-field
              v-model="formData.datetime_end"
              :error="$v.formData.datetime_end.$error"
              :error-messages="errors.datetime_end"
              :clearable="!$_readOnly"
              :readonly="$_readOnly"
              label="Конец"
              outlined
            ></v-datetime-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="formData.comment"
          :error="$v.formData.comment && $v.formData.comment.$error"
          :error-messages="errors.comment"
          :readonly="$_readOnly"
          class="mx-3 flex-grow-0"
          label="Комментарий"
          outlined
        ></v-text-field>

        <v-spacer></v-spacer>
      </template>

      <template slot="controls">
        <v-alert
          v-if="activeRent"
          border="left"
          class="mx-3 mt-8"
          color="deep-orange"
          icon="mdi-alert"
          outlined
        >
          Вы уже используете автомобиль "{{ activeRent.car.name }}".
          Его использование будет автоматически прекращено при оформлении заявки.
        </v-alert>

        <v-row
          v-if="!instance || !instance.is_finished"
          class="mx-0 my-0 d-flex flex-column flex-grow-0"
        >
          <v-col class="flex-fill d-flex flex-column justify-space-between flex-sm-row">
            <v-btn
              key="cancel"
              :block="$vuetify.breakpoint.xsOnly"
              :disabled="isCreating || isUpdating"
              class="my-2 white--text"
              color="red"
              large
              outlined
              v-on:click="$router.push($route.meta.backref)"
            >
              <v-icon left>mdi-close</v-icon>
              Отмена
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              key="give_out"
              :block="$vuetify.breakpoint.xsOnly"
              :loading="isCreating || isUpdating"
              class="my-2 white--text"
              color="green"
              width="196px"
              large
              v-on:click="(car.driver_id && car.driver_id !== $auth.user().id) ? confirmSubmit() : submit()"
            >
              <v-icon
                color="white"
                left
              >mdi-check</v-icon>
              Сохранить
            </v-btn>
          </v-col>

          <template v-if="instance">
            <v-divider></v-divider>

            <v-col>
              <v-btn
                color="error"
                prepend-icon="mdi-flag-checkered"
                block
                large
                v-on:click="finish"
              >
                <v-icon
                  color="white"
                  left
                >mdi-flag-checkered</v-icon>

                Завершить
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import Form from '@/mixins/Form.vue'
import { DATE_FORMAT_ISO, TIME_FORMAT } from '@/filters/datetime'
import ContentForm from '@/components/common/content/form/ContentForm.vue'
import { maxLength, required } from 'vuelidate/lib/validators'
import { ApiData, ApiPaginatedAccumulatedData } from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import VDatetimeField from '@/components/inputs/VDatetimeField.vue'
import { mapMutations } from 'vuex'
import { DETAIL_SET } from '@/store/_prototypes/loadable/detail/mutations_types'
import CarsRentsFinishModalForm from '@/components/cars/rents/CarsRentsFinishModalForm.vue'


export default {
  components: {VDatetimeField, ContentForm},
  mixins: [Form],

  props: {
    car: Object,
    instance: [Object, null],
  },

  data() {
    return {
      DATE_FORMAT_ISO,
      TIME_FORMAT,
      creator: new ApiData(this.instance ? api.cars.rents.update : api.cars.rents.create),
      formData: {
        car_id: this.car.id,
        uid: null,
        datetime_begin: this.$moment().toISOString(),
        datetime_end: null,
        comment: '',
      },
      rentsActive: new ApiPaginatedAccumulatedData(
        api.cars.rents.list,
        [],
        {
          params: {
            is_finished: false,
          }
        })
    }
  },

  computed: {
    $_readOnly() {
       return (this.instance && this.instance.is_finished) || this.creator.loading || (this.copier && this.copier.loading)
    },

    activeRent() {
      return this.rentsActive.data.find(rent => rent.car_id !== this.car.id)
    },

    apiConfig() {
      return {
        carId: this.car.id,
        rentId: this.instance && this.instance.id,
        data: this.apiFormData(this.formData)
      }
    },

    formDataErrors() {
      return {
        datetime_begin: {
          minValue: `Дата начала эксплуатации не может быть в будущем`,
        },
        datetime_end: {
          minValue: `Дата окончания эксплуатации не может быть ранее ее начала`,
        },
      }
    },
  },

  created() {
    if (!this.instance) {
      this.rentsActive.all()
    }
  },

  methods: {
    ...mapMutations('cars/rents', {
      'setStoreInstance': DETAIL_SET
    }),

    confirmSubmit() {
      this.$modal.confirm(
        `Автомобиль числится в эксплуатации у другого водителя. Вы уверены, что хотите продолжить оформление заявки на "${this.car.name}"`,
        this.submit
      )
    },

    finish() {
      this.$modal.open({
        bind: {
          instance: this.instance,
          title: this.instance.user.id === this.$auth.user().id
            ? `Завершение эксплуатации ${this.car.name}`
            : `Завершить эксплуатацию ${this.instance.name} для ${this.instance.user.full_name}?`,
        },
        component: CarsRentsFinishModalForm,
        on: {
          close: () => this.$modal.close(),
          input: () => {
            this.$modal.close()
            this.$router.push({ name: 'cars.list' })
            this.$notifications.show(`Эксплуатация автомобиля "${this.car.name}" успешно завершена`)
          },
        },
      })
    },

    formDataCopy(instance) {
      this.formData = {
        car_id: instance.car.id,
        uid: instance.uid,
        datetime_begin: instance.datetime_begin,
        datetime_end: instance.datetime_end,
        comment: instance.comment,
      }
    },

    onSaved(instance) {
      this.$router.push({
        name: 'cars.rents.edit',
        params: {
          carId: instance.car.id,
          rentId: instance.id,
        }
      })
    }
  },

  validations() {
    return {
      formData: {
        datetime_begin: {
          minValue: (value) => {
            return !value || this.$moment(value) <= this.$moment()
          },
          required,
        },
        datetime_end: {
          minValue: (value) => !value || this.$moment(this.formData.datetime_begin) <= this.$moment(value),
        },
        comment: {
          maxLength: maxLength(255),
        }
      }
    }
  }
}
</script>
