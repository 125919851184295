<script>
export default {
  props: {
    /*
     Return get pk (id) for item.
    */
    itemKey: {
      type: [Array, String, Function],
      default: 'id',
    },

    /*
     Return human readable text and search string for item
    */
    itemText: {
      type: [Array, String, Function],
      default: 'text',
    },

    /*
     Return value for item.
     Using to define unique value (at mostly primary key like id)
    */
    itemValue: {
      type: [Array, String, Function],
      default: 'value',
    },

    returnObject: Boolean,
  },

  methods: {
    getItemData(item, key) {
      if (!item) {
        return null
      }

      if (typeof item !== 'object') {
        return item
      } else if (typeof key === 'string') {
        return item[key]
      } else if (Array.isArray(key)) {
        return key.forEach($key => item[$key])
      } else if (typeof key === 'function'){
        return key(item)
      } else {
        return item
      }
    },

    /* itemKey => return get pk (id) for item */
    getItemKey(item) {
      return this.getItemData(item, this.itemKey)
    },

    getItemText(item) {
      return this.getItemData(item, this.itemText)
    },

    getItemValue(item) {
      if (this.returnObject) {
        return item
      } else {
        return this.getItemData(item, this.itemValue)
      }
    },
  }
}
</script>