import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import controls from './controls'
import matching from './matching'
import money from './money'
import privateRequests from './private'
import stores from './stores'


export default {
  namespaced: true,

  actions,
  getters,
  mutations,
  state,

  modules: {
    controls,
    matching,
    money,
    stores,
    private: privateRequests,
  }
}
