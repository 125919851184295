<script>
import {isDeletable} from '@/assets/js/api/requests/private/applications'
import ContentContextMenu from '@/components/common/content/ContentContextMenu'
import {mapState} from 'vuex'

export default {
  extends: ContentContextMenu,

  data() {
    return {
      event: 'movements.menu'
    }
  },

  computed: {
    ...mapState('requests', [
      'summary'
    ]),

    actions() {
      return [
        {
          event: 'copy',
          icon: 'mdi-content-copy',
          show: !this.$auth.impersonating() && !(this.summary.private.applications.length && this.summary.private.applications.find(application => application.sent)),
          text: 'Копировать',
        },
        {
          event: 'delete',
          icon: 'mdi-delete',
          show: !this.$auth.impersonating() && this.item && isDeletable(this.item),
          text: 'Удалить',
        },
      ]
    }
  },
}
</script>
