export default {
    getCurrencyByNumericCode: (state) => numericCode => {
        return state.list.find(currency => currency.numeric_code === numericCode)
    },

    getCurrencyPostfixByNumericCode: (state, getters) => numericCode => {
        const currency = getters.getCurrencyByNumericCode(numericCode)
        return currency
            ? currency.symbol || currency.alphabetic_code
            : ''
    },

    isCurrencyIsRubbles: (state, getters) => numericCode => {
        const postfix = getters.getCurrencyPostfixByNumericCode(numericCode)
        return postfix === '₽'
    }
}
