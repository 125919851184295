export default {
    summary: {
        private: {
            applications: [],
            movements: [],
            purchases: [],
        },
        matching: {
            money: {
                compensations: 0,
                give_outs_approved: 0,
            }
        },
        stores: {
            movements: [],
        }
    },
    summaryError: null,
    summaryLoading: false,
}