import TheMatchingArchivesDetailWrapper from '@/views/requests/matching/archives/TheMatchingArchivesDetailWrapper'
import TheMatchingArchivesFormView from '@/views/requests/matching/archives/TheMatchingArchivesFormView'
import { PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW } from '@/assets/js/api/users'


export default [
    {
        path: '/requests/matching/archives/requests/:instanceId',
        component: TheMatchingArchivesDetailWrapper,
        props: true,
        meta: {
            auth: {
                roles: [PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW],
                forbiddenRedirect: {
                    name: 'requests.matching.archives_and_money_give_outs',
                },
                redirect: {
                    name: 'accounts.private'
                }
            },
        },
        children: [
            {
                path: '',
                name: 'requests.matching.archives.requests.form',
                component: TheMatchingArchivesFormView,
                props: true,
                meta: {
                    backref: {
                        name: 'requests.matching.archives_and_money_give_outs'
                    },
                    title: 'Заявка в архив'
                },
            },
        ]
    },
]
