<template>
  <v-container class="pa-0" fluid>
    <portal to="the-app-bar__btn-prepend">
      <v-btn
        height="100%"
        dark
        depressed
        exact
        text
        tile
        v-on:click="refresh"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </portal>

    <list-infinity :source="source">
      <template v-slot:item="{ item }">
        <v-list-item :to="{name: 'journals.purchases.detail', params: {instanceId: item.id}}">
          <v-list-item-avatar>
            <v-icon size="32" v-html="gatAvatarIcon(item)"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="purchases-list-item__content px-3 text-no-wrap flex-column align-stretch">
            <v-row class="ma-0">
              <v-col class="pa-0 text-truncate overflow-hidden">
                {{ item.object ? item.object.name : 'Неизвестный объект' }}&nbsp;
                <span class="grey--text">x{{ item.amount }}</span>
              </v-col>

              <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
                 - {{ item.sum|measurement(getCurrencyCode(getCurrency(item))) }}
               </v-col>
            </v-row>

            <v-row class="ma-0 flex-nowrap grey--text text--darken-2 text-body-2 overflow-hidden">
              <v-col class="mr-1 pa-0 text-truncate">{{ item.account ? item.account.name : 'Неизвестный счет' }}</v-col>
              <v-col class="pa-0 flex-grow-0 flex-shrink-0 text-right">{{ item.created_at|date }}</v-col>
            </v-row>

            <v-row class="ma-0 mt-n1 flex-nowrap grey--text text--darken-2 overflow-hidden">
              <v-col class="pa-0 caption text-truncate">{{ item.type_string || item.type && item.type.name }}</v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </list-infinity>
  </v-container>
</template>

<script>
import ListInfinity from '@/components/common/content/list/ListInfinity'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import {mapGetters} from 'vuex'
import {
  STATUS_ACCEPTED,
  STATUS_CANCELED,
  STATUS_DECLINED,
  STATUS_DRAFT,
  STATUS_SENT
} from '@/assets/js/api/requests/private/purchases'

export default {
  components: {
    ListInfinity
  },

  data() {
    return {
      source: new ApiPaginatedAccumulatedData(api.requests.private.purchases.list, [], {
        params: {
          status: [STATUS_ACCEPTED, STATUS_SENT, STATUS_DECLINED],
        }
      })
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),
  },

  methods: {
    gatAvatarIcon(purchase) {
      switch (purchase.status) {
        case STATUS_DECLINED:
          return 'mdi-alert-box'

        case STATUS_SENT:
          return 'mdi-timer-sand'

        case STATUS_DRAFT:
          return 'mdi-pencil'

        case STATUS_CANCELED:
          return 'mdi-close-circle-outline'

        default:
          return 'mdi-cart-arrow-down'
      }
    },

    getCurrency(purchase) {
      return this.getCurrencyByNumericCode(purchase.currency_id)
    },

    getCurrencyCode(currency) {
      return currency && (currency.symbol || currency.alphabetic_code)
    },

    refresh() {
      this.source.call()
    }
  }
}
</script>