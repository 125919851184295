import { request } from '@/assets/js/api/_helpers'


export const STATUS_ACCEPTED = 'accepted'
export const STATUS_CANCELED = 'canceled'
export const STATUS_DECLINED = 'declined'
export const STATUS_DRAFT = 'draft'
export const STATUS_SENT = 'sent'


const ENDPOINT_PURCHASES = '/api/requests/private/purchases'


export function getHumanReadableStatus(status) {
    switch (status) {
        case STATUS_ACCEPTED:
          return 'Утверждена'
        case STATUS_CANCELED:
          return 'Отклонена'
        case STATUS_DECLINED:
          return 'Требуется корректировка'
        case STATUS_DRAFT:
          return 'Черновик'
        default:
          return 'Отправлена'
    }
}


export function isDeletable(purchase) {
    return purchase.status === STATUS_DRAFT
}


export function getPurchaseFormData(data) {
    const formData = new FormData()

    formData.append('account', data.account)
    formData.append('amount', data.amount || '')
    formData.append('comment', data.comment)
    formData.append('object', data.object || '')
    formData.append('purchased_at', data.purchased_at || '')
    formData.append('status', data.status)
    formData.append('sum', data.sum || '')

    if (data.application) {
        formData.append('application', data.application.id)
    }

    if (data.type) {
        if (typeof(data.type) === 'string') {
            formData.append('type_string', data.type)
        } else {
            formData.append('type', data.type.id)
        }
    }

    data.files.map(image => image.id).filter(Boolean).forEach(fileId => formData.append('files', fileId))
    data.files.filter(image => !image.id).forEach(file => formData.append('files_upload', file.$file))

    return formData
}


export default {
    create: (config) => request('POST', ENDPOINT_PURCHASES, config),
    delete: ({ id, ...config }) => request('DELETE', `${ENDPOINT_PURCHASES}/${id}`, config),
    list: (config) => request( 'GET', ENDPOINT_PURCHASES, config),
    retrieve: ({ id, ...config }) => request( 'GET', `${ENDPOINT_PURCHASES}/${id}`, config),
    patch: ({ id, ...config }) => request('PATCH', `${ENDPOINT_PURCHASES}/${id}`, config),
}