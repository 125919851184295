<template>
  <loadable-content
    :error="error"
    :loading="loading"
  >
    <router-view v-bind="props"></router-view>
  </loadable-content>
</template>


<script>
import LoadableContent from '@/components/common/LoadableContent'

export default {
  components: {
    LoadableContent
  },

  data() {
    return {
      pk: 'id'
    }
  },

  props: {
    instanceId: {
      type: [String, Number],
    },
  },

  computed: {
    backref() {
      return undefined
    },

    instance() {
      console.warn('Please, redefine `instance` computed')
      return null
    },

    loading() {
      console.warn('Please, redefine `loading` computed')
      return false
    },

    error() {
      console.warn('Please, redefine `error` computed')
      return null
    },

    props() {
      return {
        instance: this.instance,
      }
    }
  },

  watch: {
    instanceId() {
      this.load()
    }
  },

  created() {
    this.load()
  },

  methods: {
    gotoBack() {
      console.log('gotoBack')
      let backref;
      if (typeof this.$route.meta.backref === 'function') {
        backref = this.$route.meta.backref(this.$route)
      } else {
        backref = this.$route.meta.backref
      }

      this.$router.push(backref)
    },

    load() {
      return this.retrieve(this.instanceId)
        .catch(error => {
          if (error.response && [403, 404].includes(error.response.status)) {
            this.gotoBack()
          } else {
            this.$notifications.showHttpError(error)
          }
        })
    },

    retrieve() {
      console.warn('Please, redefine `retrieve` method')
      return null
    }
  }
}
</script>
