import movements from './movements'
import purchases from './purchases'

export default {
    namespaced: true,

    modules: {
        movements,
        purchases,
    }
}