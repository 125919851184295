<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <drag-and-drop
      :disabled="$_readOnly"
      v-on:input="onFilesDropped"
    ></drag-and-drop>

    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="py-0 py-md-16"
    >
      <template slot="body">
        <v-navigation-drawer
          v-if="instance"
          v-model="commentsHistory"
          :width="$vuetify.breakpoint.mobile ? '75%' : '360px'"
          class="px-3 py-6"
          fixed
          right
          temporary
          touchless
        >
          <purchase-comment
            v-for="comment in instance.comments_active"
            :key="comment.id"
            :comment="comment"
            class="mb-6"
          ></purchase-comment>
        </v-navigation-drawer>

        <p
          v-if="instance && instance.number"
          class="mt-0 mb-6 px-3 grey--text"
        >
          Документ {{ instance.number }}
        </p>

        <status-alert-widget
          :instance="instance"
          accepted-icon="mdi-cart-arrow-down"
        ></status-alert-widget>

        <v-alert
          v-if="activeComment"
          class="mx-3"
          color="red"
          outlined
          text
        >
          <purchase-comment
            :comment="activeComment"
            class="red--text"
          ></purchase-comment>
        </v-alert>

        <v-container
          v-if="hasDeclinedComments"
          class="px-3 py-0"
        >
          <v-btn
            class="caption"
            block
            plain
            v-on:click="commentsHistory = true"
          >
            Просмотреть историю замечаний
          </v-btn>
        </v-container>

        <v-divider class="my-6"></v-divider>

        <v-select
          v-model="formData.account"
          :error="$v.formData.account && $v.formData.account.$error"
          :error-messages="errors.account"
          :items="accountsItems"
          :readonly="$_readOnly"
          class="mx-3"
          label="Счет"
          outlined
        ></v-select>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-autocomplete-paginated
              v-model="formData.object"
              :api-method-list="apiMethodObjectsList"
              :api-params-list="ApiObjectsParamsList"
              :api-params-retrieve="ApiObjectsParamsRetrieve"
              :error="$v.formData.object && $v.formData.object.$error"
              :error-messages="errors.object"
              :readonly="$_readOnly"
              item-text="name"
              item-value="id"
              label="Объект"
              ref="object"
              outlined
              v-on:input="$v.formData.object.$reset()"
            ></v-autocomplete-paginated>
          </v-col>

          <v-col class="py-0 flex-fill">
            <v-combobox
              v-model="formData.type"
              :error="$v.formData.type && $v.formData.type.$error"
              :error-messages="errors.type"
              :items="typesItems"
              :loading="types.loading"
              :readonly="$_readOnly"
              :search-input.sync="typesSearch"
              item-text="name"
              item-value="id"
              label="Номенклатура"
              hide-no-data
              outlined
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-row class="ma-0 flex-nowrap">
              <v-col class="pa-0 pr-3">
                <v-text-field
                  v-model="formData.amount"
                  :error="$v.formData.amount && $v.formData.amount.$error"
                  :error-messages="errors.amount"
                  :readonly="$_readOnly"
                  label="Количество"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col class="pa-0 pl-3">
                <v-text-field
                  v-model="formData.sum"
                  :error="$v.formData.sum && $v.formData.sum.$error"
                  :error-messages="errors.sum"
                  :readonly="$_readOnly"
                  :suffix="currency && (currency.symbol || currency.alphabetic_code)"
                  label="Сумма"
                  outlined
                  v-on:input="formData.sum = asNumber($event)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="py-0 flex-fill">
            <v-date-field
              v-model="formData.purchased_at"
              :error="$v.formData.purchased_at && $v.formData.purchased_at.$error"
              :error-messages="errors.purchased_at"
              :readonly="$_readOnly"
              class-field="purchases-form__purchased-at ml-auto"
              format="DD/MM/YYYY"
              label="Дата покупки"
            ></v-date-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-select-paginated
              v-model="formData.application"
              :api-method-list="apiMethodApplicationsList"
              :api-params-list="$data.$_applications.filters"
              :error="$v.formData.application.$error"
              :error-messages="errors.application"
              :hide-details="applicationCurrencyMismatch && !$v.formData.application.$error"
              :readonly="$_readOnly"
              item-text="number"
              item-value="id"
              label="Привязать к заявке на ДС"
              no-data-text="Нет заявок на ДС на данный счет"
              clearable
              outlined
              return-object
            >
              <template v-slot:item="{ item, on, bind }">
                <application-select-list-item
                  v-bind="bind"
                  :item="item"
                  v-on="on"
                ></application-select-list-item>
              </template>

              <template v-slot:selection="{ item }">
                <v-select-application :item="item"></v-select-application>
              </template>
            </v-select-paginated>

            <v-alert
              v-if="applicationCurrencyMismatch"
              class="mt-2 mb-5"
              icon="mdi-alert"
              type="warning"
              dense
              outlined
              text
            >
              Валюта в заявке на денежные срежства не совпадает с валютой счета
            </v-alert>
          </v-col>
        </v-row>

        <v-text-field
          v-if="showCommentField"
          v-model="formData.comment"
          :error="$v.formData.comment && $v.formData.comment.$error"
          :error-messages="errors.comment"
          :readonly="$_readOnly"
          class="mx-3"
          label="Комментарий"
          outlined
        ></v-text-field>

        <v-divider></v-divider>

        <v-files-field
          v-if="!$_readOnly || formData.files.length"
          v-model="formData.files"
          :error="$v.formData.files && $v.formData.files.$error"
          :error-messages="errors.files"
          :readonly="$_readOnly"
          :uploadable="!$_readOnly && formData.files.length < formDataLimitations.files.maxLength"
          class="mx-3 my-8"
          ref="VImagesField"
        ></v-files-field>

        <v-divider></v-divider>

        <v-container
          v-if="apiData.info.applicationPurchases.loading || apiData.info.applicationPurchases.data.length"
          class="pa-0"
          fluid
        >
          <p class="mt-0 mt-6 px-3 grey--text">
            Покупки привязанные к идентичной заявке на денежные средства
          </p>

          <v-progress-circular
            v-if="apiData.info.applicationPurchases.loading"
            class="mx-auto my-4 d-block"
            color="primary"
            indeterminate
          ></v-progress-circular>

          <template v-else-if="apiData.info.applicationPurchases.data.length">
            <purchases-list-item
              v-for="purchase in applicationPurchases"
              :key="purchase.id"
              :purchase="purchase"
            ></purchases-list-item>
          </template>
        </v-container>

        <template v-else>
          <v-alert
            border="left"
            class="mx-3"
            color="grey"
            icon="mdi-cart-remove"
            dense
            outlined
          >
            К выбранной заявке на денежные средства не привязано ни одной покупки
          </v-alert>
        </template>
      </template>

      <template v-if="!$auth.impersonating()" slot="controls">
        <purchases-form-controls
          :can-edit="instance && canEdit"
          :isCreating="!instance && creator.loading"
          :isUpdating="instance && creator.loading"
          :readonly="$_readOnly"
          v-on:edit="edit"
          v-on:save="save"
          v-on:send="send"
        ></purchases-form-controls>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import api from '@/assets/js/api'
import { mapGetters, mapMutations, mapState} from 'vuex'
import {ApiData, ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import VDateField from '@/components/inputs/VDateField'
import VFilesField from '@/components/inputs/VFilesField'
import {decimal, integer, maxLength, minValue, required} from 'vuelidate/lib/validators'
import {plural} from '@/filters/strings'
import {
  getPurchaseFormData,
  STATUS_DECLINED,
  STATUS_DRAFT,
  STATUS_SENT
} from '@/assets/js/api/requests/private/purchases'
import {
  STATUS_SENT as APPLICATION_STATUS_SENT,
  STATUS_ACCEPTED as APPLICATION_STATUS_ACCEPTED,
  applicationCanBaAttachedToPurchase
} from '@/assets/js/api/requests/private/applications'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'
import PurchaseComment from '@/components/requests/private/requests/purchases/purchaseComment'
import VSelectApplication from '@/components/requests/private/requests/applications/VSelectApplication'
import DragAndDrop from '@/components/inputs/DragAndDrop'
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import PurchasesFormControls from '@/components/requests/private/requests/purchases/form/PurchasesFormControls'
import {copy} from '@/assets/js/files'
import StatusAlertWidget from '@/components/requests/base/widgets/StatusAlertWidget'
import PurchasesListItem from '@/components/requests/private/requests/purchases/list/PurchasesListItem'
import {filesMaxTotalSize} from '@/assets/js/vuelidate'
import ApplicationSelectListItem from '@/components/requests/private/requests/applications/ApplicationSelectListItem'
import VAutocompletePaginated from '@/components/inputs/VAutocompletePaginated'
import VSelectPaginated from '@/components/inputs/VSelectPaginated'

export default {
  mixins: [Form],

  components: {
    VSelectPaginated,
    VAutocompletePaginated,
    ApplicationSelectListItem,
    PurchasesListItem,
    StatusAlertWidget,
    PurchasesFormControls,
    ContentForm,
    DragAndDrop,
    VSelectApplication,
    PurchaseComment,
    VFilesField,
    VDateField,
  },

  props: {
    accountId: Number,
    instance: Object,
    readonly: Boolean,
  },

  data() {
    return {
      $_applications: {
        filters: {
          closed: false,
          status: [APPLICATION_STATUS_SENT, APPLICATION_STATUS_ACCEPTED],
        },
      },
      apiData: {
        info: {
          applicationPurchases: new ApiPaginatedAccumulatedData(api.requests.private.purchases.list, [], {debounce: 500})
        }
      },
      apiDataForValidators: {
        application: new ApiData(api.requests.private.applications.retrieve, []),
        object: new ApiData(api.objects.retrieve, []),
      },
      copier: new ApiData(api.requests.private.purchases.retrieve),
      creator: new ApiData(this.instance ? api.requests.private.purchases.patch : api.requests.private.purchases.create),
      commentsHistory: false,
      formData: {
        account: this.accountId || null,
        amount: '',
        application: null,
        comment: '',
        files: [],
        object: null,
        purchased_at: null,
        status: STATUS_DRAFT,
        sum: '',
        type: '',
      },
      formDataLimitations: {
        comment: {
          maxLength: 255,
        },
        files: {
          maxLength: 10,
          minLength: 1,
          maxSize: 0,
        }
      },
      types: new ApiData(api.objects.types.list, []),
      typesSearch: '',
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    ...mapState('money/accounts', {
      accounts: 'list'
    }),

    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
      'getCurrencyPostfixByNumericCode',
    ]),

    account() {
      return this.getAccountById(this.formData.account || (this.instance && this.instance.account.id))
    },

    activeComment() {
      return this.instance && this.instance.status === STATUS_DECLINED && this.instance.comments_active.length && this.instance.comments_active[0]
    },

    applicationPurchases() {
      let purchases = this.apiData.info.applicationPurchases.error ? [] : this.apiData.info.applicationPurchases.data
      if (this.instance) {
        purchases = purchases.filter(purchase => purchase.id !== this.instance.id)
      }

      return purchases
    },

    canEdit() {
      return !this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.instance.status)
    },

    formDataErrors() {
      return {
        application: {
          closed: 'Заявка на ДС не должна быть закрыта',
          closedApiError: 'Неудалось проверить статус заявки на ДС. Попробуйте еще раз',
          hasAccess: `Заявка не доступна для выбора`,
          status: 'К данной заявке на ДС нельзя привязать покупку. Проверьте статус заявки на ДС',
        },
        files: {
          maxLength: `Разрешено прикрепить не более ${this.formDataLimitations.files.maxLength} ${plural(this.formDataLimitations.files.maxLength, 'изображение', 'изображения', 'изображений')}`,
          minLength: `К покупке необходимо приложить минимум ${this.formDataLimitations.files.minLength} ${plural(this.formDataLimitations.files.minLength, 'чек', 'чека', 'чеков')}`,
          required: `Необходимо добавить хотя бы ${this.formDataLimitations.files.minLength} ${plural(this.formDataLimitations.files.minLength, 'чек', 'чека', 'чеков')}`,
        },
        object: {
          hasAccess: `Вам не доступен данный объект`,
          hasAccessApiError: 'Неудалось проверить доступность объекта.'
        }
      }
    },

    currency() {
      const numericCode = this.account && this.account.currency_id
      return this.getCurrencyByNumericCode(numericCode)
    },

    accountsItems() {
      return this.accounts
        .filter(account => account.is_private)
        .map(account => ({
          text: account.name,
          value: account.id,
        }))
    },

    applicationCurrencyMismatch() {
      return Boolean(this.formData.application && this.currency && this.formData.application.currency_id !== this.currency.numeric_code)
    },

    hasDeclinedComments() {
      if (!this.instance) {
        return false
      }

      return this.instance.comments_active.length > 1 || this.instance.comments_active.length === 1 && this.instance.status === STATUS_DECLINED
    },

    ApiObjectsParamsList() {
      return {
        application: this.formData.application && this.formData.application.id,
        has_access: !this.formData.application || null,
      }
    },

    ApiObjectsParamsRetrieve() {
      return {
        application: this.readonly
          ? null
          : (this.formData.application && this.formData.application.id)
      }
    },

    showCommentField() {
      if (this.$route.name === 'accounts.private.details.requests.purchases.detail' && !this.formData.comment) {
        return false
      } else {
        return Boolean(!this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.formData.status) || this.formData.comment)
      }
    },

    typesItems() {
      return this.instance && this.instance.type && this.instance.type.deleted ? [this.instance.type, ...this.types.data] : this.types.data
    }
  },

  watch: {
    'formData.application'() {
      this.applicationPurchasesUpdate()
    },

    typesSearch() {
      this.typesUpdate()
    }
  },

  async created() {
    if (this.$auth.impersonating() && this.$route.name === 'accounts.private.details.requests.purchases.edit') {
      await this.$router.push({
        name: 'accounts.private.details.requests.purchases.detail',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id,
        }
      })
    }

    if (!this.readonly) {
      const
        application = Number(this.$route.query.application),
        object =  Number(this.$route.query.object),
        purchased_at = this.$route.query.purchased_at

      if (!this.instance) {
        this.formData.object = isNaN(object) ? this.formData.object : object
        this.formData.purchased_at = purchased_at || this.formData.purchased_at

        if (application) {
          const [promise, ] = api.requests.private.applications.retrieve({id: application})
          promise.then(([data, ]) => {
            this.formData.application = data
          })
        }
      }
    }
  },

  mounted() {
    this.typesUpdate()
  },

  methods: {
    ...mapMutations('requests/private/purchases', {
      'setStoreInstance': DETAIL_SET
    }),

    apiFormData: getPurchaseFormData,
    apiMethodObjectsList: api.objects.list,
    apiMethodApplicationsList: api.requests.private.applications.list,

    $_submit() {
      const confirmCurrencyMismatch = !this.instance || (this.instance && this.formData.application && this.instance.application.id !== this.formData.application.id)
      if (confirmCurrencyMismatch && this.applicationCurrencyMismatch) {
        this.$modal.confirm('Валюта счета и заявки ДС различается. Продолжить?', this.submit)
      } else {
        this.submit()
      }
    },

    applicationPurchasesUpdate() {
      this.apiData.info.applicationPurchases.clear()

      if (!this.formData.application) {
        return
      }

      const params = {
        application: this.formData.application.id,
        status: [APPLICATION_STATUS_ACCEPTED, APPLICATION_STATUS_SENT]
      }
      this.apiData.info.applicationPurchases.setParams(params, false)
      this.apiData.info.applicationPurchases.all()
    },

    beforeSubmit() {
      this.apiDataForValidators.application.error = ''
      this.apiDataForValidators.object.error = ''

      return Promise.all([
        this.formData.application && this.apiDataForValidators.application.call({
          id: this.formData.application.id
        }),
        this.formData.object && this.apiDataForValidators.object.call({
          id: this.formData.object,
          params: this.ApiObjectsParamsList,
        })
      ])
    },

    copyInstance() {
      this.formDataCopy({
        ...this.copier.data,
        files: copy(this.copier.data.files),
        status: STATUS_DRAFT,
      })
    },

    edit() {
      this.$router.push({
        name: 'accounts.private.details.requests.purchases.edit',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id
        }
      })
    },

    formDataCopy(purchase) {
      this.formData = {
        ...this.formData,
        account: purchase.account.id || this.accountId,
        amount: purchase.amount,
        application: purchase.application,
        comment: purchase.comment,
        files: purchase.files,
        object: purchase.object && purchase.object.id,
        purchased_at: purchase.purchased_at,
        status: purchase.status,
        sum: purchase.sum,
        type: purchase.type_string || purchase.type
      }
    },

    messageOnSaved(instance) {
      if (instance.status === STATUS_SENT) {
        this.$notifications.show('Документ успешно отправлен')
      } else {
        this.$notifications.show('Изменения сохранены')
      }
    },

    onFilesDropped(files) {
      this.$refs.VImagesField.append(files)
    },

    onSaved(instance) {
      if (instance.status === STATUS_SENT) {
        this.$router.push({
          name: 'accounts.private.details.requests.purchases',
          params: {
            accountId: this.creator.data.account.id
          }
        })
      } else {
        this.$router.push({
          name: 'accounts.private.details.requests.purchases.edit',
          params: {
            accountId: this.creator.data.account.id,
            instanceId: this.creator.data.id,
          }
        })
      }
    },

    save(event) {
      this.formData.status = STATUS_DRAFT
      this.$_submit(event)
    },

    send(event) {
      this.formData.status = STATUS_SENT
      this.$_submit(event)
    },

    typesUpdate() {
      const typePrev = this.formData.type
      const args = {
        params: {
          is_deleted: this.readonly,
          search: this.typesSearch,
        }
      }

      return this.types.call(args).then(([data, ]) => {
        const keep = typePrev && (typeof typePrev === 'string' || data.find(type => type.id === typePrev.id))
        this.formData.type = keep ? this.formData.type : null
      })
    }
  },

  validations() {
    const validators = {
      formData: {
        account: {
          required
        },
        application: {
          closed(value) {
            if (value && this.formData.application && !this.apiDataForValidators.application.error) {
              const application = this.apiDataForValidators.application.data
              return !application || (this.instance && this.instance.uid && this.instance.application.id === this.formData.application.id) || !application.closed_at
            } else {
              return true
            }
          },

          closedApiError: () => {
            return this.formData.application
              ? !this.apiDataForValidators.application.error
              : true
          },

          hasAccess: () => {
            return !this.formData.application || !!this.apiDataForValidators.application.data
          },

          status: (value) => {
            return value && this.formData.application && this.apiDataForValidators.application.data
              ? (this.instance && this.instance.uid) || applicationCanBaAttachedToPurchase(this.apiDataForValidators.application.data)
              : true
          },
        },
        amount: {
          integer,
          minValue: minValue(1),
        },
        comment: {
          maxLength: maxLength(this.formDataLimitations.comment.maxLength),
        },
        files: {
          filesMaxTotalSize: filesMaxTotalSize(1024 * 1024 * 25),
          maxLength: maxLength(this.formDataLimitations.files.maxLength),
        },
        object: {
          hasAccess: (value) => {
            if (value && !this.apiDataForValidators.object.error) {
              return !!this.apiDataForValidators.object.data
            } else {
              return true
            }
          },

          hasAccessApiError: () => {
            return !this.apiDataForValidators.error
          }
        },
        sum: {
          decimal
        }
      }
    }

    if (this.formData.status === STATUS_DRAFT) {
      return validators
    }

    const validatorsExtendedForNotDraft = {
      amount: {
        required
      },
      object: {
        required
      },
      purchased_at: {
        required
      },
      sum: {
        required
      },
      type: {
        required
      },
    }

    Object.keys(validatorsExtendedForNotDraft).forEach((key) => {
      if (validators.formData[key] === undefined) {
        validators.formData[key] = validatorsExtendedForNotDraft[key]
      } else {
        validators.formData[key] = {
          ...validators.formData[key],
          ...validatorsExtendedForNotDraft[key],
        }
      }
    })

    return validators
  }
}
</script>
