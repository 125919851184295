import Vue from 'vue'
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
    STREAM_CLIENT_SET,
    STREAM_ERROR_SET, STREAM_LAST_EVENT_SET,
} from '@/store/_prototypes/loadable/stream/mutations_types'

export default (url) => ({
    streamCustomEvents() {
        /* redefine this actions to add listeners to eventSource */
    },

    streamStart({ commit, dispatch, state }) {
        return new Promise((resolve, reject) => {
            if (state.stream && state.stream.readyState !== EventSourcePolyfill.CANCELED) {
                resolve()
                return
            } else {
                dispatch('streamStop')
                commit(STREAM_ERROR_SET, null)
                commit(STREAM_LAST_EVENT_SET, undefined)
            }

            console.log('stream: creating', url, state.stream ? state.stream.readyState : '<no stream>')
            const eventSource = new EventSourcePolyfill(url, {
              headers: {
                'Authorization': `Bearer ${Vue.auth.token()}`,
              }
            })

            commit(STREAM_CLIENT_SET, eventSource)
            dispatch('streamCustomEvents', eventSource)

            eventSource.onopen = function () {
                console.log('stream: opened', url)
                resolve()
            }

            eventSource.onerror = error => {
                commit(STREAM_ERROR_SET, error)
                console.log('stream: error', url)
                dispatch('streamStop')
                reject(error)
            }

            eventSource.onmessage = result => {
                const data = JSON.parse(result.data)
                commit(`STREAM_${result.type.toUpperCase()}`, data)
                commit(STREAM_LAST_EVENT_SET, result.lastEventId)
            }
        })
    },

    streamStop({ commit, state }) {
        if (state.stream) {
            state.stream.close()
            console.log('stream: stop', url)
        }
        commit(STREAM_CLIENT_SET, null)
    }
})
