<template>
  <v-list-item
    :class="[itemColor]"
    :disabled="deleting"
    v-ripple="false"
    :to="applicationDetailView"
    class="application-list-item"
    v-on:contextmenu.native.prevent="$root.$emit('applications.menu', [$event, application])"
  >
    <applications-list-modal-purchases
      v-model="modalPurchases"
      :application="application"
    ></applications-list-modal-purchases>

    <applications-list-item-menu
      :item="application"
      ref="contextMenu"
      v-on:copy="applicationCopy"
      v-on:purchase="purchaseCreate"
      v-on:delete="applicationDelete"
    ></applications-list-item-menu>

    <v-list-item-avatar>
      <v-progress-circular
        v-if="deleting"
        color="primary"
        indeterminate
      ></v-progress-circular>

      <v-icon
        v-else
        :color="avatarColor"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="application-list-item__content px-3 text-no-wrap flex-column align-stretch">
      <v-row class="ma-0 flex-nowrap">
        <v-col class="pa-0 text-truncate overflow-hidden">
          {{ application.object ? application.object.name : 'Неизвестный объект' }}&nbsp;
        </v-col>

        <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
           {{ application.sum|measurement(currencyCode) }}
         </v-col>
      </v-row>

      <v-row class="ma-0 text-body-2 grey--text text--darken-1 overflow-hidden text-truncate flex-nowrap">
        <div class="flex-fill text-truncate">{{ application.purpose }}</div>
      </v-row>

      <v-row class="ma-0 mt-n1 grey--text text--darken-1 caption">
        {{ application.created_at|date}}
      </v-row>
    </v-list-item-content>

    <v-list-item-action
      v-if="purchasesListView && application.purchases.length"
      class="ma-0"
    >
      <v-badge
        class="application-list-item__badge"
        color="grey"
        :content="application.purchases.length"
        :value="0 < application.purchases.length"
        offset-x="25"
        offset-y="25"
        overlap
      >
        <v-btn
          fab
          text
          tile
          v-on:click.prevent.stop="modalPurchases = true"
        >
          <v-icon color="grey">mdi-cart-outline</v-icon>
        </v-btn>
      </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import {mapGetters} from 'vuex'
import ApplicationsListItemMenu from '@/components/requests/private/requests/applications/list/ApplicationsListItemMenu'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import { DATE_FORMAT_ISO } from '@/filters/datetime'
import ApplicationsListModalPurchases
  from '@/components/requests/private/requests/applications/list/ApplicationsListModalPurchases'

export default {
  components: {ApplicationsListModalPurchases, ApplicationsListItemMenu},
  props: {
    application: Object,
    deleting: Boolean,
    purchasesListView: Boolean,
  },

  data() {
    return {
      deleter: new ApiData(api.requests.private.applications.delete),
      menu: false,
      modalPurchases: false,
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),

    avatarIcon() {
      if (this.application.is_issued) {
        return 'mdi-check-all'
      }

      switch (this.application.status) {
        case STATUS_DECLINED:
          return 'mdi-alert-box'

        case STATUS_SENT:
          return 'mdi-timer-sand'

        case STATUS_DRAFT:
          return 'mdi-pencil'

        case STATUS_CANCELED:
          return 'mdi-close-circle-outline'

        default:
          return 'mdi-check'
      }
    },

    avatarColor() {
      return this.application.status === STATUS_DECLINED ? 'red' : 'grey'
    },

    currency() {
      return this.getCurrencyByNumericCode(this.application.currency_id)
    },

    currencyCode() {
      return this.currency && (this.currency.symbol || this.currency.alphabetic_code)
    },

    itemColor() {
      switch (this.application.status) {
        case STATUS_ACCEPTED:
        case STATUS_CANCELED:
          return 'white'
        case STATUS_DECLINED:
          return 'red lighten-5'
        default:
          return 'white'
      }
    },

    applicationDetailView() {
      if ([STATUS_DRAFT, STATUS_DECLINED].includes(this.application.status)) {
        return {
          name: 'requests.applications.edit',
          params: {
            instanceId: this.application.id
          }
        }
      } else {
        return {
          name: 'requests.applications.detail',
          params: {
            instanceId: this.application.id
          }
        }
      }
    }
  },

  methods: {
    applicationCopy() {
      this.$router.push({
          name: 'requests.applications.create',
          query: {
            copy: this.application.id
          }
        })
    },

    applicationDelete() {
      this.deleter.call({id: this.application.id}).then(() => this.$emit('deleted'))
    },

    purchaseCreate() {
      this.$router.push({
        name: 'accounts.private.purchases.create',
        query: {
          application: this.application.id,
          purchased_at: this.$moment().format(DATE_FORMAT_ISO),
          object: this.application.object.id,
        }
      })
    }
  }
}
</script>

<style lang="scss">
.application-list-item {
  overflow: hidden;

  &__badge {
    & > .v-badge__wrapper > .v-badge__badge {
      pointer-events: none;
    }
  }

  &__content {
    & > * {
      max-width: 100%;
    }
  }
}
</style>
