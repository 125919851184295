import {LIST_ERROR_SET, LIST_LOADING_SET, LIST_SET} from '@/store/_prototypes/loadable/list/mutations_types'
import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'


const currencies = new ApiData(api.money.currencies.list)


export default {
    fetch({commit}) {
        commit(LIST_LOADING_SET, true)
        commit(LIST_ERROR_SET, null)

        return currencies.call()
            .then(() => commit(LIST_SET, currencies.data))
            .catch(() => commit(LIST_ERROR_SET, currencies.error))
            .finally(() => commit(LIST_LOADING_SET, currencies.loading))
    }
}