import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'
import {DETAIL_ERROR_SET, DETAIL_LOADING_SET, DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'


const purchasesRetrieve = new ApiData(api.requests.private.purchases.retrieve, null, )


export default {
    retrieve({ commit }, purchaseId) {
        commit(DETAIL_LOADING_SET, true)
        commit(DETAIL_ERROR_SET, null)

        return purchasesRetrieve.call({id: purchaseId})
            .then(() => commit(DETAIL_SET, purchasesRetrieve.data))
            .catch(error => {
                commit(DETAIL_ERROR_SET, purchasesRetrieve.error)
                throw error
            })
            .finally(() => commit(DETAIL_LOADING_SET, purchasesRetrieve.loading))
    },
}