<template>
  <v-row class="mx-0 mt-4 mb-0 flex-nowrap">
    <v-col class="flex-fill d-flex flex-column justify-space-between flex-sm-row">
      <template v-if="readonly">
        <v-btn
          v-if="instance && canEdit"
          key="edit"
          :block="$vuetify.breakpoint.xsOnly"
          color="primary"
          large
          outlined
          v-on:click="$emit('edit')"
        >
          <v-icon left>mdi-pencil</v-icon>
          Редактировать
        </v-btn>
      </template>

      <template v-else>
        <v-btn
          key="cancel"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="isCreating || isUpdating"
          class="my-2 my-sm-0 white--text"
          color="red"
          large
          outlined
          v-on:click="gotoBack"
        >
          <v-icon left>mdi-close</v-icon>
          Отмена
        </v-btn>
        <v-spacer></v-spacer>

        <template>
          <v-btn
            v-if="canSavaAsDraft"
            key="save"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="isCreating"
            :loading="isUpdating"
            class="my-2 my-sm-0 mx-0 mx-sm-4 white--text"
            color="primary"
            large
            outlined
            v-on:click="$emit('save')"
          >
            <v-icon left>mdi-content-save</v-icon>
            Сохранить
          </v-btn>
          <v-spacer v-else></v-spacer>
        </template>

        <v-btn
          key="send"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="isUpdating"
          :loading="isCreating"
          class="my-2 my-sm-0 white--text"
          color="deep-orange"
          large
          v-on:click="$emit('send')"
        >
          <v-icon
            color="white"
            left
          >mdi-cart-arrow-right</v-icon>
          Отправить
        </v-btn>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import {
  STATUS_DECLINED,
  STATUS_DRAFT
} from '@/assets/js/api/requests/private/applications'

export default {
  props: {
    instance: Object,
    readonly: Boolean,
    saving: Boolean,
  },

  computed: {
    canEdit() {
      return !this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.instance.status)
    },

    canSavaAsDraft() {
      return !this.instance
    },

    isCreating() {
      return !this.instance && this.saving
    },

    isUpdating() {
      return this.instance && this.saving
    }
  },

  methods: {
    gotoBack() {
      let backref;
      if (typeof this.$route.meta.backref === 'function') {
        backref = this.$route.meta.backref(this.$route)
      } else {
        backref = this.$route.meta.backref
      }

      this.$router.push(backref)
    }
  }
}
</script>
