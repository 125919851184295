<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="$attrs.readonly"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="{...attrs, ...$attrs}"
        :class="classField"
        :clearable="clearable"
        :hide-details="hideDetails"
        :label="label"
        :value="dateFormatted"
        outlined
        prepend-inner-icon="mdi-calendar"
        readonly
        v-on="on"
        v-on:change="$emit('input', $event)"
        v-on:click:clear="$emit('click:clear', $event)"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="valueTemp"
      first-day-of-week="1"
      locale="ru-ru"
      no-title
      v-on:input="save()"
    >
      <v-spacer></v-spacer>

      <v-btn
        text
        color="primary"
        v-on:click="menu = false"
      >
        Cancel
      </v-btn>

      <v-btn
        text
        color="primary"
        v-on:click="save()"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {DATE_FORMAT} from '@/filters/datetime'

export default {
  props: {
    classField: [String, Array, Object],
    clearable: Boolean,
    format: {
      type: String,
      default: DATE_FORMAT,
    },
    hideDetails: Boolean,
    label: String,
    value: [String, Date]
  },

  data() {
    return {
      menu: false,
      valueTemp: this.value
    }
  },

  computed: {
    dateFormatted() {
      return this.value
        ? this.$moment(this.value).format(this.format)
        : null
    }
  },

  watch: {
    value() {
      this.valueTemp = this.value
    }
  },

  methods: {
    save() {
      this.$emit('input', this.valueTemp)
      this.menu = false
    }
  }
}
</script>
