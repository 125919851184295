import {request} from '@/assets/js/api/_helpers'


const ENDPOINT_OBJECTS = '/api/objects'
const ENDPOINT_OBJECTS_TYPES = '/api/objects/types'


export default {
    list: (config) => request('GET', ENDPOINT_OBJECTS, config),
    retrieve: ({id, ...config}) => request('GET', `${ENDPOINT_OBJECTS}/${id}`, config),

    types: {
        list: (config) => request('GET', ENDPOINT_OBJECTS_TYPES, config),
    }
}