import TheRequestsMatchingArchivesAndMoneyGiveOuts from '@/views/requests/matching/TheRequestsMatchingArchivesAndMoneyGiveOuts'
import {
    PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW,
    PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW
} from '@/assets/js/api/users'

export default [
    {
        path: '/requests/matching/archives_and_money_give_outs',
        name: 'requests.matching.archives_and_money_give_outs',
        component: TheRequestsMatchingArchivesAndMoneyGiveOuts,
        meta: {
            auth: {
                roles: [PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW, PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW],
                redirect: {
                    name: 'accounts.private'
                }
            },
            title: 'Запросы'
        },
    },
]