<script>
import ContentTabbed from '@/components/common/content/ContentTabbed'
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import ApplicationsListInfinityScroll from '@/components/requests/private/requests/applications/list/ApplicationsListInfinityScroll'
import {mapState} from 'vuex'

export default {
  extends: ContentTabbed,

  computed: {
    ...mapState('requests', [
      'summary',
    ]),

    canCreate() {
      return !this.$auth.impersonating()
    },

    formRoute() {
      return {name: 'requests.applications.create'}
    },

    tabs() {
      return [
        {
          key: 'changes',
          bind: {
            filters: {status: [STATUS_SENT, STATUS_DECLINED, STATUS_CANCELED]},
            hideCaptions: false,
          },
          component: ApplicationsListInfinityScroll,
          title: 'Заявки',
        },
        {
          key: 'history',
          bind: {
            filters: {status: [STATUS_ACCEPTED]},
            hideCaptions: false,
          },
          component: ApplicationsListInfinityScroll,
          title: 'История',
        },
        {
          key: 'drafts',
          bind: {
            filters: {status: [STATUS_DRAFT]},
            hideCaptions: true,
          },
          component: ApplicationsListInfinityScroll,
          title: 'Черновики',
        },
      ]
    },
  },
}
</script>