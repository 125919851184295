<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
    >
      <template slot="body">
        <v-select
          v-model="formData.account"
          :error="$v.formData.account.$error"
          :error-messages="errors.account"
          :items="accountsItems"
          :readonly="$_readOnly"
          class="mx-3"
          item-text="name"
          item-value="id"
          label="Счет"
          outlined
        ></v-select>

        <v-row class="ma-0 justify-space-between">
          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6">
            <v-text-field
              v-model="formData.sum"
              :error="$v.formData.sum.$error"
              :error-messages="errors.sum"
              :readonly="$_readOnly"
              :suffix="account ? getCurrencyPostfixByNumericCode(account.currency_id) : ''"
              label="Сумма"
              outlined
              v-on:blur="formData.sum = asNumber(formData.sum)"
            ></v-text-field>
          </v-col>

          <v-col class="py-0" :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6">
            <v-date-field
              v-model="formData.createdAt"
              :error="$v.formData.createdAt.$error"
              :error-messages="errors.createdAt"
              :readonly="$_readOnly"
              label="Дата создания"
              outlined
            ></v-date-field>
          </v-col>
        </v-row>

        <v-text-field
          v-if="showCommentField"
          v-model="formData.comment"
          :error="$v.formData.comment.$error"
          :error-messages="errors.comment"
          :readonly="$_readOnly"
          class="mx-3"
          label="Комментарий"
          outlined
        ></v-text-field>
      </template>

      <template v-if="!$auth.impersonating()" slot="controls">
        <receipts-cash-form-controls
          :can-edit="instance && canEdit"
          :isCreating="!instance && creator.loading"
          :isUpdating="instance && creator.loading"
          :readonly="$_readOnly"
          v-on:edit="edit"
          v-on:save="save"
          v-on:send="send"
        ></receipts-cash-form-controls>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import {mapGetters, mapMutations, mapState} from 'vuex'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import {decimal, maxLength, minValue, required} from 'vuelidate/lib/validators'
import {getReceiptCashFormData} from '@/assets/js/api/requests/stores/receipts_cash'
import {STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/stores/receipts_cash'
import ReceiptsCashFormControls from '@/components/requests/stores/receipts_cash/form/ReceiptsCashFormControls'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'
import VDateField from '@/components/inputs/VDateField'

export default {
  components: {
    VDateField,
    ReceiptsCashFormControls,
    ContentForm
  },

  props: {
    accountId: [Number, null]
  },

  mixins: [Form],

  data() {
    return {
      creator: this.instance && this.instance.id
        ? new ApiData(api.requests.stores.receipts_cash.patch)
        : new ApiData(api.requests.stores.receipts_cash.create),
      formData: {
        account: this.accountId || null,
        comment: '',
        createdAt: null,
        status: STATUS_DRAFT,
        sum: 0,
      }
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById'
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    ...mapState('money/accounts', {
      accounts: 'list',
    }),

    account() {
      return this.getAccountById(this.formData.account)
    },

    accountsItems() {
      return this.accounts.filter(account => account.is_store)
    },

    backref() {
      return {name: 'accounts.stores.details.requests.receipts_cash', params: {accountId: this.accountId}}
    },

    canEdit() {
      return !this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.formData.status)
    },

    showCommentField() {
      return Boolean(!this.readonly || this.formData.comment)
    },

  },

  mounted() {
    if (this.$auth.impersonating() && this.$route.name === 'accounts.stores.details.requests.receipts_cash.edit') {
      this.$router.push({
        name: 'accounts.stores.details.requests.receipts_cash.detail',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id,
        }
      })
    }
  },

  methods: {
    ...mapMutations('requests/stores/receipts_cash', {
      setStoreInstance: DETAIL_SET,
    }),
    apiFormData: getReceiptCashFormData,

    edit() {
      this.$router.push({
        name: 'accounts.stores.details.requests.receipts_cash.edit',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id
        }
      })
    },

    formDataCopy(instance) {
      this.formData = {
        ...this.formData,
        account: instance.account_id,
        comment: instance.comment,
        createdAt: instance.created_at,
        status: instance.status,
        sum: instance.sum,
      }
    },

    messageOnSaved(instance) {
      if (instance.status === STATUS_SENT) {
        this.$notifications.show('Документ успешно отправлен')
      } else {
        this.$notifications.show('Изменения сохранены')
      }
    },

    onSaved(instance) {
      if (instance.status === STATUS_SENT) {
        this.$router.push({
          name: 'accounts.stores.details.requests.receipts_cash',
          params: {
            accountId: instance.account_id,
          }
        })
      } else {
        this.$router.push({
          name: 'accounts.stores.details.requests.receipts_cash.edit',
          params: {
            accountId: instance.account_id,
            instanceId: instance.id,
          }
        })
      }
    },

    save() {
      this.formData.status = STATUS_DRAFT
      this.submit()
    },

    send() {
      this.formData.status = STATUS_SENT
      this.submit()
    },
  },

  validations: {
    formData: {
      account: {
        required,
      },

      comment: {
        maxLength: maxLength(255),
      },

      createdAt: {
        required,
      },

      sum: {
        decimal,
        minValue: minValue(0.01),
        required,
      },
    }
  }
}
</script>