<template>
  <v-list-group
    v-model="item.expanded"
    v-if="item.hasChildren"
    :sub-group="subGroup"
  >
    <template v-slot:prependIcon>
      <v-badge
        :color="item.badgeColor"
        :content="item.badge"
        :value="!item.expanded && item.badge"
        overlap
      >
        <v-icon v-text="item.icon"></v-icon>
      </v-badge>
    </template>

    <template v-slot:activator>
      <v-list-item-title>
        {{ item.text }}
      </v-list-item-title>
    </template>

    <menu-mobile-list-item
      v-for="child in item.childrenItemsFlat"
      :key="child.key"
      :item="child"
    ></menu-mobile-list-item>

    <menu-mobile-list
      v-for="child in item.childrenItemsExpandable"
      :key="child.key"
      :item="child"
      :subGroup="true"
    ></menu-mobile-list>

    <v-divider></v-divider>
  </v-list-group>

  <menu-mobile-list-item
    v-else
    :item="item"
  ></menu-mobile-list-item>
</template>

<script>
import { MenuItem } from '@/assets/js/menu'
import MenuMobileListItem from '@/components/menu/aside/mobile/MenuMobileListItem.vue'

export default {
  name: 'menu-mobile-list',
  components: {MenuMobileListItem},
  props: {
    item: MenuItem,
    subGroup: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
