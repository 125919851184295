<template>
  <loadable-content
    :error="error"
    :loading="loading"
  >
    <v-list
      expand
      flat
      width="100%"
    >
      <component
        v-for="account in accounts"
        :is="itemListComponent"
        :key="account.uid"
        :account="account"
      ></component>
    </v-list>
  </loadable-content>
</template>

<script>
import {mapState} from 'vuex'
import MoneyAccountsBaseListItem from '@/components/money/accounts/base/MoneyAccountsBaseListItem'
import LoadableContent from '@/components/common/LoadableContent'

export default {
  components: {
    LoadableContent
  },

  data() {
    return {
      itemListComponent: MoneyAccountsBaseListItem,
    }
  },

  computed: {
    ...mapState('money/accounts', {
      error: 'listError',
      items: 'list',
      loading: 'listLoading',
    }),

    accounts() {
      return []
    }
  },
}
</script>