<template>
  <error-card
    v-if="source.error"
    :error="source.error"
  ></error-card>

  <v-container
    v-else
    class="pa-0 d-flex flex-column flex-nowrap align-stretch"
    fluid
  >
    <template v-if="items.length === 0 && !source.loading">
      <slot name="empty">
        <content-list-empty-card></content-list-empty-card>
      </slot>
    </template>

    <v-list>
      <template v-for="item in items">
        <slot name="item" :item="item">
          <v-list-item>{{ item }}</v-list-item>
        </slot>
      </template>
    </v-list>

    <v-lazy
      v-if="source.pageNext && !source.loading"
      :value="source.loading"
      v-on:input="extend"
    ></v-lazy>

    <v-progress-circular
      v-if="source.pageNext || source.loading"
      class="mx-auto my-6 flex-shrink-0"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import InfinityPagination from '@/mixins/InfinityPagination'
import ErrorCard from '@/components/common/ErrorCard'
import ContentListEmptyCard from '@/components/common/content/list/ContentListEmptyCard'

export default {
  mixins: [InfinityPagination],
  components: {ContentListEmptyCard, ErrorCard},
}
</script>