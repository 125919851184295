<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    max-width="50%"
    v-on:input="$emit('input', false)"
  >
    <v-card class="d-flex flex-column">
      <v-card-title>
        Делегирование заявки на выдачу ДС
      </v-card-title>

      <v-card-actions>
        <v-select
          v-model="delegateTo"
          :error="!!delegations.error"
          :error-messages="delegations.error"
          :hide-details="delegations.error"
          :items="delegations.data"
          :loading="delegations.loading"
          item-text="employee_name"
          item-value="employee_uid"
          class="mx-3 my-4"
          label="Кому"
          outlined
          v-on:change="delegations.error = null"
        ></v-select>
      </v-card-actions>

      <v-spacer></v-spacer>

      <v-card-actions class="my-4 d-flex justify-space-around">
        <v-progress-circular
          v-if="delegator.loading"
          color="primary"
          indeterminate
        ></v-progress-circular>

        <template v-else>
          <v-btn
            color="green"
            width="196px"
            dark
            v-on:click="delegate"
          >
            <v-icon left>mdi-check</v-icon>
            Делегировать
          </v-btn>

          <v-btn
            color="red"
            width="196px"
            dark
            v-on:click="$emit('input', false)"
          >
            <v-icon left>mdi-close</v-icon>
            Отмена
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'

export default {
  props: {
    instance: Object,
    value: Boolean,
  },

  data() {
    return {
      delegator: new ApiData(api.requests.matching.money.give_outs_approved.delegate),
      delegateTo: null,
      delegations: new ApiData(api.requests.matching.money.give_outs_approved.delegations, []),
    }
  },

  watch: {
    value() {
      if (this.value) {
        this.delegateTo = null
        this.delegations.call()
      }
    }
  },

  methods: {
    delegate() {
      if (!this.delegateTo) {
        this.delegations.error = 'Требуется выбрать пользователя'
      }

      if (this.delegations.error) {
        return
      }

      this.delegator.call({id: this.instance.id, data: {employee_uid: this.delegateTo}}).then(() => {
        this.$emit('delegated')
        this.$emit('input', false)
      })
    },
  }
}
</script>