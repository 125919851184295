import TheAuthFormWrapperView from '@/views/auth/TheAuthFormWrapperView'
import TheAuthCredentials from '@/components/auth/TheAuthCredentials'
import TheAuthCodeReceived from '@/views/auth/TheAuthCodeReceived'



export default [
    {
        path: '',
        component: TheAuthFormWrapperView,
        children: [
            {
                path: '',
                name: 'auth.login',
                component: TheAuthCredentials,
                meta: {
                    auth: false,
                },
            },
            {
                path: '/auth/:oauthSystem',
                name: 'auth.token',
                component: TheAuthCodeReceived,
                meta: {
                    appBarHide: true,
                    auth: false
                },
            }
        ]
    }

]
