import TheJournalsPurchasesView from '@/views/journals/purchases/TheJournalsPurchasesView'
import TheRequestsPrivatePurchasesDetailWrapper from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesDetailWrapper'
import TheRequestsPrivatePurchasesFormView from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesFormView'
import {PERMISSION_ACCOUNTS_PRIVATE_VIEW} from '@/assets/js/api/users'

export default [
    {
        path: 'purchases',
        component: TheJournalsPurchasesView,
        name: 'journals.purchases',
        meta: {
            title: 'Покупки'
        },
        auth: {
            roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
            forbiddenRedirect: {
                name: 'requests.matching.archives_and_money_give_outs'
            },
            redirect: {
                name: 'auth.login'
            }
        }
    },
    {
        path: 'purchases/:instanceId',
        component: TheRequestsPrivatePurchasesDetailWrapper,
        props: true,
        auth: {
            roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
            forbiddenRedirect: {
                name: 'requests.matching.archives_and_money_give_outs'
            },
            redirect: {
                name: 'auth.login'
            }
        },

        children: [
            {
                path: '',
                name: 'journals.purchases.detail',
                component: TheRequestsPrivatePurchasesFormView,
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: {name: 'journals.purchases'},
                    title: 'Просмотр покупки'
                },
            },
        ]
    },
]
