import archives from './archives'
import money from './money'

export default {
    namespaced: true,

    modules: {
        archives,
        money,
    }
}