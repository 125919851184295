<template>
  <money-compensations-list-infinity-scroll></money-compensations-list-infinity-scroll>
</template>

<script>
import MoneyCompensationsListInfinityScroll
  from '@/components/requests/matching/money/compensations/list/MoneyCompensationsListInfinityScroll'
export default {
  components: {MoneyCompensationsListInfinityScroll}
}
</script>