<template>
  <v-container class="the-money-accounts-router-wrapper-view pa-0 d-md-flex flex-row flex-nowrap align-start" fluid>
    <v-navigation-drawer
      :absolute="!$vuetify.breakpoint.mobile"
      :fixed="$vuetify.breakpoint.mobile"
      :permanent="!$vuetify.breakpoint.mobile"
      :temporary="$vuetify.breakpoint.mobile"
      :width="$vuetify.breakpoint.mobile ? '75%' : '340px'"
      clipped
    >
      <component :is="accountsListComponent"></component>
    </v-navigation-drawer>

    <portal
      to="the-app-bar__title"
    >
      {{ account ? account.name : $route.meta.title }}
    </portal>

    <router-view
      :class="{'with-menu': !$vuetify.breakpoint.mobile}"
      class="the-money-accounts-router-wrapper-view__content flex-fill"
    ></router-view>
  </v-container>
</template>

<script>
import MoneyAccountsPrivateList from '@/components/money/accounts/private/MoneyAccountsPrivateList'
import {mapGetters} from 'vuex'

export default {
  components: { MoneyAccountsPrivateList },

  props: {
    accountsListComponent: undefined,
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    account() {
      const accountId = this.$route.params.accountId
      if (accountId) {
        return this.getAccountById(Number(accountId))
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.the-money-accounts-router-wrapper-view {
  &__help {
    padding-left: 340px !important;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .the-money-accounts-router-wrapper-view {
    &__content {
      &.with-menu {
        padding-left: 340px !important;
      }
    }
  }
}
</style>