export function copy(files) {
    const filesWithSource = files.map(image => ({
        ...image,
        id: null,
        loading: true,
    }))

    filesWithSource.forEach(image =>
        fetch(image.url, {mode: 'cors'})
            .then(res => res.blob())
            .then(blob => {
                image.loading = false
                image.$file = new File([blob], image.filename, {type: blob.type})
                image.url = URL.createObjectURL(image.$file)
            })
    )

    return filesWithSource
}