<template>
  <modal-dialog
    :title="title"
    v-on:close="$emit('close')"
  >
    <p v-html="question"></p>

    <template v-slot:actions>
      <v-btn
        :dark="!isLoading"
        :disabled="isLoading"
        :loading="isLoading"
        color="green"
        width="150px"
        outlined
        v-on:click="submit"
      >
        <v-icon left>mdi-check</v-icon>
        Да
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        :dark="!isLoading"
        :disabled="isLoading"
        color="red"
        width="150px"
        v-on:click="$emit('close')"
      >
        <v-icon left>mdi-close</v-icon>
        Нет
      </v-btn>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/common/modals/ModalDialog'
export default {
  components: {ModalDialog},

  props: {
    callback: {
      type: Function,
      default: undefined
    },
    question: String,
    title: String,
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    async submit() {
      this.isLoading = true
      this.$modal.locked = true

      Promise.resolve(this.callback())
        .then(() => {
          this.$emit('close')
        })
        .finally(() => {
          this.isLoading = false
          this.$modal.locked = false
        })
    }
  }
}
</script>