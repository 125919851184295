export function comparator(a, b) {
  if (a < b) {
    return -1
  } else if (a > b) {
    return 1
  } else {
    return 0;
  }
}


export function comparatorBy(key) {
    return (a, b) => comparator(a[key], b[key])
}