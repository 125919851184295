<template>
  <v-card :width="$vuetify.breakpoint.mobile ? '100%' : '400px'">
    <v-overlay
      v-if="impersonating"
      fullscreen
      fixed
    >
      <v-progress-circular
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card-title>Просмотр под пользователем</v-card-title>

    <v-card-text>
      <v-autocomplete-paginated
        v-model="formData.user"
        :api-method-list="apiMethodUsersList"
        :api-params-list="{ is_boss: false, is_active: true }"
        :error="$v.formData.user.$error"
        :error-messages="errors.user"
        :hide-details="!$v.formData.user.$error"
        item-text="full_name"
        item-value="id"
        label="Пользователь"
        clearable
        outlined
        return-object
        v-on:change="$v.formData.user.$reset()"
      >
        <template v-slot:item="{ item }">
          <v-list-item-content class="flex-column align-start">
            <p class="ma-0">{{ item.full_name }}</p>
            <p class="ma-0 grey--text">{{ item.email }}</p>
          </v-list-item-content>
        </template>
      </v-autocomplete-paginated>
    </v-card-text>

    <v-card-actions class="px-4 pb-8">
      <v-btn
        color="grey"
        width="150px"
        outlined
        v-on:click="showInfo"
      >
        <v-icon left>mdi-help</v-icon>
        Справка
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        color="green"
        width="150px"
        outlined
        v-on:click="submit"
      >
        <v-icon left>mdi-incognito</v-icon>
        Просмотр
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from '@/assets/js/api'
import VAutocompletePaginated from '@/components/inputs/VAutocompletePaginated'
import {required} from 'vuelidate/lib/validators'
import Validations from '@/mixins/Validations'

export default {
  mixins: [Validations],

  components: {VAutocompletePaginated},
  data() {
    return {
      formData: {
        user: null,
      },
      impersonating: false,
    }
  },

  methods: {
    apiMethodUsersList: api.users.list,

    submitEvent() {
      this.$modal.confirm(`Начать просмотр под пользователем ${this.formData.user.full_name}?`, this.impersonate)
    },

    async impersonate() {
        this.impersonating = true
        if (this.$auth.impersonating()) {
          await this.$auth.unimpersonate({})
        }

        return this.$auth.impersonate({
            url: '/api/users/' + this.formData.user.id + '/impersonate',
            redirect: {name: 'accounts.private'},
        })
        .catch(error => this.$notifications.showHttpError(error))
        .finally(() => this.impersonating = false)
    },

    showInfo() {
      this.$modal.info(
        'Имперсонализация',
        'Вам будет временно предоставлен доступ в систему от лица выбранного пользователя.<br><br>Для выхода из режима просмотра от лица другого пользователя Вам необходимо будет выполнить выход из аккаунта. После чего Вы вернетесь к своему пользователю.'
      )
    }
  },

  validations: {
    formData: {
      user: {
        required
      }
    }
  }
}
</script>
