<template>
  <!--- wrapper to not create additional routes to otp verify --->
  <router-view></router-view>
</template>


<script>
export default {

}
</script>