import TheMoneyCompensationsListView from '@/views/requests/matching/money_compensations/TheMoneyCompensationsListView'
import TheMoneyCompensationsDetailWrapper
    from '@/views/requests/matching/money_compensations/TheMoneyCompensationsDetailWrapper'
import TheMoneyCompensationsForm from '@/views/requests/matching/money_compensations/TheMoneyCompensationsForm'

export default [
    {
        path: '/requests/matching/money/compensations',
        name: 'requests.matching.money.compensations',
        component: TheMoneyCompensationsListView,
        meta: {
            title: 'Возмещения ДС'
        },
    },
    {
        path: '/requests/matching/money/compensations/:instanceId',
        component: TheMoneyCompensationsDetailWrapper,
        props: route => ({
            instanceId: Number(route.params.instanceId),
        }),

        children: [
            {
                path: '',
                name: 'requests.matching.money.compensations.form',
                component: TheMoneyCompensationsForm,
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                }),
                meta: {
                    backref: {
                        name: 'requests.matching.money.compensations'
                    },
                    title: 'Возмещение ДС'
                },
            },
        ]
    },
]
