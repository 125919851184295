import moment from 'moment'


export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_ISO = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const TIME_FORMAT = 'HH:mm'

export default {
    date: (value) => moment(value).format(DATE_FORMAT),
    datetime: (value) => moment(value).format(DATETIME_FORMAT),
}
