<template>
  <v-list-item
    :key="item.key"
    :disabled="item.disabled"
    :to="item.to"
    exact
    v-on:click="callback"
  >
    <v-list-item-icon>
      <v-badge
        :color="item.badgeColor"
        :content="item.badge"
        :value="item.badge"
        overlap
      >
        <v-icon v-text="item.icon"></v-icon>
      </v-badge>
    </v-list-item-icon>

    <v-list-item-title>
      {{ item.text }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import {MenuItem} from "@/assets/js/menu";

export default {
  props: {
    item: MenuItem
  },

  methods: {
    callback() {
      if (this.item.callback) {
        this.item.callback(this)
      }
    }
  }
}
</script>
