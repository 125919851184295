<script>
import MoneyAccountsControlsListItemOperation from '@/components/money/accounts/controls/MoneyAccountsControlsListItemOperation'

export default {
  extends: MoneyAccountsControlsListItemOperation,

  computed: {
    detail() {
        return {name: 'accounts.controls.details.requests.purchases', params: {accountId: this.account.id}}
    }
  }
}
</script>