<template>
  <v-row class="mx-0 mt-4 mb-0 flex-nowrap">
    <v-col class="flex-fill d-flex flex-column justify-space-between flex-sm-row">
      <v-btn
        v-if="readonly && canEdit"
        key="edit"
        :block="$vuetify.breakpoint.xsOnly"
        color="primary"
        large
        outlined
        v-on:click="$emit('edit')"
      >
        <v-icon left>mdi-pencil</v-icon>
        Редактировать
      </v-btn>

      <template v-else-if="!readonly">
        <v-btn
          key="cancel"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="isCreating || isUpdating"
          class="my-2 my-sm-0 white--text"
          color="red"
          large
          outlined
          v-on:click="gotoBack"
        >
          <v-icon left>mdi-close</v-icon>
          Отмена
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          key="save"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="isCreating"
          :loading="isCreating || isUpdating"
          class="my-2 my-sm-0 mx-0 mx-sm-4 white--text"
          color="primary"
          large
          outlined
          v-on:click="$emit('save')"
        >
          <v-icon left>mdi-content-save</v-icon>
          Сохранить
        </v-btn>

        <v-btn
          key="send"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="isUpdating"
          :loading="isCreating || isUpdating"
          class="my-4 my-sm-0 white--text"
          color="deep-orange"
          large
          v-on:click="$emit('send')"
        >
          <v-icon
            color="white"
            left
          >mdi-cart-arrow-right</v-icon>
          Отправить
        </v-btn>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    canEdit: Boolean,
    isCreating: Boolean,
    isUpdating: Boolean,
    readonly: Boolean,
  },

  methods: {
    gotoBack() {
      let backref;
      if (typeof this.$route.meta.backref === 'function') {
        backref = this.$route.meta.backref(this.$route)
      } else {
        backref = this.$route.meta.backref
      }

      this.$router.push(backref)
    },
  }
}
</script>