import actionsStream from '@/store/_prototypes/loadable/stream/actions'
import gettersStream from '@/store/_prototypes/loadable/stream/getters'
import mutationsStream from '@/store/_prototypes/loadable/stream/mutations'
import stateList from '@/store/_prototypes/loadable/list/state'
import stateStream from '@/store/_prototypes/loadable/stream/state'

import {ENDPOINT_MATCHING_ARCHIVES_ISSUES} from '@/assets/js/api/requests/matching/archives/issues'
import getters from '@/store/requests/matching/archives/issues/getters'
import mutations from '@/store/requests/matching/archives/issues/mutations'


export default {
    namespaced: true,

    actions: {
        ...actionsStream(ENDPOINT_MATCHING_ARCHIVES_ISSUES + '/stream'),
    },
    getters: {
        ...gettersStream(),
        ...getters,
    },
    mutations: {
        ...mutationsStream(),
        ...mutations,
    },
    state: {
        ...stateList(),
        ...stateStream(),
    },
}