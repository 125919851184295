<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ContentDetailWrapper from '@/components/common/content/ContentDetailWrapper'

export default {
  extends: ContentDetailWrapper,

  data() {
    return {
      pk: 'uid'
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/issues', {
      getRequestByUid: 'getRequestByUid',
      loading: 'streamIsLoading',
    }),
    ...mapState('requests/matching/archives/issues', {
      'error': 'listError',
    }),

    instance() {
      return this.getRequestByUid(this.instanceId)
    },
  },

  methods: {
    ...mapActions('requests/matching/archives/issues', {
      retrieve: 'streamStart',
    }),
  }
}
</script>