import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'


import {DETAIL_ERROR_SET, DETAIL_LOADING_SET, DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'

const applicationsRetrieve = new ApiData(api.requests.private.applications.retrieve, null, )


export default {
    retrieve({ commit }, applicationId) {
        commit(DETAIL_LOADING_SET, true)
        commit(DETAIL_ERROR_SET, null)

        return applicationsRetrieve.call({id: applicationId})
            .then(() => commit(DETAIL_SET, applicationsRetrieve.data))
            .catch(error => {
                commit(DETAIL_ERROR_SET, applicationsRetrieve.error)
                throw error
            })
            .finally(() => commit(DETAIL_LOADING_SET, applicationsRetrieve.loading))
    },
}