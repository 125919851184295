<script>
import MoneyAccountsBaseList from '@/components/money/accounts/base/MoneyAccountsBaseList'
import MoneyAccountsStoresListItem from '@/components/money/accounts/stores/MoneyAccountsStoresListItem'

export default {
  extends: MoneyAccountsBaseList,

  data() {
    return {
      itemListComponent: MoneyAccountsStoresListItem,
    }
  },

  computed: {
    accounts() {
      return this.items.filter(item => item.is_store)
    }
  },
}
</script>