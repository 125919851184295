<template>
  <v-menu :key="item.id" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="item.badge"
        :value="item.badge"
        class="fill-height"
        color="green lighten-2"
        offset-x="22"
        offset-y="27"
        overlap
      >
        <v-btn
          color="transparent"
          height="100%"
          dark
          depressed
          exact
          tile
          v-bind="attrs"
          v-on="on"
        >
          {{ item.text }}
        </v-btn>
      </v-badge>
    </template>

    <v-list>
      <v-list-item
        v-for="child in item.children"
        :key="child.id"
        :disabled="child.disabled"
        :to="child.to"
        v-on:click="callback(child)"
      >
        <v-list-item-icon>
          <v-badge
            :content="child.badge"
            :value="child.badge"
            color="green lighten-2"
            overlap
          >
            <v-icon v-text="child.icon"></v-icon>
          </v-badge>
        </v-list-item-icon>

        <v-list-item-title>
          {{ child.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {MenuItem} from "@/assets/js/menu";

export default {
  props: {
    item: MenuItem
  },

  methods: {
    callback(item) {
      if (item.callback) {
        item.callback(this)
      }
    }
  }
}
</script>