import TheMoneyGiveOutsApprovedDetailWrapper from '@/views/requests/matching/money_give_outs_approved/TheMoneyGiveOutsApprovedDetailWrapper'
import TheMoneyGiveOutsApprovedForm from '@/views/requests/matching/money_give_outs_approved/TheMoneyGiveOutsApprovedForm'
import TheMoneyGiveOutsApprovedListView from '@/views/requests/matching/money_give_outs_approved/TheMoneyGiveOutsApprovedListView'

export default [
    {
        path: '/requests/matching/money/give_outs/approved',
        name: 'requests.matching.money.give_outs.approved',
        component: TheMoneyGiveOutsApprovedListView,
        meta: {
            title: 'Выдача ДС'
        },
    },
    {
        path: '/requests/matching/money/give_outs/approved/:instanceId',
        component: TheMoneyGiveOutsApprovedDetailWrapper,
        props: route => ({
            instanceId: Number(route.params.instanceId),
        }),

        children: [
            {
                path: '',
                name: 'requests.matching.money.give_outs.approved.form',
                component: TheMoneyGiveOutsApprovedForm,
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                }),
                meta: {
                    backref: {
                        name: 'requests.matching.money.give_outs.approved'
                    },
                    title: 'Выдача ДС'
                },
            },
        ]
    },
]
