import actionsDetail from '@/store/_prototypes/loadable/detail/actions'
import actionsList from '@/store/_prototypes/loadable/list/actions'
import mutationsDetail from '@/store/_prototypes/loadable/detail/mutations'
import mutationsList from '@/store/_prototypes/loadable/list/mutations'
import stateDetail from '@/store/_prototypes/loadable/detail/state'
import stateList from '@/store/_prototypes/loadable/list/state'

import api from '@/assets/js/api'

import rents from './rents'

export default {
    namespaced: true,

    actions: {
        ...actionsDetail(api.cars.retrieve),
        ...actionsList({ list: api.cars.list })
    },
    mutations: {
        ...mutationsDetail(),
        ...mutationsList(),
    },
    state: {
        ...stateDetail(),
        ...stateList(),
    },

    modules: {
        rents,
    }
}
