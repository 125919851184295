import axios from 'axios'
import {api} from '@/plugins/axios'
import {httpStatusGetError} from '@/assets/js/http'


export class ApiData {
    constructor(method, defaultData = null, { debounce = 0, params = {} } = {}) {
        this.method = method
        this.data = defaultData
        this.defaultData = defaultData
        this.debounce = debounce
        this._params = params  // const query params for each api call. To update it use this.setParams(newParams = {})

        this.cancelToken = null
        this.error = null
        this.loading = false

        this._timer = undefined
    }

    get params() {
        return {...this._params}
    }

    $_request(args) {
        if (this._timer) {
            clearTimeout(this._timer)
        }

        this.loading = true
        this.error = null

        return new Promise((resolve, reject) => {
            this._timer = setTimeout(() => {
                if (this.cancelToken) {
                    this.cancelToken.cancel()
                }

                let promise
                [promise, this.cancelToken] = this.method(args)

                promise.then(([data, response]) => {
                    if (response) {
                        this.loading = false // не в final т.к. в момент завершения промиса может быть другой
                        this.cancelToken = null
                        resolve([data, response])
                    }
                    // можно не розолвить т.к. запрос считается отмененным если нет response
                })

                promise.catch(error => {
                    this.clear()
                    console.error(error)
                    this.loading = false  // не в final т.к. в момент завершения промиса может быть другой
                    this.error = httpStatusGetError(error)
                    reject(error)
                })
            }, this.debounce)
        })
    }

    call({ params, ...args } = {}) {
        return this.$_request({ ...args, params: {...this.params, ...params} })
            .then(([data, response]) => {
                this.data = data
                return [data, response]
            })
    }

    cancel() {
      if (this.cancelToken) {
          this.cancelToken.cancel()
      }
    }

    clear() {
        this.data = this.defaultData
        this.error = ''
    }

    async setParams(params = {}, reload = false) {
        this._params = params
        if (reload) {
            return this.call()
        }
    }
}


export class ApiPaginatedData extends ApiData {
    constructor(method, defaultData = [], {debounce = 0, params = {}, perPage = 20} = {}) {
        super(method, defaultData, { debounce, params })
        this.perPage = perPage

        this.page = undefined
        this.pages = 1
        this.total = 0
    }

    get pageNext() {
        if (this.page) {
            return this.page < this.pages
                ? this.page + 1
                : null
        } else {
            return 1
        }
    }

    call({ params, ...args } = {}) {
        return super.call({...args, params: { per_page: this.perPage, ...params }})
            .then(([data, response]) => {
                this.paginationSet(response)
                return [data, response]
            })
    }

    paginationSet(response) {
        this.page = Number(response.headers['x-page'])
        this.pages = Number(response.headers['x-pages'])
        this.perPage = Number(response.headers['x-per-page'])
        this.total = Number(response.headers['x-total'])
    }

    paginationReset() {
        this.page = undefined
        this.pages = 1
        this.total = 0
    }

    async setParams(params = {}, reload= false) {
        this._params = params
        this.paginationReset()
        if (reload) {
            return this.call()
        }
    }
}


export class ApiPaginatedAccumulatedData extends ApiPaginatedData {
    all({ params, ...args } = {}) {
        return new Promise((resolve, reject) => {
            this.call({...args, params: { per_page: this.perPage, ...params }})
                .then(async () => {
                    while (this.page < this.pages) {
                        await this.extend()
                    }

                    resolve(this.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    call(config = {}) {
        this.paginationReset()
        return super.call(config)
    }

    extend({ params, ...args } = {}) {
        return this.$_request({ ...args, params: {page: this.pageNext, per_page: this.perPage, ...this.params, ...params} })
            .then(([data, response]) => {
                this.paginationSet(response)
                this.data = [...this.data, ...data]
                return [data, response]
            })
    }
}


export function request(method, url, { headers, params, data, ...config} = {}) {
    const cancelToken = axios.CancelToken.source()
    const promise = api.request({ cancelToken: cancelToken.token, method, url, headers, params, data, ...config })
        .then(response => {
            return [response && response.data, response]
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                return [null, null]
            }
            throw error
        })
    return [promise, cancelToken]
}
