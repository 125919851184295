import {request} from '@/assets/js/api/_helpers'

const ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED = '/api/requests/matching/money/give_outs/approved'


export default {
    delegate: ({ id, ...config }) => request('POST', `${ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED}/${id}/user`, config),
    delegations: (config) => request( 'GET', `${ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED}/delegations`, config),
    list: (config) => request( 'GET', ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED, config),
    movement: ({ id, ...config }) => request( 'POST', `${ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED}/${id}/movement`, config),
    retrieve: ({ id, ...config }) => request( 'GET', `${ENDPOINT_REQUESTS_MONEY_GIVE_OUTS_APPROVED}/${id}`, config),
}