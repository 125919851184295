import {SUMMARY_ERROR_SET, SUMMARY_LOADING_SET, SUMMARY_SET} from '@/store/requests/mutations_types'

export default {
    [SUMMARY_ERROR_SET]: (state, error) => {
        state.summaryError = error
    },

    [SUMMARY_LOADING_SET]: (state, isLoading) => {
        state.summaryLoading = isLoading
    },

    [SUMMARY_SET]: (state, summary) => {
        state.summary = summary
    }
}