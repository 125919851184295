import actionsList from '@/store/_prototypes/loadable/list/actions'
import actionsStream from '@/store/_prototypes/loadable/stream/actions'
import gettersRemoteRequests from '@/store/requests/matching/_prototype/getters'
import gettersStream from '@/store/_prototypes/loadable/stream/getters'
import mutationsMatching from '@/store/requests/matching/_prototype/mutations'
import mutationsStream from '@/store/_prototypes/loadable/stream/mutations'
import mutationsList from '@/store/_prototypes/loadable/list/mutations'
import stateDetail from '@/store/_prototypes/loadable/detail/state'
import stateList from '@/store/_prototypes/loadable/list/state'
import stateStream from '@/store/_prototypes/loadable/stream/state'

import api from '@/assets/js/api'
import {ENDPOINT_MATCHING_ARCHIVES_REQUESTS} from '@/assets/js/api/requests/matching/archives/requests'


export default {
    namespaced: true,

    actions: {
        ...actionsList({list: api.requests.matching.archives.requests.list}),
        ...actionsStream(ENDPOINT_MATCHING_ARCHIVES_REQUESTS + '/stream'),
    },
    getters: {
        ...gettersRemoteRequests(),
        ...gettersStream(),
    },
    mutations: {
        ...mutationsList(),
        ...mutationsMatching(),
        ...mutationsStream(),
        ...mutationsMatching(),  // переопределяет мутацию из стрима
    },
    state: {
        ...stateDetail(),
        ...stateList(),
        ...stateStream(),
    },
}