<template>
  <v-container class="the-application-from-view d-flex align-start align-md-center fill-height py-2 py-md-16" fluid>
    <drag-and-drop
      :disabled="$_readOnly"
      v-on:input="onFilesDropped"
    ></drag-and-drop>

    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="mb-15"
    >
      <template slot="body">
        <v-navigation-drawer
          v-if="instance"
          v-model="commentsHistory"
          :width="$vuetify.breakpoint.mobile ? '75%' : '360px'"
          class="px-3 py-6"
          fixed
          right
          temporary
          touchless
        >
          <purchase-comment
            v-for="comment in instance.comments"
            :key="comment.id"
            :comment="comment"
            class="mb-6"
          ></purchase-comment>
        </v-navigation-drawer>

        <template v-if="instance && instance.number">
          <p class="my-0 px-3 grey--text text--darken-1 text-no-wrap">
            <v-tooltip
              color="grey"
              right
            >
              <template v-slot:activator="{on, attrs}">
                <v-icon
                  v-if="instance.closed_at"
                  v-bind="attrs"
                  color="grey"
                  size="16"
                  v-on="on"
                >
                  mdi-lock
                </v-icon>
              </template>

              <span>Документ закрыт</span>
            </v-tooltip>

            Документ {{ instance.number }}
          </p>

          <v-divider class="mx-3"></v-divider>

          <p
            class="mx-3 mt-0 mb-6 caption grey--text text--darken-2">
            Остаток: {{ instance.balance | measurement(currency && (currency.symbol || currency.alphabetic_code)) }}
          </p>
        </template>

        <v-alert
          v-if="instance && instance.jira_task_url"
          icon="mdi-jira"
          class="mx-3"
          color="blue light-1"
          outlined
          text
        >
          <v-row>
            <v-col>
              Связано с задачей Jira
            </v-col>

            <v-col class="py-0 flex-grow-0 d-flex align-center">
              <v-btn
                :href="instance.jira_task_url"
                target="_blank"
              >
                Открыть
              </v-btn>
            </v-col>
          </v-row>

        </v-alert>

        <application-status-alert-widget
          :instance="instance"
        ></application-status-alert-widget>

        <v-alert
          v-if="activeComment"
          class="mx-3"
          color="red"
          outlined
          text
        >
          <purchase-comment
            :comment="activeComment"
            class="red--text"
          ></purchase-comment>
        </v-alert>

        <v-container
          v-if="hasDeclinedComments"
          class="px-3 py-0 mb-6"
        >
          <v-btn
            class="caption"
            block
            plain
            v-on:click="commentsHistory = true"
          >
            Просмотреть историю замечаний
          </v-btn>
        </v-container>

        <v-divider class="my-6"></v-divider>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-autocomplete-paginated
              v-model="formData.object"
              :api-method-list="apiMethodObjects"
              :api-params-list="apiMethodObjectsParamsList"
              :error="$v.formData.object.$error"
              :error-messages="errors.object"
              :readonly="$_readOnly"
              item-text="name"
              item-value="id"
              label="Объект"
              outlined
            ></v-autocomplete-paginated>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col class="py-0" :cols="$vuetify.breakpoint.mobile ? 12 : 6">
            <v-row class="ma-0 flex-no-wrap">
              <v-col class="pa-0">
                <v-text-field
                  v-model="formData.sum"
                  :error="$v.formData.sum.$error"
                  :error-messages="errors.sum"
                  :readonly="$_readOnly"
                  class="rounded-r-0"
                  label="Сумма"
                  outlined
                  v-on:input="formData.sum = asNumber($event)"
                ></v-text-field>
              </v-col>

              <v-col class="pa-0" cols="4">
                <v-autocomplete
                  v-model="formData.currency"
                  :error="$v.formData.currency.$error"
                  :error-messages="errors.currency"
                  :readonly="$_readOnly"
                  :items="currencies"
                  :item-text="(currency) => currency.symbol || currency.alphabetic_code"
                  class="the-application-from-view__currency rounded-l-0"
                  label="Валюта"
                  item-value="numeric_code"
                  outlined
                  v-on:input="formData.currency = asNumber($event)"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.alphabetic_code }}

                          <span
                            v-if="item.symbol"
                            class="grey--text"
                          >{{ item.symbol }}</span>
                        </v-list-item-title>

                        <v-list-item-subtitle
                          v-text="item.currency"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="py-0" :cols="$vuetify.breakpoint.mobile ? 12 : 6">
            <v-date-field
              v-model="formData.issued_at"
              :error="$v.formData.issued_at.$error"
              :error-messages="errors.issued_at"
              :readonly="$_readOnly"
              format="DD/MM/YYYY"
              label="Дата получения"
            ></v-date-field>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col class="py-0">
            <v-select
              v-model="formData.office"
              :error="$v.formData.office.$error"
              :error-messages="errors.office"
              :items="offices"
              :readonly="$_readOnly"
              label="Офис получения"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-textarea
          v-model="formData.purpose"
          :error="$v.formData.purpose.$error"
          :error-messages="errors.purpose"
          :readonly="$_readOnly"
          class="mx-3"
          label="Назначение"
          rows="1"
          auto-grow
          outlined
        ></v-textarea>

        <v-text-field
          v-if="showCommentField"
          v-model="formData.comment"
          :error="$v.formData.comment.$error"
          :error-messages="errors.comment"
          :readonly="$_readOnly"
          class="mx-3"
          label="Комментарий"
          outlined
        ></v-text-field>

        <v-divider></v-divider>

        <template v-if="!$_readOnly || formData.files.length">
          <v-files-field
            v-model="formData.files"
            :error="$v.formData.files.$error"
            :error-messages="errors.files"
            :readonly="$_readOnly"
            :uploadable="!$_readOnly && formData.files.length < formDataLimitations.files.maxLength"
            class="mx-3 my-8"
            ref="VImagesField"
          ></v-files-field>

          <v-divider v-if="instance && instance.purchases.length"></v-divider>
        </template>

        <template v-if="instance && instance.purchases.length">
          <purchases-list-item
            v-for="purchase in instance.purchases"
            :key="purchase.id"
            :purchase="purchase"
          ></purchases-list-item>
        </template>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!$auth.impersonating() && instance && applicationCanBaAttachedToPurchase(instance)"
              key="edit"
              :class="{'mb-15': $vuetify.breakpoint.smAndDown}"
              class="the-application-from-view__btn-create-purchase"
              color="primary"
              bottom
              fab
              fixed
              v-bind="attrs"
              large
              right
              v-on="on"
              v-on:click="createPurchase"
            >
              <v-icon>mdi-cart-plus</v-icon>
            </v-btn>
          </template>

          Создать покупку
        </v-tooltip>

      </template>

      <template v-if="!$auth.impersonating()" slot="controls">
        <applications-form-controls
          :instance="instance"
          :readonly="$_readOnly"
          :saving="creator.loading"
          v-on:edit="edit"
          v-on:save="save"
          v-on:send="send"
        ></applications-form-controls>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import api from '@/assets/js/api'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import { ApiData } from '@/assets/js/api/_helpers'
import VDateField from '@/components/inputs/VDateField'
import VFilesField from '@/components/inputs/VFilesField'
import {decimal, maxLength, minValue, required} from 'vuelidate/lib/validators'
import {plural} from '@/filters/strings'
import {
  applicationCanBaAttachedToPurchase,
  getApplicationFormData,
  STATUS_DECLINED,
  STATUS_DRAFT,
  STATUS_SENT
} from '@/assets/js/api/requests/private/applications'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'
import PurchaseComment from '@/components/requests/private/requests/purchases/purchaseComment'
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import ApplicationsFormControls from '@/components/requests/private/requests/applications/form/ApplicationsFormControls'
import DragAndDrop from '@/components/inputs/DragAndDrop'
import {copy} from '@/assets/js/files'
import PurchasesListItem from '@/components/requests/private/requests/purchases/list/PurchasesListItem'
import {filesMaxTotalSize} from '@/assets/js/vuelidate'
import {DATE_FORMAT_ISO} from '@/filters/datetime'
import VAutocompletePaginated from '@/components/inputs/VAutocompletePaginated'
import ApplicationStatusAlertWidget from '@/components/requests/private/requests/applications/form/ApplicationStatusAlertWidget.vue'

export default {
  mixins: [Form],

  components: {
    VAutocompletePaginated,
    ApplicationStatusAlertWidget,
    PurchasesListItem,
    DragAndDrop,
    ApplicationsFormControls,
    ContentForm,
    PurchaseComment,
    VFilesField,
    VDateField,
  },

  props: {
    instance: Object,
    readonly: Boolean,
  },

  data() {
    return {
      apiMethodObjectsParamsList: {
        has_access: true,
      },
      copier: new ApiData(api.requests.private.applications.retrieve),
      creator: new ApiData(this.instance ? api.requests.private.applications.patch : api.requests.private.applications.create),
      commentsHistory: false,
      formData: {
        comment: '',
        currency: null,
        files: [],
        issued_at: null,
        object: null,
        office: null,
        purpose: '',
        status: STATUS_DRAFT,
        sum: '',
      },
      formDataLimitations: {
        comment: {
          maxLength: 255,
        },
        purpose: {
          maxLength: 500,
        },
        files: {
          maxLength: 10,
          minLength: 1,
          maxSize: 0,
        }
      },
      offices: [
        {
          text: 'Санкт-Петербург',
          value: 'SPB',
        },
        {
          text: 'Москва',
          value: 'MOW',
        }
      ],
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),

    ...mapState('money/currencies', {
      currencies: 'list',
    }),

    activeComment() {
      return this.instance && this.instance.status === STATUS_DECLINED && this.instance.comments_active.length && this.instance.comments_active[0]
    },

    formDataErrors() {
      return {
        issued_at: {
          minValue: `Дата получения прошла`,
        },
        files: {
          maxLength: `Разрешено прикрепить не более ${this.formDataLimitations.files.maxLength} ${plural(this.formDataLimitations.files.maxLength, 'изображение', 'изображения', 'изображений')}`,
          minLength: `К покупке необходимо приложить минимум ${this.formDataLimitations.files.minLength} ${plural(this.formDataLimitations.files.minLength, 'чек', 'чека', 'чеков')}`,
          required: `Необходимо добавить хотя бы ${this.formDataLimitations.files.minLength} ${plural(this.formDataLimitations.files.minLength, 'чек', 'чека', 'чеков')}`,
        }
      }
    },

    currency() {
      const numericCode = this.formData && this.formData.currency_id
      return this.getCurrencyByNumericCode(numericCode)
    },

    hasDeclinedComments() {
      if (!this.instance) {
        return false
      }

      return this.instance.comments_active.length > 1 || this.instance.comments_active.length === 1 && this.instance.status === STATUS_DECLINED
    },

    showCommentField() {
      if (this.$route.name === 'requests.applications.detail' && !this.formData.comment) {
        return false
      } else {
        return Boolean(!this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.formData.status) || this.formData.comment)
      }
    },
  },

  mounted() {
    if (this.$auth.impersonating() && this.$route.name === 'requests.applications.edit') {
      this.$router.push({
        name: 'requests.applications.detail',
        params: {
          instanceId: this.instance.id,
        }
      })
    }
  },

  methods: {
    ...mapActions('requests', {
      fetchRequestsSummary: 'fetchSummary'
    }),

    ...mapMutations('requests/private/applications', {
      'setStoreInstance': DETAIL_SET
    }),

    applicationCanBaAttachedToPurchase,
    apiFormData: getApplicationFormData,
    apiMethodObjects: api.objects.list,

    copyInstance() {
      this.formDataCopy({
        ...this.copier.data,
        files: copy(this.copier.data.files),
        status: STATUS_DRAFT,
      })
    },

    createPurchase() {
      this.$router.push({
        name: 'accounts.private.purchases.create',
        query: {
          application: this.instance.id,
          purchased_at: this.$moment().format(DATE_FORMAT_ISO),
          object: this.instance.object.id,
        }
      })
    },

    edit() {
      this.$router.push({
        name: 'requests.applications.edit',
        params: {
          instanceId: this.instance.id
        }
      })
    },

    formDataCopy(application) {
      this.formData = {
        ...this.formData,
        comment: application.comment,
        currency: application.currency_id,
        issued_at: application.issued_at,
        files: application.files,
        object: application.object && application.object.id,
        office: application.office,
        purpose: application.purpose,
        status: application.status,
        sum: application.sum,
      }
    },

    messageOnSaved(instance) {
      if (instance.status === STATUS_SENT) {
        this.$notifications.show('Документ успешно отправлен')
      } else {
        this.$notifications.show('Изменения сохранены')
      }
    },

    onFilesDropped(files) {
      this.$refs.VImagesField.append(files)
    },

    onSaved(instance) {
      this.fetchRequestsSummary()

      if (instance.status === STATUS_SENT) {
        this.$router.push({ name: 'requests.applications' })
      } else {
        if (this.$route.name === 'requests.applications.create') {
          this.$router.push({
            name: 'requests.applications.edit',
            params: {
              instanceId: instance.id,
            }
          })
        }
      }
    },

    save() {
      this.formData.status = STATUS_DRAFT
      this.submit()
    },

    send() {
      this.formData.status = STATUS_SENT
      this.submit()
    },
  },

  validations() {
    return {
      formData: {
        comment: {
          maxLength: maxLength(this.formDataLimitations.comment.maxLength),
        },
        currency: {
          required,
        },
        files: {
          filesMaxTotalSize: filesMaxTotalSize(1024 * 1024 * 25),
          maxLength: maxLength(this.formDataLimitations.files.maxLength),
        },
        issued_at: {
          minValue: (value) => (
            !value
            || (this.instance && this.instance.uid)
            || this.$moment().subtract(1, 'days').toDate() <= this.$moment(value).toDate()
          ),
          required
        },
        object: {
          required
        },
        office: {
          required,
        },
        purpose: {
          maxLength: maxLength(this.formDataLimitations.purpose.maxLength),
          required,
        },
        sum: {
          decimal,
          required,
          minValue: minValue(0.01),
        },
      }
    }
  }
}
</script>

<style lang="scss">
.the-application-from-view {
  &__currency {

    &:not(.v-input--is-focused) > .v-input__control > .v-input__slot > fieldset {
      border-left: none !important;
    }
  }
}
</style>
