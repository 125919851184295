import rents from './rents'
import rentsConflicts from './conflicts'
import TheCarsListView from '@/views/cars/TheCarsListView.vue'
import { PERMISSION_CARS_VIEW } from '@/assets/js/api/users'


export default [
  {
    path: '/cars',
    name: 'cars.list',
    component: TheCarsListView,
    meta: {
      auth: {
          roles: PERMISSION_CARS_VIEW,
          forbiddenRedirect: {
              name: 'accounts.private'
          },
          redirect: {
              name: 'auth.login'
          }
      },
      title: 'Автомобили'
    },
  },
  ...rentsConflicts,
  ...rents,
]
