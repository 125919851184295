<template>
  <request-matching-remote-list-item
    :canceled="isCanceled"
    :completed="isCompleted"
    :has-new-message="hasNewComments(item)"
    :is-new="isNew(item)"
    :to="{ name: 'requests.matching.archives.requests.form', params: {instanceId: item.number} }"
  >
    <template v-slot:avatar>
      <v-icon>mdi-archive</v-icon>
    </template>

    <v-row class="ma-0 black--text">
      <v-col class="pa-0 overflow-hidden">
        <p
          v-for="type in typesToShow"
          :key="type"
          :class="$vuetify.breakpoint.mobile ? 'caption' : 'text-body-2 font-weight-light'"
          class="ma-0 text-truncate"
          v-text="type"
        ></p>
      </v-col>

      <v-col class="pa-0 d-flex flex-column flex-shrink-0 flex-grow-0 justify-start text-right text-no-wrap">
        <p class="ma-0">{{ item.created_by || 'Сотрудник отсутсвует' }}</p>

        <p class="ma-0 grey--text caption grey--text text--darken-1">{{ item.created_at | moment('DD.MM.YYYY') }}</p>
      </v-col>
    </v-row>
  </request-matching-remote-list-item>
</template>

<script>
import {mapGetters} from 'vuex'
import {STATUS_CANCELED, STATUS_COMPLETED} from '@/assets/js/api/requests/matching/archives/requests'
import RequestMatchingRemoteListItem from '@/components/requests/base/matching/RequestMatchingRemoteListItem'
export default {
  components: {RequestMatchingRemoteListItem},
  props: {
    item: Object,
  },

  data() {
    return {
      maxLinesToShow: 3,
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/requests', [
      'hasNewComments',
      'isNew',
    ]),

    isCanceled() {
      return this.item.status === STATUS_CANCELED
    },

    isCompleted() {
      return this.item.status === STATUS_COMPLETED
    },

    types() {
      return Array.from(new Set(this.item.documents.map(document => document.type)))
    },

    typesToShow() {
      if (this.typesTooMuch) {
        return this.types.slice(0, Math.max(0, this.maxLinesToShow - 1))
      } else {
        return this.types
      }
    },

    typesTooMuch() {
      return this.types.length > this.maxLinesToShow
    }
  }
}
</script>
