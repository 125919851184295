<template>
  <v-container class="pa-0 mb-4">
    <v-row class="ma-0 caption nowrap text-no-wrap">
      <p class="ma-0 grey--text text--darken-1 text-truncate">{{ comment.author }}</p>
      <v-spacer></v-spacer>
      <span class="grey--text text--darken-1">{{ comment.created_at|datetime }}</span>
    </v-row>

    <v-divider></v-divider>

    <span class="grey--text text--darken-4 text-body-2">{{ comment.text }}</span>
  </v-container>
</template>

<script>
export default {
  props: {
    comment: Object
  }
}
</script>