<script>
import MovementsFormBase from '@/components/requests/base/movements/form/MovementsFormBase'
import {measurement} from '@/filters/numbers'
import {getMovementFormData, STATUS_ACCEPTED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/movements'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import {mapMutations} from 'vuex'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'
import {httpStatusGetError} from '@/assets/js/http'
import {getApplicationFormData} from '@/assets/js/api/requests/private/applications'
import {DATE_FORMAT_ISO} from '@/filters/datetime'
import MovementsFormRefillDialog from '@/components/requests/base/movements/form/MovementsFormRefillDialog'

export default {
  extends: MovementsFormBase,

  data() {
    return {
      copier: new ApiData(api.requests.private.movements.retrieve),
      creator: new ApiData(this.instance ? api.requests.private.movements.patch : api.requests.private.movements.create),

      formData: {
        account_from: null,
        account_to: this.accountId || null,
        comment: '',
        currency_ratio: 1,
        number: '',
        status: STATUS_DRAFT,
        sum: '',
      },

      refill: {
        creator: new ApiData(api.requests.private.applications.create),
      },

      limits: new ApiData(api.users.limits.get),
    }
  },

  computed: {
    accountsFrom() {
      return this.accounts.filter(account => account.is_virtual_store)
    },

    accountsTo() {
      return this.accounts.filter(account => account.is_private && !account.is_account)
    },

    accountToLimitRemain() {
      if (this.limits.data && this.balance.to.data) {
        return this.balance.to.data.amount > 0
            ? this.limits.data.amount - this.balance.to.data.amount
            : this.limits.data.amount
      } else {
        return Infinity
      }
    },

    accountToMessages() {
      const messages = []

      if (this.limits.data) {
        messages.push(`Доступный лимит перевода ${measurement(this.limits.data.amount, this.accountToPostfix)}`)
      }

      return messages
    },

    accountToOvercharge() {
      if (this.limits.data && this.balance.to.data) {
        return Math.min(this.formData.sum, this.formData.sum - this.accountToLimitRemain)
      }
      return 0
    },

    formAlerts() {
      if (this.accountToOvercharge && !this.readonly) {
        const limit = measurement(this.limits.data.amount, this.accountToPostfix).replaceAll(' ', '&nbps;')
        return [`Для выбранного счета доступный лимит пополнения составляет ${limit}`]
      }
      return []
    },

    formDataLimitations() {
      return {
        comment: {
          maxLength: 255,
        },
        sum: {
          maxValue: Infinity
        }
      }
    },
  },

  async mounted() {
    if (this.$auth.impersonating() && this.$route.name === 'accounts.private.details.requests.movements.edit') {
      await this.$router.push({
        name: 'accounts.private.details.requests.movements.detail',
        params: {
          accountId: this.accountId,
          instanceId: this.instance.id,
        }
      })
    }

    this.updateLimit()
  },

  methods: {
    ...mapMutations('requests/private/movements', {
      'setStoreInstance': DETAIL_SET
    }),

    apiFormData: getMovementFormData,

    apiRefillCreate() {
      const formData = getApplicationFormData({
        account: this.formData.account_to,
        comment: '',
        issued_at: this.$moment().format(DATE_FORMAT_ISO),
        files: [],
        object: null,
        office: null,
        prepaid: true,
        purpose: [
            'Превышение лимита аванса.',
            `Остаток ДС: ${this.accounts.map(account => account.balance).reduce((a, v) => a + v, 0)}.`,
            `Сумма лимита: ${this.limits.data.amount}.`
        ].join('\n'),
        status: STATUS_SENT,
        sum: this.accountToOvercharge,
      })

      return this.refill.creator.call({id: this.instance && this.instance.id, data: formData})
        .then(([data, ]) => {
          this.refill.dialog = false
          this.setApplication(data)
          this.$router.push({
            name: 'requests.applications.detail',
            params: {
              accountId: this.refill.creator.data.account_id,
              instanceId: this.refill.creator.data.id,
            }
          })
        })
        .catch(error => {
          this.$notifications.show(httpStatusGetError(error), 'error')
          throw error
        })
    },

    onSaved(data) {
      this.fetchRequestsSummary()

      if ([STATUS_ACCEPTED, STATUS_SENT].includes(data.status)) {
        this.$router.push({
          name: 'accounts.private.details.requests.movements',
          params: {
            accountId: this.creator.data.account_id
          }
        })
      } else {
        if (this.$route.name === 'accounts.private.details.requests.movements.create') {
          this.$router.push({
            name: 'accounts.private.details.requests.movements.edit',
            params: {
              accountId: this.creator.data.account_id,
              instanceId: this.creator.data.id,
            }
          })
        }
      }
    },

    refillOpen() {
      this.$modal.open({
        bind: {
          callback: this.apiRefillCreate,
          currency: this.accountToCurrency,
          overcharge: this.accountToOvercharge,
          value: true,
        },
        component: MovementsFormRefillDialog,
        on: {
          close: () => this.$modal.close(),
        },
      })
    },

    async submit() {
      this.creator.loading = true
      return Promise.all([
        this.beforeSubmit(),
      ]).then(async () => {
        this.$v.$touch()
        if (this.$v.$error) {
          this.creator.loading = false
        } else if (this.formData.status === STATUS_SENT && this.accountToOvercharge) {
          this.creator.loading = false
          this.refillOpen()
        } else {
          this.apiSave()
        }
      }).catch(error => {
        this.creator.loading = false
        this.$notifications.show(httpStatusGetError(error), 'error')
        throw error
      })
    },

    updateLimit() {
      this.limits.call()
        .catch(error => {
          this.$notifications.show('Не удалось проверить лимиты пользователя', 'error')
          throw error
        })
    },

  }
}
</script>