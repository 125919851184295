<template>
  <content-infinity-scroll
    :data="apiData"
    :data-filters="filters"
    ref="contentLoader"
  >
    <applications-list-empty
        v-if="!apiData.loading && !apiData.data.length"
    ></applications-list-empty>

    <template v-else>
      <template v-for="group in groups">
        <p
          v-if="!noCaptions"
          :key="`title__${group.name.replace(' ', '_')}`"
          class="px-4 pb-2 mb-1 mt-4 font-weight-regular grey--text text--darken-2 caption"
        >
          {{ group.name }}
          <span class="grey--text">[{{ group.items.length }}]</span>
        </p>

        <v-divider :key="`divider_${group.name.replace(' ', '_')}`"></v-divider>

        <v-list
          :key="group.name.replace(' ', '_')"
          class="pa-0"
        >
          <component
            v-for="item in group.items"
            :key="item.uid"
            :is="listItem.component"
            :item="item"
          ></component>
        </v-list>
      </template>
    </template>
  </content-infinity-scroll>
</template>

<script>
import ContentInfinityScroll from '@/components/common/content/ContentInfinityScroll'
import ApplicationsListEmpty from '@/components/requests/private/requests/applications/list/ApplicationsListEmpty'
import ApplicationListItem from '@/components/requests/private/requests/applications/list/ApplicationListItem'
import {VListItem} from 'vuetify/lib'


export default {
  components: {
    ApplicationListItem,
    ApplicationsListEmpty,
    ContentInfinityScroll
  },

  props: {
    filters: Object,
    noCaptions: Boolean,
  },

  data() {
    return {
      apiData: undefined,  // ApiPaginatedAccumulatedData,
      listItem: {
        component: VListItem,
        bind: {},
        on: {},
      },
    }
  },

  computed: {
    groups() {
      console.warn('Please, redefine `groups` computed')
      return []
    },
  },

  watch: {
    'filters': {
      deep: true,
      handler() {
        this.$nextTick(() => this.reload())
      }
    }
  },

  methods: {
    getGroupsByDate(dateKey='created_at') {
      let groups = []
      this.apiData.data.forEach(item => {
        const date = this.$moment(item[dateKey]).format('DD MMMM')
        let group = groups.find(group => group.name === date)
        if (!group) {
          group = {name: date, items: []}
          groups.push(group)
        }
        group.items.push(item)
      })
      return groups
    },

    reload() {
      this.$refs.contentLoader.load()
    },
  },
}
</script>