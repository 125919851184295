export class MenuBadge {
    constructor({
                    color = 'green lighten-2',
                    text = '',
                    value = true
    }) {
        this.color = color
        this.text = text
        this.value = value
    }
}


export class MenuItem {
    constructor({
                    badge = null,
                    badgeColor = 'green lighten-2',
                    callback = null,
                    children = [],
                    component = null,
                    disabled = false,
                    key = null,
                    icon= null,
                    visible = true,
                    text = () => {},
                    to = null,
    }) {
        this.$_badge = badge
        this.badgeColor = badgeColor
        this.callback = callback
        this.component = component
        this.$_children = children
        this.disabled = disabled
        this.icon = icon
        this.key = key
        this.to = to
        this.$_visible = visible
        this.$_text = text
    }

    get badge() {
        if (typeof this.$_badge === 'function') {
            return this.$_badge()
        } else {
            return this.$_badge || null
        }
    }

    get children() {
      return Array.isArray(this.$_children) ? this.$_children.filter(child => child.visible) : []
    }

    get childrenItemsExpandable() {
      return this.children.filter(child => child.hasChildren)
    }

    get childrenItemsFlat() {
      return this.children.filter(child => !child.hasChildren)
    }

    get hasChildren() {
        return this.children.length > 0
    }

    get text() {
        if (typeof this.$_text === 'function') {
            return this.$_text()
        } else {
            return this.$_text
        }
    }

    get visible() {
      return this.$_visible && (this.to || this.callback || this.children.find(child => child.visible))
    }
}
