import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'
import {SUMMARY_ERROR_SET, SUMMARY_LOADING_SET, SUMMARY_SET} from '@/store/requests/mutations_types'


const summaryFetcher = new ApiData(api.requests.summary, null, )


export default {
    fetchSummary({ commit }) {
        commit(SUMMARY_LOADING_SET, true)
        commit(SUMMARY_ERROR_SET, null)

        return summaryFetcher.call()
            .then(() => commit(SUMMARY_SET, summaryFetcher.data))
            .catch(error => {
                commit(SUMMARY_ERROR_SET, summaryFetcher.error)
                throw error
            })
            .finally(() => commit(SUMMARY_LOADING_SET, summaryFetcher.loading))
    },
}