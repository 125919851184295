<template>
  <v-autocomplete
    :items="items"
    :loading="(!sourceList.error && sourceList.loading) || (!sourceRetrieve.error && sourceRetrieve.loading)"
    v-bind="{...$attrs, ...$props}"
    :search-input.sync="search"
    ref="autocomplete"
    no-filter
    v-on="$listeners"
  >
    <template v-slot:no-data>
      <div v-if="sourceList.loading"></div>

      <slot v-else name="no-data" :search="search">
        <p
          v-show="!sourceList.loading && !sourceList.error"
          class="my-2 text-center grey--text"
        >Ничего не найдено</p>
      </slot>
    </template>

    <template v-slot:item="attrs">
      <slot name="item" v-bind="attrs"></slot>
    </template>

    <template v-slot:prepend-item>
      <error-card
        v-if="sourceList.error"
        :error="sourceList.error"
        class="mx-auto"
        max-width="240px"
      ></error-card>

      <slot v-else name="prepend-item"></slot>
    </template>

    <template v-slot:item="{ item }">
      <slot name="item" :item="item">{{ getItemText(item) }}</slot>
    </template>

    <template v-slot:append-item>
      <v-lazy
        v-if="sourceList.pageNext && !sourceList.error && !sourceList.loading"
        :value="sourceList.loading"
        v-on:input="$event && isFocused && extend()"
      ></v-lazy>

      <v-row class="ma-0">
        <v-progress-circular
          v-if="!sourceList.error && (sourceList.pageNext || sourceList.loading)"
          class="mx-auto my-6"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'
import MixinList from '@/mixins/MixinList'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'

export default {
  name: 'VAutocompletePaginated',
  components: {ErrorCard},
  mixins: [MixinList],

  props: {
    apiMethodList: Function,
    apiParamsList: Object,
    apiParamsRetrieve: Object,
    value: null,
  },

  data() {
    return {
      isFocused: false,
      isMenuActive: false,
      search: '',
      sourceList: new ApiPaginatedAccumulatedData(this.apiMethodList, [], {debounce: 500}),
      sourceRetrieve: new ApiPaginatedAccumulatedData(this.apiMethodList, [], {debounce: 500}),
    }
  },

  computed: {
    items() {
      return this.isFocused
        ? this.sourceList.error
          ? []
          : this.sourceList.data
        : this.sourceRetrieve.data
          ? this.sourceRetrieve.data
          : []
    }
  },

  watch: {
    apiParamsList() {
      this.loadList()
    },

    apiParamsRetrieve() {
      this.loadSelected()
    },

    isFocused(value) {
      if (value) {
        this.loadList()
      }
    },

    'search'(value, valuePrev) {
      if (this.isFocused && (value || valuePrev) && value !== valuePrev) {
        this.loadList()
      }

      if (!value && this.value && this.$refs.autocomplete.clearable) {
        this.$emit('input', null)
      }
    },

    'sourceList'() {
      if (this.isFocused) {
        this.loadList()
      }
    },

    'sourceRetrieve'() {
      this.loadSelected()
    },

    'value'() {
      this.loadSelected()
    }
  },

  mounted() {
    this.$watch('$refs.autocomplete.isFocused', (value) => {
      this.isFocused = value
    })

    this.$watch('$refs.autocomplete.isMenuActive', (value) => {
      this.isMenuActive = value
    })

    this.loadList()
    this.loadSelected()
  },

  methods: {
    extend() {
      this.sourceList.extend()
    },

    focus() {
      this.$refs.autocomplete.focus()
      this.$refs.autocomplete.activateMenu()
    },

    loadList() {
      return this.sourceList.setParams({
        ...this.apiParamsList,
        search: this.search,
      }, true)
    },

    loadSelected() {
      const pk = this.getItemKey(this.value)
      if (!pk) {
        this.sourceRetrieve.clear()
        return
      }

      const dataPrev = this.sourceRetrieve.data
      const promise = this.sourceRetrieve.setParams({
        ...this.apiParamsRetrieve,
        [this.itemKey]: [pk],
      }, true)
      this.sourceRetrieve.data = dataPrev

      promise.then(() => {
        if (!this.sourceRetrieve.data.find(item => this.getItemKey(item) === this.getItemKey(this.value))) {
          this.$emit('input', null)
        }
      })

      return promise
    },
  },
}
</script>
