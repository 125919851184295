import {LIST_ERROR_SET, LIST_LOADING_SET, LIST_SET} from '@/store/_prototypes/loadable/list/mutations_types'
import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'


const accounts = new ApiData(api.money.accounts.list)


export default {
    fetch({commit}, setLoading= true) {
        commit(LIST_LOADING_SET, setLoading)
        commit(LIST_ERROR_SET, null)

        return accounts.call()
            .then(() => commit(LIST_SET, accounts.data))
            .catch(() => commit(LIST_ERROR_SET, accounts.error))
            .finally(() => commit(LIST_LOADING_SET, accounts.loading))
    },
}