<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    class="dialog-requests-private-report-table-settings"
    max-width="50%"
    v-on:input="$emit('input', $event)"
  >
    <v-card class="d-flex flex-column">
      <v-card-title>Настройка таблицы</v-card-title>

      <v-divider></v-divider>

      <v-card-subtitle class="mt-4 pb-0">Колонки</v-card-subtitle>

      <v-card-text class="d-flex flex-row flex-wrap">
        <v-checkbox
          v-for="column in columnsOptional"
          :key="column.value"
          v-model="columnsVisible"
          :label="column.text"
          :value="column.value"
          class="dialog-requests-private-report-table-settings__checkbox"
          hide-details
        ></v-checkbox>
      </v-card-text>

      <v-divider></v-divider>
      <v-spacer></v-spacer>

      <v-card-actions class="d-flex justify-space-between py-6">
        <v-btn
          color="red"
          width="150px"
          outlined
          v-on:click="close"
        >
          <v-icon left>mdi-close</v-icon>
          Отмена
        </v-btn>

        <v-btn
          color="green"
          width="150px"
          dark
          v-on:click="apply"
        >
          <v-icon left>mdi-check</v-icon>
          Применить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    columns: Array,
    value: Boolean
  },

  data() {
    return {
      columnsVisible: [],
    }
  },

  computed: {
    columnsOptional() {
      return this.columns.filter(column => !column.persist)
    }
  },

  watch: {
    value() {
      if (this.value) {
        this.columnsVisible = this.columns.filter(column => column.show || column.persist).map(column => column.value)
      }
    }
  },

  methods: {
    apply() {
      this.columns.forEach(column => {
        column.show = Boolean(this.columnsVisible.find(columnName => columnName === column.value))
      })
      this.close()
    },

    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss">
.dialog-requests-private-report-table-settings {
  &__checkbox {
    max-width: 200px;
    min-width: 200px;
    width: 200px;
  }
}
</style>
