<script>
import ContentTabbed from '@/components/common/content/ContentTabbed'
import {STATUS_DRAFT} from '@/assets/js/api/requests/private/movements'
import {mapState} from 'vuex'
import MovementsPrivateListInfinityScroll
  from '@/components/requests/private/requests/movements/list/MovementsPrivateListInfinityScroll'
import MovementsPrivateListItem from '@/components/requests/private/requests/movements/list/MovementsPrivateListItem'

export default {
  extends: ContentTabbed,

  props: {
    accountId: Number,
  },

  computed: {
    ...mapState('requests', [
      'summary',
    ]),

    canCreate() {
      return !this.$auth.impersonating()
    },

    formRoute() {
      return {name: 'accounts.private.details.requests.movements.create'}
    },

    tabs() {
      return [
        {
          key: 'history',
          bind: {
            filters: {account: this.accountId, status__exclude: [STATUS_DRAFT]},
            hideCaptions: false,
            listItemComponent: MovementsPrivateListItem,
          },
          component: MovementsPrivateListInfinityScroll,
          title: 'История',
        },
        {
          key: 'drafts',
          bind: {
            filters: {account: this.accountId, status: [STATUS_DRAFT]},
            hideCaptions: true,
            listItemComponent: MovementsPrivateListItem,
          },
          component: MovementsPrivateListInfinityScroll,
          title: 'Черновики',
        },
      ]
    },
  },
}
</script>