<template>
  <v-container class="pa-0 d-flex align-start align-md-center justify-center fill-height" fluid>
    <content-form
      class="px-4 py-8 py-md-4 elevation-md-4"
      no-controls-divider
    >
      <template slot="body">
        <v-text-field
          :value="instance.name"
          label="Автомобиль"
          hide-details
          outlined
          readonly
        ></v-text-field>

        <v-list>
          <v-list-item
            v-for="rent in instance.rents"
            :key="rent.id"
            :class="{'elevation-2': $vuetify.breakpoint.mobile}"
            :disabled="rent.is_finished"
            class="px-0 mb-2 "
            v-on:click="finish(rent)"
          >
            <v-list-item-avatar>
              <v-icon>mdi-steering</v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="py-0">
              <v-list-item-title>
                {{ rent.user.full_name }}
              </v-list-item-title>

              <v-list-item-subtitle>
                с {{ rent.datetime_begin|datetime }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="my-0">
              <p
                v-if="rent.is_finished"
                class="deep-orange--text"
              >
                Завершено
              </p>

              <v-btn
                v-else
                :depressed="$vuetify.breakpoint.mobile"
                :fab="$vuetify.breakpoint.mobile"
                :tile="$vuetify.breakpoint.mobile"
                color="error"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  mdi-flag-checkered
                </v-icon>

                <template v-if="!$vuetify.breakpoint.mobile">
                  Завершить
                </template>
              </v-btn>
              </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm.vue'
import CarsRentsFinishModalForm from '@/components/cars/rents/CarsRentsFinishModalForm.vue'


export default {
  components: {ContentForm},
  props: {
    instance: Object
  },

  methods: {
    finish(rent) {
      this.$modal.open({
        bind: {
          instance: rent,
          title: `Завершить эксплуатацию ${this.instance.name} для ${rent.user.full_name}?`,
        },
        component: CarsRentsFinishModalForm,
        on: {
          close: () => this.$modal.close(),
          input: () => {
            this.$modal.close()
            rent.is_finished = true
            this.$notifications.show(`Эксплуатация автомобиля "${this.instance.name}" для ${rent.user.full_name} успешно завершена`)
          },
        },
      })
    },
  }
}
</script>

