import TheRequestsPrivatePurchasesTabbedView from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesTabbedView'
import TheRequestsPrivatePurchasesFormView from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesFormView'
import TheRequestsPrivatePurchasesDetailWrapper from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesDetailWrapper'


function detailsBackref (route) {
    return {
        name: 'accounts.private.details.requests.purchases',
        params: {
            accountId: route.params.accountId,
        }
    }
}


export default [
    {
        path: 'requests/purchases',
        name: 'accounts.private.details.requests.purchases',
        component: TheRequestsPrivatePurchasesTabbedView,
        meta: {
            backref: {
                name: 'accounts.private'
            },
            title: 'Операции по счету'
        },
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
    },
    {
        path: 'requests/purchases/create',
        name: 'accounts.private.details.requests.purchases.create',
        component: TheRequestsPrivatePurchasesFormView,
        props: route => ({
            accountId: Number(route.params.accountId),
        }),
        meta: {
            backref: detailsBackref,
            title: 'Создание покупки'
        },
    },
    {
        path: 'requests/purchases/:instanceId',
        component: TheRequestsPrivatePurchasesDetailWrapper,
        props: true,

        children: [
            {
                path: '',
                name: 'accounts.private.details.requests.purchases.detail',
                component: TheRequestsPrivatePurchasesFormView,
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Просмотр покупки'
                },
            },
            {
                path: 'edit',
                component: TheRequestsPrivatePurchasesFormView,
                name: 'accounts.private.details.requests.purchases.edit',
                props: route => ({
                    accountId: Number(route.params.accountId),
                    instanceId: Number(route.params.instanceId),
                    readonly: false
                }),
                meta: {
                    backref: detailsBackref,
                    title: 'Редактирование покупки'
                },
            },
        ]
    },
]