import {request} from '@/assets/js/api/_helpers'

export const ENDPOINT_MATCHING_MONEY_GIVE_OUTS = '/api/requests/matching/money/give_outs'

export const STATUS_ACTIVE = 'active'
export const STATUS_CANCELED = 'canceled'
export const STATUS_COMPLETED = 'accepted'


export default {
    list: (config) => request( 'GET', ENDPOINT_MATCHING_MONEY_GIVE_OUTS, config),
    comments: {
      create: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_MONEY_GIVE_OUTS}/${id}/comments`, config),
    },
    history: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_MONEY_GIVE_OUTS}/${id}/history`, config),
    result: ({id, task, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_MONEY_GIVE_OUTS}/${id}/${task}/result`, config),
    viewed: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_MONEY_GIVE_OUTS}/${id}/viewed`, config),
}
