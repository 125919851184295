import { request } from '@/assets/js/api/_helpers'


export const STATUS_ACCEPTED = 'accepted'
export const STATUS_CANCELED = 'canceled'
export const STATUS_DECLINED = 'declined'
export const STATUS_DRAFT = 'draft'
export const STATUS_SENT = 'sent'


const ENDPOINT_RECEIPTS_CASH = '/api/requests/stores/receipts/cash'


export function isDeletable(purchase) {
    return purchase.status === STATUS_DRAFT
}


export function getReceiptCashFormData(data) {
    const formData = new FormData()

    formData.append('account', data.account)
    formData.append('comment', data.comment)
    formData.append('created_at', data.createdAt)
    formData.append('object', data.object)
    formData.append('status', data.status)
    formData.append('sum', data.sum)

    return formData
}


export default {
    create: (config) => request('POST', ENDPOINT_RECEIPTS_CASH, config),
    delete: ({ id, ...config }) => request('DELETE', `${ENDPOINT_RECEIPTS_CASH}/${id}`, config),
    list: (config) => request( 'GET', ENDPOINT_RECEIPTS_CASH, config),
    retrieve: ({ id, ...config }) => request( 'GET', `${ENDPOINT_RECEIPTS_CASH}/${id}`, config),
    patch: ({ id, ...config }) => request('PATCH', `${ENDPOINT_RECEIPTS_CASH}/${id}`, config),
}