import loadableListMutations from '@/store/_prototypes/loadable/list/mutations'
import loadableListState from '@/store/_prototypes/loadable/list/state'

import actions from './actions'
import getters from './getters'


export default {
    namespaced: true,

    actions,
    getters,

    mutations: {
        ...loadableListMutations()
    },

    state: {
        ...loadableListState()
    },
}