<script>
import ContentInfinityScrollView from '@/components/common/content/ContentInfinityScrollView'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import MoneyGiveOutsApprovedListItem from '@/components/requests/matching/money/give_outs_approved/list/MoneyGiveOutsApprovedListItem'

export default {
  extends: ContentInfinityScrollView,

  data() {
    return {
      apiData: new ApiPaginatedAccumulatedData(api.requests.matching.money.give_outs_approved.list),
      listItem: {
        component: MoneyGiveOutsApprovedListItem,
      }
    }
  },

  computed: {
    groups() {
      return this.getGroupsByDate('issued_at')
    }
  }
}
</script>