<script>
import {isDeletable} from '@/assets/js/api/requests/private/purchases'
import ContentContextMenu from '@/components/common/content/ContentContextMenu'

export default {
  extends: ContentContextMenu,

  data() {
    return {
      event: 'purchases.menu'
    }
  },

  computed: {
    actions() {
      return [
        {
          method: this.purchaseCopy,
          event: 'copy',
          icon: 'mdi-content-copy',
          show: !this.$auth.impersonating(),
          text: 'Копировать',
        },
        {
          event: 'delete',
          icon: 'mdi-delete',
          show: !this.$auth.impersonating() && this.item && isDeletable(this.item),
          text: 'Удалить',
        },
      ]
    }
  },

  methods: {
    purchaseCopy() {
      this.$router.push({
        name: 'accounts.private.details.requests.purchases.create',
        params: {
          accountId: this.item.account.id
        },
        query: {
          copy: this.item.id
        }
      })
    },
  }
}
</script>
