import applications from './applications'
import movements from './movements'
import purchases from './purchases'
import report from './report'


export default {
    applications,
    movements,
    purchases,
    report,
}