import MoneyAccountsBaseRouterWrapperView from '@/components/money/accounts/base/MoneyAccountsBaseRouterWrapperView'
import MoneyAccountsPrivateList from '@/components/money/accounts/private/MoneyAccountsPrivateList'

import privateControls from './controls'
import privateRequests from './requests'
import {PERMISSION_ACCOUNTS_PRIVATE_VIEW} from '@/assets/js/api/users'
import TheRequestsPrivatePurchasesFormView from '@/views/requests/private/requests/purchases/TheRequestsPrivatePurchasesFormView'
import TheMoneyAccountsView from '@/views/money/TheMoneyAccountsView'
import TheJournalsReport from '@/views/journals/reports/TheJournalsReport'


export default [
    {
        path: '/accounts/private',
        component: TheMoneyAccountsView,
        name: 'accounts.private',
        props: {
          accountsListComponent: MoneyAccountsPrivateList,
        },
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'requests.matching.archives_and_money_give_outs'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            title: 'Личные счета'
        },
    },
    {
        path: '/accounts/private',
        component: MoneyAccountsBaseRouterWrapperView,
        props: {
          accountsListComponent: MoneyAccountsPrivateList,
        },
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'requests.matching.archives_and_money_give_outs'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
        },
        children: [
            {
                path: 'purchases/create',
                name: 'accounts.private.purchases.create',
                component: TheRequestsPrivatePurchasesFormView,
                meta: {
                    backref: 'requests.applications',
                    title: 'Создание покупки'
                },
            },
        ]
    },
    {
        path: '/accounts/private/:accountId',
        component: MoneyAccountsBaseRouterWrapperView,
        props: route => ({
            accountsListComponent: MoneyAccountsPrivateList,
            accountId: Number(route.params.accountId),
        }),
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'requests.matching.archives_and_money_give_outs'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            title: 'Личные счета'
        },
        children: [
            ...privateRequests,
            ...privateControls,
            {
                path: 'report',
                name: 'accounts.private.details.report',
                component: TheJournalsReport,
                meta: {
                    backref: {
                        name: 'accounts.private',
                    },
                    title: 'Отчет'
                }
            }
        ]
    },
]
