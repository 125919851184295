import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import PortalVue from 'portal-vue'
Vue.use(PortalVue)


import VueMoment from 'vue-moment';
import 'moment/locale/ru'  // without this line it didn't work
import moment from 'moment-timezone'
moment.locale('ru')
Vue.use(VueMoment, {
  moment
})


import auth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x';
import driverRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x';
Vue.router = router;  // VueAuth required
Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        router: driverRouter,
    },
    options: {
        authRedirect: { path: '/' },
        fetchData: { url: '/api/users/me', method: 'GET', enabled: true },
        forbiddenRedirect: {name: 'accounts.private'},
        loginData: {
          url: '/api/auth/login', method: 'POST', redirect: {name: 'accounts.private'}, fetchUser: true,
        },
        logoutData: {
          url: '/api/auth/logout', method: 'POST', redirect: {name: 'auth.login'}, makeRequest: true,
        },
        notFoundRedirect: {name: 'accounts.private'},
        refreshData: {
          url: '/api/users/me', method: 'GET', enabled: true, interval: 15,
        },
        rolesKey: 'permissions_active',
        stores: ['cookie'],
        tokenDefaultKey: 'Token',
        cookie: {
            Path: '/',
            Domain: null,
            Secure: process.env.VUE_APP_DEV !== '1',
            Expires: 12096e5,
            SameSite: 'Lax'
        },
        parseUserData: (data) =>  data,
    }
});


import '@/plugins/axios'
import '@/plugins/filters'
import '@/plugins/vuelidate'
import './registerServiceWorker'

import Modals from '@/plugins/modals'
Vue.use(Modals)

import Notifications from '@/plugins/notifications'
Vue.use(Notifications)

import pushes from '@/plugins/pushes'
Vue.use(pushes)

Vue.config.productionTip = false

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (window.ENV && window.ENV.SENTRY_DSN_PUBLIC) {
    Sentry.init({
      Vue,
      dsn: window.ENV.SENTRY_DSN_PUBLIC,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["*"],
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
}



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
