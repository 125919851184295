<template>
  <modal-dialog
    :title="title"
    icon="mdi-information-outline"
    v-on:close="$emit('close')"
  >
    <p v-html="description"></p>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        color="red"
        width="150px"
        dark
        v-on:click="$emit('close')"
      >
        <v-icon left>mdi-close</v-icon>
        Закрыть
      </v-btn>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/common/modals/ModalDialog'
export default {
  components: {ModalDialog},

  props: {
    description: String,
    title: String,
  }

}
</script>