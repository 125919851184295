<template>
  <v-list-item
    :class="[itemColor]"
    :disabled="deleter.loading"
    v-ripple="false"
    :to="purchaseDetailView"
    class="purchases-list-item"
    v-on:contextmenu.native.prevent="$root.$emit('purchases.menu', [$event, purchase])"
  >
    <purchases-list-item-menu
      :item="purchase"
      ref="contextMenu"
      v-on:delete="purchaseDelete"
    ></purchases-list-item-menu>

    <v-list-item-avatar>
      <v-progress-circular
        v-if="deleter.loading"
        color="primary"
        indeterminate
      ></v-progress-circular>

      <v-icon
        v-else
        :color="avatarColor"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="purchases-list-item__content px-3 text-no-wrap flex-column align-stretch">
      <v-row class="ma-0">
        <v-col class="pa-0 text-truncate overflow-hidden">
          {{ purchase.object ? purchase.object.name : 'Неизвестный объект' }}&nbsp;
          <span class="grey--text">x{{ purchase.amount }}</span>
        </v-col>

        <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
           - {{ purchase.sum|measurement(currencyCode) }}
         </v-col>
      </v-row>

      <v-row class="ma-0 flex-nowrap grey--text text--darken-2 overflow-hidden">
        <v-col class="pa-0 caption text-truncate">{{ purchase.type_string || purchase.type && purchase.type.name }}</v-col>
        <v-col class="pa-0 caption text-right">{{ purchase.created_at|date }}</v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import api from '@/assets/js/api'
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import {mapGetters} from 'vuex'
import PurchasesListItemMenu from '@/components/requests/private/requests/purchases/list/PurchasesListItemMenu'
import {ApiData} from '@/assets/js/api/_helpers'

export default {
  components: {
    PurchasesListItemMenu,
  },
  props: {
    purchase: Object,
  },

  data() {
    return {
      deleter: new ApiData(api.requests.private.purchases.delete),
      menu: false,
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
      'getAccountBelongIcon',
      'getAccountTypeIcon',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),

    account() {
      return this.getAccountById(this.purchase.account.id)
    },

    avatarIcon() {
      switch (this.purchase.status) {
        case STATUS_DECLINED:
          return 'mdi-alert-box'

        case STATUS_SENT:
          return 'mdi-timer-sand'

        case STATUS_DRAFT:
          return 'mdi-pencil'

        case STATUS_CANCELED:
          return 'mdi-close-circle-outline'

        default:
          return 'mdi-cart-arrow-down'
      }
    },

    avatarColor() {
      return this.purchase.status === STATUS_DECLINED ? 'red' : 'grey'
    },

    currency() {
      return this.getCurrencyByNumericCode(this.purchase.currency_id)
    },

    currencyCode() {
      return this.currency && (this.currency.symbol || this.currency.alphabetic_code)
    },

    itemColor() {
      switch (this.purchase.status) {
        case STATUS_ACCEPTED:
        case STATUS_CANCELED:
          return 'white'
        case STATUS_DECLINED:
          return 'red lighten-5'
        default:
          return 'white'
      }
    },

    purchaseDetailView() {
      if ([STATUS_DRAFT, STATUS_DECLINED].includes(this.purchase.status)) {
        return {
          name: 'accounts.private.details.requests.purchases.edit',
          params: {
            accountId: this.purchase.account.id, instanceId: this.purchase.id
          }
        }
      } else {
        return {
          name: 'accounts.private.details.requests.purchases.detail',
          params: {
            accountId: this.purchase.account.id, instanceId: this.purchase.id
          }
        }
      }

    }
  },

  methods: {
    purchaseDelete() {
      this.deleter.call({id: this.purchase.id}).then(() => this.$emit('deleted'))
    },
  }
}
</script>

<style lang="scss">
.purchases-list-item {
  overflow: hidden;

  &__content {
    & > * {
      max-width: 100%;
    }
  }
}
</style>