<script>
import ContentTabbed from '@/components/common/content/ContentTabbed'
import {STATUS_ACCEPTED, STATUS_CANCELED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/purchases'
import PurchasesListInfinityScroll from '@/components/requests/private/requests/purchases/list/PurchasesListInfinityScroll'

export default {
  extends: ContentTabbed,

  props: {
    accountId: Number,
  },

  computed: {
    canCreate() {
      return !this.$auth.impersonating()
    },

    formRoute() {
      if (this.$route.params.accountId) {
        return {name: 'accounts.private.details.requests.purchases.create'}
      } else {
        return {name: 'accounts.private.purchases.create'}
      }
    },

    tabs() {
      return [
        {
          key: 'changes',
          bind: {
            filters: {account: this.accountId, status: [STATUS_SENT, STATUS_DECLINED, STATUS_CANCELED]},
            hideCaptions: false,
          },
          component: PurchasesListInfinityScroll,
          title: 'Заявки',
        },
        {
          key: 'history',
          bind: {
            filters: {account: this.accountId, status: [STATUS_ACCEPTED]},
            hideCaptions: false,
          },
          component: PurchasesListInfinityScroll,
          title: 'История',
        },
        {
          key: 'drafts',
          bind: {
            filters: {account: this.accountId, status: [STATUS_DRAFT]},
            hideCaptions: true,
          },
          component: PurchasesListInfinityScroll,
          title: 'Черновики',
        },
      ]
    },
  },
}
</script>