<script>
import {MenuItem} from "@/assets/js/menu";
import User from '@/mixins/User.vue'
import { mapGetters, mapState } from 'vuex'
import {
  PERMISSION_ACCOUNTS_OPERATIONS_VIEW,
  PERMISSION_ACCOUNTS_PRIVATE_VIEW,
  PERMISSION_ACCOUNTS_STORES_VIEW, PERMISSION_CARS_RENTS_VIEW, PERMISSION_CARS_RENTS_VIEW_ALL, PERMISSION_CARS_VIEW,
  PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW,
  PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW,
  PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW,
  PERMISSION_REQUESTS_MONEY_GIVE_OUT_APPROVED_VIEW
} from '@/assets/js/api/users'

export default {
  mixins: [User],

  computed: {
    ...mapGetters('requests/matching/archives/requests', {
      matchingArchivesIsNew: 'isNew',
      matchingArchivesHasNewComments: 'hasNewComments',
    }),

    ...mapGetters('requests/matching/money/give_outs', {
      matchingMoneyGiveOusIsNew: 'isNew',
      matchingMoneyGiveOusHasNewComments: 'hasNewComments',
    }),

    ...mapState('money/accounts', {
      accounts: 'list',
    }),

    ...mapState('requests/matching/archives/issues', {
      matchingArchivesIssuesList: 'list',
    }),

    ...mapState('requests/matching/archives/requests', {
      matchingArchivesRequestsList: 'list',
    }),

    ...mapState('requests/matching/money/give_outs', {
      matchingMoneyGiveOutsList: 'list',
    }),

    ...mapState('requests', [
      'summary'
    ]),

    menu() {
      return new MenuItem({
        key: 'menu',
        children: [
          this.menuAccounts,
          this.menuRequests,
          this.menuCars,
          this.menuJournals,
          this.menuAccount,
        ]
      })
    },

    menuAccount() {
      return new MenuItem({
        icon: 'mdi-account',
        key: 'account',
        text: () => {
          const user = this.$auth.user()
          return user ? user.full_name : 'Аккаунт'
        },
        children: [
          new MenuItem({
            icon: 'mdi-account-cog',
            key: 'account.settings',
            text: 'Настройки',
            to: {name: 'users.me.settings'},
          }),
          new MenuItem({
            callback: () => this.$modal.confirm(
              'Вы действительно хотите выйти?',
              this.$auth.impersonating()
                ? this.$auth.unimpersonate
                : this.logout
            ),
            icon: 'mdi-exit-to-app',
            key: 'account.logout',
            text: 'Выход',
          }),
        ]
      })
    },

    menuAccounts() {
      return new MenuItem({
        badge: this.requestsPrivateDeclinedCount + this.requestsStoresDeclinedCount,
        badgeColor: 'red',
        icon: 'mdi-cash-multiple',
        key: 'accounts',
        text: 'Счета',
        children: [
          new MenuItem({
            badge: 0,
            badgeColor: 'red',
            icon: 'mdi-safe-square',
            key: 'accounts.stores',
            text: 'Хранилище',
            to: {name: 'accounts.stores'},
            visible: this.userHasPermissions(PERMISSION_ACCOUNTS_STORES_VIEW),
          }),
          new MenuItem({
            badge: this.requestsPrivateDeclinedCount,
            badgeColor: 'red',
            icon: 'mdi-account-cash',
            key: 'accounts.private',
            text: 'Личные счета',
            to: {name: 'accounts.private'},
            visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
          }),
          new MenuItem({
            badge: 0,
            badgeColor: 'red',
            icon: 'mdi-eye',
            key: 'accounts.controls',
            text: 'Контроль',
            to: {name: 'accounts.controls'},
            visible: this.userHasPermissions(PERMISSION_ACCOUNTS_OPERATIONS_VIEW),
          }),
        ],
      })
    },

    menuCars() {
      return new MenuItem({
        icon: 'mdi-car',
        key: 'cars',
        text: this.$vuetify.breakpoint.mobile ? 'Авто' : 'Автомобили',
        children: [
          new MenuItem({
            icon: 'mdi-car-multiple',
            key: 'cars.list',
            text: 'Автопарк',
            to: {name: 'cars.list'},
            visible: this.userHasPermissions(PERMISSION_CARS_VIEW),
          }),
          new MenuItem({
            icon: 'mdi-history',
            key: 'cars.rents',
            text: 'История',
            to: {name: 'cars.rents.list'},
            visible: this.userHasPermissions(PERMISSION_CARS_RENTS_VIEW),
          }),
          new MenuItem({
            icon: 'mdi-car-info',
            key: 'cars.rents.conflicts',
            text: 'Конфликты',
            to: {name: 'cars.rents.conflicts.list'},
            visible: this.userHasPermissions(PERMISSION_CARS_RENTS_VIEW_ALL),
          }),
        ]
      })
    },

    menuJournals() {
      return new MenuItem({
        icon: 'mdi-book-multiple',
        key: 'journals',
        text: 'Журналы',
        to: {name: 'journals'},
        visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
        children: [
          new MenuItem({
            icon: 'mdi-cash',
            key: 'journals.applications',
            text: 'Заявки на ДС',
            to: {name: 'journals.applications'},
          }),
          new MenuItem({
            icon: 'mdi-cart',
            key: 'journals.purchases',
            text: 'Покупки',
            to: {name: 'journals.purchases'},
          }),
          new MenuItem({
            icon: 'mdi-clipboard-text',
            key: 'journals.reports',
            text: 'Отчет',
            to: {name: 'journals.reports'},
          }),
        ],
      })
    },

    menuRequests() {
      return new MenuItem({
        badge: this.requestsMatchingCount,
        badgeColor: 'red',
        icon: 'mdi-clipboard-text',
        key: 'requests',
        text: 'Заявки',
        children: [
          new MenuItem({
            icon: 'mdi-cash',
            key: 'requests.applications',
            text: 'Заказы ДС',
            to: {name: 'requests.applications'},
            visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW),
          }),
          new MenuItem({
            badge: this.summary.matching.money.give_outs_approved,
            badgeColor: 'red',
            icon: 'mdi-cash-refund',
            key: 'requests.matching.money.give_outs.approved',
            text: 'Выдача ДС',
            to: {name: 'requests.matching.money.give_outs.approved'},
            visible: this.userHasPermissions(PERMISSION_REQUESTS_MONEY_GIVE_OUT_APPROVED_VIEW),
          }),
          new MenuItem({
            badge: this.summary.matching.money.compensations,
            badgeColor: 'red',
            icon: 'mdi-cash-refund',
            key: 'requests.matching.money.compensations',
            text: 'Возмещения ДС',
            to: {name: 'requests.matching.money.compensations'},
            visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_VIEW)
          }),
          new MenuItem({
            badge: this.requestsMatchingMoneyGiveOutsNewCount + this.requestsMatchingArchivesRequestsNewCount,
            badgeColor: 'red',
            icon: 'mdi-chat-alert',
            key: 'requests.matching.archives_and_money_give_outs',
            text: 'Запросы',
            to: {name: 'requests.matching.archives_and_money_give_outs'},
            visible: this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW) || this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW)
          }),
          new MenuItem({
            badge: this.requestsMatchingArchivesIssuesNewCount,
            badgeColor: 'red',
            icon: 'mdi-archive-arrow-up',
            key: 'requests.matching.archives.issues',
            text: 'Выдача из архива',
            to: {name: 'requests.matching.archives.issues'},
            visible: this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW)
          }),
        ],
      })
    },

    requestsPrivateDeclinedCount() {
      return this.summary.private.applications.reduce((acc, stat) => acc + stat.declined, 0) +
        this.summary.private.movements.reduce((acc, stat) => acc + stat.declined, 0) +
        this.summary.private.purchases.reduce((acc, stat) => acc + stat.declined, 0)
    },

    requestsMatchingArchivesIssuesNewCount() {
      return this.matchingArchivesIssuesList
        .filter(request => !request.is_waiting && !request.is_processed)
        .length
    },

    requestsMatchingArchivesRequestsNewCount() {
      return this.matchingArchivesRequestsList
        .filter(request => !request.is_processed)
        .length
    },

    requestsMatchingMoneyGiveOutsNewCount() {
      return this.matchingMoneyGiveOutsList
        .filter(request => !request.is_processed)
        .length
    },

    requestsMatchingCount() {
      return Object.values(this.summary.matching.money).reduce((acc, stat) => acc + stat, 0) +
        this.requestsMatchingMoneyGiveOutsNewCount +
        this.requestsMatchingArchivesIssuesNewCount +
        this.requestsMatchingArchivesRequestsNewCount
    },

    requestsStoresDeclinedCount() {
      return this.summary.stores.movements.reduce((acc, stat) => acc + stat.declined, 0)
    },
  },

  methods: {
    getMenuItem(target, menu = undefined) {
      const $menu = menu || this.menu
      return $menu.key === target
        ? $menu
        : $menu.children.map(menuItem => this.getMenuItem(target, menuItem)).find(Boolean)
    },
  }
}
</script>
