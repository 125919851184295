import TheJournalsApplicationsView from '@/views/journals/applications/TheJournalsApplicationsView'
import TheApplicationDetailWrapper from '@/views/requests/private/requests/applications/TheApplicationDetailWrapper'
import TheApplicationFormView from '@/views/requests/private/requests/applications/TheApplicationFormView'
import {PERMISSION_ACCOUNTS_PRIVATE_VIEW} from '@/assets/js/api/users'

export default [
    {
        path: 'applications',
        component: TheJournalsApplicationsView,
        name: 'journals.applications',
        meta: {
            title: 'Заявки на ДС',
        },
        auth: {
            roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
            forbiddenRedirect: {
                name: 'requests.matching.archives_and_money_give_outs'
            },
            redirect: {
                name: 'auth.login'
            }
        }
    },
    {
        path: 'applications/:instanceId',
        component: TheApplicationDetailWrapper,
        props: true,
        auth: {
            roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
            forbiddenRedirect: {
                name: 'requests.matching.archives_and_money_give_outs'
            },
            redirect: {
                name: 'auth.login'
            }
        },

        children: [
            {
                path: '',
                name: 'journals.applications.detail',
                component: TheApplicationFormView,
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: {
                        name: 'journals.applications'
                    },
                    title: 'Просмотр пополнения'
                },
            },
        ]
    }
]
