import TheApplicationsTabbedView from '@/views/requests/private/requests/applications/TheApplicationsTabbedView'
import TheApplicationFormView from '@/views/requests/private/requests/applications/TheApplicationFormView'
import TheApplicationDetailWrapper from '@/views/requests/private/requests/applications/TheApplicationDetailWrapper'
import {PERMISSION_ACCOUNTS_PRIVATE_VIEW} from '@/assets/js/api/users'


export default [
    //
    // Applications
    //
    {
        path: '/requests/applications',
        name: 'requests.applications',
        component: TheApplicationsTabbedView,
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'accounts.private'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            title: 'Заказы ДС'
        },
    },
    {
        path: '/requests/applications/create',
        name: 'requests.applications.create',
        component: TheApplicationFormView,
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'requests.applications'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            backref: {
                name: 'requests.applications'
            },
            title: 'Заказ ДС'
        },
    },
    {
        path: '/requests/applications/:instanceId',
        component: TheApplicationDetailWrapper,
        props: true,
        meta: {
            auth: {
                roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
                forbiddenRedirect: {
                    name: 'requests.applications'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
        },

        children: [
            {
                path: '',
                name: 'requests.applications.detail',
                component: TheApplicationFormView,
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                    readonly: true
                }),
                meta: {
                    backref: {
                        name: 'requests.applications'
                    },
                    title: 'Просмотр пополнения'
                },
            },
            {
                path: 'edit',
                component: TheApplicationFormView,
                name: 'requests.applications.edit',
                props: route => ({
                    instanceId: Number(route.params.instanceId),
                    readonly: false
                }),
                meta: {
                    backref: {
                        name: 'requests.applications'
                    },
                    title: 'Редактирование пополнения'
                },
            },
        ]
    }
]
