<template>
  <modal-dialog :title="title">
    <content-form :value="$v.formData.$errors" no-controls-divider>
      <template slot="body">
        <p class="mb-4">
          <template v-if="instance.user.id === $auth.user().id">
            Пожалуйста, подтвердите завершение эксплуатации и укажите фактическое время, когда Вы сдали ключи.
          </template>

          <template v-else>
            Пожалуйста, подтвердите завершение эксплуатации и укажите фактическое время, когда ключи были сданы.
          </template>
        </p>

        <v-divider></v-divider>

        <v-datetime-field
          v-model="formData.datetime_end"
          :error="$v.formData.datetime_end.$error"
          :error-messages="errors.datetime_end"
          :readonly="apiResult.loading"
          class="mt-4"
          outlined
        ></v-datetime-field>
      </template>
    </content-form>

    <template v-slot:actions>
      <v-col class="flex-fill d-flex justify-space-between flex-wrap flex-sm-nowrap">
        <v-btn
          key="give_out"
          :block="$vuetify.breakpoint.xsOnly"
          :loading="apiResult.loading"
          class="white--text"
          color="green"
          outlined
          v-on:click="submit"
        >
          <v-icon
            color="green"
            left
          >mdi-check</v-icon>
          Принять
        </v-btn>

        <v-btn
          key="cancel"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="apiResult.loading"
          class="mt-4 mt-sm-0 white--text"
          color="red"
          width="144px"
          v-on:click="close"
        >
          <v-icon left>mdi-close</v-icon>
          Отмена
        </v-btn>
      </v-col>
    </template>
  </modal-dialog>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm.vue'
import ModalDialog from '@/components/common/modals/ModalDialog.vue'
import VDatetimeField from '@/components/inputs/VDatetimeField.vue'
import { ApiData } from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import { required } from 'vuelidate/lib/validators'
import ModalForm from '@/components/common/modals/ModalForm.vue'


export default {
  components: {
    VDatetimeField,
    ModalDialog,
    ContentForm
  },

  extends: ModalForm,

  props: {
    instance: Object,
  },

  data() {
    return {
      apiResult: new ApiData(api.cars.rents.update),
      formData: {
        datetime_end: this.$moment().toISOString()
      }
    }
  },

  computed: {
    formDataErrors() {
      return {
        datetime_end: {
          minValue: `Дата окончания эксплуатации не может быть ранее ее начала`,
          maxValue: `Фактическая дата окончания эксплуатации не может быть будущей датой`,
        },
      }
    },
  },

  methods: {
    submitEvent() {
      return this.apiResult.call({
        rentId: this.instance.id,
        data: {
          car_id: this.instance.car.id,
          comment: this.instance.comment,
          datetime_begin: this.instance.datetime_begin,
          datetime_end: this.formData.datetime_end,
          is_finished: true,
        }
      }).then(() => {
        this.$emit('input', this.formData)
      }).catch(() => {
        this.$notifications.show(this.apiResult.error, 'error')
      })
    }
  },

  validations() {
    return {
      formData: {
        datetime_end: {
          maxValue: (value) => !value || this.$moment(value) < this.$moment(),
          minValue: (value) => !value || this.$moment(this.instance.datetime_begin) <= this.$moment(value),
          required,
        }
      }
    }
  }
}
</script>
