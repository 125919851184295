<template>
  <v-alert
    v-if="instance"
    :icon="icon"
    :outlined="outlined"
    :type="color"
    class="mx-3"
    text
  >
    {{ statusText }}
  </v-alert>
</template>

<script>
export default {
  props: {
    instance: Object,
    statusKey: {
      type: String,
      default: 'status',
    },
    acceptedIcon: {
      type: String,
      default: 'mdi-check',
    },
    acceptedOn: {
      type: String,
      default: 'accepted',
    },
    canceledIcon: {
      type: String,
      default: 'mdi-close-circle-outline',
    },
    canceledOn: {
      type: String,
      default: 'canceled',
    },
    declinedIcon: {
      type: String,
      default: 'mdi-alert-box',
    },
    declinedOn: {
      type: String,
      default: 'declined',
    },
    draftIcon: {
      type: String,
      default: 'mdi-pencil',
    },
    draftOn: {
      type: String,
      default: 'draft',
    },
    waitingIcon: {
      type: String,
      default: 'mdi-timer-sand',
    },
    waitingOn: {
      type: String,
      default: 'sent',
    },
  },

  computed: {
    color() {
      if (!this.instance) {
        return undefined
      }

      switch (this.instance[this.statusKey]) {
        case this.acceptedOn:
          return 'success'
        case this.canceledOn:
        case this.declinedOn:
          return 'error'
        case this.draftOn:
          return null
        default:
          return 'warning'
      }
    },

    icon() {
      if (!this.instance) {
        return undefined
      }

      switch (this.instance[this.statusKey]) {
        case this.acceptedOn:
          return this.acceptedIcon
        case this.canceledOn:
          return this.canceledIcon
        case this.declinedOn:
          return this.declinedIcon
        case this.draftOn:
          return this.draftIcon
        default:
          return this.waitingIcon
      }
    },

    outlined() {
      if (!this.instance) {
        return false
      }

      return [this.acceptedOn, this.declinedOn].includes(this.instance[this.statusKey])
    },

    statusText() {
      if (!this.instance) {
        return undefined
      }

      switch (this.instance[this.statusKey]) {
        case this.acceptedOn:
          return 'Утверждена'
        case this.canceledOn:
          return 'Отклонена'
        case this.declinedOn:
          return 'Требуется корректировка'
        case this.draftOn:
          return 'Черновик'
        default:
          return 'Отправлена'
      }
    },
  }
}
</script>