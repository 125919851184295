import MoneyAccountsBaseRouterWrapperView from '@/components/money/accounts/base/MoneyAccountsBaseRouterWrapperView'
import controls from './requests'
import TheMoneyAccountsView from '@/views/money/TheMoneyAccountsView'
import MoneyAccountsControlsList from '@/components/money/accounts/controls/MoneyAccountsControlsList'


export default [
    {
        path: '/accounts/controls',
        component: TheMoneyAccountsView,
        name: 'accounts.controls',
        props: {
          accountsListComponent: MoneyAccountsControlsList,
        },
        meta: {
            auth: true,
            title: 'Контроль'
        },
    },
    {
        path: '/accounts/controls/:accountId',
        component: MoneyAccountsBaseRouterWrapperView,
        props: {
          accountsListComponent: MoneyAccountsControlsList,
        },
        meta: {
            auth: true,
            backref: {name: 'accounts.controls'},
            title: 'Контроль'
        },
        children: [
            ...controls,
        ]
    },
]
