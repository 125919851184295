<template>
  <v-sheet
    class="d-flex flex-column justify-center align-center fill-height"
    v-bind="$attrs"
  >
    <v-icon
      color="red"
      size="32"
    >mdi-close-circle</v-icon>

    <p class="text-center">{{ error }}</p>
  </v-sheet>
</template>

<script>
export default {
  props: {
    error: String
  }
}
</script>