<template>
  <side-menu-router-view :permanent="!$vuetify.breakpoint.mobile || $route.name === 'journals'">
    <template v-slot:menu>
      <menu-mobile-list
        v-for="menuItem in menuJournals.children"
        :key="menuItem.id"
        :item="menuItem"
      ></menu-mobile-list>
    </template>
  </side-menu-router-view>
</template>

<script>
import SideMenuRouterView from '@/components/common/SideMenuRouterView'
import MenuMobileList from '@/components/menu/aside/mobile/MenuMobileList.vue'
import Menu from '@/components/navigation/Menu.vue'

export default {
  mixins: [Menu],

  components: {
    MenuMobileList,
    SideMenuRouterView
  },
}
</script>
