export default {
    getSummaryForAccount: state => accountId => {
        return {
            applications: state.summary.private.applications.find(application => application.account_id === accountId),
            movements: state.summary.private.movements.find(movement => movement.account_id === accountId),
            purchases: state.summary.private.purchases.find(purchase => purchase.account_id === accountId),
        }
    },

    getPrivateRequestsForAccountDeclinedCount: (state, getters) => (accountId, requestType) => {
      const summary = getters.getSummaryForAccount(accountId)
      return (summary && summary[requestType]) ? summary[requestType].declined : 0
    },
}