<template>
  <v-toolbar
    color="request-matching-archives-warning-new-comment deep-orange accent-2"
    height="36"
    dark
    dense
  >
    <v-icon
      size="20"
      left
    >
      mdi-alert
    </v-icon>

    <p class="grow flex-shrink-1 ma-0 text-truncate">
      Новый комментарий от управляющего
    </p>

    <v-btn
      right
      tile
      text
      v-on:click="$emit('help')"
    >
      <v-icon size="20">mdi-help</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    icon: String,
    iconButton: String,
    message: String,
  }
}
</script>

<style lang="scss">
.request-matching-archives-warning-new-comment {
  & > .v-toolbar__content {
    padding-right: 0;
  }
}
</style>