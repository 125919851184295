<template>
  <v-container
    class="content-tabbed pa-0 flex-column"
    fluid
  >
    <v-fab-transition>
      <v-btn
        v-if="canCreate && formRoute"
        :class="{'mb-15': $vuetify.breakpoint.smAndDown}"
        :to="formRoute"
        color="primary"
        fab
        fixed
        bottom
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-tabs
      v-model="tab"
      :show-arrows="$vuetify.breakpoint.smAndDown"
      class="flex-grow-0"
      color="primary"
      centered
      grow
      v-on:change="saveTab($event)"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.key"
        v-html="tab.title"
      ></v-tab>

      <v-btn
        :loading="reloading"
        class="pa-0"
        height="100%"
        min-width="48px"
        text
        tile
        v-on:click="reload"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="content-tabbed__tab align-self-stretch"
      touchless
    >
      <v-tab-item
        v-for="$tab in tabs"
        :key="$tab.key"
      >
        <v-row v-if="reloading">
          <v-progress-circular
            class="mx-auto my-6"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>

        <component
          v-else
          :is="$tab.component"
          :reloading="reloading"
          v-bind="$tab.bind"
          class="flex-fill"
          ref="contentTab"
        ></component>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import User from '@/mixins/User'
import {mapMutations, mapState} from 'vuex'
import {CONTENT_TAB_SET_ROUTE} from '@/store/content_tabs/mutations_types'

export default {
  mixins: [User],

  data() {
    return {
      reloading: false,
      tab: null,
    }
  },

  computed: {
    ...mapState('content_tabs', [
      'previousRoute'
    ]),

    canCreate() {
      return true
    },

    formRoute() {
      if (this.canCreate) {
        console.warn('computed `formRoute` is not defined')
      }
      return null
    },

    tabs() {
      console.warn('computed `tabs` is not defined')
      return []
    },
  },

  created() {
    if (this.previousRoute && this.previousRoute.name === this.$route.name) {
      this.tab = this.previousRoute.tab
    } else {
      this.contentTabsSave(null)
    }
  },

  methods: {
    ...mapMutations('content_tabs', {
      'contentTabsSave': CONTENT_TAB_SET_ROUTE,
    }),

    getKeyByIndex(index) {
      const tab = this.tabs[index]
      return tab ? tab.key : 'null'
    },

    reload() {
      this.reloading = true

      this.$auth.fetch({})
        .then(() => {
          this.loadUserData(false)
            .then(() => {
              this.$refs.contentTab.forEach(contentTab => contentTab.reload
                ? contentTab.reload()
                : console.warn(`${contentTab} has no method reload`)
              )
            })
            .finally(() => {
              this.reloading = false
            })
        })
        .catch(() => {
          this.reloading = false
        })
    },

    saveTab(index) {
      this.contentTabsSave({
        name: this.$route.name,
        tab: index,
      })
    }
  }
}
</script>

<style lang="scss">
.content-tabbed {
  &__tab {
    margin-bottom: 120px;
  }
}
</style>
