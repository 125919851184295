import { request } from '@/assets/js/api/_helpers'


const ENDPOINT_LOGIN_GOOGLE = '/api/auth/google'
const ENDPOINT_LOGIN_YANDEX = '/api/auth/yandex'


export default {
    google: {
        link: (config) => request('GET', ENDPOINT_LOGIN_GOOGLE, config),
        session: (config) => request('POST', ENDPOINT_LOGIN_GOOGLE, config),
    },
    yandex: {
        link: (config) => request('GET', ENDPOINT_LOGIN_YANDEX, config),
        session: (config) => request('POST', ENDPOINT_LOGIN_YANDEX, config),
    }
}
