<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="py-4 py-md-16"
    >
      <template slot="body">
        <v-select
          v-model="formData.accountFrom"
          :items="accountFrom ? [accountFrom] : []"
          :loading="accountsLoading"
          :readonly="$_readOnly"
          class="mx-3"
          item-text="name"
          item-value="id"
          label="Откуда"
          outlined
        ></v-select>

        <v-select
          v-model="formData.accountTo"
          :items="accountTo ? [accountTo] : []"
          :loading="accountsLoading"
          :readonly="$_readOnly"
          class="mx-3"
          item-text="name"
          item-value="id"
          label="Куда"
          outlined
        ></v-select>

        <v-text-field
          v-model="formData.sender"
          :readonly="$_readOnly"
          class="mx-3"
          label="Отправитель"
          outlined
        >
        </v-text-field>

        <v-text-field
          v-model="formData.recipient"
          :readonly="$_readOnly"
          class="mx-3"
          label="Получатель"
          outlined
        >
        </v-text-field>

        <v-row class="mx-0">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6">
            <v-text-field
              v-model="formData.sum"
              :readonly="$_readOnly"
              :suffix="formData.currencyString"
              label="Сумма"
              outlined
              v-on:input="formData.sum = asNumber($event)"
            >
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6">
            <v-date-field
              v-model="formData.createdAt"
              :readonly="$_readOnly"
              label="Дата создания"
              outlined
            ></v-date-field>
          </v-col>
        </v-row>

        <v-text-field
          v-model="formData.createdBy"
          :readonly="$_readOnly"
          class="mx-3"
          label="Создал"
          outlined
        >
        </v-text-field>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import {mapGetters, mapState} from 'vuex'
import VDateField from '@/components/inputs/VDateField'

export default {
  components: {
    VDateField,
    ContentForm
  },

  mixins: [Form],

  data() {
    return {
      formData: {
        accountFrom: null,
        accountTo: null,
        createdAt: null,
        createdBy: '',
        currencyString: '',
        recipient: '',
        sender: '',
        sum: '',
      }
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById'
    ]),

    ...mapState('money/accounts', {
      accounts: 'list',
      accountsLoading: 'listLoading',
    }),

    accountFrom() {
      return this.getAccountById(this.formData.accountFrom)
    },

    accountTo() {
      return this.getAccountById(this.formData.accountTo)
    },
  },

  methods: {
    formDataCopy(instance) {
      this.formData = {
        accountFrom: instance.account_from_id,
        accountTo: instance.account_to_id,
        createdAt: instance.created_at,
        createdBy: instance.created_by,
        currencyString: instance.currency_string,
        recipient: instance.recipient,
        sender: instance.sender,
        sum: instance.sum,
        ...instance,
      }
    },
  },
}
</script>