import actionsList from '@/store/_prototypes/loadable/list/actions'
import actionsStream from '@/store/_prototypes/loadable/stream/actions'
import gettersRemoteRequests from '@/store/requests/matching/_prototype/getters'
import gettersStream from '@/store/_prototypes/loadable/stream/getters'
import mutationsDetail from '@/store/_prototypes/loadable/detail/mutations'
import mutationsList from '@/store/_prototypes/loadable/list/mutations'
import mutationsMatching from '@/store/requests/matching/_prototype/mutations'
import mutationsStream from '@/store/_prototypes/loadable/stream/mutations'
import stateDetail from '@/store/_prototypes/loadable/detail/state'
import stateList from '@/store/_prototypes/loadable/list/state'
import stateStream from '@/store/_prototypes/loadable/stream/state'

import api from '@/assets/js/api'
import {ENDPOINT_MATCHING_MONEY_GIVE_OUTS} from '@/assets/js/api/requests/matching/money/give_outs'


export default {
    namespaced: true,

    actions: {
        ...actionsList({list: api.requests.matching.money.give_outs.list}),
        ...actionsStream(ENDPOINT_MATCHING_MONEY_GIVE_OUTS + '/stream'),
    },
    getters: {
        ...gettersRemoteRequests(),
        ...gettersStream(),
    },
    mutations: {
        ...mutationsDetail(),
        ...mutationsList(),
        ...mutationsStream(),
        ...mutationsMatching(),  // переопределяет мутацию из стрима
    },
    state: {
        ...stateDetail(),
        ...stateList(),
        ...stateStream(),
    },
}