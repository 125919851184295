import actions from './actions'
import mutations from '@/store/_prototypes/loadable/detail/mutations'
import state from '@/store/_prototypes/loadable/detail/state'


export default {
    namespaced: true,

    actions,
    mutations: {
        ...mutations()
    },
    state: {
        ...state()
    },
}