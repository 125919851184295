import Vue from 'vue';

import datetime from "@/filters/datetime";
import files from "@/filters/files";
import numbers from "@/filters/numbers";
import strings from "@/filters/strings";


function useFilters(...args) {
    Object.entries(Object.assign({}, ...args)).forEach(([methodName, method]) => {
        Vue.filter(methodName, method)
    })
}

useFilters(datetime, files, numbers, strings);

