<template>
  <v-responsive
    :width="width"
    aspect-ratio="1"
    class="the-menu-mobile-nav-link"
  >
    <v-badge
      :color="badgeColor"
      :content="badgeContent"
      :value="!!badgeContent"
      class="d-block fill-height"
      offset-x="35"
      offset-y="35"
      overlap
    >
      <v-btn
        class="the-menu-mobile-nav-link__button pa-1 overflow-hidden"
        height="100%"
        min-width="auto"
        width="100%"
        tile
        text
        x-small
        v-on:click="callback"
      >
        <v-row class="the-menu-mobile-nav-link__button-content ma-0 mb-4 flex-column justify-center">
          <v-icon
            color="grey darken-1"
            size="28"
            v-text="icon"
          ></v-icon>

          <div class="the-menu-mobile-nav-link__label-wrapper">
            <p
              class="ma-0 grey--text text--darken-3 text-center overflow-hidden text-wrap font-weight-regular text-break"
            >
              {{ label }}
            </p>
          </div>
        </v-row>
      </v-btn>
    </v-badge>
  </v-responsive>
</template>

<script>
export default {
  props: {
    callback: Function,
    badgeColor: {
      type: String,
      default: 'red',
    },
    badgeContent: {
      type: String,
      default: null,
    },
    icon: String,
    label: String,
    width: [String, Number]
  }
}
</script>

<style lang="scss">
.the-menu-mobile-nav-link {
  &__button-content {
    width: 100%;

    > * {
      width: 100%;
    }
  }

  &__button > .v-btn__content {
    width: 100%;
  }

  &__label-wrapper > * {
    left: 0;
    position: absolute;
    right: 0;
  }
}
</style>
