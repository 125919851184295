<template>
  <v-card class="fill-height d-flex flex-column">
    <v-card-title class="grey--text">
      <slot name="title">
        <v-icon
          v-if="icon"
          class="mr-2"
          color="grey"
          v-html="icon"
        ></v-icon>

        {{ title }}
      </slot>
    </v-card-title>

    <v-card-text class="black--text">
      <slot></slot>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions class="pb-4">
      <slot name="actions" :on="{ close }"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  props: {
    icon: String,
    title: String,
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>