<template>
  <v-app>
    <portal-target name="app"></portal-target>

    <v-sheet
      v-if="isLoading"
      class="ma-auto text-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
      <p class="caption blue--text text--darken-2 mt-2">Идентификация пользователя...</p>
    </v-sheet>

    <ErrorCard
      v-else-if="error"
      :error="error"
    ></ErrorCard>

    <template v-else>
      <component
        :is="$notifications.active.component"
        v-bind="$notifications.active.bind"
        v-on="$notifications.active.on"
      ></component>

      <v-dialog
        v-model="$modal.visible"
        :fullscreen="$vuetify.breakpoint.mobile"
        :persistent="$modal.locked"
        max-width="500px"
        v-on:input="$event ? null : $modal.close()"
      >
        <component
          :is="$modal.active.component"
          v-bind="$modal.active.bind"
          v-on="$modal.active.on"
        ></component>
      </v-dialog>

      <the-app-bar v-if="$auth.check() && !$route.meta.appBarHide"></the-app-bar>

      <v-main class="fill-height">
        <v-container class="pa-0 fill-height d-flex flex-column align-stretch" fluid>
          <v-toolbar
            v-if="$auth.impersonating()"
            :class="{caption: $vuetify.breakpoint.mobile}"
            class="flex-shrink-0 flex-grow-0"
            color="deep-orange accent-2"
            dark
            dense
          >
            <v-icon left>mdi-incognito</v-icon>
            Режим просмотра под другим пользователем

            <v-spacer></v-spacer>

            <v-btn
              class="mr-n4"
              height="100%"
              text
              tile
              v-on:click="unimpersonateDialog"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-incognito-off</v-icon>
              <template v-if="$vuetify.breakpoint.mdAndUp">отключить</template>
            </v-btn>
          </v-toolbar>

          <v-container class="pa-0 flex-fill" fluid>
            <router-view class="app__router-view fill-height"></router-view>
          </v-container>
        </v-container>
      </v-main>

      <the-menu-mobile
        v-if="$auth.check() && $vuetify.breakpoint.smAndDown"
        ref="menuMobile"
      ></the-menu-mobile>
    </template>
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import TheAppBar from '@/components/common/TheAppBar'
import TheAppMenu from '@/components/common/TheAppMenu'
import User from '@/mixins/User'
import {
  PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW,
  PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW, PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW
} from '@/assets/js/api/users'
import TheMenuMobile from '@/components/navigation/mobile/main/TheMenuMobile'
import ErrorCard from '@/components/common/ErrorCard.vue'
import { httpStatusGetError } from '@/assets/js/http'

export default {
  name: 'App',
  mixins: [User],

  components: {ErrorCard, TheMenuMobile, TheAppMenu, TheAppBar},

  data() {
    return {
      isLoading: false,
      error: null,
    }
  },

  computed: {
    ...mapState('requests/matching/archives/requests', {
      matchingArchivesList: 'list',
      matchingArchivesStreamLastEventId: 'streamLastEventId',
    }),

    ...mapState('requests/matching/money/give_outs', {
      matchingMoneyGiveOutsList: 'list',
      matchingMoneyGiveOutsStreamLastEventId: 'streamLastEventId',
    }),

    user() {
      return this.$auth.user()
    }
  },

  async created() {
    console.info('version:', process.env.VERSION)

    this.isLoading = true
    const timeout = setTimeout(() => {
      this.isLoading = false
      this.error = 'Ошибка. Обратитесь к администратору'
    }, 120000)

    this.$auth.load()
      .then(() => clearTimeout(timeout))
      .catch(error => this.error = httpStatusGetError(error))
      .finally(() => this.isLoading = false)

    document.addEventListener('swRegistered', this.$pushes.init)
    if (this.$auth.impersonating()) {
      await this.$auth.unimpersonate({})
    }
  },

  watch: {
    'matchingArchivesList'(value, valuePrev) {
      if (this.matchingArchivesStreamLastEventId) {
        const requestChanged = value.find($_request => !valuePrev.find($_el => $_request.number === $_el.number))
        if (requestChanged) {
          this.$notifications.show(`Вам назначена заявка в архив: ${requestChanged.number}`)
        }
      }
    },

    'matchingMoneyGiveOutsList'(value, valuePrev) {
      if (this.matchingMoneyGiveOutsStreamLastEventId) {
        const requestChanged = value.find($_request => !valuePrev.find($_el => $_request.number === $_el.number))
        if (requestChanged) {
          this.$notifications.show(`Вам назначена заявка на ДС: ${requestChanged.number}`)
        }
      }
    },

    'user'(value, valuePrev) {
      const userChanged = (value && !valuePrev) || value.id !== valuePrev.id

      if (!value || userChanged) {
        this.streamsStop()
      }

      if (this.user && this.user.is_boss && this.$auth.impersonating()) {
        location.reload()
      }

      if (this.user && userChanged) {
        this.streamsStart()
        this.loadUserData()
      }
    }
  },

  beforeDestroy() {
    this.streamsStop()
  },

  methods: {
    ...mapActions('requests/matching/archives/issues', {
      matchingArchivesIssuesStreamStart: 'streamStart',
      matchingArchivesIssuesStreamStop: 'streamStop',
    }),

    ...mapActions('requests/matching/archives/requests', {
      matchingArchivesRequestsStreamStart: 'streamStart',
      matchingArchivesRequestsStreamStop: 'streamStop',
    }),

    ...mapActions('requests/matching/money/give_outs', {
      matchingMoneyGiveOutsStreamStart: 'streamStart',
      matchingMoneyGiveOutsStreamStop: 'streamStop',
    }),

    ...mapActions('sse', {
      sseStreamStart: 'streamStart',
      sseStreamStop: 'streamStop',
    }),

    streamsStart() {
      const streams = [
        this.sseStreamStart()
      ]

      if (this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW)) {
        streams.push(this.matchingArchivesIssuesStreamStart())
      }

      if (this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_ARCHIVES_REQUESTS_VIEW)) {
        streams.push(this.matchingArchivesRequestsStreamStart())
      }

      if (this.userHasPermissions(PERMISSION_REQUESTS_MATCHING_MONEY_GIVE_OUTS_VIEW)) {
        streams.push(this.matchingMoneyGiveOutsStreamStart())
      }

      return Promise.all(streams)
    },

    streamsStop() {
      this.matchingArchivesIssuesStreamStop()
      this.matchingArchivesRequestsStreamStop()
      this.matchingMoneyGiveOutsStreamStop()
      this.sseStreamStop()
    },

    ...mapActions('sse', {
      sseStreamStart: 'streamStart',
      sseStreamStop: 'streamStop',
    }),

    unimpersonateDialog() {
      this.$modal.confirm(`Заврешить просмотр под пользователем ${this.$auth.user().full_name}?`, this.$auth.unimpersonate)
    }
  }
};
</script>

<style>
.app__router-view {
  position: relative;
}
</style>
