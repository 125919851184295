<template>
  <v-select
    :items="items"
    :loading="(!sourceList.error && sourceList.loading) || (!sourceRetrieve.error && sourceRetrieve.loading)"
    v-bind="{...$attrs, ...$props}"
    class="v-select-paginated"
    ref="select"
    no-filter
    v-on="$listeners"
  >
    <template v-slot:no-data>
      <div v-if="sourceList.loading"></div>

      <slot v-else name="no-data">
        <p
          v-show="!sourceList.loading && !sourceList.error"
          class="my-2 text-center grey--text"
        >Ничего не найдено</p>
      </slot>
    </template>

    <template v-slot:item="attrs">
      <slot name="item" v-bind="attrs"></slot>
    </template>

    <template v-slot:prepend-item>
      <error-card
        v-if="sourceList.error"
        :error="sourceList.error"
        class="mx-auto"
        max-width="240px"
      ></error-card>

      <slot v-else name="prepend-item"></slot>
    </template>

    <template v-slot:item="{ item }">
      <slot name="item" :item="item">{{ getItemText(item) }}</slot>
    </template>

    <template v-slot:selection="props">
      <slot name="selection" v-bind="props"></slot>
    </template>

    <template v-slot:append-item>
      <v-lazy
        v-if="sourceList.pageNext && !sourceList.error && !sourceList.loading"
        :value="sourceList.loading"
        v-on:input="$event && isFocused && extend()"
      ></v-lazy>

      <v-row class="ma-0">
        <v-progress-circular
          v-if="!sourceList.error && (sourceList.pageNext || sourceList.loading)"
          class="mx-auto my-6"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </template>
  </v-select>
</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'
import MixinList from '@/mixins/MixinList'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'

export default {
  name: 'VSelectPaginated',
  components: {ErrorCard},
  mixins: [MixinList],

  props: {
    apiMethodList: Function,
    apiParamsList: Object,
    apiParamsRetrieve: Object,
    value: [Object, null],
  },

  data() {
    return {
      isFocused: false,
      isMenuActive: false,
      sourceList: new ApiPaginatedAccumulatedData(this.apiMethodList, [], {debounce: 500}),
      sourceRetrieve: new ApiPaginatedAccumulatedData(this.apiMethodList, [], {debounce: 500}),
    }
  },

  computed: {
    items() {
      return this.isFocused
        ? this.sourceList.error
          ? []
          : this.sourceList.data
        : this.sourceRetrieve.data
          ? this.sourceRetrieve.data
          : []
    }
  },

  watch: {
    apiParamsList() {
      this.loadList()
    },

    apiParamsRetrieve() {
      this.loadSelected()
    },

    isFocused(value) {
      if (value) {
        this.loadList()
      }
    },

    'sourceList'() {
      if (this.isFocused) {
        this.loadList()
      }
    },

    'sourceRetrieve'() {
      this.loadSelected()
    },

    'value'() {
      this.loadSelected()
    }
  },

  mounted() {
    this.$watch('$refs.select.isFocused', (value) => {
      this.isFocused = value
    })

    this.$watch('$refs.select.isMenuActive', (value) => {
      this.isMenuActive = value
    })
    
    this.loadList()
    this.loadSelected()
  },

  methods: {
    extend() {
      this.sourceList.extend()
    },

    focus() {
      this.$refs.select.focus()
      this.$refs.select.activateMenu()
    },

    loadList() {
      return this.sourceList.setParams({
        ...this.apiParamsList,
      }, true)
    },

    loadSelected() {
      const pk = this.getItemKey(this.value)
      if (!pk) {
        this.sourceRetrieve.clear()
        return
      }

      const dataPrev = this.sourceRetrieve.data
      const promise = this.sourceRetrieve.setParams({
        ...this.apiParamsRetrieve,
        [this.itemKey]: [pk],
      }, true)
      this.sourceRetrieve.data = dataPrev

      promise.then(() => {
        if (!this.sourceRetrieve.data.find(item => this.getItemKey(item) === this.getItemKey(this.value))) {
          this.$emit('input', null)
        }
      })

      return promise
    },
  },
}
</script>

<style lang="scss">
.v-select-paginated {
  .v-select__selections input {
    display: none;
  }
}
</style>