<template>
  <v-main class="the-auth-credentials-form d-flex ma-0 pa-0 fill-height justify-center align-center">
    <v-card class="mx-auto rounded-xl" color="white" elevation="0" width="320px">
      <v-card-title class="grey--text text--lighten-1 flex-column align-content-center">
        <v-icon
          class="mt-n16"
          color="grey lighten-2"
          size="240px"
        >
          mdi-account-lock
        </v-icon>
      </v-card-title>

      <v-card-actions class="justify-center flex-column">
        <v-btn
          v-for="button in buttons"
          :key="button.key"
          :disabled="loadingAny && !button.loading"
          v-ripple="loadingAny && !button.loading"
          class="mx-0 mb-2 text-none font-weight-regular"
          color="white"
          elevation="4"
          block
          large
          tile
          v-on:click="login(button)"
        >
          <v-progress-circular
            v-if="loadingAny && button.loading"
            class="ml-1 mr-2"
            color="primary"
            size="20"
            indeterminate
          ></v-progress-circular>

          <img
            v-else
            :alt="button.key"
            :src="button.icon"
            class="mr-2"
            height="24px"
          >

          <span class="mt-1" v-text="button.label"></span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import iconGoogle from '@/assets/img/icons/google.svg'
import iconYandex from '@/assets/img/icons/yandex.svg'

export default {
  data() {
    return {
      auth: {
        google: new ApiData(api.auth.google.link),
        yandex: new ApiData(api.auth.yandex.link),
      },
      buttons: [
        {
          key: 'google',
          icon: iconGoogle,
          label: "Войти через Google",
          loading: false,
        },
        {
          key: 'yandex',
          icon: iconYandex,
          label: "Войти с Яндекс ID",
          loading: false,
        }
      ]
    }
  },

  computed: {
    loadingAny() {
      return this.buttons.find(button => button.loading)
    }
  },

  methods: {
    login(button) {
      if (button.loading) {
        return
      }

      button.loading = true
      this.auth[button.key].call()
        .then(([data, ]) => {
          this.auth[button.key].loading = true
          window.location = data.url
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
        .finally(() => {
          button.loading = false
        })
    }
  }
}
</script>
