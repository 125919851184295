import Vue from 'vue'
import VueRouter from 'vue-router'

import accounts from './accounts/index'
import auth from './auth'
import journals from './journals'
import requests from './requests'
import users from './users'
import TheAuthCredentials from '@/components/auth/TheAuthCredentials'
import cars from '@/router/cars'


Vue.use(VueRouter)


const routes = [
    ...auth,
    ...accounts,
    ...cars,
    ...journals,
    ...requests,
    ...users,
    {
      path: '/:pathMatch(.*)',
      component: TheAuthCredentials,
      redirect: {
          name: 'accounts.private'
      }
    }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


class BrowserHistory {
  constructor() {
    this.history = []
  }

  add(route) {
    this.history.push(route)
  }

  back() {
    const route = router.push(this.getBackref())
    this.history = this.history.slice(0, this.history.length - 1)
    return route
  }

  getBackref() {
    if (!router.currentRoute.meta.backref) {
      return null
    } else if (this.history.length) {
      return this.history[this.history.length - 1]
    } else {
      return typeof this.$route.meta.backref === 'function'
        ? router.currentRoute.meta.backref(router.currentRoute)
        : router.currentRoute.meta.backref
    }
  }

  get hasBackref() {
    return !!this.getBackref()
  }
}


export const browserHistory = new BrowserHistory()


router.beforeEach((to, from, next) => {
  browserHistory.add(from)
  next()
})


export default router
