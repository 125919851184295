import MoneyAccountsBaseRouterWrapperView from '@/components/money/accounts/base/MoneyAccountsBaseRouterWrapperView'
import MoneyAccountsStoresList from '@/components/money/accounts/stores/MoneyAccountsStoresList'
import storesRequests from './requests'
import TheMoneyAccountsView from '@/views/money/TheMoneyAccountsView'


export default [
    {
        path: '/accounts/stores',
        component: TheMoneyAccountsView,
        name: 'accounts.stores',
        props: {
          accountsListComponent: MoneyAccountsStoresList,
        },
        meta: {
            auth: true,
            title: 'Хранилище'
        },
    },
    {
        path: '/accounts/stores/:accountId',
        component: MoneyAccountsBaseRouterWrapperView,
        props: {
          accountsListComponent: MoneyAccountsStoresList,
        },
        meta: {
            auth: true,
            title: 'Хранилище'
        },
        children: [
            ...storesRequests,
        ]
    },
]
