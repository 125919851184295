<template>
  <v-list-item class="pa-0 overflow-hidden">
    <v-list-item-content class="pa-0">
      <v-list-item-title>
        {{ item.purpose || 'Назначение не указано' }}
      </v-list-item-title>

      <v-list-item-subtitle>
        <v-row class="ma-0 overflow-hidden justify-space-between flex-nowrap">
          <v-col class="pa-0 text-truncate">{{ item.created_at|date }}</v-col>
          <v-col class="pa-0 flex-grow-0">{{ item.sum|measurement(getCurrencyPostfixByNumericCode(item.currency_id)) }}</v-col>
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ])
  }
}
</script>
