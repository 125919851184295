<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :disabled="$attrs.readonly"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="{...attrs, ...$attrs}"
        :class="classField"
        :clearable="clearable"
        :hide-details="hideDetails"
        :label="label"
        :value="valueTemp"
        outlined
        prepend-inner-icon="mdi-clock-outline"
        readonly
        v-on="on"
        v-on:change="$emit('input', $event)"
        v-on:click:clear="$emit('click:clear', $event)"
      ></v-text-field>
    </template>

    <v-time-picker
      v-model="valueTemp"
      :active-picker="activePicker"
      color="primary"
      format="24hr"
      no-title
      v-on:input="save()"
    >
      <v-spacer></v-spacer>

      <v-btn
        text
        color="primary"
        v-on:click="menu = false"
      >
        Cancel
      </v-btn>

      <v-btn
        text
        color="primary"
        v-on:click="save()"
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import { TIME_FORMAT } from '@/filters/datetime'

const ACTIVE_PICKER_HOUR = 'HOUR'
const ACTIVE_PICKER_MINUTE = 'MINUTE'
// const STATE_SECOND = 'SECOND'


export default {
  props: {
    classField: [String, Array, Object],
    clearable: Boolean,
    format: {
      type: String,
      default: TIME_FORMAT,
    },
    hideDetails: Boolean,
    label: String,
    value: [String, null]
  },

  data() {
    return {
      menu: false,
      activePicker: ACTIVE_PICKER_HOUR,
      valueTemp: this.value
    }
  },

  watch: {
    menu(value, valuePrev) {
      if (value && value !== valuePrev) {
        this.activePicker = ACTIVE_PICKER_HOUR
      }
    },

    value() {
      this.valueTemp = this.value
    }
  },

  methods: {
    save() {
      if (this.activePicker === ACTIVE_PICKER_HOUR) {
        this.activePicker = ACTIVE_PICKER_MINUTE
      } else {
        this.$emit('input', this.valueTemp)
        this.menu = false
      }
    }
  }
}
</script>
