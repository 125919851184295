export default function gettersFabric() {
    return {
        hasNewComments: () => request => {
            return !request.is_processed && !request.can_create_main_resolution && request.is_repeating
        },

        getRequestByNumber: ( state ) => requestNumber => {
            return state.list.find(request => request.number === requestNumber)
        },

        isNew: () => request => {
            return !request.task.viewed && !request.is_repeating && !request.is_processed
        },
    }
}