import TheCarsRentFormView from '@/views/cars/rents/TheCarsRentFormView.vue'
import CarsRentsDetailWrapper from '@/views/cars/rents/TheCarsRentsDetailWrapperView.vue'
import TheCarsDetailWrapperView from '@/views/cars/TheCarsDetailWrapperView.vue'
import TheCarsRentsTabbedListView from '@/views/cars/rents/TheCarsRentsTabbedListView.vue'
import {
    PERMISSION_CARS_RENTS_CREATE,
    PERMISSION_CARS_RENTS_UPDATE,
    PERMISSION_CARS_RENTS_UPDATE_ALL,
    PERMISSION_CARS_RENTS_VIEW,
    PERMISSION_CARS_RENTS_VIEW_ALL,
    PERMISSION_CARS_VIEW,
} from '@/assets/js/api/users'


export default [
    {
        path: '/cars/:carId',
        component: TheCarsDetailWrapperView,
        props: route => ({
            carId: Number(route.params.carId),
        }),
        meta: {
            auth: {
                roles: PERMISSION_CARS_VIEW,
                forbiddenRedirect: {
                    name: 'accounts.private'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
        },
        children: [
            {
                path: 'rents/new',
                name: 'cars.rents.create',
                component: TheCarsRentFormView,
                meta: {
                    auth: {
                        roles: PERMISSION_CARS_RENTS_CREATE,
                        forbiddenRedirect: {
                            name: 'cars.list'
                        },
                        redirect: {
                            name: 'auth.login'
                        }
                    },
                    backref: {name: 'cars.list'},
                    title: 'Автомобили',
                    subtitle: 'Эксплуатация'
                },
            },
        ]
    },
    {
        path: '/cars/:carId/rents/:rentId',
        component: CarsRentsDetailWrapper,
        props: route => ({
            carId: Number(route.params.carId),
            rentId: Number(route.params.rentId),
        }),
        meta: {
            auth: {
                roles: [PERMISSION_CARS_RENTS_VIEW, PERMISSION_CARS_RENTS_VIEW_ALL],
                forbiddenRedirect: {
                    name: 'accounts.private'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
        },
        children: [
            {
                path: '',
                name: 'cars.rents.edit',
                component: TheCarsRentFormView,
                props: true,
                meta: {
                    auth: {
                        roles: [PERMISSION_CARS_RENTS_UPDATE, PERMISSION_CARS_RENTS_UPDATE_ALL],
                        forbiddenRedirect: {
                            name: 'cars.rents.list'
                        },
                        redirect: {
                            name: 'auth.login'
                        }
                    },
                    backref: {name: 'cars.list'},
                    title: 'Автомобили',
                    subtitle: 'Эксплуатация'
                },
            },
        ]
    },
    {
        path: '/rents',
        component: TheCarsRentsTabbedListView,
        name: 'cars.rents.list',
        meta: {
            auth: {
                roles: [PERMISSION_CARS_RENTS_VIEW, PERMISSION_CARS_RENTS_VIEW_ALL],
                forbiddenRedirect: {
                    name: 'cars.rents.list'
                },
                redirect: {
                    name: 'auth.login'
                }
            },
            title: 'История эксплуатации',
        }
    },
]
