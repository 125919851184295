<template>
  <modal-dialog :title="title">
    <content-form :value="$v.formData.$errors" no-controls-divider>
      <template slot="body">
        <v-text-field
          v-model="formData.comment"
          :error="$v.formData.comment && $v.formData.comment.$error"
          :error-messages="errors.comment"
          label="Причина отмены"
          autofocus
        ></v-text-field>
      </template>
    </content-form>

    <template v-slot:actions>
      <request-matching-archives-decline-controls
        :is-loading="validating || apiResult.loading"
        v-on:cancel="close"
        v-on:submit="submit"
      ></request-matching-archives-decline-controls>
    </template>
  </modal-dialog>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import RequestMatchingArchivesDeclineControls from '@/components/requests/matching/archives/issues/form/modals/decline/RequestMatchingArchivesDeclineControls'
import ModalDialog from '@/components/common/modals/ModalDialog'
import {maxLength, minLength, required} from 'vuelidate/lib/validators'
import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import ModalForm from '@/components/common/modals/ModalForm'

export default {
  extends: ModalForm,

  props: {
    instance: Object,
  },

  components: {
    ModalDialog,
    RequestMatchingArchivesDeclineControls,
    ContentForm
  },

  data() {
    return {
      apiResult: new ApiData(api.requests.matching.archives.issues.result),
      formData: {
        comment: '',
        result: false,
      }
    }
  },

  methods: {
    submitEvent() {
      this.$modal.locked = true
      return this.apiResult.call({id: this.instance.uid, data: this.formData})
        .then(() => {
          this.$emit('input', this.formData)
          this.close()
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          console.error(error)
        })
        .finally(() => {
          this.$modal.locked = false
        })
    }
  },

  validations: {
    formData: {
      comment: {
        maxLength: maxLength(255),
        minLength: minLength(3),
        required,
      }
    }
  }
}
</script>