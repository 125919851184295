import ModalDialogConfirm from '@/components/common/modals/ModalDialogConfirm'
import ModalDialog from '@/components/common/modals/ModalDialog'
import ModalDialogInfo from '@/components/common/modals/ModalDialogInfo'


const Modals = {
    install(Vue) {
        Vue.prototype.$modal = Vue.observable({
            active: {
                component: ModalDialog
            },
            permanent: false,
            locked: false,
            visible: false,

            close() {
                this.visible = false
            },

            open(modal) {
                this.active = modal
                this.visible = true
            },

            confirm(question = 'Do you wanna be the Boshy?', onConfirm = undefined) {
                this.open({
                    bind: {
                        callback: onConfirm,
                        question: question,
                        yes: true,
                        no: false,
                    },
                    on: {
                        close: () => {
                            this.close()
                        },
                    },
                    component: ModalDialogConfirm,
                })
            },

            info(title, description) {
                this.open({
                    bind: {
                        description,
                        title,
                    },
                    on: {
                        close: () => {
                            this.close()
                        },
                    },
                    component: ModalDialogInfo,
                })
            },
        })
    }
}


export default Modals;