<template>
  <v-card :width="$vuetify.breakpoint.mobile ? '100%' : '400px'">
    <v-card-title>О приложении</v-card-title>

    <v-card-text>
      <v-row class="align-center">
        <v-col>Версия</v-col>

        <v-col class="flex-grow-0">
          {{ version }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.VERSION
    }
  }
}
</script>
