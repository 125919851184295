<template>
  <v-row class="ma-0 flex-nowrap overflow-hidden flex-fill">
    <v-col class="px-1 py-0 flex-shrink-0 flex-grow-0 text-no-wrap">{{ item.created_at|date }}</v-col>
    <v-col class="px-1 py-0 flex-grow-1 text-no-wrap text-truncate grey--text">{{ item.purpose || 'Назначение не указано' }}</v-col>
    <v-col class="px-1 py-0 flex-shrink-0 flex-grow-0 text-no-wrap">{{ item.sum|measurement(getCurrencyPostfixByNumericCode(item.currency_id)) }}</v-col>
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ])
  }
}
</script>