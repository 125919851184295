<script>
import ContentTabbed from '@/components/common/content/ContentTabbed'
import {STATUS_DRAFT} from '@/assets/js/api/requests/stores/receipts_cash'
import ReceiptsCashListInfinityScroll
  from '@/components/requests/stores/receipts_cash/list/ReceiptsCashListInfinityScroll'

export default {
  extends: ContentTabbed,

  props: {
    accountId: Number,
  },

  computed: {
    canCreate() {
      return !this.$auth.impersonating()
    },

    formRoute() {
      return {name: 'accounts.stores.details.requests.receipts_cash.create'}
    },

    tabs() {
      return [
        {
          key: 'history',
          bind: {
            filters: {account: this.accountId, status__exclude: [STATUS_DRAFT]},
            noCaptions: false,
          },
          component: ReceiptsCashListInfinityScroll,
          title: 'История',
        },
        {
          key: 'drafts',
          bind: {
            filters: {account: this.accountId, status: [STATUS_DRAFT]},
            noCaptions: true,
          },
          component: ReceiptsCashListInfinityScroll,
          title: 'Черновики',
        },
      ]
    },
  },
}
</script>