<script>
import MoneyAccountsBaseListItem from '@/components/money/accounts/base/MoneyAccountsBaseListItem'
import {PERMISSION_ACCOUNTS_PRIVATE_REFILL_CREATE} from '@/assets/js/api/users'

export default {
  extends: MoneyAccountsBaseListItem,

  computed: {
    submenuItems() {
      return [
        {
          key: 'purchases',
          alarms: this.getPrivateRequestsForAccountDeclinedCount(this.account.id, 'purchases'),
          icon: 'mdi-cart',
          title: 'Покупки',
          to: {name: 'accounts.private.details.requests.purchases', params: {accountId: this.account.id}},
          visible: this.account.is_private,
        },
        {
          key: 'movements',
          alarms: this.getPrivateRequestsForAccountDeclinedCount(this.account.id, 'movements'),
          icon: 'mdi-subdirectory-arrow-right',
          title: 'Перемещения',
          to: {name: 'accounts.private.details.requests.movements', params: {accountId: this.account.id}},
          visible: this.userHasPermissions(PERMISSION_ACCOUNTS_PRIVATE_REFILL_CREATE) && this.account.is_private && !this.account.is_account,
        },{
          key: 'report',
          icon: 'mdi-clipboard-text',
          title: 'Отчет',
          to: {name: 'accounts.private.details.report', params: {accountId: this.account.id}},
          visible: this.account.is_private,
        }
      ]
    }
  }
}
</script>
