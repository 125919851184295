<template>
  <v-container
    class="request-matching-template ma-0 px-0 pt-0 pb-16 pb-md-2"
    fluid
  >
    <v-overlay v-if="isLoading">
      <v-progress-circular
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="fill-height flex-column flex-md-row flex-sm-nowrap ma-0">
      <v-col class="flex-grow-0 flex-md-grow-1">
        <slot name="primary"></slot>

        <slot
          name="controls-desktop"
        >
          <request-matching-result-buttons
            class="mt-8"
            v-on:accept="$emit('accept')"
            v-on:reject="$emit('reject')"
          ></request-matching-result-buttons>
        </slot>
      </v-col>

      <v-col class="py-6 py-md-3">
        <slot name="secondary"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RequestMatchingResultButtons from '@/components/requests/base/matching/result/RequestMatchingResultButtons.vue'

export default {
  components: {
    RequestMatchingResultButtons
  },

  props: {
    canResult: Boolean,
    isLoading: Boolean,
  }
}
</script>

<style lang="scss">
.request-matching-template {
  width: 100%;

  &__bottom-navigation {
    box-shadow: 0 -1px 2px 1px rgba(0, 0, 0, 0.2), 0px -1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
