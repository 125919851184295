<template>
  <v-container class="pa-0">
    <v-row class="ma-0">
      <v-col
        :class="!instance.created_by && ['grey--text', 'text--lighten-1']"
        class="overflow-hidden pa-0 text-h6 text-md-h5 text-no-wrap text-truncate"
      >
        {{ instance.created_by || 'Сотрудник отсутствует' }}
      </v-col>

      <v-col
        align-self="end"
        class="overflow-hidden pa-0 text-h6 text-md-h5 text-right text-no-wrap"
        cols="4"
      >
        {{ instance.amount | measurement(instance.currency_string) }}
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="ma-0 mb-4">
      <v-col class="pa-0 text-no-wrap grey--text lighten-3">
        {{ instance.created_at | moment('DD.MM.YYYY') }}
      </v-col>

      <v-col
        align-self="end"
        class="pa-0 text-no-wrap text-right text-capitalize-first-letter"
      >
        {{ instance.is_cashless ? 'безнал' : 'наличные' }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    instance: Object
  }
}
</script>