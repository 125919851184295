import {
    PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW,
} from '@/assets/js/api/users'
import TheMatchingArchivesIssuesListView from '@/views/requests/matching/archives_issues/TheMatchingArchivesIssuesListView'
import TheMatchingArchivesIssuesDetailWrapper from '@/views/requests/matching/archives_issues/TheMatchingArchivesIssuesDetailWrapper'
import TheMatchingArchivesIssuesFormView from '@/views/requests/matching/archives_issues/TheMatchingArchivesIssuesFormView'


export default [
    {
        path: '/requests/matching/archives/issues',
        name: 'requests.matching.archives.issues',
        component: TheMatchingArchivesIssuesListView,
        meta: {
            auth: {
                roles: [ PERMISSION_REQUESTS_MATCHING_ARCHIVES_ISSUES_VIEW ],
                redirect: {
                    name: 'accounts.private'
                }
            },
            title: 'Выдача из архива'
        },
    },
    {
        path: '/requests/matching/archives/issues/:instanceId',
        component: TheMatchingArchivesIssuesDetailWrapper,
        props: true,
        children: [
            {
                path: '',
                name: 'requests.matching.archives.issues.form',
                component: TheMatchingArchivesIssuesFormView,
                props: true,
                meta: {
                    backref: {
                        name: 'requests.matching.archives.issues'
                    },
                    title: 'Выдача из архива'
                },
            },
        ]
    },
]