<template>
  <v-sheet class="pa-0" min-width="100%">
    <request-matching-warning-new-comment
      v-if="hasNewComments(instance)"
      v-on:help="acceptDialog"
    ></request-matching-warning-new-comment>

    <request-matching-template
      :canResult="!$auth.impersonating() && !instance.is_processed"
      :isLoading="isLoading"
      v-on:accept="acceptDialog"
      v-on:reject="rejectDialog"
    >
      <template slot="primary">
        <request-matching-money-give-outs-header :instance="instance"></request-matching-money-give-outs-header>
        <request-matching-data-primary :rows="dataPrimary"></request-matching-data-primary>

        <p
          v-if="instance.files.length"
          class="ma-0 grey--text"
        >Приложенные файлы:</p>

        <v-files-field
          :value="instance.files"
          readonly
        ></v-files-field>
      </template>

      <template slot="secondary">
        <request-matching-money-give-outs-data-secondary
          :commentCreator="commentCreator"
          :instance="instance"
          v-on:comment="commentCreate"
        ></request-matching-money-give-outs-data-secondary>

        <request-matching-history
          :error="apiHistory.error"
          :history="apiHistory.data"
          :is-loading="apiHistory.loading"
          class="py-6 py-md-3"
        ></request-matching-history>
      </template>
    </request-matching-template>
  </v-sheet>
</template>

<script>
import Form from '@/mixins/Form'
import RequestMatchingTemplate from '@/components/requests/base/matching/RequestMatchingTemplate'
import {mapGetters, mapMutations} from 'vuex'
import api from '@/assets/js/api'
import {
  REQUEST_ADD_COMMENT,
  REQUEST_MARK_VIEWED,
  REQUEST_SET_COMPLETED,
  REQUEST_SET_PROCESSED
} from '@/store/requests/matching/_prototype/mutations_types'
import {ApiData} from '@/assets/js/api/_helpers'
import RequestMatchingMoneyGiveOutsHeader
  from '@/components/requests/matching/money/give_outs/form/RequestMatchingMoneyGiveOutsHeader'
import RequestMatchingDataPrimary from '@/components/requests/base/matching/RequestMatchingDataPrimary'
import {measurement} from '@/filters/numbers'
import RequestMatchingWarningNewComment from '@/components/requests/base/matching/RequestMatchingWarningNewComment'
import RequestMatchingMoneyGiveOutsDataSecondary
  from '@/components/requests/matching/money/give_outs/form/RequestMatchingMoneyGiveOutsDataSecondary'
import RequestMatchingHistory from '@/components/requests/base/matching/RequestMatchingHistory'
import {STATUS_ACTIVE, STATUS_CANCELED, STATUS_COMPLETED} from '@/assets/js/api/requests/matching/money/give_outs'
import VFilesField from '@/components/inputs/VFilesField'

export default {
  components: {
    VFilesField,
    RequestMatchingHistory,
    RequestMatchingMoneyGiveOutsDataSecondary,
    RequestMatchingWarningNewComment,
    RequestMatchingDataPrimary,
    RequestMatchingMoneyGiveOutsHeader,
    RequestMatchingTemplate
  },
  mixins: [Form],

  data() {
    return {
      apiHistory: new ApiData(api.requests.matching.money.give_outs.history, []),
      apiResult: new ApiData(api.requests.matching.money.give_outs.result),
      commentCreator: new ApiData(api.requests.matching.money.give_outs.comments.create)
    }
  },

  computed: {
    ...mapGetters('requests/matching/money/give_outs', [
      'hasNewComments'
    ]),

    dataPrimary() {
      return [
        { key: 'status', label: 'Статус', value: this.statusDescription },
        { key: 'number', label: 'Номер заявки', value: this.instance.number },
        { key: 'receiver', label: 'Получатель', value: this.instance.recipient },
        { key: 'category', label: 'Статья затрат', value: this.instance.category_string },
        { key: 'description', label: 'Назначение', value: this.instance.description },
        { key: 'object', label: 'Объект', value: this.instance.object_string },
        { key: 'budget', label: 'Остаток бюджета', value: measurement(this.instance.budget, this.instance.currency_string) },
      ]
    },

    isLoading() {
      return this.apiResult.loading || this.commentCreator.loading
    },

    statusDescription() {
      switch (this.instance.status) {
        case STATUS_CANCELED:
          return 'Отклонена'
        case STATUS_ACTIVE:
          if (!this.instance.is_processed) {
            return this.instance.is_repeating ? 'требуется ответ на замечание' : 'на рассмотрении'
          } else if (this.instance.can_create_main_resolution) {
            return 'ожидание ответа на замечание'
          } else {
            return 'на согласовании у управляющего'
          }
        case STATUS_COMPLETED:
          return 'Согласована'
        default:
          return 'Неизвестный статус'
      }
    }
  },

  mounted() {
    if (!this.$auth.impersonating() && !this.instance.task.viewed) {
      const [promise, ] = api.requests.matching.money.give_outs.viewed({id: this.instance.number})
      promise.then(() => {
        this[REQUEST_MARK_VIEWED](this.instance)
      })
    }

    this.apiHistory.call({id: this.instance.number})
  },

  methods: {
    ...mapMutations('requests/matching/money/give_outs', [
      REQUEST_ADD_COMMENT,
      REQUEST_MARK_VIEWED,
      REQUEST_SET_COMPLETED,
      REQUEST_SET_PROCESSED,
    ]),

    acceptDialog() {
      if (this.hasNewComments(this.instance)) {
        this.$modal.info(
          'Обработка заявки',
          'Для обработки заявки требуется ответить на комментарий управляющего в истории переписки рецензентов или отклонить заявку'
        )
      } else {
        this.$modal.confirm('Утвердить текущую заявку?', this.result.bind(null, true))
      }
    },

    commentCreate(comment) {
      this.commentCreator.call({id: this.instance.number, data: comment, params: {task_uid: this.instance.task.uid}})
        .then(() => {
          this[REQUEST_SET_PROCESSED](this.instance)
          this[REQUEST_ADD_COMMENT]([this.instance, {
            author: this.$auth.user().full_name,
            created_at: this.$moment(),
            ...comment,
          }])
          this.$router.push(this.$route.meta.backref)
          this.$notifications.show('Сообщение успешно отправлено')
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
    },

    rejectDialog() {
      this.$modal.confirm('Вы действительно хотите отклонить текущую заявку?', this.result.bind(null, false))
    },

    result(result) {
      return this.apiResult.call({id: this.instance.number, task: this.instance.task.uid, data: { result }})
        .then(() => {
          this[REQUEST_SET_PROCESSED](this.instance)
          if (this.instance.can_create_main_resolution) {
            this[REQUEST_SET_COMPLETED](this.instance)
          }
          this.$router.push(this.$route.meta.backref)
          this.$notifications.show(`Заявка успешно ${result ? 'согласована' : 'отклонена'}`)
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
    }
  }
}
</script>
