<template v-else>
  <v-col class="flex-fill d-flex justify-space-between flex-wrap flex-sm-nowrap">
    <v-btn
      key="give_out"
      :block="$vuetify.breakpoint.xsOnly"
      :loading="isLoading"
      class="white--text"
      color="green"
      outlined
      v-on:click="$emit('submit')"
    >
      <v-icon
        color="green"
        left
      >mdi-check</v-icon>
      Отменить выдачу
    </v-btn>

    <v-btn
      key="cancel"
      :block="$vuetify.breakpoint.xsOnly"
      :disabled="isLoading"
      class="mt-4 mt-sm-0 white--text"
      color="red"
      width="144px"
      v-on:click="$emit('cancel')"
    >
      <v-icon left>mdi-close</v-icon>
      Отмена
    </v-btn>
  </v-col>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
}
</script>