<template>
  <v-card :width="$vuetify.breakpoint.mobile ? '100%' : '400px'">
    <v-card-title>Оповещения</v-card-title>

    <v-card-text>
      <v-row class="align-center">
        <v-col>Push уведомления (текущий браузер)</v-col>

        <v-col class="flex-grow-0">
          <v-tooltip v-if="$pushes.error" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="deep-orange"
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert
              </v-icon>
            </template>

            {{ $pushes.errorDescription }}
          </v-tooltip>

          <v-switch
            v-else
            :disabled="$auth.impersonating() || $pushes.error || $pushes.isUpdating"
            :input-value="$pushes.active"
            class="ma-0"
            hide-details
            v-on:change="pushesToggle"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      $_subscriptionUpdating: false,
    }
  },

  computed: {
    pushes() {
      return this.$pushes
    },
  },

  methods: {
    pushesToggle(value) {
      value ? this.pushesTurnOn() : this.pushesTurnOff()
    },

    pushesTurnOn() {
      return this.$pushes.subscriptionCreate()
        .catch(error => {
          this.$notifications.show(error.description || 'Не удалось подписаться на push уведомления', 'error')
          console.error(error)
        })
    },

    pushesTurnOff() {
      return this.$pushes.subscriptionRemove()
        .catch(error => {
          this.$notifications.show(error.description || 'Не удалось отписаться от push уведомлений', 'error')
          console.error(error)
        })
    }
  }
}
</script>