<template>
  <v-input
    class="pa-0"
    :error="error"
    :error-messages="errorMessages"
    fluid
  >
    <v-row class="datetime-field flex-nowrap" no-gutters>
      <v-col class="datetime-field__date">
        <v-date-field
          v-model="date"
          :clearable="clearable"
          :disabled="disabled"
          :error="error"
          :label="label ? `${label} (Дата)` : 'Дата'"
          :outlined="outlined"
          :readonly="readonly"
          hide-details
          v-on:click:clear="date = null"
        ></v-date-field>
      </v-col>

      <v-col class="datetime-field__time">
        <v-time-field
          v-model="time"
          :disabled="disabled"
          :error="error"
          :label="label ? `${label} (Время)` : 'Время'"
          :outlined="outlined"
          :readonly="readonly"
          hide-details
        ></v-time-field>
      </v-col>
    </v-row>
  </v-input>
</template>

<script>
import VDateField from '@/components/inputs/VDateField.vue'
import VTimeField from '@/components/inputs/VTimeField.vue'
import { DATE_FORMAT_ISO, TIME_FORMAT } from '@/filters/datetime'


export default {
  components: {VTimeField, VDateField},

  props: {
    clearable: Boolean,
    disabled: Boolean,
    error: Boolean,
    errorMessages: Array,
    label: String,
    outlined: Boolean,
    readonly: Boolean,
    value: [String, null],
  },

  computed: {
    date: {
      get() {
        return this.value ? this.$moment(this.value).format(DATE_FORMAT_ISO) : null
      },

      set(value) {
        if (!value) {
          this.$emit('input', null)

        } else if (!this.value) {
          const date = this.$moment(value)
          date.hour(9)
          this.$emit('input', date.toISOString())

        } else {
          const origin = this.$moment(this.value)
          const date = this.$moment(value)

          origin.set({
            date: date.date(),
            month: date.month(),
            year: date.year()
          });

          this.$emit('input', origin.toISOString())
        }
      }
    },

    time: {
      get() {
        return this.value ? this.$moment(this.value).format(TIME_FORMAT) : null
      },

      set(value) {
        const origin = this.$moment(this.value)
        const date = this.$moment(value, 'H:m')

        origin.set({
          hour: date.hour(),
          minute: date.minute()
        });

        this.$emit('input', origin.toISOString())
      }
    }
  },
}
</script>

<style lang="scss">
.datetime-field {
  &__date {
    margin-right: -1px;
    width: calc(100% + 1px);

    .v-input {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;

      fieldset {
        border-right-width: 0 !important;
      }

      &.v-input--is-focused {
        fieldset {
          border-right-width: 2px !important;
        }
      }

      &:hover:not(.v-input--is-focused):not(:has( + .datetime-field__time .v-input.v-input--is-focused)) {
        fieldset {
          border-right-width: 1px !important;
        }
      }
    }

    &:has(.v-input.v-input--is-focused) + .datetime-field__time:not(:has(.v-input.v-input--is-focused)),
    &:has(.v-input:hover) + .datetime-field__time:not(:has(.v-input.v-input--is-focused)) {
      .v-input {
        fieldset {
          border-left-width: 0 !important;
        }
      }
    }
  }

  &__time {
    .v-input {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }
}
</style>
