import {request} from '@/assets/js/api/_helpers'


const ENDPOINT_CARS = '/api/cars'


export default {
    list: (config) => request('GET', ENDPOINT_CARS, config),
    retrieve: ({carId, ...config}) => request('GET', `${ENDPOINT_CARS}/${carId}`, config),

    rents: {
        conflicts: {
          list: (config) =>  request('GET', `${ENDPOINT_CARS}/rents/conflicts`, config),
          retrieve: ({carId, ...config}) =>  request('GET', `${ENDPOINT_CARS}/${carId}/rents/conflicts`, config),
        },
        create: (config) => request('POST', `${ENDPOINT_CARS}/rents`, config),
        list: (config) => request('GET', `${ENDPOINT_CARS}/rents`, config),
        retrieve: ({rentId, ...config}) => request('GET', `${ENDPOINT_CARS}/rents/${rentId}`, config),
        update: ({rentId, ...config}) => request('PATCH', `${ENDPOINT_CARS}/rents/${rentId}`, config),
    }
}
