<script>
import api from '@/assets/js/api'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import MovementsListInfinityScroll from '@/components/requests/base/movements/list/MovementsListInfinityScroll'


export default {
  extends: MovementsListInfinityScroll,

  data() {
    return {
      movements: new ApiPaginatedAccumulatedData(api.requests.stores.movements.list)
    }
  },
}
</script>