import actionsStream from '@/store/_prototypes/loadable/stream/actions'
import gettersStream from '@/store/_prototypes/loadable/stream/getters'
import mutationsStream from '@/store/_prototypes/loadable/stream/mutations'
import stateStream from '@/store/_prototypes/loadable/stream/state'

import actions from './actions'

export default {
  namespaced: true,

  actions: {
    ...actionsStream('/api/sse'),
    ...actions
  },
  getters: gettersStream(),
  mutations: mutationsStream(),
  state: stateStream(),
}
