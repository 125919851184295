import {
    REQUEST_ADD_COMMENT,
    REQUEST_MARK_VIEWED,
    REQUEST_REMOVE,
    REQUEST_SET_COMPLETED,
    REQUEST_SET_PROCESSED,
} from '@/store/requests/matching/_prototype/mutations_types'
import {STREAM_MESSAGE} from '@/store/_prototypes/loadable/stream/mutations_types'
import {STATUS_COMPLETED} from '@/assets/js/api/requests/matching/archives/requests'

export default () => ({
    [REQUEST_ADD_COMMENT]: (state, [request, comment]) => {
        const storedRequest = state.list.find($_request => $_request.number === request.number)
        if (storedRequest) {
            storedRequest.comments.push(comment)
        }
    },

    [REQUEST_MARK_VIEWED]: (state, request) => {
        const stateRequest = state.list.find($_request => $_request.number === request.number)
        if (stateRequest) {
            stateRequest.task.viewed = true
        }
    },

    [REQUEST_REMOVE]: (state, request) => {
        state.list = state.list.filter($_request => $_request.number !== request.number)
    },

    [REQUEST_SET_COMPLETED]: (state, request) => {
        const storedRequest = state.list.find($_request => $_request.number === request.number)
        if (storedRequest) {
            storedRequest.status = STATUS_COMPLETED
        }
    },

    [REQUEST_SET_PROCESSED]: (state, request) => {
        const storedRequest = state.list.find($_request => $_request.number === request.number)
        if (storedRequest) {
            storedRequest.is_processed = true
        }
    },

    [STREAM_MESSAGE]: (state, data) => {
        state.list = data
    }
})