import {request} from '@/assets/js/api/_helpers'

export const ENDPOINT_MATCHING_ARCHIVES_REQUESTS = '/api/requests/matching/archives/requests'

export const STATUS_ACTIVE = 'active'
export const STATUS_CANCELED = 'canceled'
export const STATUS_COMPLETED = 'accepted'


export default {
    list: (config) => request( 'GET', ENDPOINT_MATCHING_ARCHIVES_REQUESTS, config),
    comments: {
      create: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_ARCHIVES_REQUESTS}/${id}/comments`, config),
    },
    history: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_ARCHIVES_REQUESTS}/${id}/history`, config),
    result: ({id, task, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_ARCHIVES_REQUESTS}/${id}/${task}/result`, config),
    viewed: ({id, ...config}) => request( 'POST', `${ENDPOINT_MATCHING_ARCHIVES_REQUESTS}/${id}/viewed`, config),
}
