<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="py-0 py-md-16"
    >
      <template slot="body">
        <v-navigation-drawer
          v-if="instance"
          v-model="commentsHistory"
          :width="$vuetify.breakpoint.mobile ? '75%' : '360px'"
          class="px-3 py-6"
          fixed
          right
          temporary
          touchless
        >
          <purchase-comment
            v-for="comment in instance.comments"
            :key="comment.id"
            :comment="comment"
            class="mb-6"
          ></purchase-comment>
        </v-navigation-drawer>

        <status-alert-widget
          :instance="instance"
        ></status-alert-widget>

        <v-divider class="my-6"></v-divider>

        <v-alert
          v-if="activeComment"
          class="mx-3"
          color="red"
          outlined
          text
        >
          <purchase-comment
            :comment="activeComment"
            class="red--text"
          ></purchase-comment>
        </v-alert>

        <v-container
          v-if="hasDeclinedComments"
          class="px-3 py-0"
        >
          <v-btn
            class="caption"
            block
            plain
            v-on:click="commentsHistory = true"
          >
            Просмотреть историю замечаний
          </v-btn>
        </v-container>

        <v-select
          v-model="formData.account_from"
          :error="$v.formData.account_from.$error"
          :error-messages="errors.account_from"
          :items="$_accountsFromItems"
          :loading="accountsLoading"
          :readonly="$_readOnly"
          class="mx-3 mt-6"
          label="Откуда"
          outlined
        ></v-select>

        <v-select
          v-model="formData.account_to"
          :error="$v.formData.account_to.$error"
          :error-messages="errors.account_to"
          :items="$_accountsToItems"
          :loading="accountsLoading"
          :messages="accountToMessages"
          :readonly="$_readOnly"
          class="mx-3"
          label="Куда"
          outlined
        ></v-select>

        <v-row class="mx-0">
          <v-col>
            <v-text-field
              v-model="formData.sum"
              :error="$v.formData.sum.$error"
              :error-messages="errors.sum"
              :readonly="$_readOnly"
              :suffix="currency && (currency.symbol || currency.alphabetic_code)"
              label="Сумма к зачислению"
              outlined
              v-on:input="formData.sum = asNumber($event)"
            >
            </v-text-field>
          </v-col>

          <v-col v-if="currencyRatioRequired">
            <v-text-field
              v-model="formData.currency_ratio"
              :error="$v.formData.currency_ratio.$error"
              :error-messages="errors.currency_ratio"
              :readonly="$_readOnly"
              label="Курс валют"
              outlined
              v-on:input="formData.currency_ratio = asNumber($event)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-if="showCommentField"
          v-model="formData.comment"
          :error="$v.formData.comment.$error"
          :error-messages="errors.comment"
          :readonly="$_readOnly"
          class="mx-3"
          label="Комментарий"
          outlined
        ></v-text-field>

        <v-divider></v-divider>

        <template v-if="formAlerts.length">
          <v-alert
            v-for="alert in formAlerts"
            :key="alert"
            class="mx-3 my-4"
            icon="mdi-alert"
            type="warning"
            outlined
            text
          >
            <p class="ma-0 mb-1" v-html="alert"></p>
          </v-alert>
        </template>
      </template>

      <template v-if="!$auth.impersonating()" slot="controls">
        <v-row class="mx-0 mt-4 mb-0 flex-nowrap">
          <v-col class="flex-fill d-flex flex-column justify-space-between flex-sm-row">
            <v-btn
              v-if="readonly && canEdit"
              key="edit"
              :block="$vuetify.breakpoint.xsOnly"
              :to="{name: 'requests.applications.edit', params: {accountId, movementId}}"
              color="primary"
              large
              outlined
            >
              <v-icon left>mdi-pencil</v-icon>
              Редактировать
            </v-btn>

            <template v-else-if="!$_readOnly">
              <v-btn
                key="cancel"
                :block="$vuetify.breakpoint.xsOnly"
                :disabled="isCreating || isUpdating"
                class="my-2 my-sm-0 white--text"
                color="red"
                large
                outlined
                v-on:click="gotoBack"
              >
                <v-icon left>mdi-close</v-icon>
                Отмена
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn
                key="save"
                :block="$vuetify.breakpoint.xsOnly"
                :disabled="isCreating"
                :loading="isUpdating"
                class="my-2 my-sm-0 mx-0 mx-sm-4 white--text"
                color="primary"
                large
                outlined
                v-on:click="save"
              >
                <v-icon left>mdi-content-save</v-icon>
                Сохранить
              </v-btn>

              <v-btn
                key="send"
                :block="$vuetify.breakpoint.xsOnly"
                :disabled="isUpdating"
                :loading="isCreating"
                class="my-4 my-sm-0 white--text"
                color="deep-orange"
                large
                v-on:click="send"
              >
                <v-icon
                  color="white"
                  left
                >mdi-cart-arrow-right</v-icon>
                Отправить
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import api from '@/assets/js/api'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {ApiData} from '@/assets/js/api/_helpers'
import {decimal, maxLength, maxValue, minValue, required, requiredIf} from 'vuelidate/lib/validators'
import {DETAIL_SET} from '@/store/_prototypes/loadable/detail/mutations_types'
import PurchaseComment from '@/components/requests/private/requests/purchases/purchaseComment'
import {measurement} from '@/filters/numbers'
import {STATUS_ACCEPTED, STATUS_DECLINED, STATUS_DRAFT, STATUS_SENT} from '@/assets/js/api/requests/private/movements'
import Form from '@/mixins/Form'
import ContentForm from '@/components/common/content/form/ContentForm'
import StatusAlertWidget from '@/components/requests/base/widgets/StatusAlertWidget'

export default {
  mixins: [Form],

  components: {
    StatusAlertWidget,
    ContentForm,
    PurchaseComment,
  },

  props: {
    accountId: Number,
    instance: Object,
    readonly: Boolean,
  },

  data() {
    return {
      balance: {
        from: new ApiData(api.money.accounts.balance),
        to: new ApiData(api.money.accounts.balance),
      },

      commentsHistory: false,
      formData: {
        account_from: null,
        account_to: null,
        comment: '',
        currency_ratio: 1,
        number: '',
        status: STATUS_DRAFT,
        sum: '',
      },
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
      'getCurrencyPostfixByNumericCode',
    ]),

    ...mapState('money/accounts', {
      accounts: 'list',
      accountsLoading: 'listLoading',
    }),

    $_accountsFromItems() {
      return this.accountsFrom.map(this.getAccountItem)
    },
    
    $_accountsToItems() {
      return this.accountsTo.map(this.getAccountItem)
    },
    
    $_readOnly() {
       return this.readonly || this.creator.loading || this.copier.loading
    },

    accountsFrom() {
      console.warn('Please, redefine `accountsFrom` computed')
      return this.accounts
    },

    accountFromPostfix() {
      const account = this.getAccountById(this.formData.account_from)
      return account && this.getCurrencyPostfixByNumericCode(account.currency_id)
    },

    accountToPostfix() {
      const account = this.getAccountById(this.formData.account_to)
      return account && this.getCurrencyPostfixByNumericCode(account.currency_id)
    },

    accountTo() {
      return this.getAccountById(this.formData.account_to || (this.instance && this.instance.account_to_id))
    },

    accountToCurrency() {
      return this.getCurrencyByNumericCode(this.accountTo && this.accountTo.currency_id)
    },

    accountsTo() {
      console.warn('Please, redefine `accountsFrom` computed')
      return this.accounts
    },

    accountToMessages() {
      return []
    },

    accountToOvercharge() {
      return 0
    },

    activeComment() {
      return this.instance && this.instance.status === STATUS_DECLINED && this.instance.comments_active.length && this.instance.comments_active[0]
    },

    canEdit() {
      return !this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.instance.status)
    },

    formAlerts() {
      return []
    },

    formDataErrors() {
      return {
        sum: {
          maxValue: `Недостаточно средств для пополнения`
        }
      }
    },

    formDataLimitations() {
      return {
        comment: {
          maxLength: 255,
        },
        sum: {
          maxValue: this.formData.status === STATUS_SENT && typeof this.formData.sum === 'number' && this.balance.from.data
            ? this.balance.from.data.amount
            : Infinity
        }
      }
    },

    currency() {
      const numericCode = this.accountTo && this.accountTo.currency_id
      return this.getCurrencyByNumericCode(numericCode)
    },

    currencyRatioRequired() {
      return this.accountFromPostfix && this.accountToPostfix && this.accountFromPostfix !== this.accountToPostfix
    },

    hasDeclinedComments() {
      if (!this.application) {
        return false
      }

      return this.instance.comments_active.length > 1 || this.instance.comments_active.length === 1 && this.instance.status === STATUS_DECLINED
    },

    showCommentField() {
      if (this.$route.name === 'requests.applications.detail' && !this.formData.comment) {
        return false
      } else {
        return Boolean(!this.instance || [STATUS_DRAFT, STATUS_DECLINED].includes(this.formData.status) || this.formData.comment)
      }
    },
  },

  async mounted() {
    await Promise.all([
      this.updateBalances(),
    ])
  },

  methods: {
    ...mapActions('requests', {
      fetchRequestsSummary: 'fetchSummary'
    }),

    ...mapMutations('requests/private/applications', {
      'setApplication': DETAIL_SET
    }),

    async beforeSubmit() {
      await this.updateBalances()
    },

    getAccountItem(account) {
      const currency = this.getCurrencyByNumericCode(account.currency_id)
      const balance = measurement(account.balance, currency && (currency.symbol || currency.alphabetic_code))

      return {
        text: `${account.name} (${balance})`,
        value: account.id,
      }
    },

    gotoBack() {
      let backref;
      if (typeof this.$route.meta.backref === 'function') {
        backref = this.$route.meta.backref(this.$route)
      } else {
        backref = this.$route.meta.backref
      }

      this.$router.push(backref)
    },

    formDataCopy(movement) {
      this.formData = {
        ...this.formData,
        account_from: movement.account_from_id,
        account_to: movement.account_to_id,
        comment: movement.comment,
        currency_ratio: movement.currency_ratio,
        status: STATUS_DRAFT,
        sum: movement.sum,
      }
    },

    messageOnSaved(instance) {
      if ([STATUS_ACCEPTED, STATUS_SENT].includes(instance.status)) {
        this.$notifications.show('Документ успешно отправлен')
      } else {
        this.$notifications.show('Изменения сохранены')
      }
    },

    save(event) {
      this.formData.status = STATUS_DRAFT
      this.$nextTick(() => this.submit(event))
    },

    send(event) {
      this.formData.status = STATUS_SENT
      this.$nextTick(() => this.submit(event))
    },

    setStoreInstance() {
      console.warn('Please, define `setStoreInstance` method')
    },

    updateBalances() {
      const promises = [
        this.formData.account_from && this.balance.from.call({id: this.formData.account_from}),
        this.formData.account_to && this.balance.to.call({id: this.formData.account_to}),
      ].filter(Boolean)

      return Promise.all(promises).catch(error => {
          this.snackbar.color = 'error'
          this.snackbar.show = true
          this.snackbar.text = 'Не удалось проверить баланс счета'
          throw error
        })
    },
  },

  validations() {
    return {
      formData: {
        account_from: {
          required
        },
        account_to: {
          required
        },
        comment: {
          maxLength: maxLength(this.formDataLimitations.comment.maxLength),
        },
        currency_ratio: {
          requiredIf: requiredIf(() => this.currencyRatioRequired)
        },
        sum: {
          maxValue: maxValue(this.formDataLimitations.sum.maxValue),
          minValue: minValue(0.01),
          decimal,
          required
        },
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .the-requests-cash-movements-form {
    margin: auto;
    width: 860px;
  }
}

.the-requests-cash-movements-form {
  position: relative;

  &__copier-loading {
    position: absolute;
  }
}

</style>