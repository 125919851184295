<template>
  <v-container class="request-matching-archives-documents pa-0" fluid>
    <p class="ma-0 mb-n4 pl-0 grey--text lighten-3">Запрашиваемые документы:</p>

    <template v-for="group in groupedByType">
      <p
        :key="`group__${group.type}`"
        class="ma-0 mt-4 pl-0"
        v-text="group.type"
      ></p>

      <ul
        :key="`documents__${group.type}`"
        class="request-matching-archives-documents__list mb-4 text-body"
      >
        <li v-for="document in group.documents" :key="document.name">
          {{ document.name }}
        </li>
      </ul>
    </template>
  </v-container>
</template>

<script>
export default {
  props: {
    instance: Object,
  },

  computed: {
    types() {
      return Array.from(new Set(this.instance.documents.map(document => document.type)))
    },

    groupedByType() {
      return this.types.map(group => ({
        documents: this.instance.documents.filter(document => document.type === group),
        type: group,
      }))
    }
  },
}
</script>

<style lang="scss">
.request-matching-archives-documents {
  &__list > li {
    list-style-type: decimal;
  }
}
</style>