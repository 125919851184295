<template>
  <loadable-content
    :error="error"
    :loading="loading"
  >
    <v-list
      expand
      flat
      width="100%"
    >
      <v-list-group
        v-for="group in $_groupsVisible"
        :key="group.key"
        :prepend-icon="group.icon"
        :value="group.expanded"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-group class="flex-fill">
            <v-list-item-title>
              {{ group.title }}
              <span class="grey--text lighten-1">[{{ group.items.length }}]</span>
            </v-list-item-title>

            <v-list-item-subtitle v-if="group.subtitle" class="caption">
              {{ group.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-group>
        </template>

        <component
          v-for="item in group.items"
          :is="item.component || $data.$_defaultListItem"
          :key="item.account.uid"
          :account="item.account"
        ></component>

        <v-divider></v-divider>
      </v-list-group>
    </v-list>
  </loadable-content>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import MoneyAccountsBaseListItem from '@/components/money/accounts/base/MoneyAccountsBaseListItem'
import LoadableContent from '@/components/common/LoadableContent'

export default {
  components: {
    LoadableContent
  },

  data() {
    return {
      $_defaultListItem: MoneyAccountsBaseListItem,
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),
    ...mapState('money/accounts', {
      error: 'listError',
      items: 'list',
      loading: 'listLoading',
    }),

    $_groupsVisible() {
      return this.groups.filter(group => group.visible && group.items.length)
    },

    groups() {
      // return list[GroupItem]
      // GroupItem
      //     accounts: this.items.filter(item => item.is_private && item.is_account),
      //     childComponent: VListItem or VListGroup
      //     expanded: true,
      //     icon: 'mdi-credit-card',
      //     key: 'non-cash',
      //     title: 'Безналичные счета',
      //     visible: bool,
      //   },

      return []
    },
  },
}
</script>