import {LIST_ERROR_SET, LIST_LOADING_SET, LIST_SET} from '@/store/_prototypes/loadable/list/mutations_types'
import {ApiData} from '@/assets/js/api/_helpers'


export default ({list = null }) => {
    const instancesList = new ApiData(list, [], { debounce: 500 })

    return {
        clear({commit}) {
            instancesList.cancel()
            commit(LIST_LOADING_SET, false)
            commit(LIST_ERROR_SET, null)
            commit(LIST_SET, [])
        },

        fetch({commit}, args) {
            commit(LIST_LOADING_SET, true)
            commit(LIST_ERROR_SET, null)

            return instancesList.call(args)
                .then(() => commit(LIST_SET, instancesList.data))
                .catch(() => commit(LIST_ERROR_SET, instancesList.error))
                .finally(() => commit(LIST_LOADING_SET, instancesList.loading))
        }
    }
}
