<script>
import MovementsPrivateListItem from '@/components/requests/private/requests/movements/list/MovementsPrivateListItem'

export default {
  extends: MovementsPrivateListItem,

  data() {
    return {
      deleter: undefined,
      menu: null,
    }
  },

  computed: {
    movementDetailView() {
      return {
        name: 'accounts.private.details.controls.movements.detail',
        params: {
          accountId: this.movement.account_to_id, instanceId: this.movement.id
        }
      }
    }
  }
}
</script>
