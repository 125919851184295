import Vue from 'vue'

import NotificationBase from '@/components/common/notifications/NotificationBase'
import {httpStatusGetError} from '@/assets/js/http'


const Notifications = {
    install(Vue) {
        Vue.prototype.$notifications = Vue.observable({
            active: {
                component: NotificationBase
            },
            visible: false,

            close() {
                this.visible = false
            },

            open({bind, component, on}) {
                this.active = {
                    bind,
                    component,
                    on,
                }
                this.visible = true
            },

            show(text = 'I Wanna Be the Boshy', type='success') {
                this.open({
                    bind: {
                        text,
                        type,
                    },
                    component: NotificationBase,
                    on: {
                        input: () => {
                            this.close()
                        }
                    },
                })
            },

            showHttpError(error) {
                this.show(httpStatusGetError(error), 'error')
            }
        })
    }
}


export default Notifications;

Vue.use(Notifications)
