<template v-else>
  <v-row class="ma-0 flex-fill flex-column flex-sm-row justify-space-between">
    <v-btn
      key="cancel"
      :block="$vuetify.breakpoint.xsOnly"
      class="my-2 my-sm-0 white--text"
      color="red"
      large
      outlined
      v-on:click="$emit('decline')"
    >
      <v-icon left>mdi-close</v-icon>
      Отменить выдачу
    </v-btn>

    <v-btn
      key="confirm"
      :block="$vuetify.breakpoint.xsOnly"
      class="my-4 my-sm-0 white--text"
      color="deep-orange"
      width="196px"
      large
      v-on:click="$emit('confirm')"
    >
      <v-icon
        color="white"
        left
      >mdi-arrow-up-bold</v-icon>
      {{ instance.is_direct ? 'Выдать' : 'Отправить' }}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    instance: Object,
  },
}
</script>