<template>
  <v-form
    :value="value"
    class="content-form overflow-x-hidden"
    ref="form"
  >
    <v-overlay
      :value="isCoping"
      class="the-application-form__copier-loading"
      color="white"
      opacity="0.25"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <slot name="body"></slot>

    <v-divider v-if="!noControlsDivider"></v-divider>

    <v-row
      v-if="isCreating || isUpdating"
      class="content-form__is-processing mx-0 mt-4 mb-0 py-3 flex-nowrap"
    >
        <v-spacer></v-spacer>
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-spacer></v-spacer>
    </v-row>

    <template v-else>
      <slot name="controls"></slot>
    </template>

  </v-form>
</template>

<script>
export default {
  props: {
    isCoping: Boolean,
    isCreating: Boolean,
    isUpdating: Boolean,
    noControlsDivider: Boolean,
    readonly: Boolean,
    snackbar: {
      type: [Object, null],
      default: () => ({
        color: 'success',
        show: false,
        text: 'No text',
      })
    },
    value: Boolean,
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .content-form {
    margin: auto;
    width: 860px;
    max-width: 100%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .content-form {
    width: 100%;
  }
}

.content-form {
  position: relative;

  &__copier-loading {
    position: absolute;
  }

  &__is-processing {
    height: 68px;
  }
}

</style>
