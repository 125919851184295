import TheJournalsReport from '@/views/journals/reports/TheJournalsReport'
import {PERMISSION_ACCOUNTS_PRIVATE_VIEW} from '@/assets/js/api/users'

export default [
    {
        path: 'reports',
        component: TheJournalsReport,
        name: 'journals.reports',
        meta: {
            title: 'Общий отчет',
        },
        auth: {
            roles: PERMISSION_ACCOUNTS_PRIVATE_VIEW,
            forbiddenRedirect: {
                name: 'requests.matching.archives_and_money_give_outs'
            },
            redirect: {
                name: 'auth.login'
            }
        }
    }
]
