import issues from './issues'
import requests from './requests'

export default {
    namespaced: true,

    modules: {
        issues,
        requests,
    }
}