<template>
  <v-container class="pa-0" fluid>
    <portal to="the-app-bar__btn-prepend">
      <v-btn
        height="100%"
        dark
        depressed
        exact
        text
        tile
        v-on:click="refresh"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </portal>

    <list-infinity :source="source">
      <template v-slot:item="{ item }">
        <v-list-item :to="{name: 'journals.applications.detail', params: {instanceId: item.id}}">
          <v-list-item-avatar>
            <v-icon size="32">mdi-cash-plus</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="application-list-item__content px-3 text-no-wrap flex-column align-stretch">
            <v-row class="ma-0 flex-nowrap">
              <v-col class="pa-0 text-truncate overflow-hidden">
                {{ item.object ? item.object.name : 'Неизвестный объект' }}&nbsp;
              </v-col>

              <v-col class="pa-0 text-right flex-shrink-0 flex-grow-0">
                 {{ item.sum | measurement(getCurrencyCode(getCurrency(item))) }}
               </v-col>
            </v-row>

            <v-row
              :class="$vuetify.breakpoint.mobile ? 'caption' : 'text-body-2'"
              class="ma-0 grey--text text--darken-1 flex-nowrap overflow-hidden"
            >
              <div class="pa-0 text-truncate flex-fill">{{ item.purpose }}</div>

              <div class="pa-0 text-truncate">
                {{ item.created_at|date}}
              </div>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </list-infinity>
  </v-container>
</template>

<script>
import ListInfinity from '@/components/common/content/list/ListInfinity'
import {ApiPaginatedAccumulatedData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import {mapGetters} from 'vuex'
import {STATUS_ACCEPTED} from '@/assets/js/api/requests/private/applications'

export default {
  components: {
    ListInfinity
  },

  data() {
    return {
      source: new ApiPaginatedAccumulatedData(api.requests.private.applications.list, [], {
        params: {
          status: [STATUS_ACCEPTED],
        }
      })
    }
  },

  computed: {
    ...mapGetters('money/currencies', [
      'getCurrencyByNumericCode',
    ]),
  },

  methods: {
    getCurrency(application) {
      return this.getCurrencyByNumericCode(application.currency_id)
    },

    getCurrencyCode(currency) {
      return currency && (currency.symbol || currency.alphabetic_code)
    },

    refresh() {
      this.source.call()
    }
  }
}
</script>