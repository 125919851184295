<template>
  <!--  span wrapper to fix bug on updating vue missed order of v-menu items-->
  <span class="fill-height">
    <menu-desktop-dropdown v-if="item.hasChildren" :item="item"></menu-desktop-dropdown>

    <v-badge
      v-else
      :content="item.badge"
      :value="item.badge"
      class="fill-height"
      color="red"
      offset-x="22"
      offset-y="27"
      overlap
    >
      <v-btn
        color="transparent"
        height="100%"
        dark
        depressed
        exact
        tile
        :to="item.to"
      >
        {{ item.text }}
      </v-btn>
    </v-badge>
  </span>
</template>


<script>
import {MenuItem} from "@/assets/js/menu";
import MenuDesktopDropdown from "@/components/navigation/desktop/MenuDesktopDropdown.vue";

export default {
  components: {MenuDesktopDropdown},
  props: {
    item: MenuItem
  }
}
</script>
