<template>
  <error-card
    v-if="report.error"
    :error="report.error"
  ></error-card>

  <v-container v-else class="the-accounts-private-report-view pa-0 justify-start" fluid>
    <dialog-table-settings
      v-model="modalTableSettings"
      :columns="headers"
    ></dialog-table-settings>

    <dialog-filters
      :value="modalFilters && !$vuetify.breakpoint.mdAndUp"
      :filters="filters"
      v-on:input="modalFilters = $event"
    ></dialog-filters>

    <v-row class="ma-0 px-4 py-4 py-md-8">
      <v-col class="pa-0 flex-grow-0 d-flex flex-nowrap align-center">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-date-field
            v-model="filters.created_at__gte"
            class-field="the-accounts-private-report-view__filters-date-field"
            label="дата с"
            clearable
            dense
            hide-details
          ></v-date-field>

          <span class="px-2">—</span>

          <v-date-field
            v-model="filters.created_at__lte"
            class-field="the-accounts-private-report-view__filters-date-field"
            label="дата по"
            clearable
            dense
            hide-details
          ></v-date-field>
        </template>

        <v-btn
          v-else
          class="mx-2"
          color="grey darken-1"
          dark
          depressed
          exact
          outlined
          v-on:click="modalFilters = true"
        >
          <v-icon>
            mdi-filter-menu
          </v-icon>
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>

      <v-col class="pa-0 d-flex flex-grow-0">
        <v-btn
          class="mx-2"
          color="grey darken-1"
          dark
          depressed
          exact
          outlined
          v-on:click="modalTableSettings = true"
        >
          <v-icon>
            mdi-table-cog
          </v-icon>
        </v-btn>

        <v-btn
          class="ml-2"
          color="grey darken-1"
          dark
          depressed
          exact
          outlined
          v-on:click="load"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <template v-if="!report.loading && !report.error">
      <v-row class="the-accounts-private-report-view__total ma-0 my-4 flex-wrap flex-column flex-md-row flex-md-nowrap justify-space-around align-md-center">
        <p class="text-h5 ma-0 mx-4">Итого:</p>

        <v-text-field
          v-for="subtotal in total"
          :key="subtotal.key"
          :prepend-inner-icon="subtotal.icon"
          :label="subtotal.label"
          :value="subtotal.value"
          class="flex-fill flex-md-grow-0 ma-4"
          color="black"
          dense
          hide-details
          flat
          outlined
          readonly
        ></v-text-field>
      </v-row>

      <v-divider></v-divider>
    </template>

    <v-data-table
      :class="{'mb-15': $vuetify.breakpoint.smAndDown}"
      :loading="report.loading"
      :headers="headersVisible"
      :item-class="getItemClass"
      :items="items"
      disable-pagination
      hide-default-footer
    >
      <template v-if="$vuetify.breakpoint.width < 600" v-slot:item="{ item, headers }">
        <tr :class="['v-data-table__mobile-table-row', getItemClass(item)]">
          <td
            v-for="column in getMobileHeadersForItem(item, headers)"
            :key="column.value"
            :class="['v-data-table__mobile-row']"
          >
            <div class="v-data-table__mobile-row__header">{{ column.text }}</div>
            <div class="v-data-table__mobile-row__cell">
              <journals-report-field-status
                v-if="column.value === 'status'"
                :item="item"
              ></journals-report-field-status>

              <template v-else-if="column.value === 'document'">
                {{ getDocumentName(item.document) }}
              </template>

              <template v-else>
                {{ item[column.value] }}
              </template>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:item.document="{ item }">
        {{ getDocumentName(item.document) }}
      </template>

      <template v-slot:item.icon="{ item }">
        <v-icon
          v-bind="getItemIconAttrs(item)"
          v-text="getItemIcon(item)"
        ></v-icon>
      </template>

      <template v-slot:item.status="{ item }">
        <journals-report-field-status :item="item"></journals-report-field-status>
      </template>
    </v-data-table>

    <v-btn
      v-if="$vuetify.breakpoint.smAndDown && items.length"
      class="mb-15"
      color="white"
      bottom
      fixed
      right
      v-on:click="headersSetVisibility(headersPresetMobileIsActive ? null : headersPresetMobile)"
    >
      {{ headersPresetMobileIsActive ? 'Подробнее' : 'Компактный' }}
    </v-btn>

  </v-container>
</template>

<script>
import api from '@/assets/js/api'
import {ApiData} from '@/assets/js/api/_helpers'
import VDateField from '@/components/inputs/VDateField'
import { DATE_FORMAT_ISO } from '@/filters/datetime'
import DialogTableSettings from '@/components/requests/private/report/DialogTableSettings'
import {measurement} from '@/filters/numbers'
import {mapGetters} from 'vuex'
import DialogFilters from '@/components/requests/private/report/DialogFilters'
import ErrorCard from '@/components/common/ErrorCard'
import { getHumanReadableStatus as getApplicationHumanReadableStatus } from '@/assets/js/api/requests/private/applications'
import { getHumanReadableStatus as getPurchaseHumanReadableStatus } from '@/assets/js/api/requests/private/purchases'
import JournalsReportFieldStatus from '@/components/journals/report/fields/JournalsReportFieldStatus'


export default {
  components: {JournalsReportFieldStatus, ErrorCard, DialogFilters, DialogTableSettings, VDateField},
  props: {
    accountId: Number,
  },

  data() {
    return {
      headers: [
        {
          cellClass: 'text-center pr-0',
          persist: true,
          show: true,
          sortable: false,
          value: 'icon',
          width: 0,
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Дата',
          show: true,
          sortable: false,
          value: 'created_at',
          width: 0,
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Документ',
          show: true,
          sortable: false,
          value: 'document',
          width: 150,
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Счет',
          show: true,
          sortable: false,
          value: 'account',
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Статус',
          show: true,
          sortable: false,
          value: 'status',
          width: 0,
        },
        {
          text: 'Объект',
          show: true,
          sortable: false,
          value: 'object',
        },
        {
          text: 'Комментарий',
          show: true,
          sortable: false,
          value: 'comment',
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Приход',
          show: true,
          sortable: false,
          value: 'inflow',
          width: 0,
        },
        {
          cellClass: 'text-no-wrap',
          text: 'Расход',
          show: true,
          sortable: false,
          value: 'outflow',
          width: 0,
        },
      ],
      headersPresetMobile: ['created_at', 'comment', 'inflow', 'outflow'],
      filters: {
        account: this.accountId,
        created_at__gte: this.$moment().subtract(30, 'days').format(DATE_FORMAT_ISO),
        created_at__lte: this.$moment().format(DATE_FORMAT_ISO),
      },
      modalFilters: false,
      modalTableSettings: false,
      report: new ApiData(api.requests.private.report.list, [])
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById'
    ]),

    ...mapGetters('money/currencies', [
      'getCurrencyPostfixByNumericCode'
    ]),

    account() {
      return this.getAccountById(this.accountId)
    },

    accountCurrencyPostfix() {
      return this.account ? this.getCurrencyPostfixByNumericCode(this.account.currency_id) : ''
    },

    headersPresetMobileIsActive() {
      const [a, b] = [this.headers.filter(el => el.show).map(el => el.value), this.headersPresetMobile]
      return a.length === b.length && [...a].every(value => b.includes(value));
    },

    headersVisible() {
      return this.headers.filter(column => column.show).filter(column => this.$vuetify.breakpoint.mdAndUp || column.value !== 'icon')
    },

    items() {
      return this.report.data.map(row => ({
        account: row.account ? row.account.name : '',
        closed_at: row.closed_at && this.$moment(row.closed_at).format('DD/MM/YYYY'),
        created_at: this.$moment(row.created_at).format('DD/MM/YYYY'),
        document: row.document,  // used at table slots
        status: row,
        object: row.object && row.object.name,
        comment: row.comment,
        inflow: row.inflow ? measurement(row.inflow, this.accountCurrencyPostfix) : '',
        outflow: row.outflow ? measurement(row.outflow, this.accountCurrencyPostfix) : '',
      }))
    },

    totalBalance() {
      return this.totalInflow - this.totalOutflow
    },

    totalInflow() {
      return this.report.data.reduce((acc, reportRow) => acc + reportRow.inflow, 0)
    },

    totalOutflow() {
      return this.report.data.reduce((acc, reportRow) => acc + reportRow.outflow, 0)
    },

    total() {
      return [
        {
          key: 'inflow',
          icon: 'mdi-plus',
          label: 'Приход',
          value: measurement(this.totalInflow, this.accountCurrencyPostfix)
        },
        {
          key: 'outflow',
          icon: 'mdi-minus',
          label: 'Расход',
          value: measurement(this.totalOutflow, this.accountCurrencyPostfix),
        },
        {
          key: 'balance',
          icon: 'mdi-approximately-equal',
          label: 'Баланс',
          value: measurement(this.totalBalance, this.accountCurrencyPostfix),
        },
      ]
    }
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.load()
      }
    },

    '$vuetify.breakpoint.smAndDown'(value, valuePrev) {
      if (value && !valuePrev) {
        this.headersSetVisibility(this.headersPresetMobile)
      } else if (!value && valuePrev) {
        this.headersSetVisibility()
      }
    }
  },

  mounted() {
    this.load()
    this.headersSetVisibility(this.$vuetify.breakpoint.smAndDown ? this.headersPresetMobile : null)
  },

  methods: {
    getDocumentName(document) {
      switch (document) {
        case 'purchase':
          return 'Покупка'
        case 'application':
          return 'Заказ ДС'
        default:
          return 'Неизвестный документ'
      }
    },

    getApplicationHumanReadableStatus: getApplicationHumanReadableStatus,
    getPurchaseHumanReadableStatus: getPurchaseHumanReadableStatus,

    getItemClass(item) {
      switch (item.document) {
        case 'purchase':
          return 'the-accounts-private-report-view__table-row-purchase hide-column-3'
        case 'application':
          return 'the-accounts-private-report-view__table-row-application hide-column-4'
        default:
          return null
      }
    },

    getItemIcon(item) {
      switch (item.document) {
        case 'purchase':
          return 'mdi-minus'
        case 'application':
          return 'mdi-plus'
        default: {
          return null
        }
      }
    },

    getItemIconAttrs(item) {
      switch (item.document) {
        case 'purchase':
          return {
            color: 'deep-orange darken-3',
          }
        case 'application':
          return {
            color: 'green darken-2',
          }
        default: {
          return null
        }
      }
    },

    getMobileHeadersForItem(item, headers) {
      const exclude = item.document === 'purchase' ? 'inflow' : 'outflow'
      return headers.filter(header => header.value !== exclude)
    },

    headersSetVisibility(columns = null) {
      if (columns) {
        this.headers.filter(head => head.show = columns.includes(head.value))
      } else {
        this.headers.filter(head => head.show = true)
      }
    },

    load() {
      this.report.call({params: this.filters})
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_colors';

.the-accounts-private-report-view {
  &__filters-date-field {
    max-width: 160px;
    width: 160px;
  }

  &__table-row {
    &-application > td {
      background-color: map-deep-get($green, 'lighten-5');
    }

    &-purchase > td {
      background-color: map-deep-get($deep-orange, 'lighten-5');
    }
  }
}
</style>
