<template>
  <v-list-item
    v-ripple="false"
    :to="movementDetailView"
    class="movement-control-list-item white"
  >
    <v-list-item-avatar>
      <v-icon
        color="grey"
        size="32"
        v-html="avatarIcon"
      ></v-icon>
    </v-list-item-avatar>

    <v-list-item-content class="px-3 text-no-wrap flex-column align-start overflow-hidden">
      <v-list-item-title class="text-truncate align-self-start">
         {{ item.sum|measurement(item.currency_string) }}
       </v-list-item-title>

      <v-list-item-subtitle class="text-truncate d-flex align-center caption">
        <span>{{ accountFrom && accountFrom.name }}</span>
        <v-icon size="16">mdi-chevron-right</v-icon>
        <span>{{ accountTo && accountTo.name }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    item: Object,
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById',
    ]),

    accountFrom() {
      return this.getAccountById(this.item.account_from_id)
    },

    accountTo() {
      return this.getAccountById(this.item.account_to_id)
    },

    avatarIcon() {
      if (this.item.sender) {
        return 'mdi-check'
      } else {
        return 'mdi-timer-sand'
      }
    },

    movementDetailView() {
      return {
        name: 'accounts.controls.details.requests.movements.detail',
        params: {
          accountId: this.item.account_from_id,
          instanceId: this.item.id,
        }
      }
    }
  },
}
</script>

<style lang="scss">
.movement-control-list-item {
  overflow: hidden;
}
</style>