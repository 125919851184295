<template>
  <request-matching-remote-list-item
    :canceled="isCanceled"
    :completed="isCompleted"
    :has-new-message="hasNewComments(item)"
    :is-new="isNew(item)"
    :to="{ name: 'requests.matching.money.give_outs.form', params: {instanceId: item.number} }"
  >
    <template v-slot:avatar>
      <v-icon>mdi-cash-multiple</v-icon>
    </template>

    <v-row class="ma-0 flex-nowrap overflow-hidden">
      <v-col class="pa-0 mr-4 flex-shrink-0 flex-grow-0 text-no-wrap text-body-1">{{ item.amount | measurement(item.currency_string) }}</v-col>

      <v-col class="pa-0 flex-grow-1 text-right text-no-wrap text-truncate">{{ item.created_by || 'Сотрудник отсутсвует' }}</v-col>
    </v-row>

    <v-row class="ma-0 flex-nowrap overflow-hidden">
      <v-col
        :class="$vuetify.breakpoint.mobile ? 'caption text--darken-1' : 'text-body-2 font-weight-light text--darken-3'"
        class="pa-0 mr-4 flex-grow-1 text-no-wrap text-truncate grey--text"
      >{{ item.object_string }}</v-col>

      <v-col class="pa-0 flex-shrink-0 flex-grow-0 text-no-wrap caption grey--text text--darken-1">{{ item.created_at | moment('DD.MM.YYYY') }}</v-col>
    </v-row>
  </request-matching-remote-list-item>
</template>

<script>
import {mapGetters} from 'vuex'
import RequestMatchingRemoteListItem from '@/components/requests/base/matching/RequestMatchingRemoteListItem'
import {STATUS_CANCELED, STATUS_COMPLETED} from '@/assets/js/api/requests/matching/money/give_outs'

export default {
  components: {RequestMatchingRemoteListItem},
  props: {
    item: Object,
  },

  data() {
    return {
      maxLinesToShow: 3,
    }
  },

  computed: {
    ...mapGetters('requests/matching/money/give_outs', [
      'hasNewComments',
      'isNew',
    ]),

    isCanceled() {
      return this.item.status === STATUS_CANCELED
    },

    isCompleted() {
      return this.item.status === STATUS_COMPLETED
    }
  },
}
</script>
