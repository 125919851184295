<template>
  <v-container class="pa-0 fill-height align-center justify-center" fluid>
    <v-sheet class="ma-auto text-center">
      <template v-if="isLoading">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>

        <p class="caption blue--text text--darken-2 mt-2">Авторизация...</p>
      </template>

      <error-card
        v-else-if="error"
        :error="error"
      ></error-card>

      <v-icon
        v-else-if="$auth.ready()"
        color="green"
        size="96px"
      >mdi-check-circle</v-icon>

      <error-card
        v-else
        error="Ошибка авторизации"
      ></error-card>

      <v-row
        v-if="redirect.path"
        class="caption align-center justify-center mt-4"
      >
        <p class="ma-0 mr-2">
          Сейчас Вы будете перенаправлены
          <a
            href="javascript: void(0);"
            v-on:click="redirectToForce"
          >сюда</a>
        </p>

        <v-progress-circular
          v-if="redirect.interval"
          color="grey darken-1"
          size="14"
          :value="100 / redirect.timerMax * redirect.timer"
        ></v-progress-circular>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import ErrorCard from '@/components/common/ErrorCard'

export default {
  components: {ErrorCard},

  data() {
    return {
      isLoading: false,
      error: '',
      redirect: {
        interval: null,
        path: null,
        timer: 0,
        timerMax: 5000,
        timerStep: 200,
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.redirect.interval)
    next()
  },

  mounted() {
    if (this.$auth.check()) {
      this.$router.push({name: 'accounts.private'})
    } else if (['google', 'yandex'].includes(this.$route.params.oauthSystem)) {
      this.login()
    } else {
      this.$router.push({name: 'auth.login'})
    }
  },

  methods: {
    login() {
      this.isLoading = true
      this.error = ''

      this.$auth.login({
        method: 'POST',
        url: `/api/auth/${this.$route.params.oauthSystem}`,
        params: this.$route.query,
        redirect: false,
      })
        .then(() => {
          this.redirectTo({name: 'accounts.private'}, 1000)
        })
        .catch(error => {
          console.error(error)
          this.$auth.logout({redirect: null})
          this.error = 'Ошибка авторизации'
          this.redirectTo({name: 'auth.login'}, 5000)
        })
      .finally(() => {
        this.isLoading = false
      })
    },

    redirectToForce() {
      this.$router.push(this.redirect.path)
    },

    redirectTo(path, timer) {
      this.redirect.path = path
      this.redirect.timer = 0
      this.redirect.timerMax = timer
      this.redirect.interval = setInterval(this.redirectTimer, this.redirect.timerStep)
    },

    redirectTimer() {
      this.redirect.timer += this.redirect.timerStep
      if (this.redirect.timerMax < this.redirect.timer) {
        this.redirectToForce()
      }
    },
  },
}
</script>
