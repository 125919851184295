<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0">
      <v-col
        :class="!instance.created_by && ['grey--text', 'text--lighten-1']"
        class="overflow-hidden pa-0 text-h6 text-md-h5 text-no-wrap text-truncate align-self-end"
      >
        {{ instance.created_by || 'Сотрудник отсутсвует'  }}
      </v-col>
    </v-row>

    <v-divider></v-divider>
  </v-container>
</template>

<script>
export default {
  props: {
    instance: Object
  }
}
</script>