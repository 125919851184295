<template>
  <v-sheet class="pa-0" min-width="100%">
    <request-matching-warning-new-comment
      v-if="hasNewComments(instance)"
      v-on:help="acceptDialog"
    ></request-matching-warning-new-comment>

    <request-matching-template
      :canResult="!$auth.impersonating() && !instance.is_processed"
      :isLoading="isLoading"
      v-on:accept="acceptDialog"
      v-on:reject="rejectDialog"
    >
      <template slot="primary">
        <request-matching-archives-header :instance="instance"></request-matching-archives-header>
        <request-matching-data-primary :rows="dataPrimary"></request-matching-data-primary>

        <template v-if="instance.documents.length > 0">
          <v-divider class="my-4"></v-divider>
          <request-matching-archives-documents :instance="instance"></request-matching-archives-documents>
        </template>
      </template>

      <template slot="secondary">
        <request-matching-archives-data-secondary
          :commentCreator="commentCreator"
          :instance="instance"
          v-on:comment="commentCreate"
        ></request-matching-archives-data-secondary>

        <request-matching-history
          :error="apiHistory.error"
          :history="apiHistory.data"
          :is-loading="apiHistory.loading"
          class="py-6 py-md-3"
        ></request-matching-history>
      </template>
    </request-matching-template>
  </v-sheet>
</template>

<script>
import Form from '@/mixins/Form'
import RequestMatchingTemplate from '@/components/requests/base/matching/RequestMatchingTemplate'
import RequestMatchingArchivesHeader from '@/components/requests/matching/archives/requests/form/RequestMatchingArchivesHeader'
import RequestMatchingDataPrimary from '@/components/requests/base/matching/RequestMatchingDataPrimary'
import RequestMatchingArchivesDocuments from '@/components/requests/matching/archives/requests/form/RequestMatchingArchivesDocuments'
import RequestMatchingArchivesDataSecondary
  from '@/components/requests/matching/archives/requests/form/RequestMatchingArchivesDataSecondary'
import {mapGetters, mapMutations} from 'vuex'
import api from '@/assets/js/api'
import {
  REQUEST_ADD_COMMENT,
  REQUEST_MARK_VIEWED, REQUEST_SET_COMPLETED,
  REQUEST_SET_PROCESSED
} from '@/store/requests/matching/_prototype/mutations_types'
import {ApiData} from '@/assets/js/api/_helpers'
import RequestMatchingWarningNewComment from '@/components/requests/base/matching/RequestMatchingWarningNewComment'
import RequestMatchingHistory from '@/components/requests/base/matching/RequestMatchingHistory'
import {STATUS_ACTIVE, STATUS_CANCELED, STATUS_COMPLETED} from '@/assets/js/api/requests/matching/archives/requests'

export default {
  components: {
    RequestMatchingHistory,
    RequestMatchingArchivesDataSecondary,
    RequestMatchingWarningNewComment,
    RequestMatchingArchivesDocuments,
    RequestMatchingDataPrimary,
    RequestMatchingArchivesHeader,
    RequestMatchingTemplate
  },
  mixins: [Form],

  data() {
    return {
      apiHistory: new ApiData(api.requests.matching.archives.requests.history, []),
      apiResult: new ApiData(api.requests.matching.archives.requests.result),
      commentCreator: new ApiData(api.requests.matching.archives.requests.comments.create)
    }
  },

  computed: {
    ...mapGetters('requests/matching/archives/requests', [
      'hasNewComments'
    ]),

    dataPrimary() {
      return [
        { key: 'status', label: 'Статус', value: this.statusDescription },
        { key: 'number', label: 'Номер заявки', value: this.instance.number },
        { key: 'description', label: 'Назначение', value: this.instance.description },
      ]
    },

    isLoading() {
      return this.apiResult.loading || this.commentCreator.loading
    },

    statusDescription() {
      switch (this.instance.status) {
        case STATUS_CANCELED:
          return 'Отклонена'
        case STATUS_ACTIVE:
          if (!this.instance.is_processed) {
            return this.instance.is_repeating ? 'требуется ответ на замечание' : 'на рассмотрении'
          } else if (this.instance.can_create_main_resolution) {
            return 'ожидание ответа на замечание'
          } else {
            return 'на согласовании у управляющего'
          }
        case STATUS_COMPLETED:
          return 'Согласована'
        default:
          return 'Неизвестный статус'
      }
    }
  },

  mounted() {
    if (!this.$auth.impersonating()) {
      const [promise, ] = api.requests.matching.archives.requests.viewed(this.instance.number)
      promise.then(() => {
        this[REQUEST_MARK_VIEWED](this.instance)
      })
    }

    this.apiHistory.call({id: this.instance.number})
  },

  methods: {
    ...mapMutations('requests/matching/archives/requests', [
      REQUEST_ADD_COMMENT,
      REQUEST_MARK_VIEWED,
      REQUEST_SET_COMPLETED,
      REQUEST_SET_PROCESSED,
    ]),

    acceptDialog() {
      if (this.hasNewComments(this.instance)) {
        this.$modal.info(
          'Обработка заявки',
          'Для обработки заявки требуется ответить на комментарий управляющего в истории переписки рецензентов или отклонить заявку'
        )
      } else {
        this.$modal.confirm('Утвердить текущую заявку?', this.result.bind(null, true))
      }
    },

    commentCreate(comment) {
      this.commentCreator.call({id: this.instance.number, data: comment, params: {task_uid: this.instance.task.uid}})
        .then(() => {
          this[REQUEST_SET_PROCESSED](this.instance)
          this[REQUEST_ADD_COMMENT]([this.instance, {
            author: this.$auth.user().full_name,
            created_at: this.$moment(),
            ...comment,
          }])
          this.$router.push(this.$route.meta.backref)
          this.$notifications.show('Сообщение успешно отправлено')
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
    },

    rejectDialog() {
      this.$modal.confirm('Вы действительно хотите отклонить текущую заявку?', this.result.bind(null, false))
    },

    result(result) {
      return this.apiResult.call({id: this.instance.number, task: this.instance.task.uid, data: { result }})
        .then(() => {
          this[REQUEST_SET_PROCESSED](this.instance)
          if (this.instance.can_create_main_resolution) {
            this[REQUEST_SET_COMPLETED](this.instance)
          }
          this.$router.push(this.$route.meta.backref)
          this.$notifications.show(`Заявка успешно ${result ? 'согласована' : 'отклонена'}`)
        })
        .catch(error => {
          this.$notifications.showHttpError(error)
          throw error
        })
    }
  }
}
</script>
