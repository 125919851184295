import {ApiData} from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'

export const NOTIFICATIONS_DENIED_BY_THE_USER = 'NOTIFICATIONS_DENIED_BY_THE_USER'
export const NOTIFICATIONS_NOT_SUPPORTED = 'NOTIFICATIONS_NOT_SUPPORTED'
export const NOTIFICATIONS_PERMISSION_ERROR = 'NOTIFICATIONS_PERMISSION_ERROR'
export const NOTIFICATIONS_IMPOSSIBLE_TO_SUBSCRIBE = 'NOTIFICATIONS_IMPOSSIBLE_TO_SUBSCRIBE'
export const PUSH_MANAGER_NOT_SUPPORTED = 'PUSH_MANAGER_NOT_SUPPORTED'
export const SERVICE_WORKER_NOT_SUPPORTED = 'SERVICE_WORKER_NOT_SUPPORTED'
export const SERVICE_WORKER_REGISTRATION_NOT_FOUND = 'SERVICE_WORKER_REGISTRATION_NOT_FOUND'



export const PUSHES_ERRORS = {
    [NOTIFICATIONS_DENIED_BY_THE_USER]: 'Не получены права на подключение PUSH уведомлений',
    [NOTIFICATIONS_PERMISSION_ERROR]: 'Не получены права на подключение PUSH уведомлений',
    [NOTIFICATIONS_IMPOSSIBLE_TO_SUBSCRIBE]: 'Невозможно подключить PUSH уведомления',
    [NOTIFICATIONS_NOT_SUPPORTED]: 'Браузер не поддерживает отображение уведомлений',
    [PUSH_MANAGER_NOT_SUPPORTED]: 'Браузер не поддерживает получение уведомлений',
    [SERVICE_WORKER_NOT_SUPPORTED]: 'Браузер не поддерживает технологию Service Worker',
    [SERVICE_WORKER_REGISTRATION_NOT_FOUND]: 'Неудалось определить Service Worker',
}


class ApiSubscription {
    constructor(subscription) {
        this.id = null
        this.subscription = subscription
    }

    get apiData() {
        return {
            encoding: (PushManager.supportedContentEncodings || ['aesgcm'])[0],
            endpoint: this.subscription.endpoint,
            key: this.key,
            token: this.token,
        }
    }

    get key() {
        return this.keys.p256dh
    }

    get keys() {
        const obj = JSON.parse(JSON.stringify(this.subscription))
        return obj.keys
    }

    get token() {
        return this.keys.auth
    }

    unsubscribe() {
        return this.subscription.unsubscribe()
    }
}



const pushes = {
    install(Vue) {
        Vue.prototype.$pushes = Vue.observable({
            api: {
                create: new ApiData(api.users.subscriptions.webpush.create),
                delete: new ApiData(api.users.subscriptions.webpush.delete),
                list: new ApiData(api.users.subscriptions.webpush.list),
            },

            error: null,
            isUpdating: false,
            subscription: null,

            vapidKey: 'BLTbdMLBHlLPN5-iwqLsAmrReYAtPYALwOKcUc7A0jsRSo2JH-u24NHNC9brQ2fBSLycRVT8KBIQ88tRvrYmCmw',

            get active() {
              return !!this.subscription || this.isUpdating
            },

            get errorDescription() {
                return this.error ? PUSHES_ERRORS[this.error] : null
            },

            async init() {
                Vue.prototype.$pushes.isUpdating = true
                Vue.prototype.$pushes.checkNotificationSupported()
                    .then(async () => {
                        Vue.prototype.$pushes.subscription = await Vue.prototype.$pushes.subscriptionGetExists()
                    })
                    .finally(() => Vue.prototype.$pushes.isUpdating = false)
            },

            checkNotificationPermission() {
                return new Promise((fulfilled, reject) => {
                    if (Notification.permission === 'denied') {
                        const error = new Error('Push messages are blocked.')
                        error.code = NOTIFICATIONS_DENIED_BY_THE_USER
                        error.description = PUSHES_ERRORS[error.code]
                        return reject(error);
                    }
                    if (Notification.permission === 'granted') {
                        return fulfilled();
                    }
                    if (Notification.permission === 'default') {
                        return Notification.requestPermission().then(result => {
                            if (result !== 'granted') {
                                const error = new Error('Push messages are blocked.')
                                error.code = NOTIFICATIONS_PERMISSION_ERROR
                                error.description = PUSHES_ERRORS[error.code]
                                reject(error);
                            } else {
                                fulfilled();
                            }
                        });
                    }

                    const error = new Error('Unknown permission.')
                    error.code = NOTIFICATIONS_PERMISSION_ERROR
                    error.description = PUSHES_ERRORS[error.code]
                    return reject(error);
                });
            },

            checkNotificationSupported() {
                return new Promise((resolve, reject) => {
                if (!('serviceWorker' in navigator)) {
                    this.error = SERVICE_WORKER_NOT_SUPPORTED
                    reject(new Error('Service workers are not supported by this browser'));
                    return;
                }

                if (!('PushManager' in window)) {
                    this.error = PUSH_MANAGER_NOT_SUPPORTED
                    reject(new Error('Push notifications are not supported by this browser'));
                    return;
                }

                if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
                    this.error = NOTIFICATIONS_NOT_SUPPORTED
                    reject(new Error('Notifications are not supported by this browser'));
                    return;
                }

                resolve();
              })
            },

            subscriptionCreate() {
                this.isUpdating = true
                return new Promise((resolve, reject) => {
                    this.checkNotificationPermission()
                        .then(async () => {
                            const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration()
                            if (!serviceWorkerRegistration) {
                                const error = new Error('Push messages are blocked.')
                                error.code = NOTIFICATIONS_PERMISSION_ERROR
                                error.description = PUSHES_ERRORS[error.code]
                                this.isUpdating = false
                                reject(error)
                            }

                            serviceWorkerRegistration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: this.urlBase64ToUint8Array(this.vapidKey),
                            })
                                .then(async (subscription) => {
                                    this.subscription = new ApiSubscription(subscription)
                                    await this.subscriptionCreateSuccessHandler(this.subscription)
                                    this.isUpdating = false
                                    resolve()
                                })
                                .catch(error => {
                                    this.isUpdating = false
                                    this.subscription = null
                                    reject(this.subscriptionCreateErrorHandler(error))
                                })
                        })
                        .catch(error => {
                            this.isUpdating = false
                            reject(error)
                        })
                })
            },

            async subscriptionCreateSuccessHandler(subscription) {
                return Vue.prototype.$pushes.api.create.call({data: subscription.apiData})
                    .then(([data, ]) => {
                        subscription.id = data.id
                })
            },

            subscriptionCreateErrorHandler(error) {
                if (Notification.permission === 'denied') {
                    error = new Error('Notifications are denied by the user')
                    error.code = NOTIFICATIONS_DENIED_BY_THE_USER
                    error.description = PUSHES_ERRORS[NOTIFICATIONS_DENIED_BY_THE_USER]
                    return error
                } else {
                    return error
                }
            },

            async subscriptionGetExists() {
                const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration()
                if (!serviceWorkerRegistration) {
                    return null
                }

                const subscription = await serviceWorkerRegistration.pushManager.getSubscription()
                if (!subscription) {
                    return null
                }

                const apiSubscription = new ApiSubscription(subscription)
                await Vue.prototype.$pushes.api.list.call({
                    params: {
                        key: apiSubscription.key,
                        token: apiSubscription.token,
                    }
                }).then(([subscriptions, ]) => {
                    if (subscriptions.length) {
                        apiSubscription.id = subscriptions[0].id
                    }
                })

                return apiSubscription.id ? apiSubscription : null
            },

            subscriptionRemove() {
                this.isUpdating = true

                if (!this.subscription) {
                    return
                }

                this.api.delete.call({id: this.subscription.id})

                return this.subscription.unsubscribe()
                    .then(() => this.subscription = null)
                    .finally(() => this.isUpdating = false)
            },

            /*eslint-disable */
            urlBase64ToUint8Array(base64String) {
                const padding = '='.repeat((4 - base64String.length % 4) % 4);
                const base64 = (base64String + padding)
                    .replace(/\-/g, '+')  // eslint-disable-line no-use-before-define
                    .replace(/_/g, '/')
                ;
                const rawData = window.atob(base64);
                return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
            }
            /*eslint eqeqeq:0*/
        })
    }
}

export default pushes