<template>
  <content-infinity-scroll
    :data="apiData"
    :data-filters="filters"
    ref="contentLoader"
  >
    <v-sheet
        v-if="!apiData.loading && !apiData.data.length"
        class="mt-10 text-center grey--text"
    >
      <v-icon
        color="grey lighten-1"
        size="64"
      >
        mdi-history
      </v-icon>

      <p>История пуста</p>
    </v-sheet>

    <template v-else>
      <v-list class="pa-0">
        <v-list-item
          v-for="rent in apiData.data"
          :key="rent.uid"
          :to="{name: 'cars.rents.edit', params: {carId: rent.car.id, rentId: rent.id}}"
        >
          <v-list-item-avatar>
            <v-icon>
              mdi-car
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ rent.car.name }}
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ getDates(rent) }}

              <template v-if="showUser">
                ({{ rent.user.full_name }})
              </template>
            </v-list-item-subtitle>

            <v-list-item-subtitle :class="getStateColor(rent)">
              {{ rent.is_finished ? 'Завершена' : 'Активна' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </content-infinity-scroll>
</template>

<script>
import ContentInfinityScroll from '@/components/common/content/ContentInfinityScroll'
import { ApiPaginatedAccumulatedData } from '@/assets/js/api/_helpers'
import api from '@/assets/js/api'
import { DATETIME_FORMAT } from '@/filters/datetime'


export default {
  components: {
    ContentInfinityScroll
  },

  props: {
    filters: Object,
    showUser: Boolean,
  },

  data() {
    return {
      apiData: new ApiPaginatedAccumulatedData(
          api.cars.rents.list, [], {}),
    }
  },

  methods: {
    getDates(rent) {
      const begin = this.$moment(rent.datetime_begin).format(DATETIME_FORMAT)
      const end = rent.datetime_end ? this.$moment(rent.datetime_end).format(DATETIME_FORMAT) : null
      return [`с ${begin}`, end].filter(Boolean).join(' по ')
    },

    getStateColor(rent) {
      return rent.is_finished ? 'deep-orange--text' : 'green--text text--darken-2'
    }
  }
}
</script>
