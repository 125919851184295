<script>
import {STATUS_DRAFT} from '@/assets/js/api/requests/stores/receipts_cash'
import Validations from '@/mixins/Validations'

export default {
  mixins: [Validations],

  props: {
    instance: Object,
    readonly: Boolean,
  },

  data() {
    return {
      copier: undefined, // apiData
      creator: undefined, // apiData
      formData: {},
      snackbar: {
        color: 'success',
        show: false,
        text: '',
      }
    }
  },

  computed: {
    $_readOnly() {
       return this.readonly || this.creator.loading || (this.copier && this.copier.loading)
    },

    apiConfig() {
      return {
        id: this.instance && this.instance.id,
        data: this.apiFormData(this.formData)
      }
    },

    canEdit() {
      return false
    },

    isCoping() {
      return Boolean(this.copier && this.copier.loading)
    },

    isCreating() {
      return Boolean(this.creator && this.creator.loading && !this.instance)
    },

    isUpdating() {
      return Boolean(this.creator && this.creator.loading && this.instance)
    },
  },

  async mounted() {
    if (this.instance) {
      this.formDataCopy(this.instance)
    } else if (this.copier && this.$route.query.copy) {
      await this.copier.call({id: this.$route.query.copy}).then(() => {
        this.copyInstance()
      })
    }
  },

  watch: {
    instance() {
      if (this.instance) {
        this.formDataCopy(this.instance)
      }
    }
  },

  methods: {
    apiFormData(data) {
      return {...data}
    },

    apiSave() {
      return this.creator.call(this.apiConfig)
        .then(([data, ]) => {
          this.setStoreInstance(data)
          this.onSaved(data)
          this.messageOnSaved(data)
        })
        .catch(error => {
          this.$notifications.showHttpError(error, 'error')
          throw error
        })
    },

    beforeSubmit() {

    },

    copyInstance() {
      this.formDataCopy(
        {
          ...this.copier.data,
          status: STATUS_DRAFT,
        }
      )
    },

    formDataCopy() {
      console.warn('Please define `formDataCopy(instance)` method')
    },

    messageOnSaved(/* instance */) {
      this.$notifications.show('Изменения сохранены')
    },

    onSaved() {},

    setStoreInstance() {
      console.warn('Please, define `setStoreInstance` method')
    },

    async submit() {
      this.creator.loading = true
      return Promise.all([
        this.beforeSubmit(),
      ]).then(async () => {
        this.$v.$touch()
        if (this.$v.$error) {
          this.creator.loading = false
        } else {
          this.apiSave()
        }
      }).catch(error => {
        this.creator.loading = false
        this.$notifications.showHttpError(error)
        throw error
      })
    },
  },
}
</script>
