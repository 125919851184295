<template>
  <v-container class="d-flex align-start align-md-center fill-height" fluid>
    <content-form
      :is-coping="isCoping"
      :is-creating="isCreating"
      :is-updating="isUpdating"
      :snackbar="snackbar"
      :value="$v.formData.$errors"
      class="py-4 py-md-16"
    >
      <template slot="body">
        <status-alert-widget
          :instance="instance"
          accepted-icon="mdi-cart-arrow-down"
        ></status-alert-widget>

        <v-divider class="my-6"></v-divider>

        <v-text-field
          v-model="formData.createdBy"
          :readonly="$_readOnly"
          class="mx-3"
          label="Пользователь"
          outlined
        >
        </v-text-field>

        <v-select
          v-model="formData.account"
          :items="account ? [account] : []"
          :readonly="$_readOnly"
          class="mx-3"
          item-text="name"
          item-value="id"
          label="Счет"
          outlined
        ></v-select>

        <v-row class="ma-0 flex-wrap flex-md-nowrap">
          <v-col class="py-0 flex-fill">
            <v-autocomplete
              v-model="formData.objectString"
              :items="formData.objectString ? [formData.objectString] : []"
              :readonly="$_readOnly"
              label="Объект"
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col class="py-0 flex-fill">
            <v-combobox
              v-model="formData.typeString"
              :items="formData.typeString ? [formData.typeString] : []"
              :readonly="$_readOnly"
              item-text="name"
              item-value="id"
              label="Номенклатура"
              hide-no-data
              outlined
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6"
            class="py-0"
          >
            <v-text-field
              v-model="formData.sum"
              :readonly="$_readOnly"
              :suffix="formData.currencyString"
              label="Сумма"
              outlined
              v-on:input="formData.sum = asNumber($event)"
            >
            </v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            :cols="$vuetify.breakpoint.mdAndUp ? 3 : 6"
            class="py-0"
          >
            <v-date-field
              v-model="formData.purchasedAt"
              :readonly="$_readOnly"
              label="Дата покупки"
              outlined
            ></v-date-field>
          </v-col>
        </v-row>
      </template>
    </content-form>
  </v-container>
</template>

<script>
import ContentForm from '@/components/common/content/form/ContentForm'
import Form from '@/mixins/Form'
import {mapGetters, mapState} from 'vuex'
import VDateField from '@/components/inputs/VDateField'
import {DATE_FORMAT_ISO} from '@/filters/datetime'
import StatusAlertWidget from '@/components/requests/base/widgets/StatusAlertWidget'

export default {
  components: {
    StatusAlertWidget,
    VDateField,
    ContentForm
  },

  mixins: [Form],

  data() {
    return {
      formData: {
        accountFrom: null,
        accountTo: null,
        createdAt: null,
        createdBy: '',
        currencyString: '',
        recipient: '',
        sender: '',
        sum: '',
      }
    }
  },

  computed: {
    ...mapGetters('money/accounts', [
      'getAccountById'
    ]),

    ...mapState('money/accounts', {
      accounts: 'list',
      accountsLoading: 'listLoading',
    }),

    account() {
      return this.getAccountById(this.formData.account)
    },

    status() {
      return this.formData.status === 'На утверждении' ? 'waiting' : 'approved'
    }
  },

  methods: {
    formDataCopy(instance) {
      this.formData = {
        account: instance.account_id,
        purchasedAt: this.$moment(instance.purchased_at).format(DATE_FORMAT_ISO),
        createdBy: instance.created_by,
        currencyString: instance.currency_string,
        objectString: instance.object_string,
        typeString: instance.type_string,
        status: instance.status,
        sum: instance.sum,
      }
    },
  },
}
</script>