import { request } from '@/assets/js/api/_helpers'

export const CURRENCY_ID__RUBBLES = 643


const ENDPOINT_ACCOUNTS = '/api/money/accounts'
const ENDPOINT_CURRENCIES = '/api/money/currencies'


export default {
    accounts: {
        balance: ({ id, ...config }) => request('GET', `${ENDPOINT_ACCOUNTS}/${id}/balance`, config),
        list: () => request('GET', ENDPOINT_ACCOUNTS),
    },

    currencies: {
        list: () => request('GET', ENDPOINT_CURRENCIES)
    }
}